import React, { FormEvent, useEffect, useRef, useState } from "react";

import { Form, Formik, FormikHelpers, FormikProps } from "formik";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { usePermissions } from "../../../PermissionsProvider";
import { evidence } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import NotesContainer from "../../../components/Notes/NotesContainer";
import Popup from "../../../components/Popup";
import ProfileActivityList from "../../../components/ProfileActivityList";
import Spinner from "../../../components/Spinner";
import { messages } from "../../../constants/messages";
import useComplexValidation from "../../../hook/useComplexValidation";
import useHandleError from "../../../hook/useHandleError";
import { Member, MemberFormType, Note, Roles } from "../../../types";
import { memberValidationSchema } from "../NewMember/NewMember";
import { MemberFormSelection } from "../components/MemberFormSelection";
import {
  fillMember,
  getMemberFormType,
  memberFormTypeValidation,
  newMember,
  ValidationError,
} from "../util/member-util";

type UpdateMemberParams = {
  code: string;
};

export default function UpdateMember() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const location = useLocation();
  const [member, setMember] = useState<Member>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const memberTypeCheckboxRef = useRef<Record<string, boolean>>({});
  const [formIds, setFormIds] = useState<string[]>([]);
  const validationSchema = useComplexValidation("member.create-form", formIds, memberValidationSchema);

  const [open, setOpen] = useState(false);

  const { code } = useParams<UpdateMemberParams>();

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    if (code) {
      fetch(evidence.getMemberByCode(code))
        .then(handleError)
        .then((res) => {
          setMember(fillMember(res));
          const memberTypes = res?.memberTypes.map((memberType: any) => `ASSOCIATION_MEMBER_${memberType}`)!;
          setFormIds(memberTypes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [code, handleError, location, navigate]);

  const handleExitButtonClick = () => {
    if (location.state) {
      navigate(routes.RECORDS_URL);
    } else {
      navigate(routes.VIEW_MEMBER_URL(member?.code));
    }
  };

  const handleOnChange = (event: FormEvent) => {
    let formIdList: string[] = [];
    const value = (event.target as HTMLInputElement).value;
    const checked = (event.target as HTMLInputElement).checked;

    if (!Roles.includes(value)) {
      return;
    }

    memberTypeCheckboxRef.current[value] = checked;

    Object.keys(memberTypeCheckboxRef.current).forEach((key: string) => {
      if (memberTypeCheckboxRef.current[key]) {
        formIdList.push(`ASSOCIATION_MEMBER_${key}`);
      }
    });

    if (formIdList.length > 0) {
      setFormIds(formIdList);
    }
  };

  const onSubmit = (values: MemberFormType, formikHelpers: FormikHelpers<MemberFormType>) => {
    setIsSubmitLoading(true);
    if (code) {
      fetch(evidence.getAssociationMemberRoute(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newMember(values, code)),
      })
        .then(handleError)
        .then((res) => {
          setIsSubmitLoading(false);
          if (res.status === 200) {
            navigate(routes.VIEW_MEMBER_URL(code));
          }
        })
        .catch((err) => {
          setIsSubmitLoading(false);
          err.json().then((err: any) => {
            const errorList: ValidationError[] = err;
            const touchedMap = errorList.reduce((all, { field }) => ({ ...all, [field]: true }), {});
            const errorMap = errorList.reduce((all, { field, code }) => ({ ...all, [field]: code }), {});
            formikHelpers.setTouched(touchedMap);
            formikHelpers.setErrors(errorMap);
          });
        });
    }
  };

  if (member) {
    return (
      <>
        <Formik
          validate={memberFormTypeValidation}
          validationSchema={validationSchema}
          initialValues={getMemberFormType(member)}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, dirty }: FormikProps<MemberFormType>) => (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
              <Form onChange={handleOnChange} className="grid col-span-2">
                <div className="flex flex-col gap-4">
                  <div className="flex w-full sticky top-0 z-20">
                    <Header
                      title="Uredi profil"
                      primaryButtonText="Spremi"
                      secondaryButtonText="Odustani"
                      primaryButtonIcon="check"
                      secondaryButtonIcon="x"
                      tertiaryButtonText="Bilješke"
                      tertiaryButtonIcon="pencil-alt"
                      onPrimaryClick={handleSubmit}
                      onSecondaryClick={(e: any) => {
                        e.preventDefault();
                        !dirty ? handleExitButtonClick() : setIsOpen(true);
                      }}
                      onTertiaryClick={() => setOpen(true)}
                      showBackButton
                      onBackButtonClick={handleExitButtonClick}
                      isPrimaryLoading={isSubmitLoading}
                    />
                  </div>
                  <div className="w-full gap-8">
                    <MemberFormSelection />
                    {member.activities && <ProfileActivityList activities={member.activities} />}
                    <Attachments
                      attachments={member.attachments}
                      showDragZone={permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DELETE")}
                      uploadUrl={evidence.saveAttachment(member.code)}
                      downloadUrl={evidence.getAttachments}
                      deleteUrl={evidence.deleteAttachments}
                    />
                  </div>
                </div>
              </Form>
              <div className="grid col-span-1 mt-28">
                {permissions?.includes("ASSOCIATION_MEMBER_NOTES_VIEW") && (
                  <NotesContainer
                    module="ASSOCIATION_MEMBER"
                    open={open}
                    setOpen={stateHandler}
                    addNoteUrl={evidence.addNote}
                    getObjectContainingNotesUrl={evidence.getMemberByCode}
                    notes={member?.notes as Note[]}
                    id={member.code}
                    showNoteForm={permissions?.includes("ASSOCIATION_MEMBER_CRUD_OWN_NOTE")}
                    updateNoteUrl={evidence.updateNote}
                    deleteNoteUrl={evidence.deleteNote}
                  />
                )}
              </div>
            </div>
          )}
        </Formik>
        <Popup
          title={messages.cancelPrompt}
          content={messages.updateWarning}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleExitButtonClick}
        />
      </>
    );
  } else {
    return <Spinner />;
  }
}
