import React from "react";

import { Link } from "react-router-dom";

import { Button } from "@tiller/core";

import { Icon } from "@tiller/icons";

import { routes } from "../../../api/paths";
import logo from "../../../assets/img/altruistLogo.svg";
import { scrollToSection } from "../util/scrollToSection";

export default function LandingPageTopNavigation() {
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  React.useEffect(() => {
    if (isOpen && window.innerWidth < 641) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  return (
    <nav
      className={`px-4 xl:px-24 2xl:px-60 py-4 sticky top-0 z-20 bg-white ${isScrolled && "shadow"} ${
        isOpen && "h-screen"
      }`}
    >
      <DesktopNavigation />
      <MobileNavigation isScrolled={isScrolled} isOpen={isOpen} setIsOpen={setIsOpen} />
    </nav>
  );
}

type NavItemsProps = {
  isMobile: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

type NavItem = {
  code: string;
  label: string;
};

function NavItems({ isMobile, setIsOpen }: NavItemsProps) {
  const items: NavItem[] = [
    { code: "video", label: "Video" },
    { code: "values", label: "Vrijednosti" },
    { code: "features", label: "Funkcionalnosti" },
    { code: "team", label: "Altruist tim" },
    { code: "contact-form", label: "Kontakt" },
  ];

  return (
    <div className="flex flex-col content-end lg:flex-row items-center space-y-2 lg:space-y-0 md:space-x-2 2xl:space-x-4">
      {items.map((item: NavItem) => (
        <Link
          key={item.code}
          to="/"
          className="nav-item px-2 py-2 xl:px-4 2xl:px-6"
          id={isMobile ? `nav-${item.code}-mobile` : `nav-${item.code}`}
          onClick={() => {
            setIsOpen?.(false);
            scrollToSection(item.code, isMobile);
          }}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
}

function DesktopNavigation() {
  return (
    <div className="hidden lg:flex justify-between items-center" id="header">
      <div className="flex flex-col items-end">
        <img
          src={logo}
          alt="logo"
          className="w-48 cursor-pointer"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
        <p className="text-sm -mt-1">
          Powered by <span className="font-bold">CROZ</span>
        </p>
      </div>
      <NavItems isMobile={false} />
      <div className="flex space-x-2 2xl:space-x-4">
        <Link
          to={routes.USER_LOGIN_URL}
          target="_blank"
          className="text-indigo-600 font-medium nav-item px-2 py-4 md:py-2 xl:px-4 2xl:px-6"
        >
          Prijava
        </Link>
        <Link to={routes.ADMIN_REGISTRATION_URL} target="_blank">
          <Button variant="outlined" className="rounded-full font-medium" color="indigo" size="lg">
            Registracija
          </Button>
        </Link>
      </div>
    </div>
  );
}

type MobileNavigationProps = {
  isScrolled: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

function MobileNavigation({ isScrolled, isOpen, setIsOpen }: MobileNavigationProps) {
  return (
    <div className={`flex flex-col lg:hidden ${isOpen ? "mobile-height" : "h-full"}`}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-end">
          <img
            src={logo}
            alt="logo"
            className="w-40 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
          <p className="text-sm -mt-1">
            Powered by <span className="font-bold">CROZ</span>
          </p>
        </div>
        <Icon type={isOpen ? "x" : "menu"} onClick={() => setIsOpen(!isOpen)} />
      </div>
      {isScrolled && !isOpen && (
        <Button
          variant="filled"
          color="indigo"
          className="mt-4 -mb-2 w-full rounded-full"
          onClick={() => window.open(routes.ADMIN_REGISTRATION_URL, "_blank")}
        >
          Registracija
        </Button>
      )}
      <div className={`flex flex-col h-full justify-between items-center mt-8 ${isOpen ? "block" : "hidden"}`}>
        <NavItems isMobile={true} setIsOpen={setIsOpen} />
        <div className="flex flex-col w-full">
          <hr className="border mb-2" />
          <Button
            variant="filled"
            color="indigo"
            className="rounded-full mb-2"
            onClick={() => window.open(routes.ADMIN_REGISTRATION_URL, "_blank")}
          >
            Registracija
          </Button>
          <Button
            variant="outlined"
            color="indigo"
            className="rounded-full"
            onClick={() => window.open(routes.USER_LOGIN_URL, "_blank")}
          >
            Prijava
          </Button>
        </div>
      </div>
    </div>
  );
}
