import React, { useEffect, useState } from "react";

import { parse } from "date-fns";
import { Form, FormikHelpers, FormikProps } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { FormContainer } from "@tiller/formik-elements";

import { usePermissions } from "../../../PermissionsProvider";
import { activities, dynamicField, evidence, user } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import useComplexValidation from "../../../hook/useComplexValidation";
import useHandleError from "../../../hook/useHandleError";
import { ActivityFormType, ActivityMemberAttendance, dynamicFieldType, User } from "../../../types";
import { serializeParameterSuffix } from "../../../util/GeneralUtil";
import { ActivityFormSelection, ActivityTypeValues, PERSONAL_TASK } from "../components/ActivityFormSelection";
import AddParticipants from "../components/AddParticipants";
import { activityFormTypeValidation, newActivity } from "../util/activity-util";

export type ActivityType = {
  id?: number;
  title: string;
  activityType: ActivityTypeValues;
  projectTitle?: string;
  description: string;
  startDate: Date | string | null;
  endDate?: string | null;
  persons: ActivityMemberAttendance[];
  designatedPersons: User[];
  author: string;
  taskDuration: string;
  attachmentUuids: string[];
  numberOfParticipants: number;
  dynamicData: object;
};

let initialValues = {
  id: null,
  activityType: PERSONAL_TASK,
  author: localStorage.getItem("name") ?? "",
  projectTitle: "",
  title: "",
  description: "",
  dateOfCreation: "",
  timeOfCreation: "",
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  taskDuration: "",
  dateOfUpdate: "",
  tags: [],
  persons: [],
  designatedPersons: undefined,
  files: [],
  notes: [],
  attachmentUuids: [],
  attachments: [],
  numberOfParticipants: undefined,
  dynamicData: {},
} as ActivityFormType;

export const activityValidationSchema = Yup.object({
  projectTitle: Yup.string().when("activityType", {
    is: (activityType: string) => activityType !== "PERSONAL_TASK",
    then: Yup.string().required(messages.requiredField),
    otherwise: Yup.string(),
  }),
  title: Yup.string().nullable(),
  startDate: Yup.date()
    .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
    .typeError(messages.invalidDate)
    .nullable(),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), messages.endDateBeforeStartDate)
    .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
    .typeError(messages.invalidDate)
    .nullable(),
});

export default function NewActivity() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const location = useLocation();

  const [persons, setPersons] = useState<ActivityMemberAttendance[]>();
  const [designatedPersons, setDesignatedPersons] = useState<User[]>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [areParticipantsOpen, setAreParticipantsOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const validationSchema = useComplexValidation("activity.create-form", "ACTIVITY", activityValidationSchema);

  useEffect(() => {
    fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: ["ACTIVITY"] })))
      .then(handleError)
      .then((res) => {
        const newData: Record<string, object | string | boolean | undefined> = {};
        res.forEach((field: dynamicFieldType) => {
          if (field.fieldType === "TOGGLE") {
            newData[field.code] = false;
          } else if (field.fieldType === "CHECKBOX") {
            newData[field.code] = field.dynamicFieldDataset.reduce(
              (acc: Object, value: string) => ({ ...acc, [value]: false }),
              {}
            );
          } else if (field.fieldType === "DATE" || field.fieldType === "DATE_TIME") {
            newData[field.code] = undefined;
          } else {
            newData[field.code] = "";
          }
        });
        initialValues.dynamicData = newData;
      });
  }, [handleError, location, navigate]);

  useEffect(() => {
    fetch(evidence.getAllWithoutPagination())
      .then(handleError)
      .then((data) => setPersons(data))
      .catch((err) => console.log(err));

    fetch(user.getAll(false))
      .then(handleError)
      .then((data) => setDesignatedPersons(data))
      .catch((err) => console.log(err));
  }, [handleError, location, navigate]);

  const onSubmit = (values: ActivityFormType, formikHelpers: FormikHelpers<ActivityFormType>) => {
    setIsSubmitLoading(true);
    setAreParticipantsOpen(false);
    fetch(activities.root(), {
      method: "POST",
      body: JSON.stringify(newActivity(values)),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((data) => {
        navigate(routes.VIEW_ACTIVITY_URL(data.id));
        setIsSubmitLoading(false);
      })
      .catch((err) => console.log(err));

    formikHelpers.resetForm();
  };

  return (
    <FormContainer
      validate={activityFormTypeValidation}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, dirty }: FormikProps<ActivityFormType>) => (
        <Form>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
              <div className="col-span-3 xl:col-span-2">
                <Header
                  title="Kreiraj novi zadatak"
                  primaryButtonText="Spremi"
                  primaryButtonIcon="check"
                  onPrimaryClick={handleSubmit}
                  secondaryButtonText="Odustani"
                  secondaryButtonIcon="x"
                  showBackButton
                  onBackButtonClick={() => navigate(routes.ACTIVITIES_URL)}
                  onSecondaryClick={() => (!dirty ? navigate(routes.ACTIVITIES_URL) : setIsOpen(true))}
                  isPrimaryLoading={isSubmitLoading}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
                <div className="col-span-3 xl:col-span-2">
                  <ActivityFormSelection designatedPersons={designatedPersons} activityType={values.activityType} />
                  <AddParticipants
                    isOpen={areParticipantsOpen}
                    setIsOpen={setAreParticipantsOpen}
                    participants={persons}
                  />
                  <Attachments
                    attachments={[]}
                    uploadUrl={activities.uploadAttachmentWoActivity}
                    downloadUrl={activities.getAttachments}
                    deleteUrl={activities.deleteAttachments}
                    showDragZone={permissions?.includes("ACTIVITY_ATTACHMENTS_ADD")}
                    hideDownloadButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DOWNLOAD")}
                    hideDeleteButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DELETE")}
                  />
                  <Popup
                    title={messages.cancelPrompt}
                    content={messages.createWarning}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    onSubmit={() => navigate(routes.ACTIVITIES_URL)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </FormContainer>
  );
}
