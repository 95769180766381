import React, { useState } from "react";

import { Form, FormikHelpers, FormikProps } from "formik";

import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "@tiller/core";
import { FormContainer, InputField, PasswordInputField } from "@tiller/formik-elements";
import { LoadingIcon } from "@tiller/icons";

import { useAuth } from "../../AuthProvider";
import { routes } from "../../api/paths";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";

const signInSchema = Yup.object().shape({
  username: Yup.string().required(messages.requiredField),
  password: Yup.string().required(messages.requiredField),
});

type LoginDetails = {
  username: string;
  password: string;
};

export default function UserLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();

  const initialValues: LoginDetails = {
    username: "",
    password: "",
  };

  const onSubmit = (values: LoginDetails, formikHelpers: FormikHelpers<LoginDetails>) => {
    setIsLoading(true);
    login(
      true,
      values.username,
      values.password,
      () => {
        formikHelpers.resetForm();
        const navigateTo = searchParams.get("return");
        navigate(navigateTo ? navigateTo.toString() : routes.RECORDS_URL);
        setIsLoading(false);
      },
      (err: Error | any) => {
        if (err instanceof Error) {
          if (
            err.name === "NotAuthorizedException" ||
            err.message === "Invalid username" ||
            err.name === "UserNotFoundException"
          ) {
            setLoginError(messages.loginError);
          } else {
            setLoginError(messages.otherLoginError + err.name);
          }
        } else {
          err.json().then((data: any) => {
            if (data.message === "No association") {
              setLoginError(messages.noAssociation);
            } else {
              setLoginError(messages.unexpectedLoginError);
            }
          });
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <AuthenticationContainer
      title="prijava"
      showAdditionalCard
      additionalText="Registracija udruge"
      showIcon
      additionalCardRoute={routes.ADMIN_REGISTRATION_URL}
    >
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={signInSchema}
        validateAfterSubmit
      >
        {({ handleSubmit }: FormikProps<LoginDetails>) => (
          <Form onSubmit={handleSubmit}>
            <p className="text-red-500 text-sm mb-2 mt-0">{loginError}</p>
            <p className="mb-4 md:mb-6 text-center text-sm">{messages.login}</p>
            <InputField name="username" label="Korisničko ime" className="mb-4" required={true} />
            <PasswordInputField
              name="password"
              label="Lozinka"
              className="mb-2"
              required={true}
              help={<p>{messages.capsLockOn}</p>}
            />
            <p
              className="mb-6 text-sm hover:underline cursor-pointer"
              onClick={() => navigate(routes.FORGOTTEN_PASSWORD_URL)}
            >
              Zaboravili ste lozinku?
            </p>
            <Button color="primary" className="w-full mb-4" disabled={isLoading}>
              {isLoading ? <LoadingIcon size={5} /> : "Prijavi se"}
            </Button>
          </Form>
        )}
      </FormContainer>
    </AuthenticationContainer>
  );
}
