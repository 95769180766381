import React, { useState } from "react";

import { format } from "date-fns";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { Card, IconButton } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";
import { Icon } from "@tiller/icons";

import { usePermissions } from "../../../PermissionsProvider";
import { competitions } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import { FavoritesCompetitionContext } from "../../../context/FavoritesCompetitionContext";
import useHandleError from "../../../hook/useHandleError";
import { CompetitionFilterValues, GetCompetitionsValues } from "../../../types";
import { dateClass } from "../../ViewCompetition/ViewCompetition";

type CompetitionCardProps = {
  id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  area: string;
  urlToSite: string;
  sourceName: string;
  applied: boolean;
  favorite: boolean;
  custom: boolean;
  changeFavorite: (
    id: string,
    isFavorite: boolean,
    values: GetCompetitionsValues,
    filterValues: CompetitionFilterValues
  ) => void;
  favoriteFilterValues: CompetitionFilterValues;
};

export default function CompetitionCard({
  id,
  name,
  dateFrom,
  dateTo,
  area,
  urlToSite,
  sourceName,
  applied,
  favorite,
  custom,
  changeFavorite,
  favoriteFilterValues,
}: CompetitionCardProps) {
  const competitionContext = React.useContext(FavoritesCompetitionContext);
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const location = useLocation();
  const [isApplied] = useState<boolean>(applied);
  const [isOpen, setIsOpen] = useState(false);

  const deleteCompetition = (id: string) => {
    fetch(competitions.delete(id), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => navigate(routes.COMPETITIONS_URL))
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Card className="mt-4 p-0">
        <Card.Header>
          <Card.Header.Title>
            <div className="flex">
              <>
                {permissions?.includes("COMPETITION_SET_AND_VIEW_FAVORITE") && (
                  <IconButton
                    type="star"
                    className="mr-4 mt-0.5"
                    {...(favorite
                      ? { variant: "solid", label: "Makni iz favorita", color: "yellow" }
                      : { variant: "outline", label: "Dodaj u favorite", color: "primary" })}
                    onClick={() => {
                      changeFavorite(
                        id,
                        !favorite,
                        {
                          pageNumber: competitionContext.pageNumber ?? 0,
                          pageSize: competitionContext.pageSize ?? 5,
                          keyword: competitionContext.keyword ?? "",
                        },
                        favoriteFilterValues
                      );
                    }}
                  />
                )}
                <Link to={routes.VIEW_COMPETITION_URL(id)} className="text-indigo-900">
                  {name}
                </Link>
              </>
            </div>
          </Card.Header.Title>
          <Card.Header.Actions>
            <div className="flex items-center space-x-1">
              {isApplied && permissions?.includes("COMPETITION_SET_AND_VIEW_APPLIED") && (
                <span className="flex items-center text-green-500 mr-1">
                  <Icon type="check" className="mr-1" />
                  <p className="mr-4 text-sm">Prijavljen</p>
                </span>
              )}
              <IconButton
                type="zoom-in"
                variant="outline"
                label="Pregledaj"
                color="primary"
                onClick={() => navigate(routes.VIEW_COMPETITION_URL(id))}
              />
              {custom && permissions?.includes("COMPETITION_CREATE_UPDATE") && (
                <Link to={routes.UPDATE_COMPETITION_URL(id)} state={{ routeFrom: location.pathname }} className="ml-3">
                  <IconButton type="pencil" label="Uredi" color="primary" />
                </Link>
              )}
            </div>
          </Card.Header.Actions>
        </Card.Header>
        <DescriptionList>
          <DescriptionList.Item label="Izvor natječaja">
            {sourceName && sourceName !== "" ? sourceName : "Nije poznato"}
          </DescriptionList.Item>
          <DescriptionList.Item label="Trajanje natječaja">
            <p className={dateClass(dateTo)}>
              {dateFrom ? format(new Date(dateFrom), "dd. MM. yyyy.") : "Nije poznato"} -
              {dateTo ? format(new Date(dateTo), " dd. MM. yyyy.") : " Nije poznato"}
            </p>
          </DescriptionList.Item>
          <DescriptionList.Item label="Područje provedbe">
            {area && area !== "" ? area : "Nije poznato"}
          </DescriptionList.Item>
          <DescriptionList.Item label="Link na natječaj">
            {urlToSite && urlToSite !== "" ? (
              <div className="flex items-center">
                <Icon type="globe-alt" className="mr-1" />
                <a href={urlToSite} className="hover:underline" target="_blank" rel="noreferrer">
                  Klikni ovdje
                </a>
              </div>
            ) : (
              "Nije poznato"
            )}
          </DescriptionList.Item>
        </DescriptionList>
      </Card>
      <Popup
        title={messages.competitionDeletePrompt}
        content={messages.deleteWarning}
        primaryButtonColor="red"
        primaryButtonText="Da, izbriši."
        onSubmit={() => deleteCompetition(id)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}
