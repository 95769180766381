import React, { useEffect, useState } from "react";

import { LoadingIcon } from "@tiller/icons";

type SpinnerProps = {
  marginTop?: string;
};

export default function Spinner({ marginTop = "" }: SpinnerProps) {
  const [hasTimePassed, setHasTimePassed] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setHasTimePassed(true), 500);
  }, []);

  return (
    <>
      {hasTimePassed && (
        <div className={`grid h-screen place-items-center ${marginTop}`}>
          <LoadingIcon size={20} className="text-indigo-900" />
        </div>
      )}
    </>
  );
}
