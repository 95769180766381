import type { Location } from "@remix-run/router";
import { parse } from "date-fns";
import { NavigateFunction } from "react-router-dom";
import * as Yup from "yup";
import { AnySchema } from "yup";

import { serializeParameterSuffix } from "./GeneralUtil";
import { dynamicField } from "../api/backend_paths";
import { messages } from "../constants/messages";
import {
  convertDateForDatabase,
  convertDateForDisplay,
  convertDateTimeForDisplay,
} from "../pages/MemberForm/util/member-util";
import { DynamicField } from "../types";

const oib = require("oib.js");

export const getDynamicValidationSchema =
  (navigate: NavigateFunction, location: Location, handleError: (response: Response) => Promise<any>) =>
  (formIds: string[]) => {
    return new Promise<AnySchema>((resolve, reject) => {
      fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: formIds.join(",") })))
        .then(handleError)
        .then((data) => {
          let dynamicValidation = Yup.object();
          data.forEach((field: DynamicField) => {
            if (field.fieldType === "EMAIL") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.string()
                  .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, messages.invalidEmailFormat)
                  .concat(field.required ? Yup.string().required(messages.requiredField) : Yup.string()),
              });
            } else if (field.fieldType === "PHONE") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.string()
                  .matches(/^[\d\s\-/+()]+$/, messages.invalidPhoneNumberCharacters)
                  .concat(field.required ? Yup.string().required(messages.requiredField) : Yup.string()),
              });
            } else if (field.fieldType === "DATE") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.date()
                  .concat(field.required ? Yup.date().required(messages.requiredField) : Yup.date())
                  .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
                  .typeError(messages.invalidDate)
                  .nullable(),
              });
            } else if (field.fieldType === "DATE_TIME") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.date()
                  .concat(field.required ? Yup.date().required(messages.requiredField) : Yup.date())
                  .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy. HH:mm", new Date()))
                  .typeError(messages.invalidDate)
                  .nullable(),
              });
            } else if (field.fieldType === "OIB") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.string()
                  .test("oibValidation", messages.invalidOIBFormat, (value) => {
                    return value === undefined || oib.validate(value);
                  })
                  .concat(field.required ? Yup.string().required(messages.requiredField) : Yup.string()),
              });
            } else if (field.required && field.fieldType === "CHECKBOX") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.object().test("valid-checkbox-group", messages.requiredField, (value) =>
                  Object.values(value).some((v) => v)
                ),
              });
            } else if (field.required && field.fieldType === "DROPDOWN") {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.mixed().required(messages.requiredField),
              });
            } else if (field.required) {
              dynamicValidation = dynamicValidation.shape({
                [field.code]: Yup.string().required(messages.requiredField),
              });
            }
          });
          resolve(dynamicValidation);
        })
        .catch((err) => reject(err));
    });
  };

function convertDateTimeForDatabase(typedDateTime: string) {
  const inputFormat = "dd. MM. yyyy. HH:mm";

  const parsedDate = parse(typedDateTime, inputFormat, new Date());
  const utcDate = new Date(
    Date.UTC(
      parsedDate.getFullYear(),
      parsedDate.getMonth(),
      parsedDate.getDate(),
      parsedDate.getHours(),
      parsedDate.getMinutes()
    )
  );

  return utcDate.toISOString();
}

export function convertFieldsForDatabase(data: Record<string, string>) {
  const formattedDynamicData: Record<string, string> = {};
  for (let key in data) {
    if (/^\d{2}. \d{2}. \d{4}.$/.test(data[key])) {
      formattedDynamicData[key] = convertDateForDatabase(data[key]);
    } else if (/^\d{2}. \d{2}. \d{4}. \d{2}:\d{2}$/.test(data[key])) {
      formattedDynamicData[key] = convertDateTimeForDatabase(data[key]);
    } else {
      formattedDynamicData[key] = data[key];
    }
  }
  return formattedDynamicData;
}

export function convertFieldsForDisplay(data: Record<string, string>) {
  let formattedDynamicData: Record<string, string | undefined> = {};
  for (let key in data) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(data[key])) {
      formattedDynamicData[key] = convertDateForDisplay(data[key]);
    } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:00.000Z$/.test(data[key])) {
      formattedDynamicData[key] = convertDateTimeForDisplay(data[key]);
    } else {
      formattedDynamicData[key] = data[key];
    }
  }
  return formattedDynamicData;
}

export function formatDropdownDynamicField(value: string | string[] | undefined) {
  if (!value || value.length === 0) {
    return "-";
  }

  if (typeof value === "string") {
    return value;
  }

  return value.join(", ");
}
