import React, { useEffect, useState } from "react";

import { Form, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { FormContainer } from "@tiller/formik-elements";

import { permission, role } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Header from "../../../components/Header";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { UserRoleFormType } from "../../../types";
import RoleForm from "../components/RoleForm";
import { convertPermissionsToArray } from "../util/roles-util";

const newRoleSchema = Yup.object().shape({
  name: Yup.string().required(messages.requiredField),
  permissions: Yup.object().test("valid-checkbox-group", messages.permissionRequiredField, (value) =>
    Object.values(value).some((v) => v)
  ),
});

const initialValues = {
  id: null,
  name: "",
  permissions: {},
  mutable: true,
} as UserRoleFormType;

export default function NewRole() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allPermissions, setAllPermissions] = useState<string[]>([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const { handleError } = useHandleError();
  const navigate = useNavigate();

  const handleSubmit = (values: UserRoleFormType) => {
    setIsSubmitLoading(true);
    const selectedPermissions = convertPermissionsToArray(values.permissions);
    fetch(role.saveRole(), {
      method: "POST",
      body: JSON.stringify({ ...values, permissions: selectedPermissions }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        navigate(routes.ADMIN_URL);
        setIsSubmitLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch(permission.getAllPermissions())
      .then(handleError)
      .then((data) => {
        setAllPermissions(data);
      });
  }, [handleError]);

  return (
    <FormContainer initialValues={initialValues} validationSchema={newRoleSchema} onSubmit={handleSubmit}>
      {({ dirty, handleSubmit }: FormikProps<UserRoleFormType>) => (
        <Form>
          <Header
            title="kreiraj rolu"
            primaryButtonText="Spremi"
            primaryButtonIcon="check"
            secondaryButtonText="Odustani"
            secondaryButtonIcon="x"
            onPrimaryClick={handleSubmit}
            onSecondaryClick={() => (!dirty ? navigate(routes.ADMIN_URL) : setIsOpen(true))}
            showBackButton
            onBackButtonClick={() => navigate(routes.ADMIN_URL)}
            isPrimaryLoading={isSubmitLoading}
          />
          <RoleForm permissions={allPermissions} />
          <Popup
            title={messages.cancelPrompt}
            content={messages.createWarning}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSubmit={() => navigate(routes.ADMIN_URL)}
          />
        </Form>
      )}
    </FormContainer>
  );
}
