import React, { useRef, useState } from "react";

import Popover, { positionMatchWidth } from "@reach/popover";

import { Button, IconButton } from "@tiller/core";
import { Input } from "@tiller/form-elements";
import { Icon } from "@tiller/icons";

import { optionContainerClassName, optionItemClassName } from "../pages/ActivityForm/components/DataListField";
import { SortItem } from "../types";

type SearchProps = {
  placeholder: string;
  showSort?: boolean;
  sortOptions?: SortItem[];
  showFilter?: boolean;
  onSubmit: (searchTerm: string) => void;
  onSortClick?: (sortType: string) => void;
  onFilterClick?: () => void;
};

export default function Search({
  placeholder,
  showSort = true,
  sortOptions,
  showFilter = true,
  onSubmit,
  onSortClick,
  onFilterClick,
}: SearchProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [delaySubmit, setDelaySubmit] = useState<NodeJS.Timeout>();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [sortType, setSortType] = useState("");

  const sortRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex flex-col sm:flex-row sm:justify-between gap-4 mt-4">
      <Input
        name="search"
        className="w-full sm:w-1/2 lg:w-1/4"
        inlineTrailingIcon={
          <IconButton
            type={searchTerm ? "x" : "search"}
            label={searchTerm ? "Izbriši" : "Pretraži"}
            onClick={() => {
              setSearchTerm("");
              onSubmit("");
            }}
          />
        }
        value={searchTerm}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(searchTerm);
            clearTimeout(delaySubmit);
          }
        }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          clearTimeout(delaySubmit);
          setDelaySubmit(setTimeout(() => onSubmit(e.target.value), 500));
        }}
      />
      <div className="flex flex-row gap-4">
        {showSort && (
          <>
            <div
              ref={sortRef}
              onBlur={() => {
                if (isHovered) return;
                setIsOpen(false);
              }}
            >
              <Button
                leadingIcon={isOpen ? <Icon type="sort-ascending" /> : <Icon type="sort-descending" />}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="w-full"
              >
                {sortType ? sortType : "Poredaj po"}
              </Button>
            </div>
            {isOpen && sortRef && (
              <Popover className="z-50" targetRef={sortRef} position={positionMatchWidth}>
                <div className={optionContainerClassName}>
                  {sortOptions?.map((item: SortItem, index) => (
                    <p
                      key={index}
                      className={optionItemClassName}
                      onClick={() => {
                        onSortClick?.(item.code);
                        setSortType(item.label);
                        setIsOpen(false);
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>
              </Popover>
            )}
          </>
        )}
        {showFilter && (
          <Button leadingIcon={<Icon type="filter" />} variant="outlined" color="primary" onClick={onFilterClick}>
            Filter
          </Button>
        )}
      </div>
    </div>
  );
}
