import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "@tiller/core";

import { routes } from "../../api/paths";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="ml-4">
      <h1 className="text-indigo-900 font-bold text-9xl mt-12 mb-6">404</h1>
      <h1 className="font-bold text-4xl mb-1">Stranica nije pronađena.</h1>
      <p className="mb-4 font-semibold">
        Stranica je možda uklonjena, promijenjeno joj je ime ili je privremeno nedostupna.
      </p>
      {!localStorage.getItem("username") && (
        <Button onClick={() => navigate(routes.USER_LOGIN_URL)}>Prijava u aplikaciju</Button>
      )}
    </div>
  );
}
