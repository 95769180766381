import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { Card, Tabs } from "@tiller/core";
import { RadioGroupField, SelectField } from "@tiller/formik-elements";

import AssociationTab from "./components/AssociationTab";
import DataImport from "./components/DataImport";

import DynamicFields from "./components/DynamicFields";
import EmployeeList from "./components/EmployeeList";
import General from "./components/General";
import InviteForm from "./components/InviteForm";
import Roles from "./components/Roles";
import { usePermissions } from "../../PermissionsProvider";
import { association } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import Header from "../../components/Header";
import useHandleError from "../../hook/useHandleError";
import { useRolesTabIndex } from "../../hook/useRolesTabIndex";

type FormType = {
  code: string;
  label: string;
};

const selectOptions: FormType[] = [
  {
    code: "ASSOCIATION_MEMBER_MEMBER",
    label: "Član",
  },
  {
    code: "ASSOCIATION_MEMBER_USER",
    label: "Korisnik",
  },
  {
    code: "ASSOCIATION_MEMBER_VOLUNTEER",
    label: "Volonter",
  },
  {
    code: "ASSOCIATION_MEMBER_EMPLOYEE",
    label: "Zaposlenik",
  },
  {
    code: "ASSOCIATION_MEMBER_DONOR",
    label: "Donator",
  },
  {
    code: "ASSOCIATION_MEMBER_PARTNER",
    label: "Partner",
  },
  {
    code: "ASSOCIATION_MEMBER_EXTERNAL_COLLABORATOR",
    label: "Vanjski suradnik",
  },
  {
    code: "ASSOCIATION_MEMBER_OTHERS",
    label: "Ostalo",
  },
  {
    code: "ASSOCIATION_MEMBER_ALL",
    label: "Svi profili",
  },
];

type AdminProps = {
  defaultTab: number;
  setDefaultTab: (tab: number) => void;
};
export default function Admin({ defaultTab, setDefaultTab }: AdminProps) {
  const navigate = useNavigate();
  const { handleError } = useHandleError();

  const { permissions } = usePermissions();
  const rolesTabIndex = useRolesTabIndex();
  const [hasMultipleAdmins, setHasMultipleAdmins] = useState(false);

  const getAdmin = () => {
    fetch(association.admin)
      .then(handleError)
      .then((res) => setHasMultipleAdmins(res))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Header
        title="postavke"
        primaryButtonText="Kreiraj novu rolu"
        primaryButtonIcon="plus"
        hidePrimaryButton={defaultTab !== rolesTabIndex || !permissions?.includes("SETTINGS_ROLE_MANAGEMENT")}
        hideSecondaryButton
        onPrimaryClick={() => navigate(routes.NEW_ROLE_URL)}
      />
      <Tabs defaultIndex={defaultTab}>
        <Tabs.Tab
          label="Osobni račun"
          onClick={(tabIndex) => {
            getAdmin();
            setDefaultTab(tabIndex);
          }}
        >
          <General hasMultipleAdmins={hasMultipleAdmins} setHasMultipleAdmins={setHasMultipleAdmins} />
        </Tabs.Tab>
        <Tabs.Tab label="Udruga" onClick={(tabIndex) => setDefaultTab(tabIndex)}>
          <AssociationTab />
        </Tabs.Tab>
        {(permissions?.includes("SETTINGS_EMPLOYEE_LIST") ||
          permissions?.includes("SETTINGS_SEND_INVITATION_LINK")) && (
          <Tabs.Tab label="Korisnički računi" onClick={(tabIndex) => setDefaultTab(tabIndex)}>
            {permissions?.includes("SETTINGS_SEND_INVITATION_LINK") && <InviteForm />}
            {permissions?.includes("SETTINGS_EMPLOYEE_LIST") && <EmployeeList />}
          </Tabs.Tab>
        )}
        {(permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") ||
          permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE")) && (
          <Tabs.Tab label="Personalizirana polja" onClick={(tabIndex) => setDefaultTab(tabIndex)}>
            <Formik initialValues={{ profileType: "", module: "" }} onSubmit={() => {}}>
              {({ values }: any) => (
                <>
                  <Form>
                    <Card className="flex flex-col mt-6 mb-4 p-4">
                      <RadioGroupField
                        name="module"
                        label="Odaberite modul za koji želite proširiti formu pomoću personaliziranih polja"
                      >
                        <RadioGroupField.Item label="Evidencija" value="ASSOCIATION_MEMBER" />
                        <RadioGroupField.Item label="Zadaci" value="ACTIVITY" />
                      </RadioGroupField>
                      {values.module === "ASSOCIATION_MEMBER" && (
                        <SelectField
                          name="profileType"
                          label="Vrsta profila"
                          className="w-1/4 mt-8"
                          options={selectOptions}
                          getOptionLabel={(item: FormType) => item.label}
                          hideClearButton
                          required
                        />
                      )}
                    </Card>
                  </Form>
                  {values.module === "ACTIVITY" && <DynamicFields formId="ACTIVITY" />}
                  {values.module === "ASSOCIATION_MEMBER" && values.profileType !== "" && (
                    <DynamicFields formId={values.profileType.code} />
                  )}
                </>
              )}
            </Formik>
          </Tabs.Tab>
        )}
        {permissions?.includes("SETTINGS_ROLE_MANAGEMENT") && (
          <Tabs.Tab label="Role i prava" onClick={(tabIndex) => setDefaultTab(tabIndex)}>
            <Roles />
          </Tabs.Tab>
        )}

        {permissions?.includes("SETTINGS_DATA_IMPORT") && (
          <Tabs.Tab label="Uvoz podataka" onClick={(tabIndex) => setDefaultTab(tabIndex)}>
            <DataImport />
          </Tabs.Tab>
        )}
      </Tabs>
    </>
  );
}
