import React, { useEffect, useState } from "react";

import { Form, FormikProps } from "formik";

import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";

import { FormContainer } from "@tiller/formik-elements";

import { permission, role } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Header from "../../../components/Header";
import Popup from "../../../components/Popup";
import Spinner from "../../../components/Spinner";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { UserRoleFormType } from "../../../types";
import RoleForm from "../components/RoleForm";
import { convertPermissionsToArray } from "../util/roles-util";

const updateRoleSchema = Yup.object().shape({
  name: Yup.string().required(messages.requiredField),
  permissions: Yup.object().test("valid-checkbox-group", messages.permissionRequiredField, (value) =>
    Object.values(value).some((v) => v)
  ),
});

type UpdateRoleParams = {
  id: string;
};

export default function UpdateRole() {
  const [currentRole, setCurrentRole] = useState<UserRoleFormType>();
  const [allPermissions, setAllPermissions] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const { id } = useParams<UpdateRoleParams>();
  const { handleError } = useHandleError();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof id === "string") {
      fetch(role.getRoleById(id))
        .then(handleError)
        .then((data) => {
          const selectedRolePermissions: Record<string, boolean> = {};
          data.permissions.forEach((perm: string) => {
            selectedRolePermissions[perm] = true;
          });
          setCurrentRole({
            ...data,
            permissions: selectedRolePermissions,
          });
        });
    }
  }, [handleError, id]);

  useEffect(() => {
    fetch(permission.getAllPermissions())
      .then(handleError)
      .then((data) => {
        setAllPermissions(data);
      });
  }, [handleError]);

  const handleUpdate = (values: UserRoleFormType) => {
    setIsSubmitLoading(true);
    const selectedPermissions = convertPermissionsToArray(values.permissions);
    fetch(role.updateRole(id), {
      method: "PUT",
      body: JSON.stringify({ ...values, permissions: selectedPermissions }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        navigate(routes.ADMIN_URL);
        setIsSubmitLoading(false);
      })
      .catch((err) => console.log(err));
  };

  if (currentRole) {
    return (
      <FormContainer initialValues={currentRole} onSubmit={handleUpdate} validationSchema={updateRoleSchema}>
        {({ dirty, handleSubmit }: FormikProps<UserRoleFormType>) => (
          <Form>
            <Header
              title="uredi rolu"
              primaryButtonText="Spremi"
              primaryButtonIcon="check"
              secondaryButtonText="Odustani"
              secondaryButtonIcon="x"
              onPrimaryClick={handleSubmit}
              onSecondaryClick={() => (!dirty ? navigate(routes.ADMIN_URL) : setIsOpen(true))}
              showBackButton
              onBackButtonClick={() => navigate(routes.ADMIN_URL)}
              isPrimaryLoading={isSubmitLoading}
            />
            <RoleForm permissions={allPermissions} currentRole={currentRole} />
            <Popup
              title={messages.cancelPrompt}
              content={messages.updateWarning}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onSubmit={() => navigate(routes.ADMIN_URL)}
            />
          </Form>
        )}
      </FormContainer>
    );
  } else {
    return <Spinner />;
  }
}
