import useHandleError from "./useHandleError";
import { user } from "../api/backend_paths";

export default function useSignOut() {
  const { handleError } = useHandleError();

  const signOut = () => {
    fetch(user.removeToken, {
      method: "DELETE",
    })
      .then(handleError)
      .then(() => {
        localStorage.removeItem("associationName");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("username");
      })
      .catch((err) => console.log(err));
  };

  return signOut;
}
