import React, { useEffect, useState } from "react";

import { parseISO } from "date-fns";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";
import { Date } from "@tiller/date";

import { usePermissions } from "../../PermissionsProvider";
import { evidence } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import Attachments from "../../components/Attachments";
import DynamicFieldView from "../../components/DynamicFieldView";
import Header from "../../components/Header";
import NotesContainer from "../../components/Notes/NotesContainer";
import Popup from "../../components/Popup";
import ProfileActivityList from "../../components/ProfileActivityList";
import Spinner from "../../components/Spinner";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";
import { Member, MemberType, Note, personType, RolesOnCroatian } from "../../types";
import { parseNoteDate } from "../../util/NotesUtil";
import { fillMember } from "../MemberForm/util/member-util";

type ViewMemberParams = {
  code: string;
};

export default function ViewMember() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const [member, setMember] = useState<Member>();
  const [isOpen, setIsOpen] = useState(false);
  const [formIdList, setFormIdList] = useState<string[] | undefined>([]);
  const { code } = useParams<ViewMemberParams>();

  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    const member_code = code ?? "";
    fetch(evidence.getMemberByCode(member_code))
      .then(handleError)
      .then((res) => {
        parseNoteDate(res.notes);
        setMember(fillMember(res));
        setFormIdList(res.memberTypes.map((id: any) => `ASSOCIATION_MEMBER_${id}`));
      })
      .catch((err) => {
        console.log(err);
      });
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [code, handleError, navigate]);

  useEffect(() => {
    if (windowSize > 1280) {
      setOpen(false);
    }
  }, [windowSize]);

  function handleDelete(code: string) {
    fetch(evidence.deleteMemberByCode(code), {
      method: "DELETE",
    })
      .then(handleError)
      .catch((err) => console.log(err));
    navigate(routes.RECORDS_URL);
  }

  return (
    <>
      {member ? (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
            <div className="col-span-3 xl:col-span-2">
              <Header
                title="Detalji profila"
                showBackButton
                primaryButtonText="Uredi profil"
                primaryButtonIcon="pencil"
                secondaryButtonText="Izbriši profil"
                secondaryButtonIcon="trash"
                tertiaryButtonText="Bilješke"
                tertiaryButtonIcon="pencil-alt"
                onPrimaryClick={() => navigate(routes.UPDATE_MEMBER_URL(member.code))}
                onSecondaryClick={() => setIsOpen(true)}
                onTertiaryClick={() => setOpen(true)}
                onBackButtonClick={() => navigate(routes.RECORDS_URL)}
                hidePrimaryButton={!permissions?.includes("ASSOCIATION_MEMBER_CREATE_UPDATE")}
                hideSecondaryButton={!permissions?.includes("ASSOCIATION_MEMBER_DELETE")}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
              <div className="col-span-3 xl:col-span-2">
                <Card>
                  <Card.Header className="font-bold">
                    {member.personType === personType.LEGAL_ENTITY ? "Pravna osoba" : "Fizička osoba"}
                  </Card.Header>
                  <DescriptionList>
                    <DescriptionList.Item label="Vrsta profila">
                      {member.memberTypes.map((label, index) => (
                        <p key={index}>{RolesOnCroatian[label]}</p>
                      ))}
                    </DescriptionList.Item>
                    {member.personType === personType.LEGAL_ENTITY ? (
                      <DescriptionList>
                        <DescriptionList.Item label="Naziv">{member.name}</DescriptionList.Item>
                        <DescriptionList.Item label="OIB">{member.oib ? member.oib : "-"}</DescriptionList.Item>
                        <DescriptionList.Item label="Adresa">
                          {member.address ? member.address : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="Kontakt osoba">
                          {member.contactPerson ? member.contactPerson : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="Kontakt broj">
                          {member.phoneNumber ? member.phoneNumber : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="E-mail">{member.email ? member.email : "-"}</DescriptionList.Item>
                      </DescriptionList>
                    ) : (
                      <DescriptionList>
                        <DescriptionList.Item label="Ime">{member.firstName}</DescriptionList.Item>
                        <DescriptionList.Item label="Prezime">{member.lastName}</DescriptionList.Item>
                        <DescriptionList.Item label="Datum rođenja">
                          {member.dateOfBirth !== null ? <Date>{parseISO(member.dateOfBirth)}</Date> : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="OIB">{member.oib ? member.oib : "-"}</DescriptionList.Item>
                        <DescriptionList.Item label="Kontakt broj">
                          {member.phoneNumber ? member.phoneNumber : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="E-mail">{member.email ? member.email : "-"}</DescriptionList.Item>
                        <DescriptionList.Item label="Adresa">
                          {member.address ? member.address : "-"}
                        </DescriptionList.Item>
                      </DescriptionList>
                    )}

                    {member.memberTypes.includes(MemberType.MEMBER) && (
                      <DescriptionList>
                        <DescriptionList.Item label="Datum učlanjenja">
                          {member.dateOfMembership ? <Date>{parseISO(member.dateOfMembership)}</Date> : "-"}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="Datum prestanka članstva">
                          {member.dateOfTerminationOfMembership ? (
                            <Date>{parseISO(member.dateOfTerminationOfMembership)}</Date>
                          ) : (
                            "-"
                          )}
                        </DescriptionList.Item>
                        <DescriptionList.Item label="Kategorija članstva">
                          {member.userCategory ? member.userCategory : "-"}
                        </DescriptionList.Item>
                      </DescriptionList>
                    )}
                    <DynamicFieldView formId={formIdList ?? [""]} dynamicData={member.dynamicData} />
                  </DescriptionList>
                </Card>
                {member.activities && <ProfileActivityList activities={member.activities} />}
                <Formik
                  initialValues={{ files: member.attachments.map((document) => document.id) }}
                  onSubmit={() => {}}
                >
                  <Form>
                    <Attachments
                      attachments={member.attachments ?? []}
                      showDragZone={permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DELETE")}
                      uploadUrl={evidence.saveAttachment(member.code)}
                      downloadUrl={evidence.getAttachments}
                      deleteUrl={evidence.deleteAttachments}
                    />
                  </Form>
                </Formik>
              </div>
              {permissions?.includes("ASSOCIATION_MEMBER_NOTES_VIEW") && (
                <NotesContainer
                  module="ASSOCIATION_MEMBER"
                  open={open}
                  setOpen={stateHandler}
                  addNoteUrl={evidence.addNote}
                  getObjectContainingNotesUrl={evidence.getMemberByCode}
                  notes={member?.notes as Note[]}
                  id={member.code}
                  showNoteForm={permissions?.includes("ASSOCIATION_MEMBER_CRUD_OWN_NOTE")}
                  updateNoteUrl={evidence.updateNote}
                  deleteNoteUrl={evidence.deleteNote}
                />
              )}
            </div>
          </div>
          <Popup
            title={messages.profileDeletePrompt}
            content={messages.profileDeleteWarning}
            primaryButtonColor="red"
            primaryButtonText="Da, izbriši."
            onSubmit={() => handleDelete(member?.code)}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
