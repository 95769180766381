import React, { useContext, useEffect, useState } from "react";

import { Form, Formik, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";

import ImportValidationListForm from "./ImportValidationListForm";
import { dataImport } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Spinner from "../../../components/Spinner";
import { DataImportContext } from "../../../context/DataImportContext";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import useHandleError from "../../../hook/useHandleError";
import { CheckedSheets, DataImportResponse } from "../../../types";

export default function ImportValidationList() {
  const { handleError } = useHandleError();
  const { updateProgressBar } = useContext(ImportProgressBarContext);
  const { checkedSheets, data } = React.useContext(DataImportContext);
  const [response, setResponse] = useState<DataImportResponse>({ duplicates: [], errorsPerSheet: {} });
  const [insertableSheets, setInsertableSheets] = useState<CheckedSheets | undefined>(undefined);
  const [sheetsWithError, setSheetsWithError] = useState<string[]>([]);
  const [sheetsWithoutError, setSheetsWithoutError] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    updateProgressBar(false, false, true);
  }, [updateProgressBar]);

  const handleSubmit = (values: FormikValues) => {
    const encoded = localStorage.getItem("data-import-file")?.split(",");
    fetch(dataImport.importData, {
      method: "POST",
      body: JSON.stringify({
        ...values,
        ...data,
        fileInfo: {
          encodedFile: encoded?.[1],
          fileName: localStorage.getItem("data-import-file-name"),
          type: encoded?.[0].match(/:(.*?);/)?.[1],
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((res) => {
        if (res.ok) {
          navigate(routes.ADMIN_URL + "?return=true");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const encoded = localStorage.getItem("data-import-file")?.split(",");
    fetch(dataImport.parseData, {
      method: "POST",
      body: JSON.stringify({
        checkedSheets,
        ...data,
        fileInfo: {
          encodedFile: encoded?.[1],
          fileName: localStorage.getItem("data-import-file-name"),
          type: encoded?.[0].match(/:(.*?);/)?.[1],
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((res) => {
        const checkedSheets: Record<string, boolean> = {};
        const withError: string[] = [];
        const withoutError: string[] = [];
        Object.keys(res.errorsPerSheet).forEach((sheetName) => {
          const errorless = res.errorsPerSheet[sheetName].errors.length === 0;
          checkedSheets[sheetName] = errorless;
          errorless ? withoutError.push(sheetName) : withError.push(sheetName);
        });
        setResponse(res);
        setInsertableSheets({ checkedSheets: checkedSheets });
        setSheetsWithError(withError);
        setSheetsWithoutError(withoutError);
      })
      .catch((err) => console.error(err));
  }, [checkedSheets, data, handleError]);

  return (
    <>
      {sheetsWithError && sheetsWithoutError && insertableSheets ? (
        <Formik
          initialValues={insertableSheets}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form>
            <ImportValidationListForm
              response={response}
              sheetsWithError={sheetsWithError}
              sheetsWithoutError={sheetsWithoutError}
            />
          </Form>
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
