import React from "react";

import { MaskedInputField } from "@tiller/formik-elements";

type MaskedDateInputFieldProps = {
  name: string;
  label: string | JSX.Element;
  className?: string;
  required?: boolean;
};

export function MaskedDateInputField({ name, label, className, required = false }: MaskedDateInputFieldProps) {
  return (
    <MaskedInputField
      name={name}
      mask={[/[0-3]/, /\d/, ".", " ", /[0,1]/, /\d/, ".", " ", /\d/, /\d/, /\d/, /\d/, "."]}
      label={label}
      placeholder="dd. mm. gggg."
      autoComplete="off"
      className={className}
      keepCharPositions
      required={required}
    />
  );
}
