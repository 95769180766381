import React from "react";

import { motion } from "framer-motion";

import activity0 from "../../../assets/img/featureImg/activity0.png";
import activity1 from "../../../assets/img/featureImg/activity1.png";
import activity2 from "../../../assets/img/featureImg/activity2.png";
import activity3 from "../../../assets/img/featureImg/activity3.png";
import activity4 from "../../../assets/img/featureImg/activity4.png";
import activity5 from "../../../assets/img/featureImg/activity5.png";
import comp0 from "../../../assets/img/featureImg/comp0.png";
import comp1 from "../../../assets/img/featureImg/comp1.png";
import comp2 from "../../../assets/img/featureImg/comp2.png";
import comp3 from "../../../assets/img/featureImg/comp3.png";
import comp4 from "../../../assets/img/featureImg/comp4.png";
import comp5 from "../../../assets/img/featureImg/comp5.png";
import comp6 from "../../../assets/img/featureImg/comp6.png";
import comp7 from "../../../assets/img/featureImg/comp7.png";
import comp8 from "../../../assets/img/featureImg/comp8.png";
import dynamic0 from "../../../assets/img/featureImg/dynamic0.png";
import dynamic1 from "../../../assets/img/featureImg/dynamic1.png";
import dynamic2 from "../../../assets/img/featureImg/dynamic2.png";
import dynamic3 from "../../../assets/img/featureImg/dynamic3.png";
import records0 from "../../../assets/img/featureImg/records0.png";
import records1 from "../../../assets/img/featureImg/records1.png";
import records2 from "../../../assets/img/featureImg/records2.png";
import records3 from "../../../assets/img/featureImg/records3.png";
import records4 from "../../../assets/img/featureImg/records4.png";
import records5 from "../../../assets/img/featureImg/records5.png";

import slikeEvidencija from "../../../assets/img/featureImg/slikeEvidencija.png";
import slikeNatjecaja from "../../../assets/img/featureImg/slikeNatjecaja.png";
import slikePostavki from "../../../assets/img/featureImg/slikePostavki.png";
import slikeZadataka from "../../../assets/img/featureImg/slikeZadataka.png";

import FeatureSectionParts from "../components/FeatureSectionParts";

export default function FeaturesSection() {
  return (
    <section className="w-full flex flex-col gap-16 xl:gap-48 py-32" id="features">
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ amount: 0.2 }}>
        <div className="flex flex-col gap-4">
          <h2 className="landing-h2">Što dobivate aplikacijom?</h2>
          <p className="landing-p-md">
            Altruist vam vraća vrijeme za rad s vašim korisnicima. Riješite se papirologije i vodite evidenciju osoba,
            zadataka i prijava na natječaje digitalno, na jednom mjestu uz Altruist.
          </p>
        </div>
      </motion.div>
      <div className="grid grid-cols-1 gap-32 md:gap-64 ">
        <FeatureSectionParts
          flexDirection="flex-col lg:flex-row"
          defaultImage={slikeEvidencija}
          images={[records0, records1, records2, records3, records4, records5]}
          heading="Evidencija osoba"
          paragraph="Olakšajte si administraciju, pratite i čuvajte sve bitne informacije o ljudima na jednom mjestu."
          list={[
            "kreiranje profila osoba od korisnika, članova, volontera do donatora",
            "brzo dođite do željenih osoba kroz filtriranja i pretraživanja liste profila",
            "vodite bilješke i priložite bitne dokumente na profil osobe",
            "lakše kreirajte izvještaje pomoću izvoza podataka u excel",
          ]}
        ></FeatureSectionParts>
        <FeatureSectionParts
          flexDirection="flex-col lg:flex-row-reverse"
          defaultImage={slikeZadataka}
          images={[activity0, activity1, activity2, activity3, activity4, activity5]}
          heading="Zadaci udruge"
          paragraph="Pratite sva događanja unutar udruge. Koristite zadatke za internu, ali i vanjsku organizaciju posla."
          list={[
            "kreirajte interne zadatke i/ili one u sklopu projekta/programa",
            "evidentirajte sudionike na zadacima i njihovu prisutnost",
            "dodijelite zadatke zaposlenicima udruge",
            "pronađite željeni zadatak kroz filtriranja i pretraživanja liste zadataka",
            "vodite bilješke i priložite dokumente uz svaki zadatak",
            "lakše kreirajte izvještaje pomoću izvoza u excel",
          ]}
        ></FeatureSectionParts>
        <FeatureSectionParts
          flexDirection="flex-col lg:flex-row"
          defaultImage={slikeNatjecaja}
          images={[comp0, comp1, comp2, comp3, comp4, comp5, comp6, comp7, comp8]}
          heading="Natječaji"
          paragraph="Pratite sve natječaje te njihovu dokumentaciju čuvajte na jednom mjestu."
          list={[
            "informirajte se o otvorenim natječajima",
            "kreirajte vlastite natječaje",
            "pronađite natječaje koji vas zanimaju kroz filtriranje i pretraživanje",
            "vodite bilješke i priložite sve potrebne dokumente na natječaju",
            "označite natječaje favoritima i prijavljenima",
            "primajte e-mail obavijesti kada na natječaju dođe do promjene i prije isteka roka",
          ]}
        ></FeatureSectionParts>
        <FeatureSectionParts
          flexDirection="flex-col lg:flex-row-reverse"
          defaultImage={slikePostavki}
          images={[dynamic0, dynamic1, dynamic2, dynamic3]}
          heading="Personalizirana polja"
          paragraph="Proširite postojeće forme poljima koja trebaju baš Vašoj udruzi."
          list={[
            "dodajte nova polja na forme evidencije i zadataka",
            "odaberite vrstu polja po vašoj želji",
            "označite kao filter polja po kojima želite filtrirati liste",
            "polja za koja želite uvijek imati unos, označite kao obavezna",
          ]}
        ></FeatureSectionParts>
      </div>
    </section>
  );
}
