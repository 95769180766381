export const messages = {
  loading: "Učitavanje...",
  requiredField: "Ovo polje je obavezno.",
  permissionRequiredField: "Niste odabrali niti jedno pravo za novu rolu.",
  loginError: "Uneseno je krivo korisničko ime ili lozinka. Pokušajte ponovno.",
  login: "Unesite svoje korisničke podatke kako biste se prijavili u aplikaciju.",
  adminRegistration: "Kreirajte račun administratora kako biste mogli registrirati udrugu.",
  userRegistration: "Registrirajte se i započnite koristiti Altruist aplikaciju.",
  associationRegistration: "Molimo unesite podatke o vašoj udruzi kako biste završili proces registracije.",
  registerErrorUsernameTaken: "Uneseno korisničko ime već postoji.",
  registerErrorUsernameFormat: "Korisničko ime ne smije sadržavati razmake.",
  registerErrorEmailFormat: "Uneseni e-mail nije valjan.",
  noSearchResults: "Nisu pronađeni rezultati za zadane kriterije.",
  emptyCompetitionTab: "Trenutno nema natječaja u odabranoj kategoriji.",
  noActivities: "Trenutno ne postoji niti jedan zadatak.",
  noProfiles: "Trenutno ne postoji niti jedan profil.",
  dynamicFieldError: "Polje s ovim nazivom već postoji, odaberite drugi naziv.",
  leavePrompt: "Jeste li sigurni da želite napustiti stranicu?",
  cancelPrompt: "Jeste li sigurni da želite odustati?",
  profileDeletePrompt: "Jeste li sigurni da želite izbrisani odabrani profil?",
  activityDeletePrompt: "Jeste li sigurni da želite izbrisati zadatak?",
  attachmentsDeletePrompt: "Jeste li sigurni da želite izbrisati odabrane dokumente?",
  noteDeletePrompt: "Jeste li sigurni da želite izbrisati odabranu bilješku?",
  fieldDeletePrompt: "OPREZ! Ovom akcijom nepovratno brišete sve podatke upisane u polja ",
  roleDeletePrompt: "Jeste li sigurni da želite izbrisati odabranu rolu?",
  createWarning: "Svi podaci će biti izgubljeni.",
  updateWarning: "Sve promjene će biti izgubljene.",
  deleteWarning: "Ova radnja se ne može poništiti.",
  profileDeleteWarning: "Ako je profil dodan na određeni zadatak, brisanjem će se ukloniti.",
  fieldDeleteWarning: "Ako ste sigurni da želite obrisati sve od navedenog, upišite riječ ",
  noAssociation: "Vaš račun nije povezan s udrugom. Molimo kontaktirajte podršku na altruist-podrska@croz.net",
  unexpectedLoginError: "Neočekivana greška, kontaktirajte korisničku podršku.",
  otherLoginError: "Neočekivana greška, kontaktirajte korisničku podršku. Detalji: ",
  expiredLink:
    "Ovaj link već je iskorišten za registraciju zaposlenika. Obratite se administratoru udruge kako bi Vam dao pristup novom linku.",
  resendMail: "Niste dobili link? Pošalji ponovo.",
  sentLink: "Poslana je poruka s linkom za promjenu lozinke.",
  consent: "Dajem suglasnost za primanje e-mailova od strane aplikacije.",
  consentWarningMessage:
    "U slučaju da niste suglasni nećete moći primati sljedeće e-mailove: e-mail za zaboravljenu lozinku, podsjetnik prije roka isteka natječaja, promjena na natječaju i e-mail da ste dodani kao odgovorna osoba na zadatak.",
  invalidDateOfBirth: "Datum rođenja ne može biti u budućnosti.",
  invalidDateOfMembership: "Datum učlanjenja ne može biti prije datuma rođenja.",
  dateOfTerminationMembershipWithoutDateOfMembership:
    "Ne može postojati datum kraja članstva, ako ne postoji datum početka članstva.",
  dateOfTerminationMembershipBeforeDateOfMembership: "Datum kraja članstva ne može biti prije datuma početka članstva.",
  invalidEmailFormat: "Email adresa je neispravnog formata.",
  invalidPhoneNumberCharacters: 'Moguće je unijeti samo brojeve i znakove "/", "-", "+" i "()".',
  invalidOIBFormat: "OIB je neispravnog formata.",
  insertEmailMessage: "Upišite email s kojim ste registrirani u aplikaciju i dobit ćete link za reaktivaciju lozinke.",
  passwordRequirements:
    "Lozinka mora sadržavati barem 8 znakova od kojih barem jedno malo slovo, jedno veliko slovo i jedan broj.",
  passwordChangeConfirmation: "Lozinka je uspješno promijenjena.",
  alreadyRegistered: "Već ste registrirani? Otiđite na stranicu za prijavu.",
  changePasswordTitle: "Promjena lozinke",
  mailSentSuccessfully: "E-mail s linkom je uspješno poslan!",
  mailFailedToSend: "E-mail s linkom nije poslan. Pokušajte ponovo.",
  invalidDate: "Datum je neispravan.",
  invalidTime: "Vrijeme je neispravno.",
  endDateBeforeStartDate: "Kraj zadatka ne može biti prije početka zadatka.",
  competitionName: "Natječaj s ovim imenom već postoji",
  sourceName: "Izvor s ovim imenom već postoji",
  sourceDeletePrompt: "Jeste li sigurni da želite izbrisati odabrani izvor?",
  competitionDeletePrompt: "Jeste li sigurni da želite izbrisati odabrani natječaj?",
  dynamicFieldsDatasetError: "Polje mora sadržavati barem jednu vrijednost.",
  noAdmin: "Nije moguće promijeniti rolu jer u udruzi mora biti barem jedan admin.",
  startRowTooltipMessage: "Broj reda u kojemu počinju podatci: 2",
  endRowTooltipMessage: "Broj reda u kojemu završavaju podaci: 10",
  dataImportSuccess: "Podaci su uspješno uvezeni",
  dataMappingRequired: "Polje mora biti popunjeno",
  invalidDataMapping: "Obavezna polja moraju biti spojena sa stupcem u excelu.",
  capsLockOn: "Uključena velika slova.",
  deletePersonalAccount: "Brisanje korisničkog računa",
  deletePersonalAccountWarning: "Kako biste izbrisali odabrani korisnički račun, u polje unesite ključnu riječ ",
  deleteAssociationWarning: "Kako biste izbrisali udrugu, u polje unesite naziv udruge ",
  deleteAccountTitle: "Korisnički račun je uspješno izbrisan.",
  deleteAssociationTitle: "Vaša udruga je izbrisana.",
  noOtherAdminInAssociation: "Ako želite obrisati svoj korisnički račun mora biti barem jedan Administrator u udruzi.",
  forbidden: "Nemate pravo na modul. Molimo javite se administratoru udruge.",
  changeUserData: "Promjena osobnih podataka",
  changeAssociationData: "Promjena podataka udruge",
  emailUsed: "Email već postoji, odaberite drugi email",
  associationNameUsed: "Naziv udruge već postoji. Pokušajte s nekim drugim nazivom",
  deleteAssociationExport:
    "Prije nego izbrišete udrugu iz aplikacije, sačuvajte svoje podatke izvozom iz aplikacije. Označite module za koje želite izvesti podatke ili ostavite prazno ako ne želite, te zatim kliknite gumb Nastavi.",
  deleteAssociationThankYouNote: "Hvala Vam što ste koristili aplikaciju Altruist",
};
