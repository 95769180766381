import React from "react";

import pageTopFlowers from "../../../assets/img/landingBackground/pageTopFlowers.svg";
import pageTopFlowers2xl from "../../../assets/img/landingBackground/pageTopFlowers2xl.svg";
import pageTopFlowersLg from "../../../assets/img/landingBackground/pageTopFlowersLg.svg";
import pageTopFlowersSm from "../../../assets/img/landingBackground/pageTopFlowersSm.svg";
import pageTopFlowersXl from "../../../assets/img/landingBackground/pageTopFlowersXl.svg";

export default function TopBackgroundImages() {
  return (
    <>
      <div className="w-full object-fill absolute translate-y-48 -z-10 block sm:hidden opacity-80">
        <img src={pageTopFlowers} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill absolute translate-y-48 -z-10 hidden sm:block lg:hidden opacity-80">
        <img src={pageTopFlowersSm} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill absolute translate-y-48 -z-10 hidden lg:block xl:hidden opacity-80">
        <img src={pageTopFlowersLg} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill absolute translate-y-28 -z-10 hidden xl:block 2xl:hidden opacity-80">
        <img src={pageTopFlowersXl} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill absolute translate-y-12 -z-10 hidden 2xl:block opacity-80">
        <img src={pageTopFlowers2xl} alt="flowers" className="w-full" />
      </div>
    </>
  );
}
