import React from "react";

import { BrowserRouter } from "react-router-dom";

import { IntlProvider } from "@tiller/intl";

import { AuthProvider } from "./AuthProvider";
import ZadrugaRoutes from "./ZadrugaRoutes";
import FormConfiguration from "./components/FormConfiguration";
import RecordStateContextWrapper from "./context/PagesStateContext";
import EnvironmentVariableProvider from "./hook/EnvironmentVariableProvider";
import { intlDictionary } from "./util/intlDictionary";

function App() {
  return (
    <FormConfiguration>
      <EnvironmentVariableProvider>
        <IntlProvider lang="hr" dictionary={intlDictionary}>
          <BrowserRouter>
            <AuthProvider>
              <RecordStateContextWrapper>
                <ZadrugaRoutes />
              </RecordStateContextWrapper>
            </AuthProvider>
          </BrowserRouter>
        </IntlProvider>
      </EnvironmentVariableProvider>
    </FormConfiguration>
  );
}

export default App;
