import React, { useState } from "react";

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Form, FormikProps } from "formik";

import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "@tiller/core";
import { FormContainer, InputField, PasswordInputField } from "@tiller/formik-elements";

import { routes } from "../../api/paths";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";
import { useEnvironmentVariable } from "../../hook/EnvironmentVariableProvider";

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/^(?=.*[a-z])/, "Lozinka mora sadržavati barem jedno malo slovo.")
    .matches(/^(?=.*[A-Z])/, "Lozinka mora sadržavati barem jedno veliko slovo.")
    .matches(/^(?=.*[0-9])/, "Lozinka mora sadržavati barem jedan broj.")
    .matches(/^(?=.{8,})/, "Lozinka mora sadržavati barem 8 znakova.")
    .required("Lozinka mora sadržavati barem 8 znakova."),
  confirmNewPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Lozinke se ne podudaraju."),
});

type NewPasswordData = {
  code: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function ResetPassword() {
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const params = new URLSearchParams(location.search);

  const handleSubmit = (code: string, newPassword: string) => {
    const email = params.get("email") ?? location.state.email;
    const cognitoUser = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: userPool,
    });

    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: () => {
        navigate(routes.PASSWORD_CHANGE_SUCCESS_URL);
      },
      onFailure: (err) => {
        setMessage("Pogrešan kod.");
        console.error("err ", err);
      },
    });
  };

  return (
    <AuthenticationContainer title="Promjena lozinke">
      <FormContainer
        initialValues={{ code: params.get("code") ?? "", newPassword: "", confirmNewPassword: "" }}
        onSubmit={(values) => handleSubmit(values.code, values.newPassword)}
        validationSchema={resetPasswordSchema}
        validateAfterSubmit
      >
        {({ getFieldHelpers, isValid, dirty }: FormikProps<NewPasswordData>) => (
          <Form>
            <p className="mb-6 mt-0 text-sm">{messages.passwordRequirements}</p>
            <InputField name="code" label="Kod za oporavak" className="mb-0" />
            <p className="text-red-500 text-sm mb-0 mt-0 self-start">{message}</p>
            <PasswordInputField
              name="newPassword"
              label="Nova lozinka"
              className="mt-4"
              onBlurCapture={() => {
                const helpers = getFieldHelpers("newPassword");
                helpers.setTouched(true, true);
              }}
              help={<p>{messages.capsLockOn}</p>}
            />
            <PasswordInputField
              name="confirmNewPassword"
              label="Potvrdi novu lozinku"
              className="mt-4 mb-6"
              onBlurCapture={() => {
                const helpers = getFieldHelpers("confirmNewPassword");
                helpers.setTouched(true, true);
              }}
              help={<p>{messages.capsLockOn}</p>}
            />
            <Button className="w-full" disabled={!(isValid && dirty)}>
              Promjeni lozinku
            </Button>
          </Form>
        )}
      </FormContainer>
    </AuthenticationContainer>
  );
}
