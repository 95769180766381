import React from "react";

import { Form, Formik } from "formik";

import { Button, Card } from "@tiller/core";
import { AutocompleteField, DateRangeInputField, SelectField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import DynamicFieldFilters from "../../../components/DynamicFieldFilters";
import { ActivityFilterValues, ActivityTypeNames, Member, User } from "../../../types";

export type ActivityFilterProps = {
  persons?: Member[];
  designatedPersons?: User[];
  onSubmit: (values: ActivityFilterValues) => void;
  showDesignatedPersons: boolean;
  projectTitlesOptions: string[];
  programTitlesOptions: string[];
};

export const initialValues = {
  activityStatus: "Svi",
  activityType: undefined,
  projectTitle: undefined,
  programTitle: undefined,
  personCodes: undefined,
  designatedPersonUsernames: undefined,
} as ActivityFilterValues;

export default function ActivityFilter({
  persons,
  designatedPersons,
  onSubmit,
  showDesignatedPersons,
  projectTitlesOptions,
  programTitlesOptions,
}: ActivityFilterProps) {
  const personProps = {
    options: persons as Member[],
    itemToString: (person: Member) => `${person.name}`,
    getOptionLabel: (person: Member) => `${person.name}`,
    getOptionValue: (person: Member) => person.code,
    sendOptionValue: true,
    sort: (persons: Member[]) => persons.sort((a, b) => a.name.localeCompare(b.name)),
    filter: (name: string, option: Member) => option.name.toLowerCase().includes(name.toLowerCase()),
  };

  const designatedPersonProps = {
    options: designatedPersons as User[],
    itemToString: (person: User) => `${person.firstName} ${person.lastName}`,
    getOptionLabel: (person: User) => `${person.firstName} ${person.lastName}`,
    getOptionValue: (person: User) => person.username,
    sendOptionValue: true,
    sort: (persons: User[]) => persons.sort((a, b) => a.firstName.localeCompare(b.firstName)),
    filter: (name: string, option: User) =>
      (option.firstName.toLowerCase() + " " + option.lastName.toLowerCase()).includes(name.toLowerCase()),
  };

  const titleProps = {
    sendOptionValue: false,
    itemToString: (item: string) => item,
    getOptionLabel: (item: string) => item,
    sort: (items: string[]) => items.sort((a, b) => a.localeCompare(b)),
    filter: (name: string, option: string) => option.toLowerCase().includes(name.toLowerCase()),
  };

  const autocompleteProps = {
    allowMultiple: true,
    getMultipleSelectedLabel: (items: Object[]) => items.join(", "),
  };

  return (
    <Card className="mt-2">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleReset, handleSubmit, values }) => (
          <Form>
            <Card.Body>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row gap-4 mb-4">
                <SelectField
                  name="activityStatus"
                  placeholder="Svi"
                  options={["Svi", "Aktivni", "Neaktivni"]}
                  label="Status zadatka"
                  hideClearButton={true}
                  className="w-full lg:w-1/4"
                />
                <AutocompleteField
                  name="personCodes"
                  label="Sudionici"
                  {...personProps}
                  {...autocompleteProps}
                  getMultipleSelectedLabel={(items: Member[]) => {
                    return items.map((item) => item.name).join(", ");
                  }}
                  className="w-full lg:w-1/4"
                />
                {showDesignatedPersons ? (
                  <AutocompleteField
                    name="designatedPersonUsernames"
                    label="Odgovorne osobe"
                    {...designatedPersonProps}
                    {...autocompleteProps}
                    getMultipleSelectedLabel={(items: User[]) => {
                      return items.map((item) => `${item.firstName} ${item.lastName}`).join(", ");
                    }}
                    className="w-full lg:w-1/4"
                  />
                ) : (
                  ""
                )}
                <DateRangeInputField
                  allowClear={true}
                  popoverPosition="right"
                  start="startDate"
                  end="endDate"
                  label="Datum početka"
                  className="w-full lg:w-1/4"
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 pb-4">
                <SelectField
                  name="activityType"
                  placeholder="Svi"
                  options={Object.keys(ActivityTypeNames)}
                  label="Vrsta zadatka"
                  hideClearButton={true}
                  allowMultiple={false}
                />
                {values.activityType === "Zadatak projekta" && (
                  <AutocompleteField
                    name="projectTitles"
                    label="Naziv projekta"
                    options={projectTitlesOptions}
                    {...autocompleteProps}
                    {...titleProps}
                  />
                )}
                {values.activityType === "Zadatak programa" && (
                  <AutocompleteField
                    name="programTitles"
                    label="Naziv programa"
                    options={programTitlesOptions}
                    {...autocompleteProps}
                    {...titleProps}
                  />
                )}
                <DynamicFieldFilters formId="ACTIVITY" />
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="flex gap-3 justify-end">
                <Button
                  onClick={() => {
                    handleReset();
                    handleSubmit();
                  }}
                  variant="outlined"
                  leadingIcon={<Icon type="refresh" />}
                >
                  Resetiraj
                </Button>
                <Button type="submit">Filtriraj</Button>
              </div>
            </Card.Footer>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
