import React from "react";

import ReactDOM from "react-dom/client";

import { IntlProvider } from "@tiller/intl";
import { ThemeProvider } from "@tiller/theme";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { tillerConfig } from "./styles/tillerConfig";

const getEmptyTranslations = (lang: string) => {
  return Promise.resolve({ translations: {}, messages: {} });
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider themeConfig={tillerConfig}>
      <IntlProvider lang="en" loadDictionary={getEmptyTranslations}>
        <App />
      </IntlProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
