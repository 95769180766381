import React from "react";

import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@tiller/icons";
import { SidebarNavigation } from "@tiller/menu";

import { usePermissions } from "../PermissionsProvider";
import { routes } from "../api/paths";
import logo from "../assets/img/altruistLogo-white.svg";
import logoMark from "../assets/img/altruistLogoMark-white.svg";
import useSignOut from "../hook/useSignOut";
import { ACTIVITY_PERMISSIONS, ASSOCIATION_MEMBER_PERMISSIONS, COMPETITION_PERMISSIONS } from "../types";

export default function Navigation() {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const support = () => {
    window.location.href = "mailto:altruist-podrska@croz.net";
  };

  return (
    <>
      {localStorage.getItem("username") && (
        <SidebarNavigation
          logo={
            <div className="w-full flex justify-center md:mt-4">
              <img
                alt="Altruist logo"
                src={logoMark}
                className="lg:hidden cursor-pointer w-8"
                onClick={() => navigate(routes.RECORDS_URL)}
              />
              <img
                alt="Altruist logo"
                src={logo}
                className="hidden lg:block cursor-pointer h-9"
                onClick={() => navigate(routes.RECORDS_URL)}
              />
            </div>
          }
          bottomActions={
            <div className="grid grid-cols-1 gap-2">
              <hr className="opacity-25" />
              <SidebarNavigation.Item to={routes.ADMIN_URL} icon={<Icon type="cog" />}>
                <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Postavke</p>
              </SidebarNavigation.Item>

              <div className="flex mx-auto md:ml-9 lg:ml-3 my-1 items-center opacity-80 hover:bg-indigo-900 p-0.5">
                <Icon type="book-open" variant="outline" />
                <Link
                  to="/media/KorisnickeUpute.pdf"
                  target="_blank"
                  className="ml-2 font-normal text-navigation md:hidden lg:inline"
                  download
                >
                  Korisničke upute
                </Link>
              </div>

              <div onClick={support}>
                <SidebarNavigation.Item icon={<Icon type="chat" variant="outline" />}>
                  <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Podrška</p>
                </SidebarNavigation.Item>
              </div>

              <div onClick={signOut} className="md:mb-12">
                <SidebarNavigation.Item to={routes.USER_LOGIN_URL} icon={<Icon type="logout" variant="outline" />}>
                  <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Odjavi se</p>
                </SidebarNavigation.Item>
              </div>
              <div className="flex flex-col bg-[#3c3995] -mx-6 -mb-4 px-4 pb-4 pt-3 lg:pl-9 md:-my-6">
                <p className="flex justify-center text-center lg:text-left lg:justify-start text-navigation font-medium opacity-90 tracking-wide">
                  {localStorage.getItem("firstName")} {localStorage.getItem("lastName")}
                </p>
                <p className="flex justify-center text-center lg:text-left lg:justify-start text-sm font-light whitespace-pre-wrap">
                  {localStorage.getItem("email")}
                </p>
              </div>
            </div>
          }
        >
          <div className="grid grid-cols-1 gap-2 mt-4">
            {permissions?.some((permission) => ASSOCIATION_MEMBER_PERMISSIONS.includes(permission)) && (
              <SidebarNavigation.Item to={routes.RECORDS_URL} icon={<Icon type="user-group" />}>
                <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Evidencija</p>
              </SidebarNavigation.Item>
            )}
            {permissions?.some((permission) => ACTIVITY_PERMISSIONS.includes(permission)) && (
              <SidebarNavigation.Item to={routes.ACTIVITIES_URL} icon={<Icon type="clipboard" />}>
                <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Zadaci</p>
              </SidebarNavigation.Item>
            )}
            {permissions?.some((permission) => COMPETITION_PERMISSIONS.includes(permission)) && (
              <SidebarNavigation.Item to={routes.COMPETITIONS_URL} icon={<Icon type="newspaper" />}>
                <p className="ml-2 font-normal text-navigation md:hidden lg:inline">Natječaji</p>
              </SidebarNavigation.Item>
            )}
          </div>
        </SidebarNavigation>
      )}
    </>
  );
}
