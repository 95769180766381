import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import { Card, IconButton } from "@tiller/core";
import { Toggle } from "@tiller/form-elements";
import { AutocompleteField, InputField } from "@tiller/formik-elements";

import { usePermissions } from "../../../PermissionsProvider";
import { sources } from "../../../api/backend_paths";
import { MaskedDateInputField } from "../../../components/Fields/MaskedDateInputField";
import useHandleError from "../../../hook/useHandleError";
import { CompetitionFormType, FundingSource } from "../../../types";

type CompetitionFormSelectionProps = {
  className?: string;
  errorName: string;
};

export default function CompetitionFormSelection({ className, errorName }: CompetitionFormSelectionProps) {
  const { values, setFieldValue } = useFormikContext<CompetitionFormType>();
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();
  const [source, setSources] = useState<string[]>([]);

  const formatName = (name: string, option: string) => option.toLowerCase().includes(name.toLowerCase());

  useEffect(() => {
    fetch(sources.root, { method: "GET" })
      .then(handleError)
      .then((data) => {
        setSources(data.map((source: FundingSource) => source.name));
      })
      .catch((err) => console.error(err));
  }, [handleError]);

  return (
    <Card className={className}>
      <Card.Body>
        <div className="grid grid-cols-1 md:grid-cols-2  mb-4 md:mb-8">
          <div className="flex flex-col">
            <InputField name="name" label="Naslov" required />
            <p className="text-red-500 text-sm mt-1 self-start">{errorName}</p>
          </div>
          {permissions?.includes("COMPETITION_SET_AND_VIEW_FAVORITE") && (
            <div className="flex w-full items-end mt-2 md:mt-0 mb-2">
              <Toggle
                checked={values.applied}
                className="mr-8"
                name="applied"
                label={<span className="text-sm font-medium text-gray-900">Prijavljen</span>}
                onClick={() => setFieldValue("applied", !values.applied)}
              />
              <IconButton
                type="star"
                {...(values.favorite
                  ? { variant: "solid", label: "Makni iz favorita", color: "yellow" }
                  : { variant: "outline", label: "Dodaj u favorite", color: "primary" })}
                name="favorite"
                onClick={() => setFieldValue("favorite", !values.favorite)}
              />
            </div>
          )}
        </div>

        <hr />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <AutocompleteField
            name="sourceName"
            label="Izvor natječaja"
            options={source}
            sendOptionValue={true}
            filter={formatName}
            maxItems={source?.length}
          />
          <InputField name="institution" label="Izvor financiranja" />
          <InputField name="institutionLevel" label="Razina institucije" />
          <MaskedDateInputField name="dateFrom" label="Početak natječaja" />
          <MaskedDateInputField name="dateTo" label="Istek roka natječaja" />
          <InputField name="minFundingAmount" label="Najniži iznos sredstava za financiranje" />
          <InputField name="maxFundingAmount" label="Najviši iznos sredstava za financiranje" />
          <InputField name="totalFundingAmount" label="Ukupni iznos sredstava za financiranje" />
          <InputField name="area" label="Područje provedbe" />
          <InputField name="urlToSite" label="Link na stranicu natječaja" />
          <InputField name="urlToPdf" label="Link na dokument natječaja" />
        </div>
      </Card.Body>
    </Card>
  );
}
