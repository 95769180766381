import React, { createContext, useState } from "react";

import { MemberType } from "../types";

type Paging = {
  pageSize: number;
  pageNumber: number;
};
type PagesStateContextType = {
  children: React.ReactNode;
};
export const PagesStateContext = createContext({
  //tab indexes
  membersTabIndex: 0,
  activitiesTabIndex: 0,
  competitionsTabIndex: 0,
  setMembersTabIndex: (page: number) => {},
  setActivitiesTabIndex: (page: number) => {},
  setCompetitionsTabIndex: (page: number) => {},

  memberType: MemberType.ALL,
  setMemberType: (memberType: MemberType) => {},

  //records pagination's per record tab - user type
  allMembersRecordPaging: { pageSize: 10, pageNumber: 0 },
  userRecordPaging: { pageSize: 10, pageNumber: 0 },
  volunteerRecordPaging: { pageSize: 10, pageNumber: 0 },
  donorRecordPaging: { pageSize: 10, pageNumber: 0 },
  memberRecordPaging: { pageSize: 10, pageNumber: 0 },
  partnerRecordPaging: { pageSize: 10, pageNumber: 0 },
  externalCollaboratorRecordPaging: { pageSize: 10, pageNumber: 0 },
  employeeRecordPaging: { pageSize: 10, pageNumber: 0 },
  otherRecordPaging: { pageSize: 10, pageNumber: 0 },
  updateAll: (page: Paging) => {},
  updateUserRecordPaging: (page: Paging) => {},
  updateVolunteerRecordPaging: (page: Paging) => {},
  updateDonorRecordPaging: (page: Paging) => {},
  updateMemberRecordPaging: (page: Paging) => {},
  updatePartnerRecordPaging: (page: Paging) => {},
  updateExternalCollaboratorRecordPaging: (page: Paging) => {},
  updateEmployeeRecordPaging: (page: Paging) => {},
  updateOtherRecordPaging: (page: Paging) => {},

  //activities pagination's
  allActivitiesRecordPaging: { pageSize: 10, pageNumber: 0 },
  personalActivitiesRecordPaging: { pageSize: 10, pageNumber: 0 },
  updateAllActivitiesRecordPage: (page: Paging) => {},
  updatePersonalActivitiesRecordPaging: (page: Paging) => {},

  //competitions pagination's
  allCompetitionsRecordPaging: { pageSize: 5, pageNumber: 0 },
  customCompetitionsRecordPaging: { pageSize: 5, pageNumber: 0 },
  favoriteCompetitionsRecordPaging: { pageSize: 5, pageNumber: 0 },
  appliedCompetitionsRecordPaging: { pageSize: 5, pageNumber: 0 },
  updateAllCompetitionsRecordPaging: (page: Paging) => {},
  updateCustomCompetitionsRecordPaging: (page: Paging) => {},
  updateFavoriteCompetitionsRecordPaging: (page: Paging) => {},
  updateAppliedCompetitionsRecordPaging: (page: Paging) => {},
});

export default function RecordStateContextWrapper({ children }: PagesStateContextType) {
  const [membersTabIndex, setMembersTabIndex] = useState(0);
  const [activitiesTabIndex, setActivitiesTabIndex] = useState(0);
  const [competitionsTabIndex, setCompetitionsTabIndex] = useState(0);

  const [memberType, setMemberType] = useState(MemberType.ALL);

  const [allMembersRecordPaging, setAllMembersRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [userRecordPaging, setUserRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [volunteerRecordPaging, setVolunteerRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [donorRecordPaging, setDonorRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [memberRecordPaging, setMemberRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [partnerRecordPaging, setPartnerRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [externalCollaboratorRecordPaging, setExternalCollaboratorRecordPaging] = useState<Paging>({
    pageSize: 10,
    pageNumber: 0,
  });
  const [employeeRecordPaging, setEmployeeRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [otherRecordPaging, setOtherRecordPaging] = useState<Paging>({ pageSize: 10, pageNumber: 0 });

  const [allActivitiesRecordPaging, setAllActivitiesRecordPage] = useState<Paging>({ pageSize: 10, pageNumber: 0 });
  const [personalActivitiesRecordPaging, setPersonalActivitiesRecordPaging] = useState<Paging>({
    pageSize: 10,
    pageNumber: 0,
  });

  const [allCompetitionsRecordPaging, setAllCompetitionsRecordPaging] = useState<Paging>({
    pageSize: 5,
    pageNumber: 0,
  });
  const [customCompetitionsRecordPaging, setCustomCompetitionsRecordPaging] = useState<Paging>({
    pageSize: 5,
    pageNumber: 0,
  });
  const [favoriteCompetitionsRecordPaging, setFavoriteCompetitionsRecordPaging] = useState<Paging>({
    pageSize: 5,
    pageNumber: 0,
  });
  const [appliedCompetitionsRecordPaging, setAppliedCompetitionsRecordPaging] = useState<Paging>({
    pageSize: 5,
    pageNumber: 0,
  });

  const updateAll = (value: Paging) => {
    setAllMembersRecordPaging(value);
  };
  const updateUserRecordPaging = (value: Paging) => {
    setUserRecordPaging(value);
  };
  const updateVolunteerRecordPaging = (value: Paging) => {
    setVolunteerRecordPaging(value);
  };
  const updateDonorRecordPaging = (value: Paging) => {
    setDonorRecordPaging(value);
  };
  const updateMemberRecordPaging = (value: Paging) => {
    setMemberRecordPaging(value);
  };
  const updatePartnerRecordPaging = (value: Paging) => {
    setPartnerRecordPaging(value);
  };
  const updateExternalCollaboratorRecordPaging = (value: Paging) => {
    setExternalCollaboratorRecordPaging(value);
  };
  const updateEmployeeRecordPaging = (value: Paging) => {
    setEmployeeRecordPaging(value);
  };

  const updateOtherRecordPaging = (value: Paging) => {
    setOtherRecordPaging(value);
  };

  const updateAllActivitiesRecordPage = (value: Paging) => {
    setAllActivitiesRecordPage(value);
  };

  const updatePersonalActivitiesRecordPaging = (value: Paging) => {
    setPersonalActivitiesRecordPaging(value);
  };

  const updateAllCompetitionsRecordPaging = (value: Paging) => {
    setAllCompetitionsRecordPaging(value);
  };
  const updateCustomCompetitionsRecordPaging = (value: Paging) => {
    setCustomCompetitionsRecordPaging(value);
  };
  const updateFavoriteCompetitionsRecordPaging = (value: Paging) => {
    setFavoriteCompetitionsRecordPaging(value);
  };
  const updateAppliedCompetitionsRecordPaging = (value: Paging) => {
    setAppliedCompetitionsRecordPaging(value);
  };

  const value = {
    //tabs values
    membersTabIndex,
    activitiesTabIndex,
    competitionsTabIndex,
    setMembersTabIndex,
    setActivitiesTabIndex,
    setCompetitionsTabIndex,

    memberType,
    setMemberType,

    //records pagination's values per record tab - user type
    allMembersRecordPaging,
    userRecordPaging,
    volunteerRecordPaging,
    donorRecordPaging,
    memberRecordPaging,
    partnerRecordPaging,
    externalCollaboratorRecordPaging,
    employeeRecordPaging,
    otherRecordPaging,
    updateAll,
    updateUserRecordPaging,
    updateVolunteerRecordPaging,
    updateDonorRecordPaging,
    updateMemberRecordPaging,
    updatePartnerRecordPaging,
    updateExternalCollaboratorRecordPaging,
    updateEmployeeRecordPaging,
    updateOtherRecordPaging,

    //activities pagination's values per activity tab
    allActivitiesRecordPaging,
    personalActivitiesRecordPaging,
    updateAllActivitiesRecordPage,
    updatePersonalActivitiesRecordPaging,

    //competitions pagination's values per competitions tab
    allCompetitionsRecordPaging,
    customCompetitionsRecordPaging,
    favoriteCompetitionsRecordPaging,
    appliedCompetitionsRecordPaging,
    updateAllCompetitionsRecordPaging,
    updateCustomCompetitionsRecordPaging,
    updateFavoriteCompetitionsRecordPaging,
    updateAppliedCompetitionsRecordPaging,
  };

  return <PagesStateContext.Provider value={value}>{children}</PagesStateContext.Provider>;
}
