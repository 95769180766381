import { FormikValues } from "formik";

import { Row } from "read-excel-file";

import { getRolesFromAny } from "../pages/MemberForm/util/member-util";
import { dynamicFieldType, MemberType } from "../types";

export const dataImportFinished = () => {
  localStorage.removeItem("data-import-file");
  localStorage.removeItem("data-import-file-name");
};

export const generateTableDataForDataImport = (
  sheetName: string,
  dynamicFields: dynamicFieldType[],
  data: any,
  memberTypes: { [key in keyof typeof MemberType]: boolean }
) => {
  if (memberTypes) {
    const tableData = [
      { field: `mapping.name`, label: "Ime", value: data?.mapping?.name, required: true },
      {
        field: `mapping.familyName`,
        label: "Prezime",
        value: data?.mapping?.familyName,
        required: true,
      },
      {
        field: `mapping.dateOfBirth`,
        label: "Datum rođenja",
        value: data?.mapping?.dateOfBirth,
        required: false,
      },
      { field: `mapping.oib`, label: "OIB", value: data?.mapping?.oib, required: false },
      {
        field: `mapping.phoneNumber`,
        label: "Kontakt broj",
        value: data?.mapping?.phoneNumber,
        required: false,
      },
      { field: `mapping.email`, label: "E-mail", value: data?.mapping?.email, required: false },
      { field: `mapping.address`, label: "Adresa", value: data?.mapping?.address, required: false },
    ];
    if (memberTypes && getRolesFromAny(memberTypes).includes(MemberType.MEMBER)) {
      tableData.push(
        {
          field: `mapping.dateOfMembership`,
          label: "Datum učlanjenja",
          value: data?.mapping?.dateOfMembership,
          required: false,
        },
        {
          field: `mapping.dateOfTerminationOfMembership`,
          label: "Datum prestanka Članstva",
          value: data?.mapping?.dateOfTerminationOfMembership,
          required: false,
        },
        {
          field: `mapping.userCategory`,
          label: "Kategorija članstva",
          value: data?.mapping?.userCategory,
          required: false,
        }
      );
    }
    tableData.push(
      ...dynamicFields.map((dynamicField) => ({
        field: `mapping.dynamicFields["${dynamicField.code}"]`,
        label: dynamicField.fieldName,
        value: data?.mapping?.dynamicFields?.[dynamicField.code],
        required: dynamicField.required,
      }))
    );
    return tableData;
  }
};

export const option = "Ne želim upariti";

export function convertNumberToString(number: number): string {
  let ret = "";
  let pom = Math.floor(number / 26);
  if (pom > 0) {
    ret = convertNumberToString(pom - 1) + ret;
    number -= 26 * pom;
  }
  if (number === -1) {
    return option;
  }
  ret += String.fromCharCode(number + 65);
  return ret;
}

export function convertStringToAscii(column: string): number {
  if (column === option) {
    return -1;
  }
  let ret = 0;
  for (let i = column.length - 1; i >= 0; i--) {
    let code = column.charCodeAt(i) - 65;
    if (i !== column.length - 1) {
      code += 1;
    }
    ret += code * Math.pow(26, column.length - i - 1);
  }
  return ret;
}

export function validMemberTypes(values: FormikValues): boolean {
  if (values?.memberTypes) {
    let valid = false;
    Object.keys(values?.memberTypes ?? {})
      .map((value: string): MemberType => MemberType[value as keyof typeof MemberType])
      .forEach((value: MemberType) => {
        if (values?.memberTypes![value]) {
          valid = true;
        }
      });
    return valid;
  }
  return false;
}

export function getPreview(value: string | undefined, values: FormikValues, sheetData: Row[] | undefined) {
  return value && value !== option && values?.startingRow
    ? sheetData
        // @ts-ignore
        ?.at(values?.startingRow - 1)
        ?.at(convertStringToAscii(value))
        ?.toString() || "Ne postoji"
    : "";
}
