import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Card, Link } from "@tiller/core";
import { Icon } from "@tiller/icons";

import { routes } from "../api/paths";
import logo from "../assets/img/altruistLogo.svg";

type AuthenticationContainerProps = {
  title: string;
  titleFontSize?: string;
  showAdditionalCard?: boolean;
  additionalText?: string;
  showIcon?: boolean;
  additionalCardRoute?: string;
  responseError?: string;
  children: React.ReactNode;
  width?: string;
};

export default function AuthenticationContainer({
  title,
  titleFontSize = "text-3xl",
  showAdditionalCard = false,
  additionalText,
  showIcon = false,
  additionalCardRoute,
  responseError,
  children,
  width = "w-80 md:w-96",
}: AuthenticationContainerProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const className =
    location.pathname === routes.ADMIN_REGISTRATION_URL || location.pathname === routes.USER_REGISTRATION_URL()
      ? "justify-start pt-8 md:justify-center"
      : "justify-center";

  return (
    <div className="relative flex flex-col items-center w-screen h-screen background-flowers bg-contain bg-bottom bg-no-repeat bg-fixed overflow-y-auto">
      <div className="w-full pl-8 lg:pl-16 pt-6">
        <img src={logo} alt="logo" className="w-44 cursor-pointer" onClick={() => navigate(routes.LANDING_PAGE_URL)} />
      </div>
      <div className={`flex flex-col items-center ${className} h-full drop-shadow-2xl`}>
        <Card className={`px-2 md:px-6 py-4 w-80 ${width} mb-4`}>
          <Card.Body>
            <p className={`${titleFontSize} font-bold text-center uppercase mb-4 md:whitespace-pre-wrap`}>{title}</p>
            <p className="text-red-500">{responseError}</p>
            {children}
          </Card.Body>
        </Card>
        {showAdditionalCard && (
          <Link onClick={() => navigate(additionalCardRoute!)}>
            <Card className={`px-6 ${width} cursor-pointer hover:opacity-95 hover:underline mb-4 md:mb-0`}>
              <Card.Body>
                <div className="flex justify-between font-semibold">
                  <p className="text-sm text-indigo-900">{additionalText}</p>
                  {showIcon && <Icon type="arrow-right" />}
                </div>
              </Card.Body>
            </Card>
          </Link>
        )}
      </div>
    </div>
  );
}
