import React, { useEffect, useRef } from "react";

import { useField, useFormikContext } from "formik";

import { Badge, Card, IconButton, Typography } from "@tiller/core";

import { DataTable, useDataTable } from "@tiller/data-display";
import { AutocompleteField, ToggleField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import { ActivityFormType, ActivityMemberAttendance } from "../../../types";
import { getParticipantIndex } from "../util/activity-util";

type ParticipantsProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  participants?: ActivityMemberAttendance[];
  width?: string;
};

export default function AddParticipants({ isOpen, setIsOpen, participants, width }: ParticipantsProps) {
  const [, dataTableHook] = useDataTable();

  const [field, , helpers] = useField("persons");
  const personsChanged = useRef<number>(field.value.length);
  const selectedParticipants = useRef<ActivityMemberAttendance[]>(field.value);

  useEffect(() => {
    if (Array.isArray(field.value) && personsChanged.current !== field.value.length) {
      field.value.forEach((value) => {
        if (!(value["attended"] === false)) {
          value["attended"] = true;
        }
      });
    }
    personsChanged.current = field.value.length;
    selectedParticipants.current = field.value;
  }, [field.value]);

  const { values, setFieldValue } = useFormikContext<ActivityFormType>();

  const formatNameMemberType = (name: string, option: ActivityMemberAttendance) =>
    (option.firstName?.toLowerCase() + " " + option.lastName?.toLowerCase()).includes(name.toLowerCase());

  const handleRemove = (id: number) => {
    const filteredValues = values.persons?.filter((p: ActivityMemberAttendance) => p.id !== id);
    setFieldValue("persons", filteredValues);
    helpers.setValue(filteredValues);
  };

  useEffect(() => {
    setFieldValue("persons", selectedParticipants.current);
    selectedParticipants.current = field.value;
  }, [field.value, setFieldValue]);

  return (
    <Card className={`mt-8 ${width}`}>
      <Card.Header>
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Typography element="h1" variant="title">
                Sudionici
              </Typography>
              <Badge className="ml-2">{values.persons ? values.persons?.length : 0}</Badge>
            </div>
            <Icon
              className="cursor-pointer"
              type={isOpen ? "chevron-up" : "chevron-down"}
              variant="outline"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
        <AutocompleteField
          name="persons"
          options={participants as ActivityMemberAttendance[]}
          itemToString={(person: ActivityMemberAttendance) => `${person.name}`}
          getOptionLabel={(person: ActivityMemberAttendance) => `${person.name}`}
          getOptionValue={(person: ActivityMemberAttendance) => person.id}
          sendOptionValue={false}
          sort={(persons: ActivityMemberAttendance[]) => persons.sort((a, b) => a.name.localeCompare(b.name))}
          className="lg:w-1/2 mt-4"
          placeholder="Odaberi sudionike"
          filter={formatNameMemberType}
          allowMultiple
          getMultipleSelectedLabel={() => "Odaberi sudionika"}
        />
      </Card.Header>
      {isOpen && values.persons!?.length > 0 && (
        <div className="p-0 max-h-[290px] overflow-y-auto scrollbar">
          <DataTable data={values.persons || []} hook={dataTableHook}>
            <DataTable.Column header="ime i prezime" accessor="name" canSort={false} className="w-2/3" />
            <DataTable.Column header="dolaznost" id="attended" canSort={false}>
              {(person: ActivityMemberAttendance) => <AttendanceColumn persons={values.persons} person={person} />}
            </DataTable.Column>
            <DataTable.Column header="akcije" id="actions" canSort={false}>
              {(person: ActivityMemberAttendance) => (
                <IconButton type="trash" label="Izbriši" color="red" onClick={() => handleRemove(person.id)} />
              )}
            </DataTable.Column>
          </DataTable>
        </div>
      )}
    </Card>
  );
}

const AttendanceColumn = ({
  persons,
  person,
}: {
  persons: ActivityMemberAttendance[] | undefined;
  person: ActivityMemberAttendance;
}) => {
  return (
    <div className="flex items-center">
      <ToggleField
        className="mr-3 text-white"
        name={`persons[${getParticipantIndex(persons, person)}].attended`}
        tokens={{
          container: "mt-1",
          backgroundColor: person.attended ? "bg-green-100" : "bg-red-100",
          toggle: `w-5 h-5 ${
            person.attended ? "bg-green-800" : "bg-red-800"
          } inline-block rounded-full transform transition ease-in-out duration-200 shadow`,
          gray: "bg-red-100",
        }}
      />
      <p className={`mt-1 uppercase text-sm ${person.attended ? "text-green-800" : "text-red-800"}`}>
        {person.attended ? "prisutan" : "odsutan"}
      </p>
    </div>
  );
};
