import React, { useEffect, useRef, useState } from "react";

import Popover, { positionMatchWidth } from "@reach/popover";

import { useFormikContext } from "formik";

import { InputField } from "@tiller/formik-elements";

import { ActivityFormType } from "../../../types";

type DataListFieldProps = {
  name: string;
  label: string;
  className?: string;
  valueList: string[];
  required?: boolean;
};

export const optionContainerClassName =
  "rounded-md shadow-lg mt-2 border border-gray-200 bg-white max-h-[11.5rem] overflow-y-auto";

export const optionItemClassName = "cursor-pointer pl-4 py-2 text-sm text-gray-900 hover:bg-gray-100";

export default function DataListField({
  name,
  label,
  className = "",
  valueList,
  required = false,
}: DataListFieldProps) {
  const [filteredValues, setFilteredValues] = useState<string[]>(valueList);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { values, setFieldValue } = useFormikContext<ActivityFormType>();

  const projectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const filtered = valueList.filter((value) => {
      return value.toLowerCase().search(values.projectTitle?.toLowerCase() as string) !== -1;
    });
    setFilteredValues(filtered);
  }, [valueList, values.projectTitle]);

  return (
    <>
      <InputField
        name={name}
        label={label}
        className={className}
        inputRef={projectRef}
        required={required}
        onClick={() => setIsOpen(true)}
        onBlurCapture={() => {
          if (isHovered) return;
          setIsOpen(false);
        }}
      />
      {isOpen && filteredValues.length > 0 && (
        <Popover className="z-50" targetRef={projectRef} position={positionMatchWidth}>
          <div className={optionContainerClassName}>
            {filteredValues.map((value, index) => (
              <p
                key={index}
                className={optionItemClassName}
                onClick={() => {
                  setFieldValue("projectTitle", value);
                  setIsOpen(false);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {value}
              </p>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}
