import React from "react";

import { Form, Formik, FormikHelpers, FormikProps } from "formik";

import { motion } from "framer-motion";

import { Button } from "@tiller/core";
import { InputProps, TextareaProps } from "@tiller/form-elements";

import { InputField, TextareaField } from "@tiller/formik-elements";
import { LoadingIcon } from "@tiller/icons";

import { landingPage } from "../../../api/backend_paths";

import useHandleError from "../../../hook/useHandleError";

type ContactFormType = {
  email: string;
  message: string;
};

const initialValues = {
  email: "",
  message: "",
} as ContactFormType;

export default function ContactFormSection() {
  const { handleError } = useHandleError();
  const [isLoading, setIsLoading] = React.useState(false);

  function handleSubmit(values: ContactFormType, formikHelpers: FormikHelpers<ContactFormType>) {
    setIsLoading(true);
    fetch(landingPage.sendInquiry(), {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        formikHelpers.resetForm();
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <motion.div
      className="w-full flex"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ amount: 0.2 }}
    >
      <section
        className="w-full flex flex-col lg:flex-row gap-16 xl:gap-24 pt-24 pb-40 lg:py-40 lg:pb-96"
        id="contact-form"
      >
        <div>
          <h2 className="landing-h2 mb-4">Imate pitanja za nas?</h2>
          <p className="landing-p-md">Zainteresirani ste, ili nam se samo želite javiti s pitanjem?</p>
          <p className="landing-p-md">Ispunite jednostavnu formu i pošaljite.</p>
          <p className="landing-p-md">Ubrzo Vam se javljamo :)</p>
        </div>

        <div className="lg:w-1/2 px-4 py-5 rounded-lg shadow bg-white">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values }: FormikProps<ContactFormType>) => (
              <Form className="flex flex-col items-end space-y-4">
                <RoundedInput name="email" label="E-mail" className="w-full" />
                <RoundedTextarea
                  value={values.message}
                  name="message"
                  label="Poruka"
                  className="w-full"
                  textareaClassName="h-40 mb-1"
                />
                <Button type="submit" className="rounded-full bg-indigo-600 lg:px-8 lg:py-2 font-medium">
                  {isLoading ? <LoadingIcon /> : "Pošalji"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </motion.div>
  );
}

const roundedInputProps = {
  borderRadius: "rounded-full",
  container: {
    base: "rounded-full shadow-sm",
  },
};

const RoundedInput = (props: InputProps) => <InputField {...props} inputTokens={roundedInputProps} />;

const roundedTextareaProps = {
  borderRadius: "rounded-xl",
  container: {
    base: "rounded-xl shadow-sm",
  },
};

const RoundedTextarea = (props: TextareaProps) => <TextareaField {...props} tokens={roundedTextareaProps} />;
