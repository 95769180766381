import React from "react";

import { motion } from "framer-motion";

import adjustmentIcon from "../../../assets/img/valueIcons/adjustmentIcon.svg";
import clickIcon from "../../../assets/img/valueIcons/clickIcon.svg";
import folderIcon from "../../../assets/img/valueIcons/folderIcon.svg";
import listIcon from "../../../assets/img/valueIcons/listIcon.svg";
import ValueCard from "../components/ValueCard";

export default function ValuesSection() {
  return (
    <section className="w-full flex flex-col gap-12 py-24 lg:py-40" id="values">
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ amount: 0.2 }}>
        <div className="flex flex-col gap-4">
          <h2 className="landing-h2"> Vrijednosti aplikacije</h2>
          <p className="landing-p-md mb-4">
            Olakšavamo udrugama svakodnevni rad kako bi se mogle fokusirati na društveno korisne aktivnosti, a smanjiti
            papirologiju.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 md:gap-8 xl:gap-12">
          <ValueCard
            icon={folderIcon}
            heading="Administracija na jednom mjestu"
            paragraph="Transparentno praćenje bitnih osoba i važnih zadataka udruge."
          ></ValueCard>
          <ValueCard
            icon={adjustmentIcon}
            heading="
            Personalizacija po vašoj mjeri"
            paragraph="Pomoću dodatnih polja lako proširite aplikaciju s podacima koji su Vama bitni i tako prilagodite aplikaciju potrebama svoje udruge."
          ></ValueCard>
          <ValueCard
            icon={clickIcon}
            heading="Do natječaja u par klikova"
            paragraph="U samo par klikova ubrzajte praćenje natječaja i pripremu projekata. "
          ></ValueCard>
          <ValueCard
            icon={listIcon}
            heading="Izvezite bitne podatke u excel"
            paragraph="Ubrzajte pisanje izvještaja i izvlačenje statistike jednim klikom na izvoz u excel. "
          ></ValueCard>
        </div>
      </motion.div>
    </section>
  );
}
