import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Badge, Tabs } from "@tiller/core";
import { Icon } from "@tiller/icons";

import CompetitionList from "./components/CompetitionList";
import FailedFetchAlert from "./components/FailedFetchAlert";
import FundingSourcesList from "./components/FundingSourcesList";
import MySourcesList from "./components/MySourcesList";
import { usePermissions } from "../../PermissionsProvider";
import { competitions, sources, user } from "../../api/backend_paths";

import { routes } from "../../api/paths";
import Header from "../../components/Header";
import TabLabel from "../../components/TabLabel";
import { COMPETITION_FILTER_INITIAL_VALUES } from "../../constants/FilterConstants";
import CompetitionContextWrapper from "../../context/FavoritesCompetitionContext";
import { PagesStateContext } from "../../context/PagesStateContext";
import useHandleError from "../../hook/useHandleError";
import {
  Area,
  Competition,
  CompetitionFilters,
  CompetitionFilterValues,
  CompetititionType,
  FailedSourceFetch,
  FundingSource,
  GetCompetitionsValues,
  Notification,
  Paging,
} from "../../types";
import { checkIfForbidden, dateReformat, serializeParameterSuffix } from "../../util/GeneralUtil";

export type CompetitionsProps = {
  allFiltersValues: CompetitionFilters;
  setFilterValues: (values: CompetitionFilters) => void;
};

export default function Competitions({ allFiltersValues, setFilterValues }: CompetitionsProps) {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const { competitionsTabIndex, setCompetitionsTabIndex } = useContext(PagesStateContext);
  const {
    allCompetitionsRecordPaging,
    updateAllCompetitionsRecordPaging,
    customCompetitionsRecordPaging,
    updateCustomCompetitionsRecordPaging,
    favoriteCompetitionsRecordPaging,
    updateFavoriteCompetitionsRecordPaging,
    appliedCompetitionsRecordPaging,
    updateAppliedCompetitionsRecordPaging,
  } = useContext(PagesStateContext);

  const [allCompetitionList, setAllCompetitionList] = useState<Competition[]>([]);
  const [totalElementsAll, setTotalElementsAll] = useState(0);
  const [totalElementsApplied, setTotalElementsApplied] = useState(0);
  const [totalElementsCustom, setTotalElementsCustom] = useState(0);
  const [totalElementsFavorite, setTotalElementsFavorite] = useState(0);
  const [appliedCompetitionList, setAppliedCompetitionList] = useState<Competition[]>([]);
  const [favoriteCompetitionList, setFavoriteCompetitionList] = useState<Competition[]>([]);
  const [customCompetitionList, setCustomCompetitionList] = useState<Competition[]>([]);
  const [defaultSources, setDefaultSources] = useState<FundingSource[]>([]);
  const [createdSources, setCreatedSources] = useState<FundingSource[]>([]);
  const [failedSites, setFailedSites] = useState<FailedSourceFetch[]>([]);
  const [isAllLoading, setIsAllLoading] = useState<boolean>(false);
  const [isAllSearchSuccessful, setIsAllSearchSuccessful] = useState<boolean>(true);
  const [isAllListEmpty, setIsAllListEmpty] = useState<boolean>(false);
  const [allAreas, setAllAreas] = useState<Area[]>([]);

  const [isCustomLoading, setIsCustomLoading] = useState<boolean>(false);
  const [isCustomSearchSuccessful, setIsCustomSearchSuccessful] = useState<boolean>(true);
  const [isCustomListEmpty, setIsCustomListEmpty] = useState<boolean>(false);

  const [isFavoriteLoading, setIsFavoriteLoading] = useState<boolean>(false);
  const [isFavoriteSearchSuccessful, setIsFavoriteSearchSuccessful] = useState<boolean>(true);
  const [isFavoriteListEmpty, setIsFavoriteListEmpty] = useState<boolean>(false);

  const [isAppliedLoading, setIsAppliedLoading] = useState<boolean>(false);
  const [isAppliedSearchSuccessful, setIsAppliedSearchSuccessful] = useState<boolean>(true);
  const [isAppliedListEmpty, setIsAppliedListEmpty] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [sortType, setSortType] = useState<string | undefined>(undefined);
  const [isForbidden, setIsForbidden] = useState(false);

  const userId = "1";
  const notificationType = "COMPETITION";
  const associationId = "1";

  const navigate = useNavigate();

  const getRequest = useCallback(
    (path: string, thenFunction: (data: any) => void) => {
      fetch(path)
        .then(handleError)
        .then(thenFunction)
        .catch((err) => {
          console.log(err);
          setIsAllLoading(false);
          setIsAppliedLoading(false);
          setIsCustomLoading(false);
          setIsFavoriteLoading(false);
          checkIfForbidden(err, setIsForbidden);
        });
    },
    [handleError]
  );

  const createdSourcesRefresh = () => {
    getRequest(sources.getCreatedSources, (data) => {
      setCreatedSources(data);
    });
  };

  useEffect(() => {
    getRequest(sources.getCreatedSources, (data) => {
      setCreatedSources(data);
    });
    getRequest(sources.getDefaultSources, (data) => {
      setDefaultSources(data);
    });
    getRequest(competitions.getAreas, (data) => {
      setAllAreas(data);
    });
  }, [getRequest]);

  const getCompetitionParameters = (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
    return {
      ...values,
      ...filterValues,
      source: filterValues.source.join(),
      area: filterValues.area.join(),
      dateFrom: dateReformat(filterValues.dateFrom),
      dateTo: dateReformat(filterValues.dateTo),
      associationId,
    };
  };

  const getAllCompetitionsList = useCallback(
    (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
      setAllCompetitionList([]);
      setIsAllLoading(true);
      setIsAllSearchSuccessful(true);
      setIsAllListEmpty(false);
      getRequest(
        competitions.getAll(
          serializeParameterSuffix({
            ...getCompetitionParameters(values, filterValues),
            competitionType: CompetititionType.NORMAL,
            sortType: sortType ? sortType : "",
          })
        ),
        (data) => {
          setTotalElementsAll(data.totalElements);
          setAllCompetitionList([...data.content]);
          data.content.length === 0 && setIsAllSearchSuccessful(false);
          if (
            values.keyword === "" &&
            data.content.length === 0 &&
            filterValues === COMPETITION_FILTER_INITIAL_VALUES
          ) {
            setIsAllListEmpty(true);
            setIsAllSearchSuccessful(true);
          }
          setIsAllLoading(false);
        }
      );
    },
    [getRequest, sortType]
  );

  const getAppliedCompetitionsList = useCallback(
    (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
      setAppliedCompetitionList([]);
      setIsAppliedLoading(true);
      setIsAppliedSearchSuccessful(true);
      setIsAppliedListEmpty(false);
      getRequest(
        competitions.getAll(
          serializeParameterSuffix({
            ...getCompetitionParameters(values, filterValues),
            competitionType: CompetititionType.APPLIED,
            sortType: sortType ? sortType : "",
          })
        ),
        (data) => {
          setTotalElementsApplied(data.totalElements);
          setAppliedCompetitionList([...data.content]);
          data.content.length === 0 && setIsAppliedSearchSuccessful(false);
          if (values.keyword === "" && data.content.length === 0) {
            setIsAppliedListEmpty(true);
            setIsAppliedSearchSuccessful(true);
          }
          setIsAppliedLoading(false);
        }
      );
    },
    [getRequest, sortType]
  );

  const getCustomCompetitionsList = useCallback(
    (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
      setCustomCompetitionList([]);
      setIsCustomLoading(true);
      setIsCustomSearchSuccessful(true);
      setIsCustomListEmpty(false);
      getRequest(
        competitions.getAll(
          serializeParameterSuffix({
            ...getCompetitionParameters(values, filterValues),
            competitionType: CompetititionType.CUSTOM,
            sortType: sortType ? sortType : "",
          })
        ),
        (data) => {
          setTotalElementsCustom(data.totalElements);
          setCustomCompetitionList([...data.content]);
          data.content.length === 0 && setIsCustomSearchSuccessful(false);
          if (values.keyword === "" && data.content.length === 0) {
            setIsCustomListEmpty(true);
            setIsCustomSearchSuccessful(true);
          }
          setIsCustomLoading(false);
        }
      );
    },
    [getRequest, sortType]
  );

  const getFavoriteCompetitionsList = useCallback(
    (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
      setFavoriteCompetitionList([]);
      setIsFavoriteLoading(true);
      setIsFavoriteSearchSuccessful(true);
      setIsFavoriteListEmpty(false);
      getRequest(
        competitions.getAll(
          serializeParameterSuffix({
            ...getCompetitionParameters(values, filterValues),
            competitionType: CompetititionType.FAVORITE,
            sortType: sortType ? sortType : "",
          })
        ),
        (data) => {
          if (data.content.length === 0 && values.pageNumber > 0) {
            getFavoriteCompetitionsList({ ...values, pageNumber: values.pageNumber - 1 }, filterValues);
          } else {
            setTotalElementsFavorite(data.totalElements);
            setFavoriteCompetitionList([...data.content]);
            data.content.length === 0 && setIsFavoriteSearchSuccessful(false);
            if (values.keyword === "" && data.content.length === 0) {
              setIsFavoriteListEmpty(true);
              setIsFavoriteSearchSuccessful(true);
            }
            setIsFavoriteLoading(false);
          }
        }
      );
    },
    [getRequest, sortType]
  );

  const setAllFilters = useCallback(
    (all: CompetitionFilterValues) => {
      setFilterValues({ ...allFiltersValues, all });
    },
    [allFiltersValues, setFilterValues]
  );

  const setAppliedFilters = useCallback(
    (applied: CompetitionFilterValues) => {
      setFilterValues({ ...allFiltersValues, applied });
    },
    [allFiltersValues, setFilterValues]
  );

  const setFavoriteFilters = useCallback(
    (favorite: CompetitionFilterValues) => {
      setFilterValues({ ...allFiltersValues, favorite });
    },
    [allFiltersValues, setFilterValues]
  );

  const setCustomFilters = useCallback(
    (custom: CompetitionFilterValues) => {
      setFilterValues({ ...allFiltersValues, custom: custom });
    },
    [allFiltersValues, setFilterValues]
  );

  const afterFavoriteChange = useCallback(
    (id: string, isFavorite: boolean) => {
      setAllCompetitionList(
        allCompetitionList.map((value) => (value.id === id ? { ...value, favorite: isFavorite } : value))
      );
      setAppliedCompetitionList(
        appliedCompetitionList.map((value) => (value.id === id ? { ...value, favorite: isFavorite } : value))
      );
      setCustomCompetitionList(
        customCompetitionList.map((value) => (value.id === id ? { ...value, favorite: isFavorite } : value))
      );
    },
    [allCompetitionList, appliedCompetitionList, customCompetitionList]
  );

  const changeFavorite = useCallback(
    (id: string, isFavorite: boolean, values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => {
      if (isFavorite) {
        fetch(competitions.setCompetitionFavorite(id ?? "-1", serializeParameterSuffix({ associationId })), {
          method: "POST",
        })
          .then(handleError)
          .then(() => {
            afterFavoriteChange(id, isFavorite);
            getFavoriteCompetitionsList(values, filterValues);
          })
          .catch((err) => console.log(err));
      } else {
        fetch(competitions.setCompetitionFavorite(id ?? "-1", serializeParameterSuffix({ associationId })), {
          method: "DELETE",
        })
          .then(handleError)
          .then(() => {
            afterFavoriteChange(id, isFavorite);
            getFavoriteCompetitionsList(values, filterValues);
          })
          .catch((err) => console.log(err));
      }
    },
    [afterFavoriteChange, getFavoriteCompetitionsList, handleError]
  );

  const mapAreas = () => allAreas.map((value) => value.name);

  useEffect(() => {
    setFailedSites([]);
    defaultSources.forEach(
      (source) =>
        source.errorOccurred &&
        setFailedSites((prevSites) => [
          ...prevSites,
          {
            name: source.name,
            url: source.url,
          },
        ])
    );
  }, [defaultSources]);

  useEffect(() => {
    getRequest(user.getNotifications(serializeParameterSuffix({ notificationType })), (data) => {
      setNotifications(data ? data : []);
    });
  }, [getRequest]);

  return (
    <>
      <Header
        title="Natječaji"
        hideSecondaryButton
        primaryButtonIcon="plus"
        primaryButtonText="Kreiraj novi natječaj"
        onPrimaryClick={() => navigate(routes.NEW_COMPETITION_URL)}
        hidePrimaryButton={!permissions?.includes("COMPETITION_CREATE_UPDATE")}
      />
      {notifications.length > 0 && (
        <div className="mt-2">
          {notifications.map((notification, index) => (
            <FailedFetchAlert {...notification} userId={userId} key={index} />
          ))}
        </div>
      )}
      <CompetitionContextWrapper>
        <Tabs defaultIndex={competitionsTabIndex}>
          <Tabs.Tab
            label={<TabLabel title="Svi" count={totalElementsAll} />}
            onClick={(tabIndex) => setCompetitionsTabIndex(tabIndex)}
          >
            {
              <CompetitionList
                updatePagingContextIndex={(page: Paging) => updateAllCompetitionsRecordPaging(page)}
                pagingContextIndex={allCompetitionsRecordPaging}
                endPoint={competitions}
                competitionList={allCompetitionList}
                totalElements={totalElementsAll}
                getCompetitionsList={getAllCompetitionsList}
                allSources={[...defaultSources, ...createdSources]}
                isLoading={isAllLoading}
                isSearchSuccessful={isAllSearchSuccessful}
                isListEmpty={isAllListEmpty}
                changeFavorite={changeFavorite}
                favoriteFilterValues={allFiltersValues.favorite}
                allAreas={mapAreas()}
                setFilterValues={setAllFilters}
                filterValues={allFiltersValues.all}
                sortType={sortType}
                setSortType={setSortType}
                favoriteTab={false}
                isForbidden={isForbidden}
              />
            }
          </Tabs.Tab>
          <Tabs.Tab
            label={<TabLabel title="Moji natječaji" count={totalElementsCustom} />}
            onClick={(tabIndex) => setCompetitionsTabIndex(tabIndex)}
          >
            {
              <CompetitionList
                updatePagingContextIndex={(page: Paging) => updateCustomCompetitionsRecordPaging(page)}
                pagingContextIndex={customCompetitionsRecordPaging}
                endPoint={competitions}
                competitionList={customCompetitionList}
                totalElements={totalElementsCustom}
                allSources={[...defaultSources, ...createdSources]}
                getCompetitionsList={getCustomCompetitionsList}
                isLoading={isCustomLoading}
                isSearchSuccessful={isCustomSearchSuccessful}
                isListEmpty={isCustomListEmpty}
                changeFavorite={changeFavorite}
                allAreas={mapAreas()}
                setFilterValues={setCustomFilters}
                filterValues={allFiltersValues.custom}
                favoriteFilterValues={allFiltersValues.favorite}
                sortType={sortType}
                setSortType={setSortType}
                favoriteTab={false}
                isForbidden={isForbidden}
              />
            }
          </Tabs.Tab>
          {permissions?.includes("COMPETITION_SET_AND_VIEW_FAVORITE") && (
            <Tabs.Tab
              label={<TabLabel title="Favoriti" count={totalElementsFavorite} />}
              onClick={(tabIndex) => setCompetitionsTabIndex(tabIndex)}
            >
              {
                <CompetitionList
                  updatePagingContextIndex={(page: Paging) => updateFavoriteCompetitionsRecordPaging(page)}
                  pagingContextIndex={favoriteCompetitionsRecordPaging}
                  endPoint={competitions}
                  competitionList={favoriteCompetitionList}
                  totalElements={totalElementsFavorite}
                  allSources={[...defaultSources, ...createdSources]}
                  getCompetitionsList={getFavoriteCompetitionsList}
                  isLoading={isFavoriteLoading}
                  isSearchSuccessful={isFavoriteSearchSuccessful}
                  isListEmpty={isFavoriteListEmpty}
                  changeFavorite={changeFavorite}
                  allAreas={mapAreas()}
                  setFilterValues={setFavoriteFilters}
                  filterValues={allFiltersValues.favorite}
                  favoriteFilterValues={allFiltersValues.favorite}
                  sortType={sortType}
                  setSortType={setSortType}
                  favoriteTab={true}
                  isForbidden={isForbidden}
                />
              }
            </Tabs.Tab>
          )}
          {permissions?.includes("COMPETITION_SET_AND_VIEW_APPLIED") && (
            <Tabs.Tab
              label={<TabLabel title="Prijavljeni" count={totalElementsApplied} />}
              onClick={(tabIndex) => setCompetitionsTabIndex(tabIndex)}
            >
              {
                <CompetitionList
                  updatePagingContextIndex={(page: Paging) => updateAppliedCompetitionsRecordPaging(page)}
                  pagingContextIndex={appliedCompetitionsRecordPaging}
                  endPoint={competitions}
                  competitionList={appliedCompetitionList}
                  totalElements={totalElementsApplied}
                  allSources={[...defaultSources, ...createdSources]}
                  getCompetitionsList={getAppliedCompetitionsList}
                  isLoading={isAppliedLoading}
                  isSearchSuccessful={isAppliedSearchSuccessful}
                  isListEmpty={isAppliedListEmpty}
                  changeFavorite={changeFavorite}
                  setFilterValues={setAppliedFilters}
                  filterValues={allFiltersValues.applied}
                  favoriteFilterValues={allFiltersValues.favorite}
                  allAreas={mapAreas()}
                  sortType={sortType}
                  setSortType={setSortType}
                  favoriteTab={false}
                  isForbidden={isForbidden}
                />
              }
            </Tabs.Tab>
          )}

          <Tabs.Tab
            label={
              <>
                <p className="mr-2">Popis izvora</p>
                {failedSites.length > 0 ? (
                  <Icon type="exclamation-circle" color="red" />
                ) : (
                  <Badge className="px-3 py-0">{defaultSources.length}</Badge>
                )}
              </>
            }
          >
            <FundingSourcesList fundingSources={defaultSources} />
          </Tabs.Tab>
          <Tabs.Tab label={<TabLabel title="Moji izvori" count={createdSources.length} />}>
            <MySourcesList fundingSources={createdSources} refresh={createdSourcesRefresh} />
          </Tabs.Tab>
        </Tabs>
      </CompetitionContextWrapper>
    </>
  );
}
