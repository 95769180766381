import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { DescriptionList } from "@tiller/data-display";

import { Icon } from "@tiller/icons";

import { dynamicField } from "../api/backend_paths";
import useHandleError from "../hook/useHandleError";
import { dynamicFieldType } from "../types";
import { formatDropdownDynamicField } from "../util/DynamicFieldUtil";
import { serializeParameterSuffix } from "../util/GeneralUtil";

type dynamicFieldInputType = {
  formId: string[];
  dynamicData: Record<string, any>;
};

export default function DynamicFieldView({ formId, dynamicData }: dynamicFieldInputType) {
  const { handleError } = useHandleError();

  const [dynamicFields, setDynamicFields] = useState<dynamicFieldType[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: formId.join(",") })))
      .then(handleError)
      .then((data) => setDynamicFields(data));
  }, [formId, handleError, location, navigate]);

  return (
    <>
      {dynamicFields.map((field) => {
        if (dynamicData && dynamicData[field.code] && field.fieldType === "CHECKBOX") {
          let values = "";
          Object.keys(dynamicData[field.code])?.forEach((key) => {
            if (dynamicData && dynamicData[field.code][key]) {
              values += ", " + key;
            }
          });
          values = values.substring(2);
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item label={<p className="break-all">{field.fieldName}</p>}>
                {values ? values : "-"}
              </DescriptionList.Item>
            </div>
          );
        } else if (field.fieldType === "DATE") {
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item label={<p className="break-all">{field.fieldName}</p>}>
                {dynamicData[field.code] ? dynamicData[field.code] : "-"}
              </DescriptionList.Item>
            </div>
          );
        } else if (field.fieldType === "DATE_TIME") {
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item key={field.code} label={<p className="break-all">{field.fieldName}</p>}>
                {dynamicData[field.code] ? (
                  <div className="flex ">
                    <Icon type="calendar" className="mr-1" />
                    <p>{dynamicData[field.code].substring(0, 12)}</p>
                    <Icon type="clock" variant="outline" className="ml-2 mr-1" />
                    <p className="mr-2">{dynamicData[field.code].substring(13, 19)}</p>
                  </div>
                ) : (
                  "-"
                )}
              </DescriptionList.Item>
            </div>
          );
        } else if (field.fieldType === "TOGGLE") {
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item label={<p className="break-all">{field.fieldName}</p>}>
                {dynamicData[field.code] ? "DA" : "NE"}
              </DescriptionList.Item>
            </div>
          );
        } else if (field.fieldType === "DROPDOWN") {
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item label={<p className="break-all">{field.fieldName}</p>}>
                {formatDropdownDynamicField(dynamicData[field.code])}
              </DescriptionList.Item>
            </div>
          );
        } else {
          return (
            <div key={field.code} className="border-b">
              <DescriptionList.Item label={<p className="break-all">{field.fieldName}</p>}>
                {dynamicData[field.code] ? <span className="break-all">{dynamicData[field.code]}</span> : "-"}
              </DescriptionList.Item>
            </div>
          );
        }
      })}
    </>
  );
}
