import React from "react";

import "../styles/LandingPageStyles.css";

import { Button } from "@tiller/core";

import { routes } from "../../../api/paths";

import arrow from "../../../assets/img/arrow.svg";

export default function BannerSection() {
  const [isMobile] = React.useState<boolean>(window.innerWidth < 641);
  return (
    <section className="w-full flex flex-col gap-6 xl:gap-10 pt-20 pb-56 sm:pt-40 sm:pb-48" id="banner">
      <div className="flex flex-col gap-4">
        <h1 className="landing-h1">Vraćamo Vam vrijeme!</h1>
        <div className="landing-p-lg">
          Vratite se viziji Vaše udruge pomoću aplikacije Altruist koja skraćuje vrijeme administriranja, a pruža
          praćenje podataka i natječaja na jednom mjestu.
        </div>
        <p className="landing-h5 text-slate-700">Sve što vam treba za registraciju su naziv i OIB udruge.</p>
      </div>
      <div className="flex">
        <img src={arrow} alt="arrow" className="mr-6 -mt-4" />
        <Button
          variant="filled"
          color="indigo"
          className="rounded-full px-5 py-2 text-sm md:text-lg md:py-4 lg:py-5 md:px-16 lg:px-20"
          size={isMobile ? "lg" : "xl"}
          onClick={() => window.open(routes.ADMIN_REGISTRATION_URL, "_blank")}
        >
          Registriraj se i koristi aplikaciju
        </Button>
      </div>
    </section>
  );
}
