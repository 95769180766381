export const serializeParameterSuffix = (parameters: Record<string, string | number | undefined | null | object>) => {
  return Object.entries(parameters)
    .filter(([, value]) => value || value === 0)
    .map(([key, value]) => {
      if (value || value === 0) return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`;
      return "";
    })
    .join("&");
};

export const dateReformat = (date?: string) => {
  if (!date) return undefined;
  date = date.replaceAll("-", ".");
  return `${date.substring(date.lastIndexOf(".") + 1)}.${date.substring(
    date.indexOf(".") + 1,
    date.lastIndexOf(".")
  )}.${date.substring(0, date.indexOf("."))}`;
};

export const checkIfForbidden = (err: any, setIsForbidden: (value: boolean) => void) => {
  if (err.status === 403) {
    setIsForbidden(true);
  }
};
