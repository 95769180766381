import React from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { Button } from "@tiller/core";

import { routes } from "../../../api/paths";
import PricingCard from "../components/PricingCard";

export default function PricingSection() {
  const navigate = useNavigate();
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ amount: 0.2 }}>
      <section className="w-full flex flex-col gap-16 py-32" id="pricing">
        <div className="flex flex-col gap-2 md:gap-4">
          <h2 className="landing-h2">Zašto je Altruist besplatan</h2>
          <p className="landing-p-md pb-0">
            CROZ želi napraviti lijepu stvar za udruge, koje svakodnevno nesebično rade za druge. <br />
            Koristite aplikaciju koja će skratiti vrijeme administriranja Vaše udruge i pomoći da se više posvetite
            svojoj viziji.
          </p>
          <div>
            <Button
              variant="outlined"
              color="indigo"
              className="rounded-full mt-2 md:mt-0"
              onClick={() => navigate(routes.ADMIN_REGISTRATION_URL)}
            >
              Registriraj se i koristi aplikaciju
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-11">
          <PricingCard
            heading="Koristite aplikaciju potpuno besplatno"
            bullets={[
              "razvoj i održavanje Altruista je besplatno i tako će i ostati",
              "možete u bilo kojem trenutku odustati od korištenja bez obveze",
              "ako odustajete, možete dobiti svoje podatke izvozom u XLS",
              "podaci su zauvijek samo Vaši",
            ]}
            link="Imate pitanja? Kontaktirajte nas."
          />
          <PricingCard
            heading="CROZ pokriva"
            bullets={[
              "razvoj aplikacije",
              "vođenje i održavanje aplikacije",
              "prostor na serveru za smještaj aplikacije i čuvanje podataka (hosting) na Amazon Web Services",
              "sigurnosne elemente",
            ]}
          />
        </div>
      </section>
    </motion.div>
  );
}
