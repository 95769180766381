import React from "react";

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "@tiller/core";
import { InputField } from "@tiller/formik-elements";

import { routes } from "../../api/paths";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";
import { useEnvironmentVariable } from "../../hook/EnvironmentVariableProvider";

const forgottenPasswordSchema = Yup.object().shape({
  email: Yup.string().required(messages.requiredField),
});

export default function ForgottenPassword() {
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
  });

  const navigate = useNavigate();

  const sendMail = (email: string) => {
    const cognitoUser = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {},
      onFailure: (err) => {
        console.error(err);
      },
      inputVerificationCode: () => {
        navigate(routes.RESET_PASSWORD_URL, { state: { email: email } });
      },
    });
  };

  return (
    <AuthenticationContainer title="Zaboravljena lozinka">
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => sendMail(values.email)}
        validationSchema={forgottenPasswordSchema}
      >
        <Form>
          <p className="mb-4 text-sm">{messages.insertEmailMessage}</p>
          <InputField name="email" label="E-mail" className="mb-6" />
          <Button className="w-full">Pošalji</Button>
        </Form>
      </Formik>
    </AuthenticationContainer>
  );
}
