import React, { useState } from "react";

import { Form, FormikProps } from "formik";

import * as Yup from "yup";

import { Button } from "@tiller/core";

import { FormContainer, InputField } from "@tiller/formik-elements";

import { association as association_path } from "../../../api/backend_paths";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { Association } from "../../../types";

const oib = require("oib.js");

const UpdateAssociationValidationSchema = Yup.object().shape({
  name: Yup.string().required(messages.requiredField),
  oib: Yup.string().test("oibValidation", messages.invalidOIBFormat, (value) => {
    return value === undefined || oib.validate(value);
  }),
});

type UpdateAssociationPopupProps = {
  onSubmit: () => void;
  association: Association | undefined;
};

export default function UpdateAssociationPopup({ onSubmit, association }: UpdateAssociationPopupProps) {
  const { handleError } = useHandleError();

  const [associationNameError, setAssociationNameError] = useState("");

  const updateAssociation = (values: any) => {
    setAssociationNameError("");
    fetch(association_path.updateAssociation, {
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        onSubmit();
        localStorage.setItem("associationName", values.name);
      })
      .catch((err) => {
        console.log(err);
        err.json().then((data: any) => {
          if (data.message === "Association name exists") {
            setAssociationNameError(messages.associationNameUsed);
          }
        });
      });
  };

  return (
    <FormContainer
      initialValues={association}
      onSubmit={(values) => {
        updateAssociation(values);
      }}
      validationSchema={UpdateAssociationValidationSchema}
    >
      {({ isValid }: FormikProps<Association | undefined>) => (
        <Form className="flex flex-col items-end ">
          <InputField name="name" label="Naziv" className="w-full mb-2" allowClear />
          <p className="text-red-500 text-sm mb-0 mt-0 self-start">{associationNameError}</p>
          <InputField name="oib" label="OIB" className="w-full mb-2" allowClear />
          <Button className="w-1/5" disabled={!isValid}>
            Spremi
          </Button>
        </Form>
      )}
    </FormContainer>
  );
}
