import React, { useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import { Card } from "@tiller/core";

import { Icon } from "@tiller/icons";

import { routes } from "../../../api/paths";

const excelType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export default function FileImport() {
  const navigate = useNavigate();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ multiple: false });
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles.length > 1) {
        setMessage("Možete prenijeti po jednu datoteku");
        return;
      }
      const file = acceptedFiles[0];
      if (file.type !== excelType) {
        setMessage("Odabrana datoteka nije excel");
        return;
      }
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.addEventListener("load", () => {
        const url = fileReader.result;
        if (url) {
          localStorage.setItem("data-import-file-name", file.name);
          localStorage.setItem("data-import-file", url.toString());
          navigate(routes.DATA_IMPORT);
        }
      });
    }
  }, [acceptedFiles, navigate]);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Header.Title>
            <div className="mt-2">Uvezi podatke</div>
          </Card.Header.Title>
        </Card.Header>
        <Card.Body>
          <div className="py-4 px-6.5 border-2 border-dashed border-slate-400 hover:border-primary rounded-md">
            <div {...getRootProps({ className: "dropzone" })}>
              <input className="input-zone" {...getInputProps()} />
              <div className="flex flex-col items-center">
                <Icon type="paper-clip" className="w-12 h-12 text-slate-400 group-hover:text-primary" />
                <p className="dropzone-content text-sm font-medium text-slate-400">Klikni za odabir datoteke</p>
                <p className="dropzone-content text-xs text-slate-500">ili dovuci novu datoteku</p>
              </div>
            </div>
          </div>
          <div>
            <p className="mt-2 text-red-500 text-sm">{message}</p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
