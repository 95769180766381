import React, { useEffect, useState } from "react";

import { BatchItem } from "@rpldy/shared";
import { parseISO } from "date-fns";

import FileDownload from "js-file-download";

import { Button, Card, useLocalPagination } from "@tiller/core";

import { DataTable, useDataTable } from "@tiller/data-display";
import { Date } from "@tiller/date";
import { DragZoneField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";
import { Destination, useFileUpload } from "@tiller/upload";

import DeleteAttachmentPopupContent from "./DeleteAttachmentPopupContent";
import Popup from "./Popup";
import TranslatedPagination from "./TranslatedPagination";
import TranslatedResizer from "./TranslatedReziser";
import { messages } from "../constants/messages";
import { Document } from "../types";
import { getIconFromExtension } from "../util/getIconFromExtension";

type AttachmentsProps = {
  attachments: Document[];
  fieldName?: string;
  uploadUrl: string;
  downloadUrl: (uuid: string[]) => string;
  deleteUrl: (uuid: string[]) => string;
  showDragZone?: boolean;
  hideDownloadButton?: boolean;
  hideDeleteButton?: boolean;
  hideSecondaryButton?: boolean;
  width?: string;
};

function mapBackendResponse(item: BatchItem, originalFileName: string) {
  return {
    id: item.uploadResponse.data.id,
    name: item.uploadResponse.data.name,
    originalFileName: originalFileName,
    dateOfUpload: item.uploadResponse.data.dateOfUpload,
  } as Document;
}

export default function Attachments({
  attachments,
  fieldName = "attachmentUuids",
  uploadUrl,
  downloadUrl,
  deleteUrl,
  showDragZone = true,
  hideDownloadButton = false,
  hideDeleteButton = false,
  hideSecondaryButton = false,
  width = "",
}: AttachmentsProps) {
  const [files, setFiles] = useState<Document[]>(attachments);
  const useFileUploadHook = useFileUpload<Document>();

  const [dataTableState, dataTableHook] = useDataTable();
  const [paginationState, paginationHook] = useLocalPagination(files, 5);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState<Document[]>([]);

  const { uploadedFileIds, uploadedFiles } = useFileUploadHook;

  useEffect(() => {
    setFiles((oldFiles) => [
      ...oldFiles,
      ...uploadedFiles.filter(
        (f) =>
          !oldFiles.map((old) => old.id).includes(f.id) && !deletedFiles.map((deleted) => deleted.id).includes(f.id)
      ),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFileIds]);

  const uploadDestination: Destination = {
    url: uploadUrl,
    headers: {},
  };

  const onDownload = () => {
    let uuids: string[] = [];

    Object.keys(dataTableState.selected).forEach((key) => {
      uuids.push(key);
    });

    fetch(downloadUrl(uuids))
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res;
        }
      })
      .then((res) => {
        if (dataTableState.selectedCount > 1) {
          let headerLine = res.headers.get("content-disposition");
          let filename = headerLine!.substring(headerLine!.indexOf("=") + 1);
          res.blob().then((blob) => {
            FileDownload(blob, filename);
          });
        } else {
          let fileName = files.find((file) => file.id === uuids[0])!.name;
          res.blob().then((blob) => {
            FileDownload(blob, fileName);
          });
        }
        dataTableHook.setSelected({}, false);
      })
      .catch((err) => console.log(err));
  };

  const onDelete = () => {
    let filesToDelete: Document[] = [];

    Object.keys(dataTableState.selected).forEach((key) => {
      filesToDelete.push(files.find((file) => file.id === key)!);
    });
    setDeletedFiles(filesToDelete);
    setIsModalOpen(true);
  };

  const deleteFiles = () => {
    let uuids: string[] = [];

    uuids = deletedFiles.map((file) => file.id);

    fetch(deleteUrl(uuids), {
      method: "DELETE",
      body: JSON.stringify(uuids),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
      })
      .then(() => {
        useFileUploadHook.onDeletedFiles(files.filter((f) => uuids.includes(f.id)));
        setFiles(files.filter((f) => !uuids.includes(f.id)));
      })
      .catch((err) => console.log(err));

    closeAllPopupsAndSelected();
  };

  const closeAllPopupsAndSelected = () => {
    dataTableHook.setSelected({}, false);
    setIsModalOpen(false);
  };

  return (
    <Card className={`mt-8 ${width}`}>
      <Card.Header>
        <div className="flex flex-col md:flex-row md:justify-between gap-y-2 content-center">
          <Card.Header.Title>
            <div className="flex flex-wrap mt-2">
              Dokumenti <span className="text-gray-400 flex flex-wrap ml-1"> (max. 10MB po dokumentu)</span>
            </div>
          </Card.Header.Title>
          <div className="flex gap-4 items-center">
            {!hideDeleteButton && (
              <Button
                variant="outlined"
                leadingIcon={<Icon type="trash" />}
                hidden={hideSecondaryButton}
                disabled={dataTableState.selectedCount === undefined ? true : dataTableState.selectedCount === 0}
                onClick={onDelete}
                type={"button"}
              >
                Izbriši
              </Button>
            )}
            {!hideDownloadButton && (
              <Button
                leadingIcon={<Icon type="download" />}
                disabled={dataTableState.selectedCount === undefined ? true : dataTableState.selectedCount === 0}
                onClick={onDownload}
                type={"button"}
              >
                Preuzmi
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      {showDragZone && (
        <DragZoneField
          name={fieldName}
          hook={useFileUploadHook}
          url={uploadUrl}
          mapUploadResponse={mapBackendResponse}
          allowMultiple
          title="Klikni za odabir dokumenta"
          subtitle="ili dovuci novi dokument"
          containerClassName="-mt-2 p-6"
          destinationOptions={uploadDestination}
        />
      )}
      {files.length > 0 && (
        <>
          <DataTable
            data={files.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)}
            hook={dataTableHook}
            getItemId={(document: Document) => document.id}
          >
            <DataTable.Selector />
            <DataTable.Column header="naziv" id="fileName">
              {(document: Document) => (
                <span className="flex items-center">
                  <img src={getIconFromExtension(document.name)} alt="file" className="w-6 mr-1" />
                  <p>{document.name}</p>
                </span>
              )}
            </DataTable.Column>
            <DataTable.Column header="datum učitavanja" id="fileNameDate" className="font-normal">
              {(document: Document) => (
                <span className="flex">
                  <Date>{parseISO(document.dateOfUpload)}</Date>
                </span>
              )}
            </DataTable.Column>
          </DataTable>
          <Card.Footer className="flex justify-between">
            <TranslatedResizer
              onPageSizeChange={(pageSize: number) => {
                setPageSize(pageSize);
                setPageNumber(0);
              }}
              pageSize={pageSize}
              pageSizes={[5, 10, 15]}
              totalElements={paginationState.totalElements}
            />
            <TranslatedPagination
              paginationState={paginationState}
              paginationHook={paginationHook}
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalElements={paginationState.totalElements}
              onPageChange={setPageNumber}
            />
          </Card.Footer>
        </>
      )}

      <Popup
        primaryButtonText="Da, izbriši."
        title={messages.attachmentsDeletePrompt}
        content={messages.deleteWarning}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={deleteFiles}
        primaryButtonColor="red"
      >
        <DeleteAttachmentPopupContent documents={deletedFiles.map((file) => file.name)} />
      </Popup>
    </Card>
  );
}
