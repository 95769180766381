export const activities = {
  root: (query?: string) => `/api/activities${query ? `?${query}` : ""}`,
  getAll: (pageSize: number, pageNumber: number) => `/api/activities?pageSize=${pageSize}&pageNumber=${pageNumber}`,
  getById: (id: string) => `/api/activities/${id}`,
  uploadAttachment: (id: number) => `/api/activities/${id}/attachment`,
  exportToExcel: (query?: string) => `/api/activities/export${query ? `?${query}` : ""}`,
  addNote: (activityId: string) => `/api/activities/${activityId}/note`,
  getTitles: (activityType: string) => `/api/activities/titles${activityType ? `?activityType=${activityType}` : ""}`,
  deleteNote: (id: number) => `/api/activities/note/${id}`,
  updateNote: `/api/activities/note`,
  uploadAttachmentWoActivity: `/api/activities/attachment`,
  getAttachments: (uuids: string[]) => `/api/activities/attachment/download?uuids=${uuids.map((uuid) => uuid)}`,
  deleteAttachments: (uuids: string[]) => `/api/activities/attachment?uuids=${uuids.map((uuid) => uuid)}`,
  deleteActivityById: (id: number | null | undefined) => `/api/activities/${id}`,
};

export const notes = {
  root: `/api/notes`,
};

export const evidence = {
  getAll: (query?: string) => `/api/association-member${query ? `?${query}` : ""}`,
  getAllWithActivity: () => `/api/association-member/with-activity`,
  getAllWithoutPagination: () => `/api/association-member?pageable=false`,
  getMemberCount: () => `/api/association-member/get-member-count`,
  getMemberByCode: (code: string) => `/api/association-member/${code}`,
  getUniqueCode: () => `/api/association-member/get-unique-code`,
  getAssociationMemberRoute: () => `/api/association-member`,
  getAssociationRoute: () => `/api/associations`,
  getAttachmentDownloadRoute: (uuids: string[]) => `/api/attachment/download?uuids=${uuids.map((uuid) => uuid)}`,
  saveAttachment: (code: string) => `/api/association-member/${code}/attachment`,
  addNote: (code: string) => `/api/association-member/${code}/note`,
  sendInvite: (email: string, roleId: number | null) => `/api/associations/invite-user?email=${email}&roleId=${roleId}`,
  getUsers: `/api/associations/get-users`,
  exportToExcel: (query?: string) => `/api/association-member/export${query ? `?${query}` : ""}`,
  deleteNote: (id: number) => `/api/association-member/note/${id}`,
  updateNote: `/api/association-member/note`,
  uploadAttachmentWoProfile: `/api/association-member/attachment`,
  getAttachments: (uuids: string[]) => `/api/association-member/attachment/download?uuids=${uuids.map((uuid) => uuid)}`,
  deleteAttachments: (uuids: string[]) => `/api/association-member/attachment?uuids=${uuids.map((uuid) => uuid)}`,
  deleteMemberByCode: (code: string | undefined) => `/api/association-member/${code}`,
};

export type CompetitionEndpoint = {
  root: string;
  getAll: (query: string) => string;
};

export const competitions = {
  root: "/api/competitions",
  getAll: (query?: string) => `/api/competitions${query ? `?${query}` : ""}`,
  getCompetitionNumbers: (query?: string) => `/api/competitions/numberOfCompetitions${query ? `?${query}` : ""}`,
  getCompetitionDetails: (competitionId: string, query?: string) =>
    `/api/competitions/${competitionId}${query ? `?${query}` : ""}`,
  getAreas: "/api/competitions/areas",
  setCompetitionApplied: (competitionId: string, query?: string) =>
    `/api/competitions/applied/${competitionId}${query ? `?${query}` : ""}`,
  addNote: (competitionId: string, query?: string) =>
    `/api/competitions/${competitionId}/note${query ? `?${query}` : ""}`,
  setCompetitionFavorite: (competitionId: string, query?: string) =>
    `/api/competitions/favorites/${competitionId}${query ? `?${query}` : ""}`,
  deleteNote: (id: number) => `/api/competitions/note/${id}`,
  updateNote: `/api/competitions/note`,
  uploadAttachmentWoCompetition: `/api/competitions/attachment`,
  uploadAttachment: (id: number) => `/api/competitions/${id}/attachment`,
  getAttachments: (uuids: string[]) => `/api/competitions/attachment/download?uuids=${uuids.map((uuid) => uuid)}`,
  deleteAttachments: (uuids: string[]) => `/api/competitions/attachment?uuids=${uuids.map((uuid) => uuid)}`,
  createCompetition: "/api/competitions/create",
  delete: (id: string) => `/api/competitions/${id}`,
};

export const sources = {
  root: "/api/competitions/sources",
  getCreatedSources: "/api/competitions/sources/created",
  getDefaultSources: "/api/competitions/sources/default",
  delete: (id: number) => `/api/competitions/sources/${id}`,
};

export const attachment = {
  root: "/api/attachment",
};

export const user = {
  saveUser: (code?: string) => `/api/user/register${code ? `?inviteToken=${code}` : ""}`,
  addUserToAssociation: (code: string) => `/api/user/${code}`,
  getNotifications: (query?: string) => `/api/user/notifications${query ? `?${query}` : ""}`,
  getAll: (isAssignedActivity: boolean) => `/api/user?isAssignedActivity=${isAssignedActivity}`,
  getToken: "/api/token",
  removeToken: "/api/token",
  getCurrentAssociation: () => "/api/user/current-association",
  changeSubscription: "/api/user/change-subscription",
  subscription: "/api/user/get-subscription",
  getCurrentUser: () => `/api/user/current`,
  updateUser: "/api/user",
};

export const notification = {
  removeNotification: (notificationId: string, query?: string) =>
    `/api/notifications/${notificationId}${query ? `?${query}` : ""}`,
};

export const dynamicField = {
  saveDynamicField: () => "/api/dynamic-field",
  getDynamicFields: (query?: string) => `/api/dynamic-field/${query ? `?${query}` : ""}`,
  updateDynamicField: () => "/api/dynamic-field",
  deleteDynamicField: (query: string) => `/api/dynamic-field/${query}`,
};

export const inviteToken = {
  getInviteToken: (inviteToken: string) => `/api/invite-token/${inviteToken}`,
};

export const permission = {
  getAllPermissions: () => "/api/role/permission",
};

export const role = {
  getAllRoles: () => "/api/role",
  getRoleById: (id: string | undefined) => `/api/role/${id}`,
  saveRole: () => "/api/role",
  updateRole: (id: string | undefined) => `/api/role/?id=${id}`,
  deleteRole: (id: number) => `/api/role/?id=${id}`,
  addRoleToUser: (userId: number) => `/api/role/user/?userId=${userId}`,
  getAllRolesForInvLink: () => "/api/role/inv-link",
};

export const landingPage = {
  sendInquiry: () => "/api/inquiry",
};

export const dataImport = {
  parseData: "/api/data-import/parse-data",
  importData: "/api/data-import",
  getAttachments: "/api/associations/get-data-import-attachments",
};

export const account = {
  deleteEmployee: (id: number) => `/api/user/${id}`,
  deletePersonalAccount: () => `/api/user`,
};

export const association = {
  deleteAssociation: () => `/api/associations`,
  updateAssociation: "/api/associations",
  current: "/api/associations/current",
  admin: "/api/associations/admin",
};
