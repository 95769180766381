import React, { useState } from "react";

import { Form, Formik, FormikValues } from "formik";

import { Button } from "@tiller/core";
import { TextareaField } from "@tiller/formik-elements";

type NoteFormProps = {
  id?: number;
  content: string;
  buttonText: string;
  onSubmit: any;
  isDisabled?: boolean;
  setIsDisabled?: (isDisabled: boolean) => void;
};

export default function NoteForm({ id, content, buttonText, onSubmit, isDisabled, setIsDisabled }: NoteFormProps) {
  const [numberOfLetters, setNumberOfLetters] = useState(content.length);

  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
    setIsDisabled?.(false);
    setNumberOfLetters(0);
  };

  return (
    <Formik initialValues={{ id: id, content: content }} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className="flex flex-col">
          <TextareaField
            name="content"
            className="my-2 w-full"
            textareaClassName="h-20"
            maxLength={1200}
            disabled={isDisabled}
            onChangeCapture={(values: FormikValues) => setNumberOfLetters(values.target.value.length)}
          />
          <div className="flex justify-between">
            <div className="text-gray-400">{numberOfLetters} / 1200</div>
            <div>
              <Button
                color="primary"
                className="mb-4"
                type="submit"
                disabled={values.content.length === 0 || isDisabled}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
