import React, { useEffect, useState } from "react";

import { useField } from "formik";

import { Typography } from "@tiller/core";
import {
  AutocompleteField,
  CheckboxGroupField,
  FieldError,
  InputField,
  RadioGroupField,
  TextareaField,
  ToggleField,
} from "@tiller/formik-elements";

import { MaskedDateInputField } from "./Fields/MaskedDateInputField";
import { MaskedDateTimeInputField } from "./Fields/MaskedDateTimeInputField";
import { dynamicField } from "../api/backend_paths";
import useHandleError from "../hook/useHandleError";
import { dynamicFieldType } from "../types";
import { serializeParameterSuffix } from "../util/GeneralUtil";

type DynamicFieldsProps = {
  formId: string[];
};

export default function DynamicFields({ formId }: DynamicFieldsProps) {
  const { handleError } = useHandleError();

  const [dynamicFields, setDynamicFields] = useState<dynamicFieldType[]>([]);
  const [formikField, , helpers] = useField("dynamicData");

  useEffect(() => {
    fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: formId.join(",") })))
      .then(handleError)
      .then((res) => {
        const newData: Record<string, object | string | boolean | undefined> = {};
        res.forEach((field: dynamicFieldType) => {
          if (Object.keys(formikField.value).indexOf(field.code) === -1) {
            if (field.fieldType === "TOGGLE") {
              newData[field.code] = false;
            } else if (field.fieldType === "CHECKBOX") {
              newData[field.code] = field.dynamicFieldDataset.reduce(
                (acc: Object, value: string) => ({ ...acc, [value]: false }),
                {}
              );
            } else if (field.fieldType === "DATE" || field.fieldType === "DATE_TIME") {
              newData[field.code] = undefined;
            } else {
              newData[field.code] = "";
            }
          }
        });
        helpers.setValue({
          ...formikField.value,
          ...newData,
        });
        setDynamicFields(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  return (
    <>
      {dynamicFields.map((field) => {
        if (
          field.fieldType === "TEXT_FIELD" ||
          field.fieldType === "PHONE" ||
          field.fieldType === "EMAIL" ||
          field.fieldType === "OIB"
        ) {
          return (
            <InputField
              key={field.code}
              name={"dynamicData." + field.code}
              label={<p className="break-all">{field.fieldName}</p>}
              className="lg:w-1/2 w-full"
              required={field.required}
            />
          );
        }
        if (field.fieldType === "TEXT_AREA") {
          return (
            <TextareaField
              key={field.code}
              name={"dynamicData." + field.code}
              label={<p className="break-all">{field.fieldName}</p>}
              className="lg:w-1/2 w-full"
              required={field.required}
            />
          );
        }
        if (field.fieldType === "DATE") {
          return (
            <MaskedDateInputField
              key={field.code}
              name={"dynamicData." + field.code}
              label={<p className="break-all">{field.fieldName}</p>}
              className="lg:w-1/2 w-full"
              required={field.required}
            />
          );
        }
        if (field.fieldType === "DATE_TIME") {
          return (
            <MaskedDateTimeInputField
              key={field.code}
              name={"dynamicData." + field.code}
              label={<p className="break-all">{field.fieldName}</p>}
              className="lg:w-1/2 w-full"
              required={field.required}
            />
          );
        }
        if (field.fieldType === "CHECKBOX") {
          return (
            <div key={field.code}>
              <CheckboxGroupField
                key={field.code}
                name={"dynamicData." + field.code}
                label={<p className="break-all">{field.fieldName}</p>}
                className="lg:w-1/2 w-full"
                vertical
                required={field.required}
              >
                {field.dynamicFieldDataset.map((val) => {
                  return <CheckboxGroupField.Item key={val} value={val} label={val} />;
                })}
              </CheckboxGroupField>
              <FieldError name={"dynamicData." + field.code} className="text-sm mt-2" />
            </div>
          );
        }
        if (field.fieldType === "TOGGLE") {
          return (
            <div key={field.code} className="flex space-x-0 items-end">
              <Typography variant="text" element="p" className="mr-2 font-medium">
                {field.fieldName}
              </Typography>
              <ToggleField name={"dynamicData." + field.code} />
            </div>
          );
        }
        if (field.fieldType === "RADIO") {
          return (
            <div key={field.code}>
              <RadioGroupField
                key={field.code}
                name={"dynamicData." + field.code}
                label={<p className="break-all">{field.fieldName}</p>}
                className="lg:w-1/2 w-full space-y-2"
                vertical
                required={field.required}
              >
                {field.dynamicFieldDataset.map((val) => {
                  return <RadioGroupField.Item key={val} value={val} label={val} />;
                })}
              </RadioGroupField>
            </div>
          );
        }
        if (field.fieldType === "DROPDOWN") {
          return (
            <AutocompleteField
              key={field.code}
              options={field.dynamicFieldDataset}
              name={"dynamicData." + field.code}
              label={<p className="break-all">{field.fieldName}</p>}
              className="lg:w-1/2 w-full"
              required={field.required}
              itemToString={(item: string) => `${item}`}
              filter={(value, option) => option.toLowerCase().includes(value.toLowerCase())}
              allowMultiple
              getMultipleSelectedLabel={(values: string[]) => values.join(", ")}
            />
          );
        }
        return <></>;
      })}
    </>
  );
}
