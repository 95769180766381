import React from "react";

import { Form, Formik } from "formik";

import { Button, Card } from "@tiller/core";
import { AutocompleteField, DateRangeInputField, SelectField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import { COMPETITION_FILTER_INITIAL_VALUES } from "../../../constants/FilterConstants";
import { CompetitionFilterValues } from "../../../types";

type CompetitionFilterProps = {
  locationOptions: string[];
  fundingSourceOptions: string[];
  onSubmit: (values: CompetitionFilterValues) => void;
  filterValues: CompetitionFilterValues;
};

export default function CompetitionFilter({
  locationOptions,
  fundingSourceOptions,
  onSubmit,
  filterValues,
}: CompetitionFilterProps) {
  const commonProps = {
    getOptionLabel: (item: string) => item,
    getOptionValue: (item: string) => item,
  };

  const autocompleteProps = {
    className: "w-full",
    itemToString: (item: string) => item,
    sort: (items: string[]) => items.sort((a, b) => a.localeCompare(b)),
    allowMultiple: true,
  };

  return (
    <Card className="mt-2">
      <Formik initialValues={filterValues} onSubmit={onSubmit}>
        {({ handleReset, handleSubmit, setValues }) => (
          <Form>
            <Card.Body>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <SelectField
                  name="competitionStatus"
                  {...commonProps}
                  placeholder="Svi"
                  options={["Svi", "Otvoreni", "Zatvoreni"]}
                  label="Status natječaja"
                  hideClearButton={true}
                />
                <AutocompleteField
                  name="area"
                  {...commonProps}
                  {...autocompleteProps}
                  getMultipleSelectedLabel={(items: string[]) => {
                    return items.map((item) => item).join(", ");
                  }}
                  options={locationOptions}
                  label="Lokacija"
                  sendOptionValue={false}
                  filter={(name: string, option) => option.toLowerCase().includes(name.toLowerCase())}
                />
                <AutocompleteField
                  name="source"
                  {...commonProps}
                  {...autocompleteProps}
                  getMultipleSelectedLabel={(items: string[]) => {
                    return items.map((item) => item).join(", ");
                  }}
                  options={fundingSourceOptions}
                  label="Izvor natječaja"
                  sendOptionValue={false}
                  filter={(name: string, option) => option.toLowerCase().includes(name.toLowerCase())}
                />
                <DateRangeInputField
                  allowClear={true}
                  popoverPosition="right"
                  start="dateFrom"
                  end="dateTo"
                  label="Istek roka natječaja"
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="flex justify-end">
                <Button
                  className="mr-3"
                  onClick={() => {
                    handleReset();
                    setValues(COMPETITION_FILTER_INITIAL_VALUES);
                    handleSubmit();
                  }}
                  variant="outlined"
                  leadingIcon={<Icon type="refresh" />}
                >
                  Resetiraj
                </Button>
                <Button type="submit">Filtriraj</Button>
              </div>
            </Card.Footer>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
