import React from "react";

import { MaskedInputField } from "@tiller/formik-elements";

type MaskedTimeInputFieldProps = {
  name: string;
  label: string | JSX.Element;
  className?: string;
  required?: boolean;
};

export function MaskedTimeInputField({ name, label, className, required = false }: MaskedTimeInputFieldProps) {
  return (
    <MaskedInputField
      name={name}
      mask={[/[0-2]/, /\d/, ":", /[0-5]/, /\d/]}
      label={label}
      placeholder="ss:mm"
      autoComplete="off"
      className={className}
      keepCharPositions
      required={required}
    />
  );
}
