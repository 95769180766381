import React, { useEffect, useState } from "react";

import { useField } from "formik";
import * as Yup from "yup";

import { Card, IconButton } from "@tiller/core";
import { DataTable, useDataTable } from "@tiller/data-display";
import { SelectField, useDataTableField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import ChangeConfirmation from "./ChangeConfirmation";
import { usePermissions } from "../../../PermissionsProvider";
import { account, role } from "../../../api/backend_paths";
import x from "../../../assets/img/x-circle.svg";
import Popup from "../../../components/Popup";
import PopupWithInput from "../../../components/PopupWithInput";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { Employee, UserRole } from "../../../types";

const employeeListSchema = Yup.object().shape({
  roles: Yup.array().min(1, messages.requiredField),
});

export const deleteAccountActions = [
  "Korisnik više nema pristup aplikaciji",
  "Brišu se svi podaci o korisniku",
  "Korisnik se briše sa zadataka na kojima je dodan kao odgovorna osoba",
  "Profili, zadaci i natječaji koje je korisnik kreirao ostaju",
  "Bilješke i dokumente koje je korisnik dodao/kreirao ostaju",
];

export default function EmployeeListTable() {
  const [, dataTableHook] = useDataTable();
  const [noAdminError, setNoAdminError] = useState<string>("");
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState<boolean | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number>(-1);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);

  const [dataTableFieldState, dataTableFieldHook] = useDataTableField<Employee[]>("items", employeeListSchema);

  const [allRoles, setAllRoles] = useState<UserRole[]>([]);
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [items, , itemsHandler] = useField("items");

  useEffect(() => {
    fetch(role.getAllRoles())
      .then(handleError)
      .then((data) => setAllRoles(data))
      .catch((err) => console.log(err));
  }, [handleError]);

  const deleteEmployee = (index: number, employeeId: number) => {
    fetch(account.deleteEmployee(employeeId), {
      method: "DELETE",
    })
      .then(handleError)
      .then(() => {
        dataTableFieldHook.onRowDelete(index);
        setIsSubmittedSuccessfully(true);
        setIsFailureModalOpen(false);
        setIsConfirmationModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmittedSuccessfully(false);
        setIsConfirmationModalOpen(false);
        setIsFailureModalOpen(true);
        console.log("daa");
      });
  };

  return (
    <Card>
      <Card.Body removeSpacing>
        <DataTable
          data={dataTableFieldState.data}
          hook={dataTableHook}
          rowEditingIndex={dataTableFieldState.rowEditingIndex}
        >
          <DataTable.Column header="korisničko ime" accessor="username" canSort={false} />
          <DataTable.Column header="ime" accessor="firstName" canSort={false} />
          <DataTable.Column header="prezime" accessor="lastName" canSort={false} />
          <DataTable.Column header="email" accessor="email" canSort={false} />
          <DataTable.Column header="role" id="roles" className="w-1/3" canSort={false}>
            {(employee: Employee, index: number, row, { isEditMode }) =>
              isEditMode ? (
                <>
                  <SelectField
                    name={dataTableFieldHook.onRowEditField(index, "roles")}
                    options={allRoles}
                    getOptionLabel={(role: UserRole) => role.name}
                    getOptionValue={(role: UserRole) => role}
                    getMultipleSelectedLabel={(roles: UserRole[]) => (
                      <>{roles.map((role, index) => (index === 0 ? "" : ", ") + role.name)}</>
                    )}
                    allowMultiple
                    hideClearButton
                  />
                  {noAdminError && <p className="text-red-500 text-sm my-2">{noAdminError}</p>}
                </>
              ) : (
                employee.roles.map((role, index) => (index === 0 ? "" : ", ") + role.name)
              )
            }
          </DataTable.Column>
          {permissions?.includes("SETTINGS_ROLE_MANAGEMENT") ? (
            <DataTable.Column header="akcije" id="actions" canSort={false}>
              {(employee: Employee, index, row, { isEditMode }) =>
                isEditMode ? (
                  <div className="flex space-x-2">
                    <IconButton
                      type="x"
                      label="Cancel"
                      onClick={() => {
                        setNoAdminError("");
                        dataTableFieldHook.onRowEditCancel();
                      }}
                      iconClassName="text-red-600"
                      color="red"
                    />
                    <IconButton
                      type="check"
                      label="Save"
                      onClick={() => {
                        setNoAdminError("");
                        const updatedEmployee = dataTableFieldHook.onRowEditSave(index) as Employee;
                        const rolesBeforeEdit = items;
                        if (updatedEmployee.roles.length === 0) return;
                        fetch(role.addRoleToUser(employee.id), {
                          method: "POST",
                          body: JSON.stringify(updatedEmployee.roles),
                          headers: {
                            "Content-Type": "application/json",
                          },
                        })
                          .then(handleError)
                          .catch((err) => {
                            err.json().then((data: any) => {
                              if (data.message === "No admin in association") {
                                dataTableFieldHook.onRowEdit(index);
                                setNoAdminError(messages.noAdmin);
                                itemsHandler.setValue(rolesBeforeEdit.value);
                                dataTableFieldHook.onRowEdit(index);
                              }
                            });
                          });
                      }}
                      color="primary"
                    />
                  </div>
                ) : (
                  <div className="flex justify-start items-center space-x-1 -ml-1">
                    <IconButton
                      type="pencil"
                      label="Edit"
                      onClick={() => dataTableFieldHook.onRowEdit(index)}
                      color="primary"
                    />

                    {!(localStorage.getItem("username") === employee.username) ? (
                      <IconButton
                        type="trash"
                        label="Delete"
                        color="red"
                        onClick={() => {
                          setSelectedEmployee(employee);
                          setDeleteIndex(index);
                          setIsInputModalOpen(true);
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                )
              }
            </DataTable.Column>
          ) : (
            <></>
          )}
        </DataTable>
      </Card.Body>
      <PopupWithInput
        text={selectedEmployee?.username ?? ""}
        content={messages.deletePersonalAccountWarning}
        title={
          <div>
            <div className="mr-10">
              <p className="text-red-500 mb-3 text-xl">
                <span className="text-black">Brisanje korisničkog računa</span>
              </p>
            </div>
            <span className="mb-2">Brisanje računa će učiniti sljedeće:</span>
            <ul className="landing-p-sm list-none list-outside pl-4 mb-2">
              {deleteAccountActions.map((item: string, index: number) => {
                return (
                  <div className="flex space-x-2 mb-1 -ml-4" key={index}>
                    {index < 3 ? (
                      <Icon type="x-circle" color="red" variant="outline" size={6} className="min-w-[24px]" />
                    ) : (
                      <Icon type="check-circle" color="green" variant="outline" size={6} />
                    )}
                    <li key={index}>{item}</li>
                  </div>
                );
              })}
            </ul>
          </div>
        }
        isOpen={isInputModalOpen}
        setIsOpen={setIsInputModalOpen}
        primaryButtonText="Da, izbriši"
        onSubmit={() => deleteEmployee(deleteIndex, selectedEmployee!.id)}
      />
      {isSubmittedSuccessfully === true ? (
        <Popup
          onSubmit={() => {
            setIsSubmittedSuccessfully(null);
            setIsConfirmationModalOpen(false);
          }}
          title=""
          content=""
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          hideButtons={true}
        >
          <ChangeConfirmation text={messages.deleteAccountTitle} className="flex flex-col items-center" />
        </Popup>
      ) : isSubmittedSuccessfully === false ? (
        <>
          <Popup
            onSubmit={() => {
              setIsSubmittedSuccessfully(null);
              setIsConfirmationModalOpen(false);
            }}
            title=""
            content=""
            isOpen={isFailureModalOpen}
            setIsOpen={setIsFailureModalOpen}
            hideButtons={true}
          >
            <ChangeConfirmation src={x} text="Nije dobro" className="flex flex-col items-center" />
          </Popup>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
}
