import React from "react";

import { File } from "@tiller/upload";

import { ActivityTypeValues } from "./pages/ActivityForm/components/ActivityFormSelection";

export type Role =
  | "DONOR"
  | "USER"
  | "VOLUNTEER"
  | "MEMBER"
  | "PARTNER"
  | "EXTERNAL_COLLABORATOR"
  | "EMPLOYEE"
  | "OTHERS";

export enum RolesOnCroatian {
  DONOR = "DONATOR",
  USER = "KORISNIK",
  VOLUNTEER = "VOLONTER",
  MEMBER = "ČLAN",
  PARTNER = "PARTNER",
  EXTERNAL_COLLABORATOR = "VANJSKI SURADNIK",
  EMPLOYEE = "ZAPOSLENIK",
  OTHERS = "OSTALO",
}

export enum personType {
  PHYSICAL_PERSON = "PHYSICAL_PERSON",
  LEGAL_ENTITY = "LEGAL_ENTITY",
}

export enum fieldType {
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  TOGGLE = "TOGGLE",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  OIB = "OIB",
  TEXT_AREA = "TEXT_AREA",
  TEXT_FIELD = "TEXT_FIELD",
  RADIO = "RADIO",
  DROPDOWN = "DROPDOWN",
}

export type Association = {
  name: string;
  oib: string;
};

export type Member = {
  id: number;
  code: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  oib: string;
  memberTypes: Role[];
  associationId: number;
  dateOfBirth: string | null;
  description: string;
  attachments: Document[];
  attachmentUuids: string[];
  activities: Activity[];
  dateOfMembership: string | null;
  dateOfTerminationOfMembership: string | null;
  dynamicData: Record<string, any>;
  personType: personType;
  userCategory: string;
  name: string;
  address: string;
  contactPerson: string;
  notes: Note[];
};

export type MemberFormType = {
  id: number;
  code: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  oib: string;
  memberTypes: { [key in keyof typeof MemberType]: boolean };
  associationId: number;
  dateOfBirth: string | null;
  description: string;
  attachments: Document[];
  attachmentUuids: string[];
  activities: Activity[];
  dateOfMembership: string | null;
  dateOfTerminationOfMembership: string | null;
  dynamicData: Record<string, any>;
  personType: personType;
  userCategory: string;
  name: string;
  address: string;
  contactPerson: string;
};

export enum MemberType {
  ALL = "ALL",
  DONOR = "DONOR",
  VOLUNTEER = "VOLUNTEER",
  USER = "USER",
  MEMBER = "MEMBER",
  PARTNER = "PARTNER",
  EXTERNAL_COLLABORATOR = "EXTERNAL_COLLABORATOR",
  EMPLOYEE = "EMPLOYEE",
  OTHERS = "OTHERS",
}

export type MemberCount = {
  totalCount: number;
  volunteerCount: number;
  donorCount: number;
  userCount: number;
  memberCount: number;
  partnerCount: number;
  externalCollaboratorCount: number;
  employeeCount: number;
  othersCount: number;
};

export type Activity = {
  id: number;
  activityType: string;
  title: string;
  projectTitle?: string;
  description: string;
  persons: MemberFormType[];
  designatedPersons: User[];
  dateOfCreation: string;
  startDate: string;
  endDate: string;
  author: string;
  label?: string[];
};

export type ActivityFormType = {
  id: number | null;
  activityType: ActivityTypeValues;
  author: string;
  title: string;
  projectTitle?: string;
  description: string;
  dateOfCreation: string | null;
  timeOfCreation: string | null;
  startDate: string | null;
  startTime: string;
  endDate?: string | null;
  endTime?: string | null;
  taskDuration: string;
  dateOfUpdate: string;
  tags: string[];
  persons?: ActivityMemberAttendance[];
  notes: Note[];
  designatedPersons?: User[];
  attachmentUuids: string[];
  attachments: Document[];
  numberOfParticipants?: number;
  dynamicData: object;
};

export type Competition = {
  id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  area: string;
  urlToSite: string;
  sourceName: string;
  applied: boolean;
  favorite: boolean;
  custom: boolean;
};

export type CompetitionDetails = {
  id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  area: string;
  urlToSite: string;
  urlToPdf: string;
  sourceName: string;
  sourceUrl: string;
  lastUpdated: string;
  attachments: Document[];
  notes: Note[];
  institution: string;
  institutionLevel: string;
  applied: boolean;
  favorite: boolean;
  totalFundingAmount: string;
  minFundingAmount: string;
  maxFundingAmount: string;
  custom: boolean;
};

export type CompetitionFormType = {
  id: string;
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  area?: string;
  urlToSite?: string;
  urlToPdf?: string;
  sourceName: string;
  attachmentUuids: string[];
  institution?: string;
  institutionLevel?: string;
  applied: boolean;
  favorite: boolean;
  totalFundingAmount?: string;
  minFundingAmount?: string;
  maxFundingAmount?: string;
};

export type Document = {
  dateOfUpload: string;
} & File;

export type CompetitionNumbers = {
  numberOfOpenedCompetitions?: number;
  numberOfClosedCompetitions?: number;
  numberOfFavoriteCompetitions?: number;
  numberOfAppliedCompetitions?: number;
};

export type FundingSource = {
  id: number;
  name: string;
  url: string;
  lastChecked: string;
  scraped: boolean;
  errorOccurred: boolean;
  created: boolean;
};

export type FailedSourceFetch = {
  name: string;
  url: string;
};

export type Area = {
  name: string;
  count: number;
};

export type CompetitionFilterValues = {
  competitionStatus: "Svi" | "Otvoreni" | "Zatvoreni";
  area: string[];
  source: string[];
  dateFrom?: string;
  dateTo?: string;
};

export type CompetitionFilters = {
  all: CompetitionFilterValues;
  applied: CompetitionFilterValues;
  favorite: CompetitionFilterValues;
  custom: CompetitionFilterValues;
};

export type ActivityFilterValues = {
  activityStatus: "Svi" | "Aktivni" | "Neaktivni";
  personCodes?: string[];
  designatedPersonUsernames?: string[];
  startDate?: string;
  endDate?: string;
  dynamicData?: object;
  dynamicDataDate?: object;
  activityType?: string;
  projectTitles?: string[];
  programTitles?: string[];
};

export type MemberFilterValuesType = {
  birthDateFrom?: string;
  birthDateTo?: string;
  membershipDateFrom?: string;
  membershipDateTo?: string;
  membershipTerminationDateFrom?: string;
  membershipTerminationDateTo?: string;
  dynamicData?: object;
  dynamicDataDate?: object;
};

export type Note = {
  id?: number;
  author: string;
  dateOfCreation: string;
  timeOfCreation: string;
  content: string;
  updatedAuthor: string;
  dateOfUpdate: string;
  timeOfUpdate: string;
};

export type Notification = {
  id: string;
  message: string;
};

export type GetCompetitionsValues = {
  pageSize: number;
  pageNumber: number;
  keyword: string;
};

export type DynamicField = {
  code: string;
  id?: number;
  formId: string;
  fieldName: string;
  fieldType: fieldType | null;
  required: boolean;
  isOnFilter: boolean;
  associationId: number;
  dynamicFieldDataset: string[];
};

export type dynamicFieldType = {
  formId: string[];
  code: string;
  fieldName: string;
  fieldType: fieldType | null;
  required: boolean;
  isOnFilter: boolean;
  dynamicFieldDataset: string[];
  associationId: number;
  timesUsed: number;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
};

export enum CompetititionType {
  NORMAL = "NORMAL",
  APPLIED = "APPLIED",
  FAVORITE = "FAVORITE",
  CUSTOM = "CUSTOM",
}

export type FieldItem = {
  code: string;
  label: string;
  icon?: string;
};

export type MemberFilterBaseValues = {
  birthDateFrom?: string;
  birthDateTo?: string;
  membershipDateFrom?: string;
  membershipDateTo?: string;
  membershipTerminationDateFrom?: string;
  membershipTerminationDateTo?: string;
};

export type Paging = {
  pageSize: number;
  pageNumber: number;
};

export type ChangePasswordData = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const Roles = ["DONOR", "USER", "VOLUNTEER", "MEMBER", "PARTNER", "EXTERNAL_COLLABORATOR", "EMPLOYEE", "OTHERS"];

export enum ActivityTypeNames {
  "Svi" = "",
  "Samostalan zadatak" = "PERSONAL_TASK",
  "Zadatak projekta" = "PROJECT",
  "Zadatak programa" = "PROGRAM",
}

export type SortItem = {
  label: string;
  code: string;
};

export const sortOptions: SortItem[] = [
  {
    label: "Zadnje kreirano",
    code: "CHRONOLOGICAL_DESCENDING",
  },
  {
    label: "Prvo kreirano",
    code: "CHRONOLOGICAL_ASCENDING",
  },

  {
    label: "A - Z po imenu",
    code: "ALPHABETICAL_ASCENDING_NAME",
  },
  {
    label: "Z - A po imenu",
    code: "ALPHABETICAL_DESCENDING_NAME",
  },
];

export const sortOptionsForMembers: SortItem[] = [
  ...sortOptions,
  {
    label: "A - Z po prezimenu",
    code: "ALPHABETICAL_ASCENDING_LASTNAME",
  },
  {
    label: "Z - A po prezimenu",
    code: "ALPHABETICAL_DESCENDING_LASTNAME",
  },
];

export const sortOptionsForActivities: SortItem[] = [
  ...sortOptions,
  {
    label: "Datum početka silazno",
    code: "START_DATE_DESCENDING",
  },
  {
    label: "Datum početka uzlazno",
    code: "START_DATE_ASCENDING",
  },
];

export const competitionSortOptions: SortItem[] = [
  {
    label: "Skorije ističe",
    code: "END_DATE_ASCENDING",
  },
  {
    label: "Kasnije ističe",
    code: "END_DATE_DESCENDING",
  },
  {
    label: "Zadnje ažurirano",
    code: "CHRONOLOGICAL_DESCENDING",
  },
];

export type Source = {
  id?: number;
  name: string;
  url: string;
};

export type ActivityMemberAttendance = {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  name: string;
  attended: boolean;
};

export type UserRole = {
  id: number | null;
  name: string;
  permissions: string[];
  mutable: boolean;
};

export type UserRoleFormType = {
  id: number | null;
  name: string;
  permissions: Record<string, boolean>;
  mutable: boolean;
};

export type Employee = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  roles: UserRole[];
};

export type dataImportSheet = {
  sheetName: string;
  form: string;
  startingRow: number;
  data: Example;
};

export type dataImportForm = {
  checkedSheets: Record<string, boolean>;
  data: Record<string, dataImportSheet[]>;
};

export type CheckedSheets = {
  checkedSheets: Record<string, boolean>;
};

export type Example = {
  name: string;
  familyName: string;
};

export type ExcelDataTableType = {
  field: string;
  label: string;
  value: string | undefined;
  required: boolean;
};

export type DataImportMappingType = {
  data: Record<string, DataImportSheetType>;
};

export type DataImportSheetType = {
  startingRow?: number;
  endingRow?: number;
  memberTypes?: Record<MemberType, boolean>;
  mapping?: DataMappingType;
};

export type DataMappingType = {
  name?: string;
  lastName?: string;
  dateOfBirth?: string;
  oib?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  dateOfMembership?: string;
  dateOfTerminationOfMembership?: string;
  userCategory?: string;
  dynamicFields?: Record<string, string>;
};

export type SheetError = {
  insertable: boolean;
  errors: string[];
};

export type DataImportResponse = {
  duplicates: string[];
  errorsPerSheet: Record<string, SheetError>;
};

export type DataImportStepType = {
  step: React.ReactElement;
  sheetName: string;
};

export const ASSOCIATION_MEMBER_PERMISSIONS = [
  "ASSOCIATION_MEMBER_VIEW",
  "ASSOCIATION_MEMBER_CREATE_UPDATE",
  "ASSOCIATION_MEMBER_DELETE",
  "ASSOCIATION_MEMBER_ATTACHMENTS_ADD",
  "ASSOCIATION_MEMBER_ATTACHMENTS_DOWNLOAD",
  "ASSOCIATION_MEMBER_ATTACHMENTS_DELETE",
  "ASSOCIATION_MEMBER_NOTES_VIEW",
  "ASSOCIATION_MEMBER_CRUD_OWN_NOTE",
  "ASSOCIATION_MEMBER_UPDATE_DELETE_OTHERS_NOTE",
  "ASSOCIATION_MEMBER_EXPORT",
  "ACTIVITY_NOTES_VIEW",
  "ACTIVITY_CRUD_OWN_NOTE",
];

export const ACTIVITY_PERMISSIONS = [
  "ACTIVITY_VIEW",
  "ACTIVITY_CREATE_UPDATE",
  "ACTIVITY_DELETE",
  "ACTIVITY_ATTACHMENTS_ADD",
  "ACTIVITY_ATTACHMENTS_DOWNLOAD",
  "ACTIVITY_ATTACHMENTS_DELETE",
  "ACTIVITY_NOTES_VIEW",
  "ACTIVITY_CRUD_OWN_NOTE",
  "ACTIVITY_UPDATE_DELETE_OTHERS_NOTE",
  "ACTIVITY_EXPORT",
];

export const COMPETITION_PERMISSIONS = [
  "COMPETITION_VIEW",
  "COMPETITION_CREATE_UPDATE",
  "COMPETITION_DELETE",
  "COMPETITION_SET_AND_VIEW_APPLIED",
  "COMPETITION_SET_AND_VIEW_FAVORITE",
  "COMPETITION_ATTACHMENTS_ADD",
  "COMPETITION_ATTACHMENTS_DOWNLOAD",
  "COMPETITION_ATTACHMENTS_DELETE",
  "COMPETITION_NOTES_VIEW",
  "COMPETITION_CRUD_OWN_NOTE",
  "COMPETITION_UPDATE_DELETE_OTHERS_NOTE",
];

export const SETTINGS_PERMISSIONS = [
  "SETTINGS_EMPLOYEE_LIST",
  "SETTINGS_SEND_INVITATION_LINK",
  "SETTINGS_ROLE_MANAGEMENT",
  "SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE",
  "SETTINGS_DYNAMIC_FIELDS_DELETE",
];
