import React, { useState } from "react";

import { Form, Formik, FormikProps } from "formik";

import { useLocation, useNavigate } from "react-router-dom";

import { useYupFormConfiguration } from "@nrich/form-configuration-core";
import { Button } from "@tiller/core";
import { InputField } from "@tiller/formik-elements";
import { Icon, LoadingIcon } from "@tiller/icons";

import { evidence } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";
import { Association } from "../../types";

export default function AssociationRegistration() {
  const { handleError } = useHandleError();

  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [nameError, setNameError] = useState("");
  const [oibError, setOibError] = useState("");
  const title = "registracija\nudruge";
  const navigate = useNavigate();
  const location = useLocation();
  const validationSchema = useYupFormConfiguration("association.create-form");

  const onSubmit = (values: Association) => {
    setIsLoading(true);
    fetch(evidence.getAssociationRoute(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(location.state as any)?.jwt}`,
      },
      body: JSON.stringify(values),
    })
      .then(handleError)
      .then((res) => {
        if (res.status === 201) {
          navigate(routes.RECORDS_URL);
          localStorage.setItem("associationName", values.name);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        err.json().then((data: any) => {
          if (data.message.split(" ")[0] === "Naziv") {
            setNameError(data.message);
            setOibError("");
            setResponseError("");
          } else if (data.message.split(" ")[0] === "OIB") {
            setOibError(data.message);
            setNameError("");
            setResponseError("");
          } else {
            setResponseError(data.message);
            setNameError("");
            setOibError("");
          }
          setIsLoading(false);
        });
      });
  };

  return (
    <AuthenticationContainer title={title} responseError={responseError}>
      <div className="flex mb-4">
        <Icon type="check" className="text-green-600 mr-1" />
        <p className="text-sm">
          Administracijski račun <span className="font-semibold">{localStorage.getItem("username")}</span> uspješno je
          registriran.
        </p>
      </div>
      <p className="text-sm text-center mb-4">{messages.associationRegistration}</p>
      <Formik validationSchema={validationSchema} initialValues={{ name: "", oib: "" }} onSubmit={onSubmit}>
        {({ handleSubmit }: FormikProps<Association>) => (
          <Form onSubmit={handleSubmit}>
            <InputField name="name" label="Naziv udruge" required className={nameError === "" ? "mb-4" : "mb-2"} />
            <p className="text-red-500 text-sm mb-2 mt-0">{nameError}</p>
            <InputField name="oib" label="OIB" required className={oibError === "" ? "mb-6" : "mb-2"} />
            <p className="text-red-500 text-sm mb-4 mt-0">{oibError}</p>
            <Button color="primary" variant="filled" className="w-full" disabled={isLoading}>
              {isLoading ? <LoadingIcon size={5} /> : "Registriraj"}
            </Button>
          </Form>
        )}
      </Formik>
    </AuthenticationContainer>
  );
}
