import React, { Dispatch, SetStateAction } from "react";

import { Modal, useModal } from "@tiller/alert";
import { Button } from "@tiller/core";

export type PopupProps = {
  title: React.ReactNode;
  content: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonColor?: string;
  hideButtons?: boolean;
  hideSecondaryButton?: boolean;
  onClose?: () => void;
  children?: JSX.Element;
};

export default function Popup({
  title,
  content,
  isOpen,
  setIsOpen,
  onSubmit,
  primaryButtonText = "Da",
  secondaryButtonText = "Ne",
  primaryButtonColor = "primary",
  hideButtons = false,
  onClose,
  hideSecondaryButton = false,
  children,
}: PopupProps) {
  const modal = useModal();

  return (
    <>
      <Modal
        {...modal}
        isOpen={isOpen}
        onClose={() => {
          onClose?.();
          setIsOpen(false);
        }}
      >
        <>
          <Modal.Content title={<p className="mb-4 -mt-2 md:mb-0 md:-mt-1 md:mx-0 md:mr-10">{title}</p>}>
            {content}
          </Modal.Content>
          {children}
          <Modal.Footer>
            {!hideButtons && (
              <>
                {hideSecondaryButton && (
                  <Button variant="outlined" onClick={() => setIsOpen(false)} className="mr-2">
                    {secondaryButtonText}
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="filled"
                  onClick={() => {
                    setIsOpen(false);
                    onSubmit?.();
                  }}
                  color={primaryButtonColor}
                >
                  {primaryButtonText}
                </Button>
              </>
            )}
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
}
