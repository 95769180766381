import React from "react";

import check from "../../../assets/img/check.svg";

type ChangeConfirmationProps = {
  text: string;
  className?: string;
  src?: string;
  title?: string;
};

export default function ChangeConfirmation({ text, src = check, className = "", title }: ChangeConfirmationProps) {
  return (
    <div className={className}>
      <img src={src} alt="check" className="content-center w-20 mb-4" />
      <p className="font-bold">{title}</p>
      <p>{text}</p>
    </div>
  );
}
