import React from "react";

import { Badge } from "@tiller/core";

type TabLabelProps = {
  title: string;
  count: number;
};

export default function TabLabel({ title, count }: TabLabelProps) {
  return (
    <>
      <p className="mr-2">{title}</p>
      <Badge color="primary" className="px-3 py-0">
        {count}
      </Badge>
    </>
  );
}
