import OIB from "../../../assets/img/fields/OIB.svg";
import checkbox from "../../../assets/img/fields/checkbox.svg";
import dateTime from "../../../assets/img/fields/date-time.svg";
import date from "../../../assets/img/fields/date.svg";
import dropdown from "../../../assets/img/fields/dropdown.svg";
import email from "../../../assets/img/fields/email.svg";
import phone from "../../../assets/img/fields/phone.svg";
import radio from "../../../assets/img/fields/radio.svg";
import textArea from "../../../assets/img/fields/text-area.svg";
import textField from "../../../assets/img/fields/text-field.svg";
import toggle from "../../../assets/img/fields/toggle.svg";

import { FieldItem } from "../../../types";

export const selectOptions: FieldItem[] = [
  {
    code: "TEXT_FIELD",
    label: "Tekst",
    icon: textField,
  },
  {
    code: "TEXT_AREA",
    label: "Duži tekst",
    icon: textArea,
  },
  {
    code: "DATE",
    label: "Datum",
    icon: date,
  },
  {
    code: "DATE_TIME",
    label: "Datum i vrijeme",
    icon: dateTime,
  },
  {
    code: "PHONE",
    label: "Kontakt broj",
    icon: phone,
  },
  {
    code: "EMAIL",
    label: "E-Mail",
    icon: email,
  },
  {
    code: "OIB",
    label: "OIB",
    icon: OIB,
  },
  {
    code: "CHECKBOX",
    label: "Višestruki izbor",
    icon: checkbox,
  },
  {
    code: "TOGGLE",
    label: "DA/NE",
    icon: toggle,
  },
  {
    code: "RADIO",
    label: "Jednostruki izbor",
    icon: radio,
  },
  {
    code: "DROPDOWN",
    label: "Padajući izbornik",
    icon: dropdown,
  },
];
