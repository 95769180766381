import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Badge, Card, Link, Typography } from "@tiller/core";

import { DataTable, useDataTable } from "@tiller/data-display";
import { Icon } from "@tiller/icons";

import { routes } from "../../../api/paths";
import { ActivityMemberAttendance } from "../../../types";

type ParticipantsProps = {
  participants?: ActivityMemberAttendance[];
  width?: string;
};

export default function ViewParticipants({ participants }: ParticipantsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [, dataTableHook] = useDataTable();

  const navigate = useNavigate();
  return (
    <Card className="mt-8 w-full">
      <Card.Header>
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Typography element="h1" variant="title">
                Sudionici
              </Typography>
              <Badge className="ml-2">{participants ? participants.length : 0}</Badge>
            </div>
            <Icon
              className="cursor-pointer"
              type={isOpen ? "chevron-up" : "chevron-down"}
              variant="outline"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      </Card.Header>
      {isOpen && participants!.length > 0 && (
        <div className="p-0 max-h-[270px] overflow-y-auto scrollbar">
          <DataTable data={participants || []} hook={dataTableHook}>
            <DataTable.Column header="ime i prezime" id="name" canSort={false} className="w-3/4">
              {(person: ActivityMemberAttendance) => (
                <Link onClick={() => navigate(routes.VIEW_MEMBER_URL(person.code))}>{person.name}</Link>
              )}
            </DataTable.Column>
            <DataTable.Column header="dolaznost" id="attendance" canSort={false}>
              {(person: ActivityMemberAttendance) => (
                <Badge color={person.attended ? "green" : "red"} className="uppercase">
                  {person.attended ? "prisutan" : "odsutan"}
                </Badge>
              )}
            </DataTable.Column>
          </DataTable>
        </div>
      )}
    </Card>
  );
}
