import { permissionTranslations } from "./permissionTranslations";

export const convertPermissionsToArray = (permissions: Record<string, boolean>) => {
  const selectedPermissions: string[] = [];
  Object.keys(permissions).forEach((key) => {
    if (permissions[key]) {
      selectedPermissions.push(key);
    }
  });
  return selectedPermissions;
};

export const convertPermissionsToObject = (data: string[], module: string) => {
  return data
    .filter((value: string) => value.split("_")[0] === module)
    .map((value: string) => ({
      label: permissionTranslations[value],
      code: value,
    }));
};
