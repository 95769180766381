import React, { useContext, useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";
import readXlsxFile, { Row } from "read-excel-file";

import { Button, Card, IconButton, Typography } from "@tiller/core";
import { DataTable } from "@tiller/data-display";
import { CheckboxGroupField, NumberInputField, SelectField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import DataTablePlaceholder from "./DataTablePlaceholder";
import RowImgPopup from "./RowImgPopup";
import endRowImg from "../../../../src/assets/img/importTips/rowEndImg.png";
import startRowImg from "../../../../src/assets/img/importTips/rowStartImg.png";
import { dynamicField } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import { DataImportContext } from "../../../context/DataImportContext";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import useHandleError from "../../../hook/useHandleError";
import { DataImportSheetType, dynamicFieldType, ExcelDataTableType } from "../../../types";
import {
  convertNumberToString,
  dataImportFinished,
  generateTableDataForDataImport,
  getPreview,
  validMemberTypes,
} from "../../../util/DataImportUtil";
import { serializeParameterSuffix } from "../../../util/GeneralUtil";
import { calculateFormForDynamicFields } from "../../MemberForm/components/MemberFormSelection";

type DataMappingProps = {
  sheetName: string;
  file: File;
};

export default function DataMapping({ sheetName, file }: DataMappingProps) {
  const { values } = useFormikContext<DataImportSheetType>();
  const { isLoading, previousStep } = useWizard();
  const { handleError } = useHandleError();
  const [checkedSheetsArray, setCheckedSheetsArray] = useState<string[]>([]);
  const [formId, setFormId] = useState<string[]>([]);
  const [dynamicFields, setDynamicFields] = useState<dynamicFieldType[]>([]);
  const [isStartRowTooltipOpen, setIsStartRowTooltipOpen] = useState(false);
  const [isEndRowTooltipOpen, setIsEndRowTooltipOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const { updateProgressBar } = useContext(ImportProgressBarContext);

  const { checkedSheets, setData, data, dynamicFieldsRecord, setDynamicFieldsRecord } = useContext(DataImportContext);

  const [sheetData, setSheetData] = useState<Row[]>();

  const navigate = useNavigate();

  useEffect(() => {
    updateProgressBar(false, true, false);
    readXlsxFile(file, { sheet: sheetName }).then((data) => {
      setSheetData(data);
    });
  }, [file, sheetName, updateProgressBar]);

  useEffect(() => {
    if (values?.memberTypes) {
      const newFields = calculateFormForDynamicFields(values?.memberTypes);
      setFormId(newFields);
    }
  }, [sheetName, values]);

  const handleChange = () => {
    const newData = data;
    newData.data[sheetName] = values;
    setData(newData);
    const newDynamicFieldsRecord = dynamicFieldsRecord;
    newDynamicFieldsRecord[sheetName] = dynamicFields;
    setDynamicFieldsRecord(newDynamicFieldsRecord);
  };

  const tableData = React.useMemo(
    () => generateTableDataForDataImport(sheetName, dynamicFields, values, values?.memberTypes!),
    // eslint-disable-next-line
    [sheetName, dynamicFields, values, values?.startingRow]
  );

  useEffect(() => {
    fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: formId.join(",") })))
      .then(handleError)
      .then((res) => {
        setDynamicFields(res);
      });
  }, [formId, handleError]);

  const options = [-1, ...Array.from({ length: sheetData?.at(0)?.length ?? 0 }, (e, i) => i)].map((item) =>
    convertNumberToString(item)
  );

  useEffect(() => {
    if (checkedSheets) {
      const newArray: string[] = [];
      Object.keys(checkedSheets).forEach((key: string) => {
        if (checkedSheets[key]) {
          if (!(key in newArray)) {
            newArray.push(key);
          }
        }
      });
      setCheckedSheetsArray(newArray);
    }
  }, [checkedSheets]);

  const readyForNext = (): boolean => {
    return values?.startingRow !== undefined && values?.endingRow !== undefined && validMemberTypes(values);
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <Card>
          <Card.Header>
            <div className="w-full flex items-center justify-between">
              <Typography variant="h5" element="h5">
                Uvezite podatke za list: {sheetName}
              </Typography>
              <p className="text-zinc-600">
                List {checkedSheetsArray.indexOf(sheetName) + 1} od {checkedSheetsArray.length}
              </p>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="flex flex-col space-y-4">
              <div className="w-full flex flex-col justify-start space-y-4">
                <div className="flex items-center space-x-4">
                  <div className="flex space-x-1">
                    <NumberInputField
                      label="Broj reda u kojemu počinju podaci za uvoz"
                      name={`startingRow`}
                      aria-valuenow={values?.startingRow}
                      required
                    />
                    <IconButton
                      type="information-circle"
                      color="black"
                      label="Upute"
                      onClick={() => setIsStartRowTooltipOpen(true)}
                    ></IconButton>
                  </div>
                </div>
                <div className="flex space-x-1 ">
                  <NumberInputField
                    label="Broj reda u kojemu završavaju podaci za uvoz"
                    name={`endingRow`}
                    aria-valuenow={values?.endingRow}
                    required
                  />
                  <IconButton
                    type="information-circle"
                    color="black"
                    label="Upute"
                    onClick={() => setIsEndRowTooltipOpen(true)}
                  ></IconButton>
                </div>
              </div>
              <div>
                <CheckboxGroupField
                  name={`memberTypes`}
                  label="Odaberite vrstu profila koju imaju osobe koje želite uvesti u aplikaciju na odabrani list:"
                  className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-8 md:w-3/4 lg:w-2/3 xl:w-2/4 2xl:w-2/5"
                  required
                  vertical
                >
                  <CheckboxGroupField.Item label="Član" value="MEMBER" />
                  <CheckboxGroupField.Item label="Donator" value="DONOR" />
                  <CheckboxGroupField.Item label="Korisnik" value="USER" />
                  <CheckboxGroupField.Item label="Partner" value="PARTNER" />
                  <CheckboxGroupField.Item label="Volonter" value="VOLUNTEER" />
                  <CheckboxGroupField.Item label="Vanjski suradnik" value="EXTERNAL_COLLABORATOR" />
                  <CheckboxGroupField.Item label="Zaposlenik" value="EMPLOYEE" />
                  <CheckboxGroupField.Item label="Ostalo" value="OTHERS" />
                </CheckboxGroupField>
              </div>
            </div>
          </Card.Body>
        </Card>

        {!readyForNext() && <DataTablePlaceholder />}

        {readyForNext() && (
          <Card>
            <Card.Header>
              <Typography variant="h4" element="h4">
                Spajanje podataka iz excela s poljima u aplikaciji
              </Typography>
            </Card.Header>
            <Card.Body removeSpacing>
              <DataTable data={tableData ?? []}>
                <DataTable.Column header="slovo stupca" canSort={false} id="field" className="w-1/3">
                  {(item: ExcelDataTableType) => (
                    <>
                      <SelectField key={item.field} name={item.field} options={options} />
                    </>
                  )}
                </DataTable.Column>
                <DataTable.Column header="polje u aplikaciji" canSort={false} id="label">
                  {(item: ExcelDataTableType) => (
                    <>
                      <span>{item.label} </span>
                      {item.required && <span className="text-red-500">*</span>}
                    </>
                  )}
                </DataTable.Column>
                <DataTable.Column header="pregled unesenih podataka" canSort={false} id="value">
                  {(item: ExcelDataTableType) => (
                    <>
                      <span>{getPreview(item.value, values, sheetData)}</span>
                    </>
                  )}
                </DataTable.Column>
              </DataTable>
            </Card.Body>
          </Card>
        )}
      </div>
      <div className="w-full flex items-center justify-between mt-8">
        <Button
          type="button"
          variant="outlined"
          onClick={() => setIsOpen(true)}
          leadingIcon={<Icon variant="outline" type="x" />}
          size="md"
        >
          Odustani
        </Button>
        <div className="flex space-x-5 items-center">
          <Button
            variant="outlined"
            onClick={() => {
              handleChange();
              previousStep();
              if (checkedSheetsArray.indexOf(sheetName) === 0) {
                updateProgressBar(true, false, false);
              }
            }}
            type="button"
            disabled={isLoading}
            leadingIcon={<Icon variant="outline" type="arrow-left" />}
            size="md"
          >
            Prethodno
          </Button>
          <Button
            onClick={() => {
              handleChange();
            }}
            type="submit"
            disabled={isLoading || !readyForNext()}
            trailingIcon={<Icon variant="outline" type="arrow-right" />}
            size="md"
          >
            Sljedeće
          </Button>
        </div>
      </div>
      <Popup
        title={messages.cancelPrompt}
        content={messages.createWarning}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => {
          dataImportFinished();
          navigate(routes.ADMIN_URL);
        }}
      />
      <Popup
        onSubmit={() => {
          setIsStartRowTooltipOpen(false);
        }}
        title={messages.startRowTooltipMessage}
        content=""
        isOpen={isStartRowTooltipOpen}
        setIsOpen={setIsStartRowTooltipOpen}
        hideButtons={true}
      >
        <RowImgPopup image={startRowImg} />
      </Popup>
      <Popup
        onSubmit={() => {
          setIsEndRowTooltipOpen(false);
        }}
        title={messages.endRowTooltipMessage}
        content=""
        isOpen={isEndRowTooltipOpen}
        setIsOpen={setIsEndRowTooltipOpen}
        hideButtons={true}
      >
        <RowImgPopup image={endRowImg} />
      </Popup>
    </>
  );
}
