import React, { useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";

import AddSourceForm from "./AddSourceForm";
import SourcesTable from "./SourcesTable";
import { sources } from "../../../api/backend_paths";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";

import { FundingSource, Source } from "../../../types";

export type MySourcesListProps = {
  fundingSources: FundingSource[];
  refresh: () => void;
};

export default function MySourcesList({ fundingSources, refresh }: MySourcesListProps) {
  const { handleError } = useHandleError();
  const [errorName, setErrorName] = useState("");

  const initialValues = {
    items: fundingSources.map((source) => {
      return { id: source.id, name: source.name, url: source.url } as Source;
    }),
  };

  const onSubmit = (values: Source, formikHelpers: FormikHelpers<Source>) => {
    fetch(sources.root, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        setErrorName("");
        formikHelpers.resetForm();
        refresh();
      })
      .catch((err) => {
        console.error(err);
        setErrorName(messages.sourceName);
      });
  };

  return (
    <>
      <AddSourceForm errorName={errorName} onSubmit={onSubmit} />
      {fundingSources.length > 0 && (
        <Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize={true}>
          <Form>
            <SourcesTable />
          </Form>
        </Formik>
      )}
    </>
  );
}
