import React from "react";

import { Pagination } from "@tiller/core";

type TranslatedPaginationProps = {
  paginationState: any;
  paginationHook: any;
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  onPageChange: any;
};

export default function TranslatedPagination({
  paginationState,
  paginationHook,
  pageNumber,
  pageSize,
  totalElements,
  onPageChange,
}: TranslatedPaginationProps) {
  return (
    <Pagination
      {...paginationState}
      {...paginationHook}
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalElements={totalElements}
      onPageChange={onPageChange}
    >
      {(pageInfo) => (
        <p className="text-sm">
          Prikazuje se <span className="font-semibold">{pageInfo.from}</span> do{" "}
          <span className="font-semibold">{pageInfo.to}</span> od{" "}
          <span className="font-semibold">{pageInfo.totalElements}</span> rezultata
        </p>
      )}
    </Pagination>
  );
}
