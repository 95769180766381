import React, { CSSProperties } from "react";

import { motion } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Icon } from "@tiller/icons";

import images from "../util/images";

export default function TeamSection() {
  const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "white",
  };

  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ amount: 0.2 }}>
      <section className="w-full grid grid-cols-1 xl:grid-cols-2 gap-12 py-24 lg:py-40" id="team">
        <div className="flex flex-col gap-4">
          <h2 className="landing-h2">CROZ tim iza Altruista</h2>
          <p className="landing-p-md">
            Tvrtka CROZ sa svojom grupom mladih ljudi ponosno razvija i održava aplikaciju Altruist koji je napravljen u
            smislu donacije udrugama i društvu u cijelini. Želimo napraviti lijepu stvar za udruge, koje svakodnevno
            nesebično rade za druge.
          </p>
        </div>
        <div>
          <Carousel
            emulateTouch
            useKeyboardArrows
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            renderArrowPrev={(clickHandler: () => void, hasPrev) =>
              hasPrev && (
                <Icon type="chevron-left" size={12} onClick={clickHandler} style={{ ...arrowStyles, left: 15 }} />
              )
            }
            renderArrowNext={(clickHandler: () => void, hasNext) =>
              hasNext && (
                <Icon type="chevron-right" size={12} onClick={clickHandler} style={{ ...arrowStyles, right: 15 }} />
              )
            }
          >
            {images.map((img) => (
              <div key={img}>
                <img src={img} alt="team" />
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </motion.div>
  );
}
