import React from "react";

import { Card, Typography } from "@tiller/core";

export default function DataTablePlaceholder() {
  return (
    <>
      <Card>
        <Card.Header>
          <Typography variant="h4" element="h4">
            <div className="text-gray-400">Spajanje podataka iz excela s poljima u aplikaciji</div>
          </Typography>
        </Card.Header>
        <Card.Body removeSpacing>
          <div className="w-full grid grid-cols-3">
            <div className="border-b leading-4 tracking-wider uppercase border-base bg-zinc-100 text-gray-400 font-medium text-xs px-6 py-3">
              slovo stupca
            </div>
            <div className="border-b leading-4 tracking-wider uppercase border-base bg-zinc-100 text-gray-400 font-medium text-xs px-6 py-3">
              polje u aplikacji
            </div>
            <div className="border-b leading-4 tracking-wider uppercase border-base bg-zinc-100 text-gray-400 font-medium text-xs px-6 py-3">
              pregled unesenih podataka
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
