import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { ViewActivityForm } from "./components/ViewActivityForm";
import ViewParticipants from "./components/ViewParticipants";
import { usePermissions } from "../../PermissionsProvider";
import { activities } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import Attachments from "../../components/Attachments";
import Header from "../../components/Header";
import NotesContainer from "../../components/Notes/NotesContainer";
import Popup from "../../components/Popup";
import Spinner from "../../components/Spinner";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";
import { ActivityFormType, Note } from "../../types";
import { fillActivity } from "../ActivityForm/util/activity-util";

type ViewActivityParams = {
  id: string;
};

export default function ViewActivity() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const [activity, setActivity] = useState<ActivityFormType>();
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams<ViewActivityParams>();

  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    if (id) {
      fetch(activities.getById(id))
        .then(handleError)
        .then((data) => {
          setActivity(fillActivity(data));
        })
        .catch((err) => {
          console.log(err);
          navigate(routes.ACTIVITIES_URL);
        });
    }
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleError, id, navigate]);

  useEffect(() => {
    if (windowSize > 1280) {
      setOpen(false);
    }
  }, [windowSize]);

  function handleDelete(id: number | null | undefined) {
    fetch(activities.deleteActivityById(id), {
      method: "DELETE",
    })
      .then(handleError)
      .catch((err) => console.log(err));
    navigate(routes.ACTIVITIES_URL);
  }

  return (
    <>
      {activity ? (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
            <div className="col-span-3 xl:col-span-2">
              <Header
                title="Detalji zadatka"
                primaryButtonText="Uredi"
                primaryButtonIcon="pencil"
                secondaryButtonText="Izbriši zadatak"
                secondaryButtonIcon="trash"
                tertiaryButtonText="Bilješke"
                tertiaryButtonIcon="pencil-alt"
                onPrimaryClick={() => {
                  navigate(routes.UPDATE_ACTIVITY_URL(activity?.id as number));
                }}
                showBackButton
                onSecondaryClick={() => setIsOpen(true)}
                onTertiaryClick={() => setOpen(true)}
                onBackButtonClick={() => navigate(routes.ACTIVITIES_URL)}
                hidePrimaryButton={!permissions?.includes("ACTIVITY_CREATE_UPDATE")}
                hideSecondaryButton={!permissions?.includes("ACTIVITY_DELETE")}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
              <div className="col-span-3 xl:col-span-2">
                <ViewActivityForm activity={activity} />
                <ViewParticipants participants={activity.persons?.sort((a, b) => a.name.localeCompare(b.name))} />
                <Formik
                  initialValues={{ files: activity.attachments.map((document) => document.id) }}
                  onSubmit={() => {}}
                >
                  <Form>
                    <Attachments
                      attachments={activity.attachments ?? []}
                      showDragZone={permissions?.includes("ACTIVITY_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DELETE")}
                      uploadUrl={activities.uploadAttachment(activity.id ?? -1)}
                      downloadUrl={activities.getAttachments}
                      deleteUrl={activities.deleteAttachments}
                    />
                  </Form>
                </Formik>
              </div>
              {permissions?.includes("ACTIVITY_NOTES_VIEW") && (
                <NotesContainer
                  module="ACTIVITY"
                  open={open}
                  setOpen={stateHandler}
                  addNoteUrl={activities.addNote}
                  getObjectContainingNotesUrl={activities.getById}
                  notes={activity?.notes as Note[]}
                  id={`${activity?.id}`}
                  showNoteForm={permissions?.includes("ACTIVITY_CRUD_OWN_NOTE")}
                  updateNoteUrl={activities.updateNote}
                  deleteNoteUrl={activities.deleteNote}
                />
              )}
            </div>
          </div>
          <Popup
            title={messages.activityDeletePrompt}
            content={messages.deleteWarning}
            primaryButtonColor="red"
            primaryButtonText="Da, izbriši."
            onSubmit={() => handleDelete(activity?.id)}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
