import React, { useState } from "react";

import useHandleError from "./hook/useHandleError";

type PermissionsProviderContext = {
  permissions?: string[];
};

type PermissionsProviderProps = {
  children: JSX.Element;
};

export function PermissionsProvider({ children }: PermissionsProviderProps) {
  const { handleError } = useHandleError();
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  React.useEffect(() => {
    fetch("/api/role/user-permission")
      .then(handleError)
      .then((data) => setUserPermissions(data));
  }, [handleError]);

  return <Provider value={{ permissions: userPermissions }}>{children}</Provider>;
}

const usePermissionsContext = React.createContext<PermissionsProviderContext>({
  permissions: [],
});

const { Provider } = usePermissionsContext;

export function usePermissions() {
  const context = React.useContext(usePermissionsContext);
  if (context === undefined) {
    throw new Error(`usePermissions hook must be used within PermissionsProvider`);
  }
  return context;
}
