import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { Badge, IconButton } from "@tiller/core";

type NoteSlideOverProps = {
  title?: string;
  setOpen: (value: boolean) => void;
  isOpen: boolean;
  children?: JSX.Element;
  hasBadge?: boolean;
  badgeTitle?: string;
  badgeNumber?: number;
};

export default function SlideOverComponent({
  title,
  setOpen,
  isOpen,
  children,
  hasBadge,
  badgeTitle,
  badgeNumber,
}: NoteSlideOverProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="sticky flex flex-col top-0 px-4 pt-6 sm:px-6 bg-white z-20 bg-scroll:shadow">
                      <div className="flex items-start justify-between">
                        <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                          {title}{" "}
                          {hasBadge ? (
                            <Badge className="ml-2">
                              {badgeTitle} {badgeNumber}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <IconButton
                            type="x"
                            className="h-6 w-6"
                            aria-hidden="true"
                            showTooltip={true}
                            label="Izlaz"
                            onClick={() => setOpen(false)}
                          />
                        </div>
                      </div>
                      <hr className="h-1 mt-4 bg-indigo-700 border-0 rounded" />
                    </div>
                    <div className="px-4 py-6 sm:px-6">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
