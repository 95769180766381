import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "@tiller/core";

import { routes } from "../../api/paths";
import check from "../../assets/img/check.svg";
import AuthenticationContainer from "../../components/AuthenticationContainer";

import { messages } from "../../constants/messages";

export default function PasswordChangeSuccess() {
  const navigate = useNavigate();
  return (
    <AuthenticationContainer title="">
      <div className="flex flex-col align-center">
        <img src={check} alt="success-icon" className="-mt-10 mb-6 self-center" />
        <p className="text-center mb-4">{messages.passwordChangeConfirmation}</p>
        <Button onClick={() => navigate(routes.USER_LOGIN_URL)} className="w-full">
          Povratak na prijavu
        </Button>
      </div>
    </AuthenticationContainer>
  );
}
