import React, { useEffect, useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";

import { useLocation, useNavigate } from "react-router-dom";

import { Badge, Card } from "@tiller/core";

import AddFieldForm from "./AddFieldForm";
import DynamicFieldsTable from "./DynamicFieldTable";
import { usePermissions } from "../../../PermissionsProvider";
import { dynamicField as dynamicFieldPath } from "../../../api/backend_paths";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { DynamicField } from "../../../types";
import { serializeParameterSuffix } from "../../../util/GeneralUtil";

const existingFields = ["OIB", "E-mail", "Kontakt broj", "Adresa"];
const activityExistingFields = [
  "Vrsta zadatka",
  "Naslov",
  "Opis",
  "Datum početka",
  "Vrijeme početka",
  "Datum kraja",
  "Vrijeme kraja",
  "Trajanje zadatka",
  "Odgovorna osoba",
  "Sudionici",
  "Broj sudionika",
];
const existingFieldsMember = [
  "OIB",
  "E-mail",
  "Kontakt broj",
  "Adresa",
  "Datum učlanjenja",
  "Datum prestanka članstva",
  "Kategorija članstva",
];

type DynamicFieldProps = {
  formId: string;
};

export default function DynamicFields({ formId }: DynamicFieldProps) {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [dynamicFieldError, setDynamicFieldError] = useState("");
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    items: dynamicFields,
  };

  useEffect(() => {
    fetch(dynamicFieldPath.getDynamicFields(serializeParameterSuffix({ formId })))
      .then(handleError)
      .then((data) => setDynamicFields(data))
      .catch((err) => console.log(err));
  }, [formId, handleError, location, navigate]);

  const onSubmit = (dynamicField: DynamicField, formikHelpers: FormikHelpers<DynamicField>) => {
    fetch(dynamicFieldPath.saveDynamicField(), {
      method: "POST",
      body: JSON.stringify(dynamicField),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((data) => {
        fetch(dynamicFieldPath.getDynamicFields(serializeParameterSuffix({ formId })))
          .then(handleError)
          .then((data) => {
            setDynamicFields([...data].sort((a, b) => a.id - b.id));
            setDynamicFieldError("");
            formikHelpers.resetForm();
          });
        setDynamicFields([...data].sort((a, b) => a.id - b.id));
        setDynamicFieldError("");
        formikHelpers.resetForm();
      })
      .catch((err) => {
        err.json().then((data: any) => {
          if (data.message === "This field already exists.") {
            setDynamicFieldError(messages.dynamicFieldError);
          }
        });
      });
  };
  return (
    <>
      {formId !== "ACTIVITY" ? (
        <Card className="mb-4">
          <Card.Body>
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-2">
                <h5 className="-mt-2 font-semibold text-lg">Postojeća polja</h5>
                <div className="flex flex-wrap w-full gap-2">
                  {(formId === "ASSOCIATION_MEMBER_MEMBER" ? existingFieldsMember : existingFields).map(
                    (field, index) => (
                      <Badge key={index}>{field}</Badge>
                    )
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                  <h5 className="font-semibold text-md">Fizička osoba</h5>
                  <div className="flex flex-wrap w-full gap-2">
                    {(formId !== "ACTIVITY" ? ["Ime", "Prezime", "Datum rođenja"] : []).map((field, index) => (
                      <Badge key={index}>{field}</Badge>
                    ))}
                  </div>
                </div>

                <div>
                  <h5 className="font-semibold text-md">Pravna osoba</h5>
                  <div className="flex flex-wrap w-full gap-2">
                    {["Naziv", "Kontakt osoba"].map((field, index) => (
                      <Badge key={index}>{field}</Badge>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card className="mb-4">
          <Card.Body>
            <h5 className="-mt-2 mb-2 font-semibold text-lg">Postojeća polja</h5>
            {activityExistingFields.map((field, index) => (
              <Badge key={index} className="mr-2">
                {field}
              </Badge>
            ))}
          </Card.Body>
        </Card>
      )}
      {permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") && (
        <AddFieldForm className="mb-4" formId={formId} onSubmit={onSubmit} dynamicFieldError={dynamicFieldError} />
      )}
      {dynamicFields.length > 0 && (
        <Formik key={dynamicFields.reduce((p, f) => p + f.code, "")} initialValues={initialValues} onSubmit={() => {}}>
          <Form>
            <DynamicFieldsTable />
          </Form>
        </Formik>
      )}
    </>
  );
}
