export const permissionTranslations: Record<string, string> = {
  ASSOCIATION_MEMBER_VIEW: "Pregled, pretraživanje i filtriranje liste profila",
  ASSOCIATION_MEMBER_CREATE_UPDATE: "Kreiranje i uređivanje profila",
  ASSOCIATION_MEMBER_DELETE: "Brisanje profila",
  ASSOCIATION_MEMBER_ATTACHMENTS_ADD: "Dodavanje dokumenata",
  ASSOCIATION_MEMBER_ATTACHMENTS_DOWNLOAD: "Preuzimanje dokumenata",
  ASSOCIATION_MEMBER_ATTACHMENTS_DELETE: "Brisanje dokumenata",
  ASSOCIATION_MEMBER_NOTES_VIEW: "Pregled svih bilješki",
  ASSOCIATION_MEMBER_CRUD_OWN_NOTE: "Kreiranje, uređivanje i brisanje vlastite bilješke na profilu",
  ASSOCIATION_MEMBER_UPDATE_DELETE_OTHERS_NOTE: "Uređivanje i brisanje tuđe bilješke na profilu",
  ASSOCIATION_MEMBER_EXPORT: "Izvoz liste profila",
  ACTIVITY_VIEW: "Pregled, pretraživanje i filtriranje liste zadataka",
  ACTIVITY_CREATE_UPDATE: "Kreiranje i uređivanje zadataka",
  ACTIVITY_DELETE: "Brisanje zadataka",
  ACTIVITY_ATTACHMENTS_ADD: "Dodavanje dokumenata",
  ACTIVITY_ATTACHMENTS_DOWNLOAD: "Preuzimanje dokumenata",
  ACTIVITY_ATTACHMENTS_DELETE: "Brisanje dokumenata",
  ACTIVITY_NOTES_VIEW: "Pregled svih bilješki",
  ACTIVITY_CRUD_OWN_NOTE: "Kreiranje, uređivanje i brisanje vlastite bilješke na zadatku",
  ACTIVITY_UPDATE_DELETE_OTHERS_NOTE: "Uređivanje i brisanje tuđe bilješke na zadatku",
  ACTIVITY_EXPORT: "Izvoz liste zadataka",
  COMPETITION_VIEW: "Pregled, pretraživanje i filtriranje liste natječaja",
  COMPETITION_SET_AND_VIEW_APPLIED: "Označavanje natječaja prijavljenim i pregled prijavljenih natječaja",
  COMPETITION_SET_AND_VIEW_FAVORITE: "Označavanje natječaja favoritom i pregled natječaja favorita",
  COMPETITION_CREATE_UPDATE: "Kreiranje i uređivanje natječaja",
  COMPETITION_DELETE: "Brisanje natječaja",
  COMPETITION_ATTACHMENTS_ADD: "Dodavanje dokumenata",
  COMPETITION_ATTACHMENTS_DOWNLOAD: "Preuzimanje dokumenata",
  COMPETITION_ATTACHMENTS_DELETE: "Brisanje dokumenata",
  COMPETITION_NOTES_VIEW: "Pregled svih bilješki",
  COMPETITION_CRUD_OWN_NOTE: "Kreiranje, uređivanje i brisanje vlastite bilješke na natječaju",
  COMPETITION_UPDATE_DELETE_OTHERS_NOTE: "Uređivanje i brisanje tuđe bilješke na natječaju",
  SETTINGS_EMPLOYEE_LIST: "Pregled liste zaposlenika",
  SETTINGS_SEND_INVITATION_LINK: "Pozivanje korisnika u udrugu preko linka",
  SETTINGS_ROLE_MANAGEMENT: "Upravljanje rolama i pravima",
  SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE: "Kreiranje i uređivanje personaliziranih polja",
  SETTINGS_DYNAMIC_FIELDS_DELETE: "Brisanje personaliziranih polja",
  SETTINGS_DELETE_OTHER_ACCOUNTS: "Brisanje tuđeg korisničkog računa",
  SETTINGS_DELETE_ASSOCIATION: "Brisanje udruge",
  SETTINGS_UPDATE_ASSOCIATION: "Uređivanje podataka udruge",
  SETTINGS_DATA_IMPORT: "Uvoz podatka",
};
