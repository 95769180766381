import React from "react";

import pageBottomBackgroundColor from "../../../assets/img/landingBackground/pageBottomBackgroundColor.svg";
import pageBottomBackgroundColor2xl from "../../../assets/img/landingBackground/pageBottomBackgroundColor2xl.svg";
import pageBottomBackgroundColorMd from "../../../assets/img/landingBackground/pageBottomBackgroundColorMd.svg";
import pageBottomFlowers from "../../../assets/img/landingBackground/pageBottomFlowers.svg";

export default function BottomBackgroundImages() {
  return (
    <>
      <div className="w-full object-fill -translate-y-full absolute -z-10 block md:hidden opacity-80">
        <img src={pageBottomBackgroundColor} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill -translate-y-full absolute -z-10 hidden md:block xl:hidden opacity-80">
        <img src={pageBottomBackgroundColorMd} alt="flowers" className="w-full" />
      </div>
      <div className="w-full object-fill -translate-y-full absolute -z-10 hidden xl:block opacity-80">
        <img src={pageBottomBackgroundColor2xl} alt="flowers" className="w-full" />
      </div>
      <div className="absolute -translate-y-3/4 3xl:translate-x-[5%] -z-10 opacity-80">
        <img src={pageBottomFlowers} alt="flowers" className="w-full" />
      </div>
    </>
  );
}
