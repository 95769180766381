import React from "react";

import BannerSection from "../sections/BannerSection";
import ContactFormSection from "../sections/ContactFormSection";
import FeaturesSection from "../sections/FeaturesSection";
import PricingSection from "../sections/PricingSection";
import TeamSection from "../sections/TeamSection";
import ValuesSection from "../sections/ValuesSection";
import VideoSection from "../sections/VideoSection";

export default function LandingPageBody() {
  return (
    <div className="grid justify-items-center px-4 md:px-16 xl:px-24 2xl:px-60">
      <BannerSection />
      <VideoSection />
      <ValuesSection />
      <FeaturesSection />
      <PricingSection />
      <TeamSection />
      <ContactFormSection />
    </div>
  );
}
