import React, { KeyboardEventHandler, useState } from "react";

import "../styles/LandingPageStyles.css";

import { motion } from "framer-motion";

import { Icon } from "@tiller/icons";

interface ImageCarouselProps {
  defaultImage: string;
  images: string[];
}

export default function ImageCarousel({ images, defaultImage }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const handlePrevious = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    e.stopPropagation();
  };

  const handleNext = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    e.stopPropagation();
  };

  const handleOpenOverlay = (index: number) => {
    setCurrentIndex(index);
    setShowOverlay(!showOverlay);
    if (document.body.style.overflow === "unset") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevious(event);
    } else if (event.key === "ArrowRight") {
      handleNext(event);
    } else if (event.key === "Escape") {
      setShowOverlay(false);
      document.body.style.overflow = "unset";
    }
  };

  return (
    <div tabIndex={0} onKeyDown={handleKeyPress}>
      <motion.div whileHover={{ scale: [null, 1.1] }} transition={{ duration: 1.4 }}>
        <div className="flex items-center">
          <img
            alt="slike aplikacije"
            src={defaultImage}
            className="focus:outline-none w-full object-cover cursor-pointer"
            onClick={() => handleOpenOverlay(currentIndex)}
          />
        </div>
      </motion.div>
      <div
        className={`${
          showOverlay ? "block" : "hidden"
        } fixed w-screen h-full top-0 left-0 bg-gray-900 bg-opacity-80 z-50`}
        onClick={() => handleOpenOverlay(currentIndex)}
      >
        <div className="grid justify-items-end p-4 md:px-12 lg:px-16">
          <Icon type="x" color="white" className="cursor-pointer hover:bg-gray-800 focus:bg-gray-800" size={10} />
        </div>
        <div className="flex text-white items-center p-0 md:px-12 lg:px-16 lg:-mt-8 h-full">
          <div className="hidden md:block">
            <Icon
              type="chevron-left"
              onClick={handlePrevious}
              color="white"
              className="cursor-pointer hover:bg-gray-800 mr-2"
              size={10}
            />
          </div>
          <div className="w-full p-4 md:p-0 grid justify-items-center">
            <img
              alt="slike aplikacije"
              src={images[currentIndex]}
              className="w-full md:w-[80%] h-auto"
              onClick={(e) => e.stopPropagation()}
            />
            <div className="align-middle flex gap-6 p-4">
              <Icon
                type="chevron-left"
                onClick={(event) => handlePrevious(event)}
                color="white"
                className="cursor-pointer md:hidden focus:bg-gray-800 mr-2"
                size={12}
              />
              <p className="landing-p-md text-white flex items-center">
                {currentIndex + 1} / {images.length}
              </p>
              <Icon
                type="chevron-right"
                onClick={(event) => handleNext(event)}
                color="white"
                className="cursor-pointer md:hidden focus:bg-gray-800 ml-2"
                size={12}
              />
            </div>
          </div>
          <div className="hidden md:block">
            <Icon
              type="chevron-right"
              onClick={handleNext}
              color="white"
              className="cursor-pointer hover:bg-gray-800 ml-2"
              size={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
