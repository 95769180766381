import React from "react";

import { MaskedInputField } from "@tiller/formik-elements";

type MaskedDateTimeInputFieldProps = {
  name: string;
  label: string | JSX.Element;
  className: string;
  required?: boolean;
};

export function MaskedDateTimeInputField({ name, label, className, required = false }: MaskedDateTimeInputFieldProps) {
  return (
    <MaskedInputField
      name={name}
      mask={[
        /[0-3]/,
        /\d/,
        ".",
        " ",
        /[0,1]/,
        /\d/,
        ".",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ".",
        " ",
        /[0-2]/,
        /\d/,
        ":",
        /[0-5]/,
        /\d/,
      ]}
      label={label}
      placeholder="dd. mm. gggg. ss:mm"
      autoComplete="off"
      className={className}
      keepCharPositions
      required={required}
    />
  );
}
