export function scrollToSection(id: string, isMobile?: boolean) {
  const viewportHeight = window.innerHeight;
  const section = document.getElementById(id);
  if (isMobile) {
    const currentY = window.scrollY;
    const sectionTop = section!.offsetTop;
    window.scrollBy({ top: sectionTop - currentY - viewportHeight, left: 0, behavior: "smooth" });
    return;
  }

  section!.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
}
