import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import { Card } from "@tiller/core";
import { CheckboxGroupField, FieldError, InputField, RadioGroupField } from "@tiller/formik-elements";

import DynamicFields from "../../../components/DynamicFields";
import { MaskedDateInputField } from "../../../components/Fields/MaskedDateInputField";
import { MemberFormType, MemberType, personType } from "../../../types";
import { scrollToInvalidField } from "../../../util/scrollToInvalidField";
import { getRolesFromAny } from "../util/member-util";

type MemberFormSelectionProps = {
  className?: string;
};

export function calculateFormForDynamicFields(values: any): string[] {
  const formPrefix = "ASSOCIATION_MEMBER_";
  let memberTypeMap = values as unknown as Record<MemberType, boolean>;
  let formIds: string[] = [];
  Object.keys(memberTypeMap).forEach((key: string) => {
    const newFormId = `${formPrefix}${key}`;
    if (memberTypeMap[key as MemberType] && formIds.find((id) => id === newFormId) === undefined) {
      formIds.push(newFormId);
    } else if (!memberTypeMap[key as MemberType] && formIds.find((id) => id === newFormId) !== undefined) {
      formIds = formIds.filter((id) => id !== newFormId);
    }
  });
  return formIds.slice().sort();
}

export function MemberFormSelection({ className = "" }: MemberFormSelectionProps) {
  const { values, errors, isSubmitting, setFieldValue } = useFormikContext<MemberFormType>();

  const [formIdList, setFormIdList] = useState<string[]>(calculateFormForDynamicFields(values.memberTypes));

  useEffect(() => {
    const newFields = calculateFormForDynamicFields(values.memberTypes);
    const oldFields = formIdList.slice().sort();
    const isSame =
      oldFields.length === newFields.length &&
      oldFields
        .slice()
        .sort()
        .every((value, index) => value === newFields[index]);
    if (!isSame) {
      setFormIdList(newFields);
    }
  }, [values, formIdList]);

  useEffect(() => {
    scrollToInvalidField(errors, "members");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useEffect(() => {
    if (!values.memberTypes.MEMBER) {
      setFieldValue("dateOfMembership", "");
      setFieldValue("dateOfTerminationOfMembership", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.memberTypes]);

  return (
    <>
      <Card className={className}>
        <Card.Body>
          <div className="grid grid-cols-1 gap-y-8">
            <RadioGroupField
              name="personType"
              label="Vrsta osobe"
              className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-8 md:w-3/4 lg:w-2/3 xl:w-2/4 2xl:w-2/5"
              vertical
            >
              <RadioGroupField.Item label="Fizička osoba" value={personType.PHYSICAL_PERSON} />
              <RadioGroupField.Item label="Pravna osoba" value={personType.LEGAL_ENTITY} />
            </RadioGroupField>
            <div>
              <CheckboxGroupField
                name="memberTypes"
                label="Vrsta profila"
                className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-8 md:w-3/4 lg:w-2/3 xl:w-2/4 2xl:w-2/5"
                required
                vertical
              >
                <CheckboxGroupField.Item label="Član" value="MEMBER" />
                <CheckboxGroupField.Item label="Donator" value="DONOR" />
                <CheckboxGroupField.Item label="Korisnik" value="USER" />
                <CheckboxGroupField.Item label="Partner" value="PARTNER" />
                <CheckboxGroupField.Item label="Volonter" value="VOLUNTEER" />
                <CheckboxGroupField.Item label="Vanjski suradnik" value="EXTERNAL_COLLABORATOR" />
                <CheckboxGroupField.Item label="Zaposlenik" value="EMPLOYEE" />
                <CheckboxGroupField.Item label="Ostalo" value="OTHERS" />
              </CheckboxGroupField>
              <FieldError name="memberTypes" className="text-sm mt-2" />
            </div>
            {values.personType === personType.PHYSICAL_PERSON ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-4">
                <InputField name="firstName" label="Ime" className="w-full" required />
                <InputField name="lastName" label="Prezime" className="w-full" required />
                <MaskedDateInputField name="dateOfBirth" label="Datum rođenja" className="w-full mr-4" />
                <InputField name="oib" label="OIB" className="w-full" />
                <InputField name="phoneNumber" label="Kontakt broj" className="w-full" />
                <InputField name="email" label="E-mail" className="w-full" />
                <InputField name="address" label="Adresa" className="w-full" />
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-4">
                <InputField name="name" label="Naziv" className="w-full mr-4" required />
                <InputField name="oib" label="OIB" className="w-full" />
                <InputField name="address" label="Adresa" className="w-full mr-4" />
                <InputField name="contactPerson" label="Kontakt osoba" className="w-full" />
                <InputField name="phoneNumber" label="Kontakt broj" className="w-full mr-4" />
                <InputField name="email" label="E-mail" className="w-full" />
              </div>
            )}
            {getRolesFromAny(values.memberTypes).includes(MemberType.MEMBER) && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-4">
                <MaskedDateInputField name="dateOfMembership" label="Datum učlanjenja" className="w-full mr-4" />
                <MaskedDateInputField
                  name="dateOfTerminationOfMembership"
                  label="Datum prestanka Članstva"
                  className="w-full mr-4"
                />
                <InputField name="userCategory" label="Kategorija članstva" className="w-full" />
              </div>
            )}
            <DynamicFields formId={formIdList} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
