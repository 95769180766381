import React from "react";

import { format } from "date-fns";
import isPast from "date-fns/isPast";

import { Card, Typography } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";
import { Icon } from "@tiller/icons";

import activeIcon from "../../../assets/img/active-task.svg";
import inactiveIcon from "../../../assets/img/inactive-task.svg";

import DynamicFieldView from "../../../components/DynamicFieldView";
import { ActivityFormType } from "../../../types";
import {
  ActivityTypeValues,
  PERSONAL_TASK,
  PROGRAM,
  PROJECT,
} from "../../ActivityForm/components/ActivityFormSelection";

type ViewActivityParams = {
  activity?: ActivityFormType;
};

export const getLabel = (activityType?: ActivityTypeValues) => {
  switch (activityType) {
    case PROJECT:
      return "Zadatak projekta";
    case PROGRAM:
      return "Zadatak programa";
    case PERSONAL_TASK:
      return "Samostalni zadatak";
  }
};

export function ViewActivityForm({ activity }: ViewActivityParams) {
  return (
    <Card>
      <DescriptionList>
        <div className="flex justify-between">
          <div className="px-6 pb-2">
            <Typography element="h3" variant="h6" className="pt-4 pb-1">
              {activity?.title}
            </Typography>
            <p className="text-sm mb-1">{getLabel(activity?.activityType)}</p>
          </div>
          <img
            src={activity?.endDate && isPast(new Date(activity.endDate)) ? inactiveIcon : activeIcon}
            alt="task-activity"
            className="w-5 mr-6"
          />
        </div>
        {(activity?.activityType === PROJECT || activity?.activityType === PROGRAM) && (
          <DescriptionList.Item label={`Naziv ${activity?.activityType === PROJECT ? "projekta" : "programa"}`}>
            {activity.projectTitle}
          </DescriptionList.Item>
        )}
        <div className="px-4 py-1">
          <DescriptionList.Item label="Opis zadatka" type="same-column">
            {activity?.description ? activity.description : "-"}
          </DescriptionList.Item>
        </div>
        <DescriptionList.Item label="Datum i vrijeme početka">
          <div className={`flex ${activity?.endDate && isPast(new Date(activity.endDate)) && "text-gray-400"}`}>
            <Icon type="calendar" className="mr-1" />
            <p>{activity?.startDate}</p>
            <Icon type="clock" variant="outline" className="ml-2 mr-1" />
            <p className="mr-2">{activity?.startTime}</p>
          </div>
        </DescriptionList.Item>
        <DescriptionList.Item label="Datum i vrijeme kraja">
          {activity?.endDate === "" ? (
            "-"
          ) : (
            <div className={`flex ${activity?.endDate && isPast(new Date(activity.endDate)) && "text-gray-400"}`}>
              <Icon type="calendar" className="mr-1" />
              <p>{activity?.endDate}</p>
              <Icon type="clock" variant="outline" className="ml-2 mr-1" />
              <p className="mr-2">{activity?.endTime}</p>
            </div>
          )}
        </DescriptionList.Item>
        <DescriptionList.Item label="Trajanje zadatka">
          {activity?.taskDuration ? activity.taskDuration : "-"}
        </DescriptionList.Item>
        <DescriptionList.Item label="Odgovorna osoba">
          {!activity?.designatedPersons ? (
            "-"
          ) : (
            <span>{activity?.designatedPersons.map((item) => `${item.firstName} ${item.lastName}`).join(",  ")}</span>
          )}
        </DescriptionList.Item>
        {activity && <DynamicFieldView formId={["ACTIVITY"]} dynamicData={activity.dynamicData} />}
      </DescriptionList>
      <div className="flex px-6 py-4 text-gray-500 text-sm">
        <p className="w-1/2 flex">
          <span className="font-medium mr-2">Kreiranje zadatka: </span>
          <p>{activity?.dateOfCreation && format(new Date(activity.dateOfCreation), "dd. MM. yyyy.")}</p>
        </p>
        <p className="flex">
          <span className="font-medium mr-2">Zadnje ažuriranje podataka: </span>
          <p>{activity?.dateOfUpdate && format(new Date(activity.dateOfUpdate), "dd. MM. yyyy.")}</p>
        </p>
      </div>
    </Card>
  );
}
