import React, { useState } from "react";

import { Alert } from "@tiller/alert";

import { Icon } from "@tiller/icons";

import { notification } from "../../../api/backend_paths";

import useHandleError from "../../../hook/useHandleError";
import { serializeParameterSuffix } from "../../../util/GeneralUtil";

type FailedFetchAlertProps = {
  message: string;
  id: string;
  userId: string;
};

export default function FailedFetchAlert({ message, id, userId }: FailedFetchAlertProps) {
  const { handleError } = useHandleError();

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const removeNotificationFromUser = () => {
    fetch(notification.removeNotification(id, serializeParameterSuffix({ userId })), {
      method: "DELETE",
    })
      .then(handleError)
      .catch((err) => console.log(err));
  };

  return (
    <>
      {isVisible && (
        <Alert variant="danger" className="mt-1 py-3">
          <div className="flex justify-between">
            <span className="flex">
              <p className="mr-1">{message}</p>
            </span>
            <Icon
              type="x"
              className="cursor-pointer"
              onClick={() => {
                setIsVisible(false);
                removeNotificationFromUser();
              }}
            />
          </div>
        </Alert>
      )}
    </>
  );
}
