import React from "react";

import { Card, Typography } from "@tiller/core";

type DataImportCriteriaCardProps = {
  expanded: boolean;
};

export default function DataImportCriteriaCard({ expanded }: DataImportCriteriaCardProps) {
  return (
    <Card isExpanded={expanded} status="idle">
      <Card.Header>
        <Card.Header.Title>
          <Typography element="h5" variant="h5">
            Kriteriji za uvoz podataka
          </Typography>
        </Card.Header.Title>
      </Card.Header>
      <Card.Body>
        <ul className="px-6 space-y-2 list-disc">
          <li>
            Datoteka mora biti u <b>MS Excel</b> formatu (.xls).
          </li>
          <li>
            Nazivi stupaca u excelu predstavljaju nazive polja u aplikaciji, a redovi predstavljaju vrijednosti koje će
            biti zapisane u poljima.
          </li>
          <li>
            Provjerite nedostaju li vam polja u aplikaciji za slučaj da imate više stupaca u excelu nego polja u
            aplikaciji. Ako vam nedostaju polja u aplikaciji, molimo kreirajte dodatna polja u postavkama kako biste
            uvezli sve potrebne podatke.
          </li>
          <li>Polje OIB mora sadržavati validan OIB.</li>
          <li>Ako imate polje kontakt broj, mora biti u formatu 0951234567891 ili +38595123456789.</li>
          <li>
            Polje datuma mora biti tip datum u MS Excelu i mora biti u ispravnom formatu: DD.MM.GGGG. U slučaju da ste
            unijeli ispravan format i datum Vam se na mapiranju podataka prikaže kao neobičan broj, ne morate brinuti
            jer će se uvesti ispravno u aplikaciju.
          </li>
          <li> Ako imate dodatno polje datum i vrijeme, u excelu mora biti u ispravnom formatu: DD.MM.GGGG HH:MM.</li>
          <li>
            <b>Obavezna polja</b> u aplikaciji su <b>ime i prezime + dodatna polja</b> koja ste vi stvorili i označili
            obaveznima. Molimo, pripazite da obavezna polja imaju vrijednosti zapisane unutar excela.
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}
