import React, { useEffect, useState } from "react";

import { useLocalPagination } from "@tiller/core";

import CompetitionCard from "./CompetitionCard";
import CompetitionFilter from "./CompetitionFilter";
import { CompetitionEndpoint } from "../../../api/backend_paths";
import Search from "../../../components/Search";
import Spinner from "../../../components/Spinner";
import TranslatedPagination from "../../../components/TranslatedPagination";
import TranslatedResizer from "../../../components/TranslatedReziser";
import { COMPETITION_FILTER_INITIAL_VALUES } from "../../../constants/FilterConstants";
import { messages } from "../../../constants/messages";
import { FavoritesCompetitionContext } from "../../../context/FavoritesCompetitionContext";
import {
  Competition,
  CompetitionFilterValues,
  competitionSortOptions,
  FundingSource,
  GetCompetitionsValues,
  Paging,
} from "../../../types";

type CompetitionListProps = {
  pagingContextIndex: Paging;
  updatePagingContextIndex: (page: Paging) => void;
  endPoint: CompetitionEndpoint;
  competitionList: Competition[];
  getCompetitionsList: (values: GetCompetitionsValues, filterValues: CompetitionFilterValues) => void;
  totalElements: number;
  allSources: FundingSource[];
  changeFavorite: (
    id: string,
    isFavorite: boolean,
    values: GetCompetitionsValues,
    filterValues: CompetitionFilterValues
  ) => void;
  isLoading: boolean;
  isSearchSuccessful: boolean;
  isListEmpty: boolean;
  allAreas: string[];
  filterValues: CompetitionFilterValues;
  favoriteFilterValues: CompetitionFilterValues;
  setFilterValues: (values: CompetitionFilterValues) => void;
  sortType: string | undefined;
  setSortType: (sortType: string) => void;
  favoriteTab: boolean;
  isForbidden: boolean;
};

export default function CompetitionList({
  pagingContextIndex,
  updatePagingContextIndex,
  endPoint,
  competitionList,
  totalElements,
  getCompetitionsList,
  allSources,
  changeFavorite,
  isLoading,
  isSearchSuccessful,
  isListEmpty,
  filterValues,
  favoriteFilterValues,
  setFilterValues,
  allAreas,
  sortType,
  setSortType,
  favoriteTab,
  isForbidden,
}: CompetitionListProps) {
  const competitionContext = React.useContext(FavoritesCompetitionContext);
  const [paginationState, paginationHook] = useLocalPagination(competitionList, 5);
  const [keyword, setKeyword] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(
    JSON.stringify(filterValues) !== JSON.stringify(COMPETITION_FILTER_INITIAL_VALUES)
  );

  const onSubmit = (values: CompetitionFilterValues) => {
    setFilterValues({
      ...values,
    });
    updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
    if (favoriteTab) {
      competitionContext.setPageSize(pagingContextIndex.pageSize);
      competitionContext.setPageNumber(0);
    }
  };

  useEffect(() => {
    getCompetitionsList(
      {
        ...pagingContextIndex,
        keyword,
      },
      filterValues
    );
  }, [
    endPoint,
    keyword,
    filterValues,
    getCompetitionsList,
    pagingContextIndex.pageSize,
    pagingContextIndex.pageNumber,
    pagingContextIndex,
    sortType,
  ]);
  return (
    <>
      <Search
        placeholder="Pretraži natječaje..."
        onSubmit={(searchTerm) => {
          updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
          setKeyword(searchTerm);
          if (favoriteTab) {
            competitionContext.setPageSize(pagingContextIndex.pageSize);
            competitionContext.setPageNumber(0);
            competitionContext.setKeyword(searchTerm);
          }
        }}
        onFilterClick={() => setIsFilterVisible(!isFilterVisible)}
        sortOptions={competitionSortOptions}
        onSortClick={(type) => setSortType(type)}
      />
      {isFilterVisible && (
        <CompetitionFilter
          locationOptions={allAreas}
          fundingSourceOptions={allSources.filter((value) => value.scraped || value.created).map((value) => value.name)}
          onSubmit={onSubmit}
          filterValues={filterValues}
        />
      )}
      {isLoading ? (
        <Spinner marginTop="-mt-40" />
      ) : isForbidden ? (
        <p className="mt-4">{messages.forbidden}</p>
      ) : isListEmpty ? (
        <p className="mt-4">{messages.emptyCompetitionTab}</p>
      ) : isSearchSuccessful ? (
        <>
          {competitionList.map((comp, index) => (
            <CompetitionCard
              {...comp}
              key={index}
              changeFavorite={changeFavorite}
              favoriteFilterValues={favoriteFilterValues}
            />
          ))}
          <div className="flex items-center justify-between mt-4">
            <TranslatedResizer
              onPageSizeChange={(pageSize: number) => {
                updatePagingContextIndex({ pageSize: pageSize, pageNumber: 0 });
                if (favoriteTab) {
                  competitionContext.setPageSize(pageSize);
                  competitionContext.setPageNumber(0);
                }
              }}
              pageSize={pagingContextIndex.pageSize}
              pageSizes={[5, 10, 20]}
              totalElements={totalElements}
            />
            <TranslatedPagination
              paginationState={paginationState}
              paginationHook={paginationHook}
              pageNumber={pagingContextIndex.pageNumber}
              pageSize={pagingContextIndex.pageSize}
              totalElements={totalElements}
              onPageChange={(pageNumber: number) => {
                updatePagingContextIndex({ ...pagingContextIndex, pageNumber: pageNumber });
                if (favoriteTab) {
                  competitionContext.setPageSize(pagingContextIndex.pageSize);
                  competitionContext.setPageNumber(pageNumber);
                }
              }}
            />
          </div>
        </>
      ) : (
        <p className="mt-4">{messages.noSearchResults}</p>
      )}
    </>
  );
}
