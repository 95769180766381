import React from "react";

import { Typography } from "@tiller/core";

import email from "../../assets/img/email.svg";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";

export default function ForgottenPasswordConfirmation() {
  return (
    <AuthenticationContainer title="" showAdditionalCard additionalText={messages.resendMail}>
      <div className="flex flex-col align-center">
        <img src={email} alt="email" className="-mt-10 w-32 self-center" />
        <Typography
          element="h2"
          variant="h2"
          className="mb-4 text-indigo-900 whitespace-pre-wrap text-center uppercase"
        >
          Provjerite E-mail
        </Typography>
        <p className="text-sm text-center">{messages.sentLink}</p>
      </div>
    </AuthenticationContainer>
  );
}
