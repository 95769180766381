import React from "react";

import { PageResizer } from "@tiller/selectors";

type TranslatedResizerProps = {
  onPageSizeChange: any;
  pageSize: number;
  pageSizes: number[];
  totalElements: number;
};

export default function TranslatedResizer({
  onPageSizeChange,
  pageSize,
  pageSizes,
  totalElements,
}: TranslatedResizerProps) {
  return (
    <PageResizer
      onPageSizeChange={onPageSizeChange}
      pageSize={pageSize}
      pageSizes={pageSizes}
      totalElements={totalElements}
    >
      {(select) => <p className="text-sm flex items-center">Prikaži {select} rezultata po stranici</p>}
    </PageResizer>
  );
}
