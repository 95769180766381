import React, { useCallback, useEffect, useState } from "react";

import { Form, Formik } from "formik";

import { useNavigate } from "react-router-dom";

import { Button, Card } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";
import { CheckboxField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import ChangeConfirmation from "./ChangeConfirmation";
import ChangePasswordPopup from "./ChangePasswordPopup";
import { deleteAccountActions } from "./EmployeeListTable";
import UpdateUserPopup from "./UpdateUserPopup";
import { account, association, user as paths_user } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import x from "../../../assets/img/x-circle.svg";
import Popup from "../../../components/Popup";
import PopupWithInput from "../../../components/PopupWithInput";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import useSignOut from "../../../hook/useSignOut";
import { User } from "../../../types";

type GeneralProps = {
  hasMultipleAdmins: boolean;
  setHasMultipleAdmins: (hasAdmin: boolean) => void;
};

export default function General({ hasMultipleAdmins, setHasMultipleAdmins }: GeneralProps) {
  const { handleError } = useHandleError();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const [subscribed, setSubscribed] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [updateUserPopup, setUpdateUserPopup] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    fetch(association.admin)
      .then(handleError)
      .then((res) => setHasMultipleAdmins(res))
      .catch((err) => console.log(err));
  }, [handleError, setHasMultipleAdmins]);

  useEffect(() => {
    fetch(paths_user.subscription, { method: "GET" })
      .then(handleError)
      .then((s) => setSubscribed(s))
      .catch((err) => console.error(err));
  }, [handleError]);

  const getUser = useCallback(() => {
    fetch(paths_user.getCurrentUser())
      .then(handleError)
      .then((res) => setUser(res))
      .catch((err) => console.log(err));
  }, [handleError]);

  useEffect(() => {
    getUser();
  }, [getUser, handleError]);

  const changeSubscription = useCallback(() => {
    fetch(paths_user.changeSubscription, { method: "PUT" })
      .then(handleError)
      .catch((err) => console.log(err));
  }, [handleError]);

  const handlePopupClose = () => {
    if (isFormSubmitted) {
      setIsModalOpen(false);
      setIsFormSubmitted(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const deletePersonalAccount = () => {
    fetch(account.deletePersonalAccount(), { method: "DELETE" })
      .then(handleError)
      .then(() => {
        setIsConfirmationModalOpen(true);
        signOut();
        navigate(routes.USER_LOGIN_URL);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex flex-col gap-4 mt-6">
      <Card className="w-full lg:w-1/2">
        <Card.Header>
          <Card.Header.Title>Osobni podaci</Card.Header.Title>
          <Card.Header.Actions>
            <Icon
              type="pencil"
              variant="outline"
              className="cursor-pointer text-indigo-900"
              onClick={() => setUpdateUserPopup(true)}
            />
          </Card.Header.Actions>
        </Card.Header>
        <DescriptionList>
          <DescriptionList.Item label="Korisničko ime">{user?.username}</DescriptionList.Item>
          <DescriptionList.Item label="E-mail">{user?.email}</DescriptionList.Item>
          <DescriptionList.Item label="Ime">{user?.firstName}</DescriptionList.Item>
          <DescriptionList.Item label="Prezime">{user?.lastName}</DescriptionList.Item>
          <DescriptionList.Item label="Udruga">{localStorage.getItem("associationName")}</DescriptionList.Item>
        </DescriptionList>
      </Card>
      <Card className="w-full lg:w-1/2 2xl:w-1/3">
        <Card.Header>
          <Card.Header.Title>Lozinka</Card.Header.Title>
        </Card.Header>
        <Card.Body>
          <span className="flex justify-between items-center mb-1">
            <p>••••••••</p>
            <Icon
              type="pencil"
              variant="outline"
              className="cursor-pointer text-indigo-900"
              onClick={() => setIsModalOpen(true)}
            />
          </span>
        </Card.Body>
      </Card>
      <Card className="w-full lg:w-1/2 2xl:w-1/3">
        <Card.Body>
          <Formik initialValues={{ consent: true }} onSubmit={() => {}}>
            <Form className="mb-2">
              <CheckboxField
                name="consent"
                label={messages.consent}
                checked={subscribed}
                onChange={changeSubscription}
                className="min-w-[1rem]"
              />
            </Form>
          </Formik>
          <p className="text-sm text-red-500">{messages.consentWarningMessage}</p>
        </Card.Body>
      </Card>

      {isFormSubmitted ? (
        <Popup
          onSubmit={() => {
            setIsModalOpen(false);
            setIsFormSubmitted(true);
          }}
          title=""
          content=""
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          hideButtons={true}
          onClose={handlePopupClose}
        >
          <ChangeConfirmation text={messages.passwordChangeConfirmation} className="flex flex-col items-center" />
        </Popup>
      ) : (
        <Popup
          onSubmit={() => {
            setIsModalOpen(false);
            setIsFormSubmitted(true);
          }}
          title={messages.changePasswordTitle}
          content=""
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          hideButtons={true}
        >
          <ChangePasswordPopup
            onSubmit={() => {
              setIsFormSubmitted(true);
            }}
          />
        </Popup>
      )}

      <Card className="w-2/4 flex items-center justify-between lg:w-1/2 2xl:w-1/3">
        <Card.Body>
          <Button
            tabIndex={0}
            color="red"
            type="button"
            size="xl"
            variant="text"
            trailingIcon={<Icon type="trash" variant="solid" />}
            onClick={() => {
              if (hasMultipleAdmins) {
                setIsInputModalOpen(true);
              } else {
                setIsFailureModalOpen(true);
              }
            }}
          >
            {"Brisanje osobnog korisničkog računa"}
          </Button>
        </Card.Body>
        <PopupWithInput
          text={localStorage.getItem("username") ?? ""}
          content={messages.deletePersonalAccountWarning}
          title={
            <div>
              <div className="mr-10">
                <p className="text-red-500 mb-3 text-xl">
                  <span className="text-black">Brisanje korisničkog računa</span>
                </p>
              </div>
              <span className="mb-2">Brisanje računa će učiniti sljedeće:</span>
              <ul className="landing-p-sm list-none list-outside pl-4 mb-2">
                {deleteAccountActions.map((item: string, index: number) => {
                  return (
                    <div className="flex space-x-2 mb-1 -ml-4" key={index}>
                      {index < 3 ? (
                        <Icon type="x-circle" color="red" variant="outline" size={6} className="min-w-[24px]" />
                      ) : (
                        <Icon type="check-circle" color="green" variant="outline" size={6} />
                      )}
                      <li key={index}>{item}</li>
                    </div>
                  );
                })}
              </ul>
            </div>
          }
          isOpen={isInputModalOpen}
          setIsOpen={setIsInputModalOpen}
          primaryButtonText="Da, izbriši"
          onSubmit={() => deletePersonalAccount()}
        />

        <Popup
          onSubmit={() => {
            setIsConfirmationModalOpen(false);
            setIsFailureModalOpen(false);
          }}
          title=""
          content=""
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          hideButtons={true}
        >
          <ChangeConfirmation text={messages.deleteAccountTitle} className="flex flex-col items-center" />
        </Popup>

        <Popup
          onSubmit={() => {}}
          title=""
          content=""
          isOpen={isFailureModalOpen}
          setIsOpen={setIsFailureModalOpen}
          hideButtons={true}
        >
          <ChangeConfirmation
            src={x}
            text={messages.noOtherAdminInAssociation}
            className="flex flex-col items-center"
          />
        </Popup>
        <Popup
          onSubmit={() => {
            setUpdateUserPopup(true);
          }}
          title={messages.changeUserData}
          content=""
          isOpen={updateUserPopup}
          setIsOpen={setUpdateUserPopup}
          hideButtons={true}
        >
          <UpdateUserPopup
            user={user}
            onSubmit={() => {
              getUser();
              setUpdateUserPopup(false);
            }}
          />
        </Popup>
      </Card>
    </div>
  );
}
