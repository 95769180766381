import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

import { usePermissions } from "../../PermissionsProvider";
import { useEnvironmentVariable } from "../../hook/EnvironmentVariableProvider";

export function canUserEditNote(author: string, module: string) {
  const areNamesEqual = AreNamesEqual(author);

  if (areNamesEqual) {
    return CanEditOwnNote(module);
  } else {
    return CanEditForeignNote(module);
  }
}

function CanEditForeignNote(module: string) {
  const { permissions } = usePermissions();

  if (module === "ASSOCIATION_MEMBER" && permissions?.includes("ASSOCIATION_MEMBER_UPDATE_DELETE_OTHERS_NOTE")) {
    return true;
  }

  if (module === "ACTIVITY" && permissions?.includes("ACTIVITY_UPDATE_DELETE_OTHERS_NOTE")) {
    return true;
  }

  return module === "COMPETITION" && permissions?.includes("COMPETITION_UPDATE_DELETE_OTHERS_NOTE");
}

function CanEditOwnNote(module: string) {
  const { permissions } = usePermissions();

  if (module === "ASSOCIATION_MEMBER" && permissions?.includes("ASSOCIATION_MEMBER_CRUD_OWN_NOTE")) {
    return true;
  }

  if (module === "ACTIVITY" && permissions?.includes("ACTIVITY_CRUD_OWN_NOTE")) {
    return true;
  }

  return module === "COMPETITION" && permissions?.includes("COMPETITION_CRUD_OWN_NOTE");
}

function AreNamesEqual(author: string) {
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
  });

  const cognitoUser = new CognitoUser({
    Username: localStorage.getItem("username") ?? "",
    Pool: userPool,
  });

  let name;

  cognitoUser?.getSession((err: any, session: any) => {
    if (!session) return;
    name = session.idToken.payload.given_name + " " + session.idToken.payload.family_name;
  });

  return name === author;
}
