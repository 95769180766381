import React from "react";

import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from "formik";

import * as Yup from "yup";

import { Button, Card, Tooltip } from "@tiller/core";
import { CheckboxField, InputField, SelectField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import CustomValueInput from "./CustomValueInput";
import { selectOptions } from "./selectOptions";
import { messages } from "../../../constants/messages";
import { DynamicField, FieldItem, fieldType } from "../../../types";

type AddFieldFormProps = {
  className?: string;
  formId: string;
  onSubmit: (dynamicField: DynamicField, formikHelpers: FormikHelpers<DynamicField>) => void;
  dynamicFieldError: string;
};

const hasCustomValues = ["CHECKBOX", "DROPDOWN", "RADIO", "TAG_FIELD"];
const noFilterFields = ["TEXT_FIELD", "TEXT_AREA", "EMAIL", "PHONE", "OIB", "CHECKBOX", "DATE", "DATE_TIME"];

const validationSchema = Yup.object().shape({
  fieldName: Yup.string().required(messages.requiredField),
  fieldType: Yup.string().required(messages.requiredField).nullable(),
});

export default function AddFieldForm({ className = "", formId, onSubmit, dynamicFieldError }: AddFieldFormProps) {
  const initialValues: DynamicField = {
    code: "",
    formId: formId,
    fieldName: "",
    fieldType: fieldType.TEXT_FIELD,
    required: false,
    isOnFilter: false,
    associationId: 0,
    dynamicFieldDataset: [],
  };

  function checkIsInvalid(values: DynamicField): boolean {
    if (!(values.fieldType && hasCustomValues.includes(values.fieldType))) {
      return false;
    }
    if (values.dynamicFieldDataset.length === 0) {
      return true;
    }
    for (let i = 0; i < values.dynamicFieldDataset.length; i++) {
      if (!values.dynamicFieldDataset[i] || values.dynamicFieldDataset[i] === "") {
        return true;
      }
    }
    return false;
  }

  return (
    <Card className={className}>
      <Formik
        key={formId}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSubmit(values, formikHelpers);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, isValid }: FormikProps<DynamicField>) => {
          return (
            <Form>
              <Card.Body>
                <>
                  <h5 className="-mt-2 mb-4 font-semibold text-lg">Dodaj personalizirano polje</h5>
                  <div className="flex flex-col lg:flex-row flex-wrap items-start space-y-4 lg:space-y-0 lg:space-x-8">
                    <div className="w-full md:w-1/2 lg:w-1/4">
                      <InputField name="fieldName" label="Naziv polja" allowClear required />
                      <p className="text-red-500 text-sm mt-0">{dynamicFieldError}</p>
                    </div>
                    <SelectField
                      className="w-full md:w-1/2 lg:w-1/4"
                      name="fieldType"
                      label="Vrsta polja"
                      options={selectOptions}
                      getOptionLabel={(item: FieldItem) => (
                        <span className="flex">
                          <img src={item.icon} alt="" className="mr-2 text-yellow w-5 h-5" />
                          <p>{item.label}</p>
                        </span>
                      )}
                      getOptionValue={(item: FieldItem) => item.code}
                      hideClearButton
                      required
                    />
                    <div className="flex flex-nowrap items-end pt-0 lg:pt-8">
                      <CheckboxField name="required" label="Obavezno polje" />
                      <span className="ml-1">
                        <Tooltip label="Polje se mora ispuniti na formi.">
                          <Icon type="information-circle" size={4} />
                        </Tooltip>
                      </span>
                    </div>
                    {!noFilterFields.includes(values.fieldType?.toString() || "") && (
                      <div className="flex items-end pt-0 lg:pt-8">
                        <CheckboxField name="isOnFilter" label="Filter" />
                        <span className="ml-1">
                          <Tooltip label="Polje se prikazuje na filteru.">
                            <Icon type="information-circle" size={4} />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                  </div>
                  <FieldArray
                    name="dynamicFieldDataset"
                    render={() => {
                      return (
                        <div>
                          {hasCustomValues.includes(values.fieldType?.toString() || "") && (
                            <div>
                              <p className="text-sm font-semibold mt-4">
                                Predefinirane vrijednosti <span className="text-red-500">*</span>
                              </p>
                              <p className="text-xs mb-2">Potrebno je dodati barem jednu vrijednost.</p>
                              {values.dynamicFieldDataset?.map((value, index) => (
                                <>
                                  <CustomValueInput
                                    onRemoveInput={() => {
                                      setFieldValue("dynamicFieldDataset", [
                                        ...values.dynamicFieldDataset!.slice(0, index),
                                        ...values.dynamicFieldDataset!.slice(index + 1),
                                      ]);
                                    }}
                                    name={`dynamicFieldDataset[${index}]`}
                                    key={index}
                                    className="flex w-full"
                                  />
                                </>
                              ))}
                              <Button
                                leadingIcon={<Icon type="plus" />}
                                type="button"
                                variant="text"
                                onClick={() => {
                                  setFieldValue("dynamicFieldDataset", [...(values.dynamicFieldDataset || []), null]);
                                }}
                              >
                                Dodaj vrijednost
                              </Button>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                </>
              </Card.Body>
              <Card.Footer>
                <div className="flex justify-end">
                  <Button
                    leadingIcon={<Icon type="refresh" />}
                    variant="outlined"
                    type="reset"
                    className="mr-2"
                    size="sm"
                  >
                    Resetiraj
                  </Button>
                  <Button
                    leadingIcon={<Icon type="check" />}
                    variant="filled"
                    size="sm"
                    disabled={!dirty || !isValid || checkIsInvalid(values)}
                  >
                    Spremi
                  </Button>
                </div>
              </Card.Footer>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}
