import React, { useEffect, useState } from "react";

import { Form, FormikHelpers } from "formik";

import { useLocation, useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { Button } from "@tiller/core";
import { CheckboxField, FieldError, FormContainer, InputField, PasswordInputField } from "@tiller/formik-elements";
import { LoadingIcon } from "@tiller/icons";

import DataAgreement from "./DataAgreement";
import EULA from "./EULA";
import { CurrentUser, parseJwt, RegisterData, useAuth } from "../../AuthProvider";
import { inviteToken, user } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import Popup from "../../components/Popup";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";

const SignupSchema = Yup.object().shape({
  username: Yup.string().required(messages.requiredField),
  firstName: Yup.string().required(messages.requiredField),
  lastName: Yup.string().required(messages.requiredField),
  email: Yup.string().email("Email je neispravnog formata.").required(messages.requiredField),
  password: Yup.string()
    .matches(/^(?=.*[a-z])/, "Lozinka mora sadržavati barem jedno malo slovo.")
    .matches(/^(?=.*[A-Z])/, "Lozinka mora sadržavati barem jedno veliko slovo.")
    .matches(/^(?=.*[0-9])/, "Lozinka mora sadržavati barem jedan broj.")
    .matches(/^(?=.{8,})/, "Lozinka mora sadržavati barem 8 znakova.")
    .required("Lozinka mora sadržavati barem 8 znakova."),
  confirmPassword: Yup.string()
    .required(messages.requiredField)
    .oneOf([Yup.ref("password")], "Lozinke se ne podudaraju."),
  eula: Yup.boolean().oneOf([true], messages.requiredField),
  dataProcessingAgreement: Yup.boolean().oneOf([true], messages.requiredField),
});

export default function UserRegistration() {
  const { handleError } = useHandleError();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEulaOpen, setIsEulaOpen] = useState(false);
  const [isDataAgreementOpen, setIsDataAgreementOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { signUp, login } = useAuth();

  const token = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    if (token !== "registracija-admin") {
      fetch(inviteToken.getInviteToken(token), {
        method: "GET",
        headers: {
          "Content-Type": "blob",
        },
      })
        .then((res) => {
          if (res.status === 404) {
            navigate(routes.EXPIRED_LINK_URL);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [navigate, token]);

  const initialValues: RegisterData = {
    swap: false,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    eula: false,
    dataProcessingAgreement: false,
    emailSubscription: true,
  };

  const onSubmit = (values: RegisterData, formikHelpers: FormikHelpers<RegisterData>) => {
    setIsLoading(true);
    signUp(values, (err) => {
      const pathPart = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
      let code: string | undefined;
      if (location.pathname === routes.ADMIN_REGISTRATION_URL) {
        code = undefined;
      } else if (location.pathname === routes.USER_REGISTRATION_URL(pathPart)) {
        code = pathPart;
      }
      if (!err) {
        login(
          false,
          values.username.toLowerCase(),
          values.password!,
          (jwt) => {
            const currentUser: CurrentUser | undefined = parseJwt(jwt);
            localStorage.setItem("firstName", `${currentUser?.given_name}`);
            localStorage.setItem("lastName", `${currentUser?.family_name}`);
            localStorage.setItem("email", `${currentUser?.email}`);
            localStorage.setItem("username", `${currentUser?.["cognito:username"]}`);
            fetch(user.saveUser(code), {
              method: "POST",
              body: JSON.stringify(values),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            })
              .then(handleError)
              .then(() => {
                if (location.pathname === routes.ADMIN_REGISTRATION_URL) {
                  navigate(routes.ASSOCIATION_REGISTRATION_URL, { state: { jwt: jwt } });
                } else if (location.pathname === routes.USER_REGISTRATION_URL(code)) {
                  navigate(routes.RECORDS_URL);
                }
                formikHelpers.resetForm();
                setIsLoading(false);
              })
              .catch((err) => console.log(err));
          },
          () => {}
        );
      } else {
        if (err.name === "UsernameExistsException") {
          setErrorMessage(messages.registerErrorUsernameTaken);
        } else if (err.name === "InvalidParameterException") {
          if (err.message.includes("username")) {
            setErrorMessage(messages.registerErrorUsernameFormat);
          } else if (err.message.includes("email")) {
            setErrorMessage(messages.registerErrorEmailFormat);
          }
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <AuthenticationContainer
        title={
          location.pathname === routes.ADMIN_REGISTRATION_URL ? " registracija administratora udruge" : "registracija"
        }
        titleFontSize="text-2xl md:text-3xl"
        width="w-80 md:w-[40rem]"
        showAdditionalCard
        additionalCardRoute={routes.USER_LOGIN_URL}
        showIcon
        additionalText="Već imate profil? Prijavite se."
      >
        <FormContainer
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
          validateAfterSubmit
        >
          <Form>
            <p className="text-red-500 text-sm mb-4 mt-0">{errorMessage}</p>
            <p className="mb-4 md:mb-6 text-center text-sm">
              {location.pathname === routes.ADMIN_REGISTRATION_URL
                ? messages.adminRegistration
                : messages.userRegistration}
            </p>
            <div className="flex flex-col md:flex-row flex-wrap space-y-3 mb-6">
              <InputField name="username" label="Korisničko ime" className="md:pr-3 md:w-1/2 mt-3" required={true} />
              <InputField name="email" label="E-mail" className="md:w-1/2" required={true} />
              <InputField name="firstName" label="Ime" className="md:pr-3 md:w-1/2" required={true} />
              <InputField name="lastName" label="Prezime" className="md:w-1/2" required={true} />
              <PasswordInputField
                name="password"
                label="Lozinka"
                className="md:pr-3 md:w-1/2"
                required={true}
                help={<p>{messages.capsLockOn}</p>}
              />
              <PasswordInputField
                name="confirmPassword"
                label="Potvrdite lozinku"
                className="md:w-1/2"
                required={true}
                help={<p>{messages.capsLockOn}</p>}
              />
            </div>
            <div className="flex items-start md:items-center mb-3 md:mb-1">
              <CheckboxField name="eula" />
              <p className="text-sm ml-2 -mt-0.5 md:mt-0">
                Prihvaćam
                <span
                  className="cursor-pointer underline text-indigo-800 hover:text-indigo-900 mx-1"
                  onClick={() => setIsEulaOpen(true)}
                >
                  pravila korištenja
                </span>
                aplikacije. <span className="text-red-600">*</span>
              </p>
            </div>
            <FieldError name="eula" className="text-sm mt-1 mb-2" />
            <div className="flex items-start md:items-center">
              <CheckboxField name="dataProcessingAgreement" />
              <p className="text-sm ml-2 -mt-0.5 md:mt-0">
                Prihvaćam
                <span
                  className="cursor-pointer underline text-indigo-800 hover:text-indigo-900 ml-1"
                  onClick={() => setIsDataAgreementOpen(true)}
                >
                  ugovor o obradi osobnih podataka
                </span>
                . <span className="text-red-600">*</span>
              </p>
            </div>
            <FieldError name="dataProcessingAgreement" className="text-sm mt-1" />
            <Button disabled={isLoading} className="mt-6 w-full">
              {isLoading ? (
                <LoadingIcon size={5} />
              ) : (
                `Registriraj ${location.pathname === routes.ADMIN_REGISTRATION_URL ? "administratora" : "se"}`
              )}
            </Button>
          </Form>
        </FormContainer>
        <Popup title="Pravila Korištenja" content="" isOpen={isEulaOpen} setIsOpen={setIsEulaOpen} hideButtons>
          <>
            <EULA />
            <Button className="mt-4 w-full -mb-4 md:hidden" onClick={() => setIsEulaOpen(false)}>
              Zatvori
            </Button>
          </>
        </Popup>
        <Popup
          title="Ugovor o obradi osobnih podataka"
          content=""
          isOpen={isDataAgreementOpen}
          setIsOpen={setIsDataAgreementOpen}
          hideButtons
        >
          <>
            <DataAgreement />
            <Button className="mt-4 w-full -mb-4 md:hidden" onClick={() => setIsDataAgreementOpen(false)}>
              Zatvori
            </Button>
          </>
        </Popup>
      </AuthenticationContainer>
    </>
  );
}
