import React, { useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";

import { Wizard } from "react-use-wizard";

import { readSheetNames } from "read-excel-file";

import { Card, ProgressBar } from "@tiller/core";

import ChoosingSheets from "./ChoosingSheets";
import DataMapping from "./DataMapping";
import DataMappingStep from "./DataMappingStep";
import ImportValidationList from "./ImportValidationList";
import Header from "../../../components/Header";
import { DataImportContext } from "../../../context/DataImportContext";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import { DataImportStepType } from "../../../types";

export default function DataImportWizard() {
  const { checkedSheets, data } = React.useContext(DataImportContext);
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [steps, setSteps] = useState<DataImportStepType[]>([]);

  const { isFirstActive, isSecondActive, isThirdActive } = useContext(ImportProgressBarContext);

  useEffect(() => {
    const fileName = localStorage.getItem("data-import-file-name");
    const fileUrl = localStorage.getItem("data-import-file");
    if (fileName && fileUrl) {
      let arr = fileUrl.split(","),
        mime = arr?.[0].match(/:(.*?);/)?.[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const file = new File([u8arr], fileName, { type: mime });
      if (file) {
        setFile(file);
        readSheetNames(file).then((sheetNames) => setSheetNames(sheetNames));
      }
    }
  }, []);

  useEffect(() => {
    const currentSteps: any[] = [];
    if (checkedSheets) {
      Object.keys(checkedSheets)
        .filter((key) => checkedSheets[key])
        .forEach((sheet) => {
          if (file) {
            currentSteps.push({
              step: <DataMapping sheetName={sheet} file={file} />,
              sheetName: sheet,
            });
          }
        });
    }
    setSteps(currentSteps);
  }, [checkedSheets, file]);

  return (
    <>
      <Header title="uvoz podataka" hidePrimaryButton={true} />
      <div className="flex flex-col space-y-10 mt-4 w-full lg:w-3/4 xl:w-2/3">
        <Card>
          <ProgressBar>
            <ProgressBar.Step active={isFirstActive}>
              <span>Odabir listova</span>
            </ProgressBar.Step>
            <ProgressBar.Step active={isSecondActive}>
              <span>Spajanje podataka</span>
            </ProgressBar.Step>
            <ProgressBar.Step active={isThirdActive}>
              <span>Potvrda uvoza</span>
            </ProgressBar.Step>
          </ProgressBar>
        </Card>
        {sheetNames.length > 0 && (
          <Wizard>
            <Formik initialValues={{ checkedSheets: checkedSheets }} onSubmit={() => {}}>
              <Form>
                <ChoosingSheets filename={file?.name} sheets={sheetNames} file={file} sheetNames={sheetNames} />
              </Form>
            </Formik>
            {steps.map((step: DataImportStepType, index) => (
              <DataMappingStep key={index} step={step} data={data} />
            ))}
            <ImportValidationList />
          </Wizard>
        )}
      </div>
    </>
  );
}
