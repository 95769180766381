import React from "react";

import { Form, Formik } from "formik";

import { Modal, useModal } from "@tiller/alert";
import { Button } from "@tiller/core";
import { InputField } from "@tiller/formik-elements";

import { PopupProps } from "./Popup";

type PopupWithInputProps = {
  text: string;
} & PopupProps;

const initialValues = {
  text: "",
};

export default function PopupWithInput({
  title,
  content,
  isOpen,
  setIsOpen,
  onSubmit,
  primaryButtonText = "Da",
  secondaryButtonText = "Ne",
  text,
}: PopupWithInputProps & PopupProps) {
  const modal = useModal();

  return (
    <>
      <Modal {...modal} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Content title={title}>
          {content} <span className="font-semibold">{text}</span>
        </Modal.Content>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ values }) => (
            <Form className="flex flex-col">
              <InputField name="text" className="w-full mb-3" />
              <div className="self-end -mb-1">
                <Button variant="outlined" onClick={() => setIsOpen(false)} className="mr-2">
                  {secondaryButtonText}
                </Button>

                <Button
                  variant="filled"
                  color="red"
                  disabled={values.text !== text}
                  onClick={() => {
                    setIsOpen(false);
                    onSubmit?.();
                  }}
                >
                  {primaryButtonText}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
