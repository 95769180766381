import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { SelectField } from "@tiller/formik-elements";

import { dynamicField } from "../api/backend_paths";
import useHandleError from "../hook/useHandleError";
import { dynamicFieldType } from "../types";
import { serializeParameterSuffix } from "../util/GeneralUtil";

type DynamicFieldFiltersProps = {
  formId: string;
};

export default function DynamicFieldFilters({ formId }: DynamicFieldFiltersProps) {
  const [dynamicFields, setDynamicFields] = useState<dynamicFieldType[]>([]);
  const { handleError } = useHandleError();

  const navigate = useNavigate();
  const location = useLocation();

  const toggleOptions = [
    { label: "Označeno", value: "true" },
    { label: "Nije označeno", value: "false" },
  ];

  useEffect(() => {
    fetch(dynamicField.getDynamicFields(serializeParameterSuffix({ formId: formId })))
      .then(handleError)
      .then((data) => setDynamicFields(data));
  }, [formId, handleError, location, navigate]);

  return (
    <>
      {dynamicFields
        .filter((field) => field.isOnFilter)
        .map((field) => {
          if (field.fieldType === "TOGGLE") {
            return (
              <SelectField
                key={field.code}
                options={toggleOptions}
                name={"dynamicData." + field.code}
                label={field.fieldName}
                className="w-full"
                getOptionLabel={(item) => item.label}
                getOptionValue={(item) => item.value}
              />
            );
          }
          if (field.fieldType === "RADIO" || field.fieldType === "DROPDOWN") {
            return (
              <SelectField
                key={field.code}
                options={field.dynamicFieldDataset}
                name={"dynamicData." + field.code}
                label={field.fieldName}
                className="w-full"
              />
            );
          }
          return <div key={field.code} />;
        })}
    </>
  );
}
