import React from "react";

import BottomBackgroundImages from "./components/BottomBackgroundImages";
import LandingPageBody from "./components/LandingPageBody";
import LandingPageTopNavigation from "./components/LandingPageTopNavigation";
import TopBackgroundImages from "./components/TopBackgroundImages";
import FooterSection from "./sections/FooterSection";

export default function LandingPage() {
  const [isMobile] = React.useState<boolean>(window.innerWidth < 641);

  React.useEffect(() => {
    const sections = document.querySelectorAll<HTMLElement>("section[id]");

    function handleScroll() {
      const scrollY = window.scrollY;
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 200;
        const sectionId = current.getAttribute("id");
        if (sectionId === "banner" || sectionId === "pricing" || sectionId === "testimonial") return;
        let navElement: HTMLElement | null;
        if (isMobile) {
          navElement = document.getElementById(`nav-${sectionId}-mobile`);
        } else {
          navElement = document.getElementById(`nav-${sectionId}`);
        }
        if (scrollY > sectionTop && scrollY < sectionTop + sectionHeight) {
          navElement!.classList.add("active");
        } else {
          navElement!.classList.remove("active");
        }
      });
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  return (
    <div className="w-full">
      <LandingPageTopNavigation />
      <TopBackgroundImages />
      <LandingPageBody />
      <BottomBackgroundImages />
      <FooterSection />
    </div>
  );
}
