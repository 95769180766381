export type ReactEnvironmentVariable = {
  REACT_APP_COGNITO_USER_POOL_ID: string;
  REACT_APP_COGNITO_CLIENT_ID: string;
};

interface ReactWindow extends Window {
  REACT_ENV: ReactEnvironmentVariable;
}

declare let window: ReactWindow;
let env = window.REACT_ENV || {};

export default env;
