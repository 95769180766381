import img0 from "../../../assets/img/team/img0.jpg";
import img1 from "../../../assets/img/team/img1.png";
import img10 from "../../../assets/img/team/img10.png";
import img2 from "../../../assets/img/team/img2.png";
import img3 from "../../../assets/img/team/img3.png";
import img4 from "../../../assets/img/team/img4.png";
import img5 from "../../../assets/img/team/img5.png";
import img6 from "../../../assets/img/team/img6.png";
import img7 from "../../../assets/img/team/img7.png";
import img8 from "../../../assets/img/team/img8.png";
import img9 from "../../../assets/img/team/img9.png";

const images = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
export default images;
