import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Tabs } from "@tiller/core";

import { initialValues } from "./components/ActivityFilter";
import ActivityList from "./components/ActivityList";
import { usePermissions } from "../../PermissionsProvider";
import { activities } from "../../api/backend_paths";
import { routes } from "../../api/paths";

import Header from "../../components/Header";
import TabLabel from "../../components/TabLabel";
import { PagesStateContext } from "../../context/PagesStateContext";
import { ActivityFilterValues, Paging } from "../../types";
import { serializeParameterSuffix } from "../../util/GeneralUtil";

export default function Activities() {
  const { permissions } = usePermissions();

  const { activitiesTabIndex, setActivitiesTabIndex } = useContext(PagesStateContext);
  const {
    allActivitiesRecordPaging,
    updateAllActivitiesRecordPage,
    personalActivitiesRecordPaging,
    updatePersonalActivitiesRecordPaging,
  } = useContext(PagesStateContext);

  const navigate = useNavigate();
  const [exportQuery, setExportQuery] = useState<string>("");
  const [allActivitiesFilterValues, setAllActivitiesFilterValues] = useState<ActivityFilterValues>(initialValues);
  const [myActivitiesFilterValues, setMyActivitiesFilterValues] = useState<ActivityFilterValues>(initialValues);
  const [isAllExportDisabled, setIsAllExportDisabled] = useState<boolean>(true);
  const [isMyExportDisabled, setIsMyExportDisabled] = useState<boolean>(true);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const [numberOfAllActivities, setNumberOfAllActivities] = useState<number>(0);
  const [numberOfMyActivities, setNumberOfMyActivities] = useState<number>(0);
  const [allActivitiesSortType, setAllActivitiesSortType] = useState<string | undefined>(undefined);
  const [myActivitiesSortType, setMyActivitiesSortType] = useState<string | undefined>(undefined);

  let dynamicData: any;
  let dynamicDataDate: any;
  let personCodes: any;
  let designatedPersonUsernames: any;
  let filterValuesWoArrays: any;

  if (activitiesTabIndex === 0) {
    ({ dynamicData, dynamicDataDate, personCodes, designatedPersonUsernames, ...filterValuesWoArrays } =
      allActivitiesFilterValues);
  } else {
    ({ dynamicData, dynamicDataDate, personCodes, designatedPersonUsernames, ...filterValuesWoArrays } =
      myActivitiesFilterValues);
  }

  const exportToExcel = () => {
    setIsExportLoading(true);
    fetch(
      activities.exportToExcel(
        serializeParameterSuffix({
          searchKeyword: exportQuery,
          ...allActivitiesRecordPaging,
          ...filterValuesWoArrays,
          personCodes: personCodes?.join(","),
          designatedPersonUsernames:
            activitiesTabIndex === 1 ? localStorage.getItem("username") : designatedPersonUsernames?.join(","),
          dynamicData: JSON.stringify(dynamicData),
          dynamicDataDate: JSON.stringify(dynamicDataDate),
          sortType: activitiesTabIndex === 0 ? allActivitiesSortType : myActivitiesSortType,
        })
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "blob",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res;
        }
      })
      .then((res) => {
        res.blob().then((blob) => {
          const newBlob = new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(newBlob);
          link.download = "Izvještaj-zadaci";
          link.click();
          link.remove();
          setIsExportLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Header
        title="Zadaci"
        primaryButtonText="Kreiraj zadatak"
        secondaryButtonText="Izvoz u Excel"
        primaryButtonIcon="plus"
        secondaryButtonIcon="download"
        onPrimaryClick={() => navigate(routes.NEW_ACTIVITY_URL)}
        onSecondaryClick={exportToExcel}
        disableSecondaryButton={isAllExportDisabled || (activitiesTabIndex === 1 && isMyExportDisabled)}
        isSecondaryLoading={isExportLoading}
        hidePrimaryButton={!permissions?.includes("ACTIVITY_CREATE_UPDATE")}
        hideSecondaryButton={!permissions?.includes("ACTIVITY_EXPORT")}
      />
      <Tabs defaultIndex={activitiesTabIndex}>
        <Tabs.Tab
          label={<TabLabel title="Svi" count={numberOfAllActivities} />}
          onClick={(tabIndex) => setActivitiesTabIndex(tabIndex)}
        >
          {
            <ActivityList
              updatePagingContextIndex={(page: Paging) => updateAllActivitiesRecordPage(page)}
              pagingContextIndex={allActivitiesRecordPaging}
              filterValues={allActivitiesFilterValues}
              setFilterValues={setAllActivitiesFilterValues}
              setIsExportDisabled={setIsAllExportDisabled}
              currentUser={null}
              setNumberOfActivities={setNumberOfAllActivities}
              sortType={allActivitiesSortType}
              setSortType={setAllActivitiesSortType}
              setExportQuery={setExportQuery}
            />
          }
        </Tabs.Tab>
        <Tabs.Tab
          label={<TabLabel title="Moji zadaci" count={numberOfMyActivities} />}
          onClick={(tabIndex) => setActivitiesTabIndex(tabIndex)}
        >
          {
            <ActivityList
              updatePagingContextIndex={(page: Paging) => updatePersonalActivitiesRecordPaging(page)}
              pagingContextIndex={personalActivitiesRecordPaging}
              filterValues={myActivitiesFilterValues}
              setFilterValues={setMyActivitiesFilterValues}
              setIsExportDisabled={setIsMyExportDisabled}
              currentUser={localStorage.getItem("username")}
              setNumberOfActivities={setNumberOfMyActivities}
              sortType={myActivitiesSortType}
              setSortType={setMyActivitiesSortType}
              setExportQuery={setExportQuery}
            />
          }
        </Tabs.Tab>
      </Tabs>
    </>
  );
}
