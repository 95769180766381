import React, { useEffect, useState } from "react";

import { Form, Formik, FormikHelpers, FormikProps } from "formik";

import * as Yup from "yup";

import { Button, Card } from "@tiller/core";
import { InputField, SelectField } from "@tiller/formik-elements";
import { Icon, LoadingIcon } from "@tiller/icons";

import { evidence, role } from "../../../api/backend_paths";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { UserRole } from "../../../types";

type UserRoleInitialValues = {
  email: string;
  role: UserRole | null;
};

const initialValues = {
  email: "",
  role: null,
} as UserRoleInitialValues;

const inviteSchema = Yup.object().shape({
  email: Yup.string().required(""),
  role: Yup.object().required("").nullable(),
});

export default function InviteForm() {
  const { handleError } = useHandleError();
  const [allRoles, setAllRoles] = useState<UserRole[]>([]);
  const [requestSent, setRequestSent] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  useEffect(() => {
    fetch(role.getAllRolesForInvLink())
      .then(handleError)
      .then((data) => setAllRoles(data));
  }, [handleError]);

  const onSubmit = (
    values: { email: string; role: UserRole | null },
    formikHelpers: FormikHelpers<{ email: string; role: UserRole | null }>
  ) => {
    setIsInviteLoading(true);
    setRequestSent(false);
    fetch(evidence.sendInvite(values.email, values.role!.id))
      .then(handleError)
      .then(() => {
        formikHelpers.resetForm();
        setIsInviteLoading(false);
        setMailSent(true);
        setRequestSent(true);
      })
      .catch((err) => {
        console.log(err);
        setIsInviteLoading(false);
        setMailSent(false);
        setRequestSent(true);
      });
  };

  return (
    <Card className="mb-4 w-full md:w-3/4 xl:w-1/2 mt-6">
      <Card.Body>
        <h5 className="-mt-2 mb-2 font-semibold text-lg">Pošalji link za registraciju</h5>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={inviteSchema}>
          {({ dirty, isValid }: FormikProps<UserRoleInitialValues>) => (
            <Form className="flex flex-col gap-2 sm:flex-row">
              <InputField name="email" placeholder="E-mail" className="w-full sm:w-1/2" allowClear />
              <SelectField
                name="role"
                options={allRoles}
                className="-mt-1 w-full sm:w-1/3"
                placeholder="Rola"
                hideClearButton
                getOptionLabel={(role) => role.name}
                getOptionValue={(role) => role}
                getMultipleSelectedLabel={(roles: UserRole[]) => (
                  <>{roles.map((role, index) => (index === 0 ? "" : ", ") + role.name)}</>
                )}
              />
              <Button
                leadingIcon={isInviteLoading ? <LoadingIcon size={5} /> : <Icon type="mail" />}
                variant="filled"
                disabled={isInviteLoading || !(dirty && isValid)}
                className="sm:w-1/6"
              >
                Pošalji
              </Button>
            </Form>
          )}
        </Formik>
        {requestSent && (
          <div
            className={`mt-2 -mb-2 text-sm flex items-center space-x-1 ${mailSent ? "text-green-500" : "text-red-500"}`}
          >
            <Icon type={mailSent ? "check" : "x"} />
            <p>{mailSent ? messages.mailSentSuccessfully : messages.mailFailedToSend}</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
