import React, { useState } from "react";

import { Route, Routes } from "react-router-dom";

import { SidebarLayout } from "@tiller/menu";

import { PermissionsProvider } from "./PermissionsProvider";
import { routes } from "./api/paths";
import Navigation from "./components/Navigation";
import { COMPETITION_FILTER_INITIAL_VALUES } from "./constants/FilterConstants";
import DataImportContextWrapper from "./context/DataImportContext";
import ImportProgressBarContextWrapper from "./context/ImportProgressBarContext";
import Activities from "./pages/Activities/Activities";
import NewActivity from "./pages/ActivityForm/NewActivity/NewActivity";
import UpdateActivity from "./pages/ActivityForm/UpdateActivity/UpdateActivity";
import Admin from "./pages/Admin/Admin";
import DataImportWizard from "./pages/Admin/components/DataImportWizard";
import NewCompetition from "./pages/CompetitionForm/NewCompetititon/NewCompetition";
import UpdateCompetition from "./pages/CompetitionForm/UpdateCompetititon/UpdateCompetititon";
import Competitions from "./pages/Competitions/Competitions";
import ExpiredLink from "./pages/ExpiredLink/ExpiredLink";
import ForgottenPassword from "./pages/ForgottenPassword/ForgottenPassword";
import ForgottenPasswordConfirmation from "./pages/ForgottenPassword/ForgottenPasswordConfirmation";
import PasswordChangeSuccess from "./pages/ForgottenPassword/PasswordChangeSuccess";
import ResetPassword from "./pages/ForgottenPassword/ResetPassword";
import LandingPage from "./pages/LandingPage/LandingPage";
import UserLogin from "./pages/Login/UserLogin";
import NewMember from "./pages/MemberForm/NewMember/NewMember";
import UpdateMember from "./pages/MemberForm/UpdateMember/UpdateMember";
import Members from "./pages/Members/Members";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import AssociationRegistration from "./pages/Registration/AssociationRegistration";
import UserRegistration from "./pages/Registration/UserRegistration";
import NewRole from "./pages/Roles/NewRole/NewRole";
import UpdateRole from "./pages/Roles/UpdateRole/UpdateRole";
import ViewActivity from "./pages/ViewActivity/ViewActivity";
import ViewCompetition from "./pages/ViewCompetition/ViewCompetition";
import ViewMember from "./pages/ViewMember/ViewMember";

function RouteWithSidebar({ element }: { element: React.ReactNode }) {
  return (
    <PermissionsProvider>
      <SidebarLayout navigation={<Navigation />}>
        <SidebarLayout.Content>{element}</SidebarLayout.Content>
      </SidebarLayout>
    </PermissionsProvider>
  );
}

export default function ZadrugaRoutes() {
  const [adminLastTab, setAdminLastTab] = useState(0);
  const [competitionFilters, setCompetitionFilters] = useState({
    all: COMPETITION_FILTER_INITIAL_VALUES,
    applied: COMPETITION_FILTER_INITIAL_VALUES,
    favorite: COMPETITION_FILTER_INITIAL_VALUES,
    custom: COMPETITION_FILTER_INITIAL_VALUES,
  });

  return (
    <Routes>
      <Route path={routes.USER_LOGIN_URL} element={<UserLogin />} />
      <Route path={routes.ASSOCIATION_REGISTRATION_URL} element={<AssociationRegistration />} />
      <Route path={routes.USER_REGISTRATION_URL()} element={<UserRegistration />} />
      <Route path={routes.ADMIN_REGISTRATION_URL} element={<UserRegistration />} />
      <Route path={routes.FORGOTTEN_PASSWORD_URL} element={<ForgottenPassword />} />
      <Route path={routes.FORGOTTEN_PASSWORD_CONFIRMATION_URL} element={<ForgottenPasswordConfirmation />} />
      <Route path={routes.RESET_PASSWORD_URL} element={<ResetPassword />} />
      <Route path={routes.PASSWORD_CHANGE_SUCCESS_URL} element={<PasswordChangeSuccess />} />
      <Route path={routes.EXPIRED_LINK_URL} element={<ExpiredLink />} />
      <Route path={routes.LANDING_PAGE_URL} element={<LandingPage />} />

      <Route path={routes.RECORDS_URL} element={<RouteWithSidebar element={<Members />} />} />

      <Route path={routes.ACTIVITIES_URL} element={<RouteWithSidebar element={<Activities />} />} />
      <Route
        path={routes.COMPETITIONS_URL}
        element={
          <RouteWithSidebar
            element={<Competitions allFiltersValues={competitionFilters} setFilterValues={setCompetitionFilters} />}
          />
        }
      />
      <Route path={routes.NEW_MEMBER_URL} element={<RouteWithSidebar element={<NewMember />} />} />
      <Route path={routes.UPDATE_MEMBER_URL()} element={<RouteWithSidebar element={<UpdateMember />} />} />
      <Route path={routes.VIEW_MEMBER_URL()} element={<RouteWithSidebar element={<ViewMember />} />} />
      <Route path={routes.NEW_ACTIVITY_URL} element={<RouteWithSidebar element={<NewActivity />} />} />
      <Route path={routes.NEW_COMPETITION_URL} element={<RouteWithSidebar element={<NewCompetition />} />} />
      <Route path={routes.UPDATE_COMPETITION_URL()} element={<RouteWithSidebar element={<UpdateCompetition />} />} />
      <Route path={routes.VIEW_COMPETITION_URL()} element={<RouteWithSidebar element={<ViewCompetition />} />} />
      <Route path={routes.UPDATE_ACTIVITY_URL()} element={<RouteWithSidebar element={<UpdateActivity />} />} />
      <Route path={routes.VIEW_ACTIVITY_URL()} element={<RouteWithSidebar element={<ViewActivity />} />} />
      <Route
        path={routes.ADMIN_URL}
        element={<RouteWithSidebar element={<Admin defaultTab={adminLastTab} setDefaultTab={setAdminLastTab} />} />}
      />
      <Route
        path={routes.DATA_IMPORT}
        element={
          <RouteWithSidebar
            element={
              <ImportProgressBarContextWrapper>
                <DataImportContextWrapper>
                  <DataImportWizard />
                </DataImportContextWrapper>
              </ImportProgressBarContextWrapper>
            }
          />
        }
      />
      <Route path={routes.NEW_ROLE_URL} element={<RouteWithSidebar element={<NewRole />} />} />
      <Route path={routes.UPDATE_ROLE_URL()} element={<RouteWithSidebar element={<UpdateRole />} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
