import React, { useContext, useEffect, useState } from "react";

import { useField } from "formik";
import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";

import { Button, Card, Typography } from "@tiller/core";
import { ToggleField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import { DataImportResponse } from "../../../types";
import { dataImportFinished } from "../../../util/DataImportUtil";

type ImportValidationListFormProps = {
  sheetsWithoutError: string[];
  sheetsWithError: string[];
  response: DataImportResponse;
};

export default function ImportValidationListForm({
  sheetsWithoutError,
  sheetsWithError,
  response,
}: ImportValidationListFormProps) {
  const [checkedSheets] = useField("checkedSheets");
  const { updateProgressBar } = useContext(ImportProgressBarContext);
  const { isLoading, previousStep } = useWizard();
  const [isOpen, setIsOpen] = useState(false);
  const [canImport, setCanImport] = useState(true);

  const navigate = useNavigate();
  // @ts-ignore
  useEffect(() => {
    if (Object.keys(response.errorsPerSheet).length === 0) {
      return false;
    }
    let numberOfInsertable = 0;
    Object.keys(response.errorsPerSheet).forEach((value) => {
      if (response.errorsPerSheet[value].insertable) {
        numberOfInsertable += 1;
      }
    });
    setCanImport(
      numberOfInsertable > 0 && Object.keys(checkedSheets.value).filter((key) => checkedSheets.value[key]).length > 0
    );
  }, [checkedSheets, response.errorsPerSheet]);

  return (
    <>
      {sheetsWithoutError.map((value, index) => (
        <div key={index} className="flex flex-col space-y-4 pb-6">
          <Card key={index}>
            <Card.Header>
              <Card.Header.Title>
                <div className="flex space-x-2 items-center">
                  <Icon type="check-circle" className="text-green-600" />
                  <Typography element="p" variant="text">
                    List {value}
                  </Typography>
                </div>
              </Card.Header.Title>
              <Card.Header.Actions>
                <ToggleField
                  name={`checkedSheets[${value}]`}
                  label={<span className="text-sm leading-5 font-medium text-indigo-900">Uvezi</span>}
                />
              </Card.Header.Actions>
            </Card.Header>
          </Card>
        </div>
      ))}
      {sheetsWithError.map((value, index) => (
        <div key={index} className="flex flex-col space-y-4 pb-6">
          <Card isExpanded={false} key={index}>
            <Card.Header>
              <Card.Header.Title>
                <div className="flex space-x-2 items-center">
                  <Icon type="x-circle" className="text-red-600" />
                  <Typography element="p" variant="text">
                    List {value}
                  </Typography>
                </div>
              </Card.Header.Title>
            </Card.Header>
            <Card.Body>
              <div className="w-full flex justify-between">
                <div className="flex flex-col">
                  <p>
                    <span className="text-red-600">OPREZ!</span> Pojavile su se sljedeće{" "}
                    <span className="text-red-600">greške</span>:
                  </p>
                  <ol className="list-decimal list-outside px-6 pt-4 text-gray-600">
                    {response.errorsPerSheet[value].errors.map((value1, index1) => (
                      <li key={index1}> {value1} </li>
                    ))}
                  </ol>
                </div>
                {response.errorsPerSheet[value].insertable && (
                  <ToggleField
                    name={`checkedSheets[${value}]`}
                    label={<span className="text-sm leading-5 font-medium text-indigo-900">Uvezi</span>}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}

      {response.duplicates.length !== 0 && (
        <div className="flex flex-col space-y-4 pb-6">
          <Card isExpanded={false}>
            <Card.Header>
              <Card.Header.Title>
                <div className="flex space-x-2 items-center">
                  <Typography element="p" variant="text">
                    Dupli unosi
                  </Typography>
                </div>
              </Card.Header.Title>
            </Card.Header>
            <Card.Body>
              <div className="w-full flex justify-between">
                <div className="flex flex-col">
                  <p>
                    <span className="text-red-600">OPREZ!</span> Idući profili imaju isto ime i prezime.
                  </p>
                  <p>
                    <span>
                      Podaci će se uvesti u aplikaciju, no provjerite jesu li ispravni. U slučaju da nisu, iste možete
                      prepraviti u aplikaciji.
                    </span>
                  </p>
                  <ol className="list-decimal list-outside px-6 pt-4 text-gray-600">
                    {response.duplicates.map((value, index) => (
                      <li key={index}> {value} </li>
                    ))}
                  </ol>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}

      <div>
        <ul className="list-disc list-outside px-6 pt-4">
          <li>
            Klikom na gumb <strong>Uvezi</strong> koji se nalazi na dnu liste, podaci će biti uvezeni,{" "}
            <strong>no polja sa greškom ostat će prazna</strong>.
          </li>
          <li>
            Klikom na gumb <strong>Odustani</strong>, uvezeni <strong>podaci će se odbaciti</strong> te ćete se vratiti
            na početni ekran uvoza. U tom slučaju, <strong>prepravite podatke</strong> u MS Excelu i pokušajte ponovno
            uvesti excel.
          </li>
        </ul>
      </div>
      <div className="w-full flex items-center justify-between mt-8">
        <Button
          type="button"
          variant="outlined"
          onClick={() => setIsOpen(true)}
          leadingIcon={<Icon variant="outline" type="x" />}
          size="md"
        >
          Odustani
        </Button>
        <div className="flex space-x-5 items-center">
          <Button
            variant="outlined"
            onClick={() => {
              previousStep();
              updateProgressBar(false, true, false);
            }}
            type="button"
            disabled={isLoading}
            leadingIcon={<Icon variant="outline" type="arrow-left" />}
            size="md"
          >
            Prethodno
          </Button>
          <Button
            onClick={() => {}}
            type="submit"
            disabled={isLoading || !canImport}
            trailingIcon={<Icon variant="outline" type="arrow-right" />}
            size="md"
          >
            Uvezi
          </Button>
        </div>
      </div>
      <Popup
        title={messages.cancelPrompt}
        content={messages.createWarning}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => {
          dataImportFinished();
          navigate(routes.ADMIN_URL);
        }}
      />
    </>
  );
}
