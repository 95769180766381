import { FormikValues } from "formik";

import { parseDate } from "../pages/ActivityForm/util/activity-util";
import { Note } from "../types";

export const newNoteRequest = (values: FormikValues) => {
  return {
    content: values.content as string,
  };
};

export const newNoteResponse = (values: FormikValues): Note => {
  return {
    id: values.id,
    content: values.content as string,
    author: values.author,
    dateOfCreation: parseDate(values.dateOfCreation).at(0) as string,
    timeOfCreation: parseDate(values.dateOfCreation).at(1) as string,
    updatedAuthor: values.updatedAuthor,
    dateOfUpdate: parseDate(values.dateOfUpdate).at(0) as string,
    timeOfUpdate: parseDate(values.dateOfUpdate).at(1) as string,
  };
};

export const updateNoteRequest = (values: FormikValues) => {
  return {
    id: values.id,
    content: values.content as string,
  };
};

export function parseNoteDate(notes: Note[]) {
  notes?.forEach((note: Note) => {
    [note.dateOfCreation, note.timeOfCreation] = parseDate(note.dateOfCreation) as [string, string];
    [note.dateOfUpdate, note.timeOfUpdate] = parseDate(note.dateOfUpdate) as [string, string];
  });
}
