import React from "react";

import { Button, Typography } from "@tiller/core";
import { Icon, IconType, LoadingIcon } from "@tiller/icons";

type HeaderProps = {
  title: string;
  hidePrimaryButton?: boolean;
  hideSecondaryButton?: boolean;
  hideTertiaryButton?: boolean;
  showBackButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryButtonText?: string;
  primaryButtonIcon?: IconType;
  secondaryButtonIcon?: IconType;
  tertiaryButtonIcon?: IconType;
  onPrimaryClick?: () => void;
  onSecondaryClick?: (e: any) => void;
  onTertiaryClick?: (e: any) => void;
  onBackButtonClick?: () => void;
  disableSecondaryButton?: boolean;
  disableTertiaryButton?: boolean;
  isPrimaryLoading?: boolean;
  isSecondaryLoading?: boolean;
  isTertiaryLoading?: boolean;
};

export default function Header({
  title,
  hidePrimaryButton = false,
  hideSecondaryButton = false,
  hideTertiaryButton = false,
  showBackButton = false,
  primaryButtonText,
  secondaryButtonText,
  tertiaryButtonText,
  primaryButtonIcon = "x",
  secondaryButtonIcon = "x",
  tertiaryButtonIcon = "x",
  onPrimaryClick,
  onSecondaryClick,
  onTertiaryClick,
  onBackButtonClick,
  disableSecondaryButton = false,
  disableTertiaryButton = false,
  isPrimaryLoading = false,
  isSecondaryLoading = false,
  isTertiaryLoading = false,
}: HeaderProps) {
  return (
    <div className="w-full sticky top-0 z-20 py-8 ring-2 ring-secondary-light bg-secondary-light">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex flex-row items-center min-w-0">
          {showBackButton && (
            <Icon
              type="arrow-left"
              className="mr-4 hover:cursor-pointer hover:text-gray-500"
              onClick={onBackButtonClick}
            />
          )}
          <Typography variant="h2" className="uppercase">
            {title}
          </Typography>
        </div>
        <div className="hidden xl:block">
          <div className="flex content-center pt-4 lg:pt-0 gap-4">
            {secondaryButtonText && (
              <Button
                tabIndex={0}
                hidden={hideSecondaryButton}
                color="primary"
                type="button"
                leadingIcon={
                  isSecondaryLoading ? <LoadingIcon size={5} /> : <Icon type={secondaryButtonIcon} variant="solid" />
                }
                size="md"
                variant="outlined"
                onClick={onSecondaryClick}
                disabled={disableSecondaryButton || isSecondaryLoading}
              >
                {secondaryButtonText}
              </Button>
            )}
            <Button
              hidden={hidePrimaryButton}
              color="primary"
              type="submit"
              leadingIcon={
                isPrimaryLoading ? <LoadingIcon size={5} /> : <Icon type={primaryButtonIcon} variant="outline" />
              }
              size="md"
              onClick={onPrimaryClick}
              disabled={isPrimaryLoading}
            >
              {primaryButtonText}
            </Button>
          </div>
        </div>
        <div className="hidden lg:block xl:hidden">
          <div className="flex content-center justify-between pt-4 lg:pt-0 gap-4">
            <div className="flex content-center gap-4">
              {secondaryButtonText && (
                <Button
                  tabIndex={0}
                  hidden={hideSecondaryButton}
                  color="primary"
                  type="button"
                  leadingIcon={
                    isSecondaryLoading ? <LoadingIcon size={5} /> : <Icon type={secondaryButtonIcon} variant="solid" />
                  }
                  size="md"
                  variant="outlined"
                  onClick={onSecondaryClick}
                  disabled={disableSecondaryButton || isSecondaryLoading}
                >
                  {secondaryButtonText}
                </Button>
              )}
              <Button
                hidden={hidePrimaryButton}
                color="primary"
                type="submit"
                leadingIcon={
                  isPrimaryLoading ? <LoadingIcon size={5} /> : <Icon type={primaryButtonIcon} variant="outline" />
                }
                size="md"
                onClick={onPrimaryClick}
                disabled={isPrimaryLoading}
              >
                {primaryButtonText}
              </Button>
            </div>
            {tertiaryButtonText && (
              <Button
                tabIndex={0}
                hidden={hideTertiaryButton}
                color="primary"
                type="button"
                leadingIcon={
                  isTertiaryLoading ? <LoadingIcon size={5} /> : <Icon type={tertiaryButtonIcon} variant="solid" />
                }
                size="md"
                variant="outlined"
                onClick={onTertiaryClick}
                disabled={disableTertiaryButton || isTertiaryLoading}
              >
                {tertiaryButtonText}
              </Button>
            )}
          </div>
        </div>
        <div className="hidden sm:block lg:hidden">
          <div className="flex content-center justify-between pt-4 lg:pt-0 gap-4">
            <div className="flex content-center gap-4">
              {secondaryButtonText && (
                <Button
                  tabIndex={0}
                  hidden={hideSecondaryButton}
                  color="primary"
                  type="button"
                  leadingIcon={
                    isSecondaryLoading ? <LoadingIcon size={5} /> : <Icon type={secondaryButtonIcon} variant="solid" />
                  }
                  size="md"
                  variant="outlined"
                  onClick={onSecondaryClick}
                  disabled={disableSecondaryButton || isSecondaryLoading}
                >
                  {secondaryButtonText}
                </Button>
              )}
              <Button
                hidden={hidePrimaryButton}
                color="primary"
                type="submit"
                leadingIcon={
                  isPrimaryLoading ? <LoadingIcon size={5} /> : <Icon type={primaryButtonIcon} variant="outline" />
                }
                size="md"
                onClick={onPrimaryClick}
                disabled={isPrimaryLoading}
              >
                {primaryButtonText}
              </Button>
            </div>
            {tertiaryButtonText && (
              <Button
                tabIndex={0}
                hidden={hideTertiaryButton}
                color="primary"
                type="button"
                leadingIcon={
                  isTertiaryLoading ? <LoadingIcon size={5} /> : <Icon type={tertiaryButtonIcon} variant="solid" />
                }
                size="md"
                variant="outlined"
                onClick={onTertiaryClick}
                disabled={disableTertiaryButton || isTertiaryLoading}
              >
                {tertiaryButtonText}
              </Button>
            )}
          </div>
        </div>
        <div className="block sm:hidden">
          <div className="flex content-center justify-between pt-4 lg:pt-0 gap-4">
            <div className="flex content-center gap-4">
              {secondaryButtonText && (
                <Button
                  className="pr-1"
                  tabIndex={0}
                  hidden={hideSecondaryButton}
                  color="primary"
                  type="button"
                  leadingIcon={
                    isSecondaryLoading ? <LoadingIcon size={5} /> : <Icon type={secondaryButtonIcon} variant="solid" />
                  }
                  size="md"
                  variant="outlined"
                  onClick={onSecondaryClick}
                  disabled={disableSecondaryButton || isSecondaryLoading}
                >
                  {}
                </Button>
              )}
              <Button
                className="pr-1"
                hidden={hidePrimaryButton}
                color="primary"
                type="submit"
                leadingIcon={
                  isPrimaryLoading ? <LoadingIcon size={5} /> : <Icon type={primaryButtonIcon} variant="outline" />
                }
                size="md"
                onClick={onPrimaryClick}
                disabled={isPrimaryLoading}
              >
                {}
              </Button>
            </div>
            {tertiaryButtonText && (
              <Button
                tabIndex={0}
                hidden={hideTertiaryButton}
                color="primary"
                type="button"
                leadingIcon={
                  isTertiaryLoading ? <LoadingIcon size={5} /> : <Icon type={tertiaryButtonIcon} variant="solid" />
                }
                size="md"
                variant="outlined"
                onClick={onTertiaryClick}
                disabled={disableTertiaryButton || isTertiaryLoading}
              >
                {tertiaryButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
