import React from "react";

import { FormConfigurationProvider } from "@nrich/form-configuration-core";

const oib = require("oib.js");

const additionalValidatorConverters = [
  {
    supports: (configuration: any) => configuration.name === "ValidOib",
    convert: (configuration: any, validator: any) =>
      validator.test(
        "validOib",
        configuration.errorMessage,
        (value: any) => value === undefined || oib.validate(value)
      ),
  },
  {
    supports: (configuration: any) => configuration.name === "EmailValidation",
    convert: (configuration: any, validator: any) =>
      validator.test("emailValidation", configuration.errorMessage, (value: any) => {
        return (
          value === undefined ||
          String(value)
            .toLowerCase()
            .match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
        );
      }),
  },
  {
    supports: (configuration: any) => configuration.name === "NotNull",
    convert: (configuration: any, validator: any) =>
      validator.test("notNull", configuration.errorMessage, (value: any) => {
        return value !== undefined && value !== null;
      }),
  },
  {
    supports: (configuration: any) => configuration.name === "NotEmpty",
    convert: (configuration: any, validator: any) =>
      validator.test("notEmpty", configuration.errorMessage, (value: any) => {
        return value !== undefined && value !== null;
      }),
  },
];

type FormConfigurationProps = {
  children: React.ReactNode;
};
export default function FormConfiguration({ children }: FormConfigurationProps) {
  return (
    <FormConfigurationProvider
      url="/api/nrich/form/configuration"
      loader="Loading..."
      additionalValidatorConverters={additionalValidatorConverters}
    >
      {children}
    </FormConfigurationProvider>
  );
}
