import React, { useEffect, useRef, useState } from "react";

import { useFormikContext } from "formik";

import { Card } from "@tiller/core";
import { AutocompleteField, InputField, SelectField, TextareaField } from "@tiller/formik-elements";

import DataListField from "./DataListField";
import { activities } from "../../../api/backend_paths";
import DynamicFields from "../../../components/DynamicFields";
import { MaskedDateInputField } from "../../../components/Fields/MaskedDateInputField";
import { MaskedTimeInputField } from "../../../components/Fields/MaskedTimeInputField";
import useHandleError from "../../../hook/useHandleError";
import { ActivityFormType, User } from "../../../types";
import { scrollToInvalidField } from "../../../util/scrollToInvalidField";

export const PERSONAL_TASK = "PERSONAL_TASK";
export const PROJECT = "PROJECT";
export const PROGRAM = "PROGRAM";

export const types = [PERSONAL_TASK, PROJECT, PROGRAM] as const;

export type ActivityTypeValues = (typeof types)[number];

const activityTypeOptions = [
  { code: PERSONAL_TASK, label: "Samostalni zadatak" },
  { code: PROJECT, label: "Zadatak projekta" },
  { code: PROGRAM, label: "Zadatak programa" },
] as ActivityType[];

type ActivityFormProps = {
  designatedPersons?: User[];
  className?: string;
  activityType: string;
};

type ActivityType = {
  code: ActivityTypeValues;
  label: string;
};

export function ActivityFormSelection({ designatedPersons, className = "", activityType }: ActivityFormProps) {
  const { handleError } = useHandleError();

  const [titles, setTitles] = useState<string[]>([]);

  const { values, setFieldValue, errors, isSubmitting } = useFormikContext<ActivityFormType>();
  const activityTypeRef = useRef(values.activityType);

  const designatedPersonProps = {
    options: designatedPersons as User[],
    itemToString: (person: User) => `${person.firstName} ${person.lastName}`,
    getOptionLabel: (person: User) => `${person.firstName} ${person.lastName}`,
    getOptionValue: (person: User) => person.username,
    sendOptionValue: false,
    sort: (persons: User[]) => persons.sort((a, b) => a.firstName.localeCompare(b.firstName)),
  };

  const formatName = (name: string, option: User) =>
    (option.firstName.toLowerCase() + " " + option.lastName.toLowerCase()).includes(name.toLowerCase());

  const formId = React.useMemo(() => ["ACTIVITY"], []);

  useEffect(() => {
    if (activityTypeRef.current !== values.activityType) {
      setFieldValue("projectTitle", "");
    }
    if (values.activityType !== "PERSONAL_TASK") {
      fetch(activities.getTitles(values.activityType))
        .then(handleError)
        .then((data) => {
          setTitles(data);
        })
        .catch((err) => console.log(err));
    } else {
      setTitles([]);
    }
  }, [handleError, setFieldValue, values.activityType]);

  useEffect(() => {
    scrollToInvalidField(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <Card className={className}>
      <Card.Body>
        <div className="w-full flex flex-col space-y-8">
          <div className="flex flex-col lg:flex-row w-full gap-4">
            <SelectField
              name="activityType"
              label="Vrsta zadatka"
              options={activityTypeOptions}
              getOptionLabel={(item: ActivityType) => (
                <span className="flex">
                  <p>{item.label}</p>
                </span>
              )}
              getOptionValue={(item: ActivityType) => item.code}
              className="lg:w-1/2"
              hideClearButton
            />
            {(activityType === PROGRAM || activityType === PROJECT) && (
              <DataListField
                name="projectTitle"
                label={`Naziv ${values.activityType === PROJECT ? "projekta" : "programa"}`}
                className="lg:w-1/2"
                valueList={titles}
                required
              />
            )}
          </div>
          <InputField name="title" label="Naslov" required />
          <TextareaField name="description" label="Opis" textareaClassName="h-20" />
          <div className="w-full flex space-x-4">
            <MaskedDateInputField name="startDate" label="Datum početka" className="w-full lg:w-1/2" required />
            <MaskedTimeInputField name="startTime" label="Vrijeme početka" className="w-full lg:w-1/4" />
          </div>
          <div className="w-full flex space-x-4">
            <MaskedDateInputField name="endDate" label="Datum kraja" className="w-full lg:w-1/2" />
            <MaskedTimeInputField name="endTime" label="Vrijeme kraja" className="w-full lg:w-1/4" />
          </div>
          <InputField name="taskDuration" label="Trajanje zadatka" />
          <AutocompleteField
            name="designatedPersons"
            label="Odgovorna osoba"
            {...designatedPersonProps}
            className="w-full lg:w-1/2"
            filter={formatName}
            allowMultiple
            getMultipleSelectedLabel={(items: User[]) => {
              return items.map((item) => `${item.firstName} ${item.lastName}`).join(", ");
            }}
            maxItems={designatedPersons?.length}
          />
          <DynamicFields formId={formId} />
        </div>
      </Card.Body>
    </Card>
  );
}
