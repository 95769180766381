import React, { createContext, useState } from "react";

type ProgressBarContextType = {
  children: React.ReactNode;
};

export type ProgressBarA = {
  isFirstActive: boolean;
  isSecondActive: boolean;
  isThirdActive: boolean;
  updateProgressBar: (isFistActive: boolean, isSecondActive: boolean, isThirdActive: boolean) => void;
};

export const ImportProgressBarContext = createContext<ProgressBarA>({
  isFirstActive: true,
  isSecondActive: false,
  isThirdActive: false,
  updateProgressBar(isFistActive: boolean, isSecondActive: boolean, isThirdActive: boolean): void {},
});

export default function ImportProgressBarContextWrapper({ children }: ProgressBarContextType) {
  const [isFirstActive, setIsFirstActive] = useState<boolean>(true);
  const [isSecondActive, setIsSecondActive] = useState<boolean>(false);
  const [isThirdActive, setIsThirdActive] = useState<boolean>(false);

  const updateProgressBar = (updateFirst: boolean, updateSecond: boolean, updateThird: boolean) => {
    setIsFirstActive(updateFirst);
    setIsSecondActive(updateSecond);
    setIsThirdActive(updateThird);
  };

  return (
    <ImportProgressBarContext.Provider value={{ isFirstActive, isSecondActive, isThirdActive, updateProgressBar }}>
      {children}
    </ImportProgressBarContext.Provider>
  );
}
