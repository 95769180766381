import React from "react";

import "../styles/LandingPageStyles.css";

import { Link } from "react-router-dom";

import { routes } from "../../../api/paths";

import altruistLogoWhite from "../../../assets/img/altruistLogo-white.svg";
import crozLogoWhite from "../../../assets/img/crozLogo-white.gif";
import facebook from "../../../assets/img/socMedia/facebook.svg";
import instagram from "../../../assets/img/socMedia/instagram.svg";
import linkedin from "../../../assets/img/socMedia/linkedin.svg";
import twitter from "../../../assets/img/socMedia/twitter.svg";
import { scrollToSection } from "../util/scrollToSection";

export default function FooterSection() {
  return (
    <footer className="flex flex-col py-16 px-4 lg:px-16 xl:px-24 2xl:px-40 bg-slate-800 gap-16">
      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-12 xl:gap-16">
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex items-center h-10 ">
            <img src={altruistLogoWhite} alt="altruist logo" className="h-10 text-white" />
          </div>

          <p className="landing-p-sm text-white">
            Altruist je web aplikacija koja će skratiti vrijeme administriranja Vaše udruge i pomoći da se više
            posvetite svojoj misiji.
          </p>
          <div className="w-auto">
            <a href="mailto:altruist-podrska@croz.net" className="footer-item">
              altruist-podrska@croz.net
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex items-center h-10">
            <h5 className="landing-h5 text-white">NAVIGACIJA</h5>
          </div>
          <div className="flex flex-col">
            <div className="w-auto">
              <Link to="/" className="footer-item" onClick={() => scrollToSection("video")}>
                Video
              </Link>
            </div>
            <div className="w-auto">
              <Link to="/" className="footer-item" onClick={() => scrollToSection("values")}>
                Vrijednosti
              </Link>
            </div>
            <div className="w-auto">
              <Link to="/" className="footer-item" onClick={() => scrollToSection("features")}>
                Funkcionalnosti
              </Link>
            </div>
            <div className="w-auto">
              <Link to="/" className="footer-item" onClick={() => scrollToSection("contact-form")}>
                Altruist tim
              </Link>
            </div>
            <div className="w-auto">
              <Link to="/" className="footer-item" onClick={() => scrollToSection("team")}>
                Kontakt
              </Link>
            </div>
            <div className="w-auto">
              <Link to={routes.ADMIN_REGISTRATION_URL} target="_blank" rel="noreferrer" className="footer-item">
                Registracija
              </Link>
            </div>
            <div className="w-auto">
              <Link to={routes.USER_LOGIN_URL} target="_blank" rel="noreferrer" className="footer-item">
                Prijava
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden md:block xl:hidden"></div>
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex items-center h-10">
            <a href="https://croz.net" target="_blank" rel="noreferrer">
              <img src={crozLogoWhite} alt="croz logo" className="h-5 text-white" />
            </a>
          </div>
          <div className="flex flex-col landing-p-sm text-white">
            <p>CROZ Ltd</p>
            <p>Lastovska 23, 10000 Zagreb</p>
            <p>Croatia</p>
            <div className="w-auto">
              <a href="tel:385016184831" className="footer-item">
                +385 (0)1 6184831
              </a>
            </div>
            <div className="w-auto">
              <a href="mailto:croz-info@croz.net" className="footer-item">
                croz-info@croz.net
              </a>
            </div>
          </div>
          <div className="flex gap-2">
            <a href="https://www.facebook.com/CROZdoo/" target="_blank" rel="noreferrer" className="icon-item">
              <img src={facebook} alt="icon" />
            </a>
            <a href="https://twitter.com/croz_hr" target="_blank" rel="noreferrer" className="icon-item">
              <img src={twitter} alt="icon" />
            </a>
            <a href="https://www.linkedin.com/company/croz" target="_blank" rel="noreferrer" className="icon-item">
              <img src={linkedin} alt="icon" />
            </a>
            <a href="https://www.instagram.com/croz_hr/" target="_blank" rel="noreferrer" className="icon-item">
              <img src={instagram} alt="icon" />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex items-center h-10">
            <h5 className="landing-h5 text-white">OPĆENITO</h5>
          </div>
          <div className="flex flex-col landing-p-sm text-white">
            <div className="w-auto">
              <a href="https://croz.net/contact/" target="_blank" rel="noreferrer" className="footer-item">
                Kontakt
              </a>
            </div>
            <div className="w-auto">
              <a
                href="https://www.iubenda.com/privacy-policy/63439611"
                target="_blank"
                rel="noreferrer"
                className="footer-item"
              >
                Politika privatnosti
              </a>
            </div>
            <div className="w-auto">
              <a
                href="https://www.iubenda.com/privacy-policy/63439611/cookie-policy"
                target="_blank"
                rel="noreferrer"
                className="footer-item"
              >
                Politika kolačića
              </a>
            </div>
            <div className="w-auto">
              <a
                href="https://croz.net/general-terms-and-conditions-of-personal-data-protection/"
                target="_blank"
                rel="noreferrer"
                className="footer-item"
              >
                Odredbe i uvjeti GDPR-a
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex items-center h-10">
            <h5 className="landing-h5 text-white">ISTRAŽI</h5>
          </div>
          <div className="flex flex-col landing-p-sm text-white">
            <div className="w-auto">
              <a href="https://croz.net/about/" target="_blank" rel="noreferrer" className="footer-item">
                O CROZ-u
              </a>
            </div>
            <div className="w-auto">
              <a href="https://croz.net/services/" target="_blank" rel="noreferrer" className="footer-item">
                Usluge
              </a>
            </div>
            <div className="w-auto">
              <a href="https://croz.net/projects/" target="_blank" rel="noreferrer" className="footer-item">
                Projekti
              </a>
            </div>
            <div className="w-auto">
              <a href="https://croz.talentlyft.com" target="_blank" rel="noreferrer" className="footer-item">
                Karijere
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:justify-between text-md text-gray-400 pt-4 border-t-2 border-t-gray-600">
        <p>
          Powered by{" "}
          <a href="https://croz.net" target="_blank" rel="noreferrer" className="footer-item font-bold">
            CROZ
          </a>
        </p>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <p>© 2002-2024 CROZ Ltd</p>
          <p>
            <a
              href="https://croz.net/general-terms-and-conditions-of-personal-data-protection/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400 footer-item"
            >
              Opći uvjeti zaštite osobnih podataka
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
