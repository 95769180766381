import React from "react";

import { IconButton } from "@tiller/core";
import { InputField } from "@tiller/formik-elements";

type CustomValueInputProps = {
  onRemoveInput?: () => void;
  name: string;
  className?: string;
};

export default function CustomValueInput({ onRemoveInput, name, className = "" }: CustomValueInputProps) {
  return (
    <div className={className}>
      <InputField name={name} className="w-full md:w-1/2 xl:w-1/4 mb-2" />
      <IconButton
        type="x"
        label="Izbriši"
        className="h-full ml-2 cursor-pointer"
        onClick={onRemoveInput}
        tabIndex={-1}
      />
    </div>
  );
}
