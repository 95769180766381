import React, { useState } from "react";

import { Form, FormikProps } from "formik";

import * as Yup from "yup";

import { Button } from "@tiller/core";

import { FormContainer, InputField } from "@tiller/formik-elements";

import { user as paths_user } from "../../../api/backend_paths";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { User } from "../../../types";

const UpdateUserValidationSchema = Yup.object().shape({
  email: Yup.string().email("Email je neispravnog formata.").required(messages.requiredField),
  firstName: Yup.string().required(messages.requiredField),
  lastName: Yup.string().required(messages.requiredField),
});

type UpdateUserPopupProps = {
  onSubmit: () => void;
  user: User | undefined;
};

export default function UpdateUserPopup({ onSubmit, user }: UpdateUserPopupProps) {
  const { handleError } = useHandleError();

  const [emailUsedMessage, setEmailUsedMessage] = useState("");

  const updateUser = (values: User | undefined) => {
    setEmailUsedMessage("");
    fetch(paths_user.updateUser, {
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        onSubmit();
      })
      .catch((err) => {
        console.log(err);
        err.json().then((data: any) => {
          if (data.message === "Email already exists") {
            setEmailUsedMessage(messages.emailUsed);
          }
        });
      });
  };

  return (
    <FormContainer
      initialValues={user}
      onSubmit={(values) => {
        updateUser(values);
      }}
      validationSchema={UpdateUserValidationSchema}
    >
      {({ isValid }: FormikProps<User | undefined>) => (
        <Form className="flex flex-col items-end ">
          <InputField name="username" label="Korisničko ime" className="w-full my-2" disabled={true} />
          <InputField name="email" label="Email" className="w-full mb-2" allowClear />
          <p className="text-red-500 text-sm mb-0 mt-0 self-start">{emailUsedMessage}</p>
          <InputField name="firstName" label="Ime" className="w-full mb-2" allowClear />
          <InputField name="lastName" label="Prezime" className="w-full mb-4" allowClear />
          <Button className="w-1/5" disabled={!isValid}>
            Spremi
          </Button>
        </Form>
      )}
    </FormContainer>
  );
}
