import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import isPast from "date-fns/isPast";

import { useLocation, useNavigate } from "react-router-dom";

import { Card, IconButton, Link, useLocalPagination } from "@tiller/core";
import { DataTable, useDataTable } from "@tiller/data-display";

import ActivityFilter, { initialValues } from "./ActivityFilter";
import { usePermissions } from "../../../PermissionsProvider";
import { activities, evidence, user } from "../../../api/backend_paths";

import { routes } from "../../../api/paths";

import activeIcon from "../../../assets/img/active-task.svg";
import inactiveIcon from "../../../assets/img/inactive-task.svg";
import Search from "../../../components/Search";
import Spinner from "../../../components/Spinner";
import TranslatedPagination from "../../../components/TranslatedPagination";
import TranslatedResizer from "../../../components/TranslatedReziser";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { Activity, ActivityFilterValues, Member, Paging, sortOptionsForActivities, User } from "../../../types";
import { checkIfForbidden, serializeParameterSuffix } from "../../../util/GeneralUtil";
import { newFilterValues } from "../../ActivityForm/util/activity-util";

type ActivitiesProps = {
  pagingContextIndex: Paging;
  updatePagingContextIndex: (page: Paging) => void;
  setFilterValues: (input: ActivityFilterValues) => void;
  filterValues: ActivityFilterValues;
  setIsExportDisabled: (disabled: boolean) => void;
  currentUser: string | null;
  setNumberOfActivities: (number: number) => void;
  sortType: string | undefined;
  setSortType: (sortType: string) => void;
  setExportQuery: (query: string) => void;
};

export default function ActivityList({
  pagingContextIndex,
  updatePagingContextIndex,
  setFilterValues,
  filterValues,
  setIsExportDisabled,
  currentUser,
  setNumberOfActivities,
  sortType,
  setSortType,
  setExportQuery,
}: ActivitiesProps) {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [, dataTableHook] = useDataTable();
  const [searchQuery, setSearchQuery] = useState("");
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [paginationState, paginationHook] = useLocalPagination(activityList, 10);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState<boolean>(true);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [isListEmpty, setIsListEmpty] = useState<boolean>(false);
  const [membersWithActivity, setMembersWithActivity] = useState<Member[]>();
  const [usersWithActivity, setUsersWithActivity] = useState<User[]>();
  const [projectTitles, setProjectTitles] = useState<string[]>([]);
  const [programTitles, setProgramTitles] = useState<string[]>([]);
  const [isForbidden, setIsForbidden] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    fetch(evidence.getAllWithActivity())
      .then(handleError)
      .then((data) => setMembersWithActivity(data))
      .catch((err) => console.log(err));

    fetch(user.getAll(true))
      .then(handleError)
      .then((data) => setUsersWithActivity(data))
      .catch((err) => console.log(err));

    fetch(activities.getTitles("PROJECT"))
      .then(handleError)
      .then((data) => setProjectTitles(data))
      .catch((err) => console.log(err));

    fetch(activities.getTitles("PROGRAM"))
      .then(handleError)
      .then((data) => setProgramTitles(data))
      .catch((err) => console.log(err));
  }, [handleError, location, navigate]);

  useEffect(() => {
    updatePagingContextIndex(pagingContextIndex);
    const {
      dynamicData,
      dynamicDataDate,
      personCodes,
      designatedPersonUsernames,
      projectTitles,
      programTitles,
      ...filterValuesWoArrays
    } = filterValues;
    setIsSearchSuccessful(true);
    setIsLoading(true);
    setIsListEmpty(false);

    fetch(
      activities.root(
        serializeParameterSuffix({
          searchKeyword: searchQuery,
          ...pagingContextIndex,
          ...filterValuesWoArrays,
          titles:
            filterValuesWoArrays.activityType === "PROJECT"
              ? projectTitles?.join(",")
              : filterValuesWoArrays.activityType === "PROGRAM"
              ? programTitles?.join(",")
              : undefined,
          personCodes: personCodes?.join(","),
          dynamicData: JSON.stringify(dynamicData),
          dynamicDataDate: JSON.stringify(dynamicDataDate),
          designatedPersonUsernames: currentUser ?? designatedPersonUsernames?.join(","),
          sortType,
        })
      )
    )
      .then(handleError)
      .then((data) => {
        setTotalElements(data.totalElements);
        setNumberOfActivities(data.totalElements);
        setActivityList([...data.content]);
        if (data.content.length === 0) {
          setIsSearchSuccessful(false);
          setIsExportDisabled(true);
        } else {
          setIsExportDisabled(false);
        }
        if (searchQuery === "" && data.content.length === 0 && filterValues === initialValues) {
          setIsListEmpty(true);
          setIsSearchSuccessful(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        checkIfForbidden(err, setIsForbidden);
      });
  }, [
    searchQuery,
    filterValues,
    navigate,
    location,
    setIsExportDisabled,
    currentUser,
    setNumberOfActivities,
    sortType,
    handleError,
    pagingContextIndex,
    updatePagingContextIndex,
  ]);

  const updateSearchKeyword = (newSearchKeyword: string) => {
    setSearchQuery(newSearchKeyword.trim());
    setExportQuery(newSearchKeyword.trim());
  };
  const updateFilterValues = (values: ActivityFilterValues) => {
    setFilterValues(newFilterValues(values));
    updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
  };

  return (
    <>
      <Search
        placeholder="Pretraži zadatke..."
        onSubmit={(searchTerm) => {
          updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
          updateSearchKeyword(searchTerm);
        }}
        onFilterClick={() => setIsFilterVisible(!isFilterVisible)}
        sortOptions={sortOptionsForActivities}
        onSortClick={(type) => setSortType(type)}
      />
      {isFilterVisible && (
        <ActivityFilter
          persons={membersWithActivity}
          designatedPersons={usersWithActivity}
          onSubmit={updateFilterValues}
          showDesignatedPersons={!currentUser}
          projectTitlesOptions={projectTitles}
          programTitlesOptions={programTitles}
        />
      )}
      {isLoading ? (
        <Spinner marginTop="-mt-40" />
      ) : isForbidden ? (
        <p className="mt-4">{messages.forbidden}</p>
      ) : isListEmpty ? (
        <p className="mt-4">{messages.noActivities}</p>
      ) : isSearchSuccessful ? (
        <Card className="mt-4">
          <Card.Body removeSpacing>
            <DataTable data={activityList} hook={dataTableHook}>
              <DataTable.Column header="naziv" id="title" canSort={false}>
                {(activity: Activity) => (
                  <p
                    onClick={() => navigate(routes.VIEW_ACTIVITY_URL(activity.id))}
                    className="cursor-pointer text-indigo-900"
                  >
                    {activity.title}
                  </p>
                )}
              </DataTable.Column>
              <DataTable.Column header="Program / projekt" id="projectName" canSort={false}>
                {(activity: Activity) => (!activity?.projectTitle ? "-" : <span>{activity?.projectTitle}</span>)}
              </DataTable.Column>
              <DataTable.Column header="odgovorna osoba" id="odgovorna osoba" canSort={false}>
                {(activity: Activity) =>
                  !activity?.designatedPersons ? (
                    "-"
                  ) : (
                    <span>
                      {activity?.designatedPersons.map((item) => `${item.firstName} ${item.lastName}`).join(",  ")}
                    </span>
                  )
                }
              </DataTable.Column>
              <DataTable.Column header="početak" id="pocetka_aktivnosti" canSort={false}>
                {(activity: Activity) => (
                  <p className={`${activity?.endDate && isPast(new Date(activity.endDate)) && "text-gray-400"}`}>
                    {format(new Date(activity.startDate), "dd. MM. yyyy.")}
                  </p>
                )}
              </DataTable.Column>
              <DataTable.Column header="kraj" id="kraj_aktivnosti" canSort={false}>
                {(activity: Activity) =>
                  !activity.endDate ? (
                    "-"
                  ) : (
                    <p className={`${isPast(new Date(activity.endDate)) && "text-gray-400"}`}>
                      {format(new Date(activity.endDate), "dd. MM. yyyy.")}
                    </p>
                  )
                }
              </DataTable.Column>
              <DataTable.Column header="Status" id="activity_icon" className="" canSort={false}>
                {(activity: Activity) => (
                  <img
                    src={activity.endDate && isPast(new Date(activity.endDate)) ? inactiveIcon : activeIcon}
                    alt="task-activity"
                    className="w-4 -mr-8"
                  />
                )}
              </DataTable.Column>
              <DataTable.Column header="akcije" id="edit" canSort={false}>
                {(activity: Activity) => (
                  <div className="flex justify-start items-center space-x-1">
                    <IconButton
                      type="zoom-in"
                      variant="outline"
                      label="Pregledaj"
                      color="primary"
                      onClick={() => {
                        navigate(routes.VIEW_ACTIVITY_URL(activity.id));
                      }}
                    />
                    {permissions?.includes("ACTIVITY_CREATE_UPDATE") && (
                      <Link to={routes.UPDATE_ACTIVITY_URL(activity.id)} state={{ routeFrom: location.pathname }}>
                        <IconButton type="pencil" label="Uredi" color="primary" />
                      </Link>
                    )}
                  </div>
                )}
              </DataTable.Column>
            </DataTable>
          </Card.Body>
          <Card.Footer className="flex justify-between">
            <TranslatedResizer
              onPageSizeChange={(pageSize: number) => {
                updatePagingContextIndex({ pageNumber: 0, pageSize: pageSize });
              }}
              pageSize={pagingContextIndex.pageSize}
              pageSizes={[10, 20, 30]}
              totalElements={totalElements}
            />
            <TranslatedPagination
              paginationState={paginationState}
              paginationHook={paginationHook}
              pageNumber={pagingContextIndex.pageNumber}
              pageSize={pagingContextIndex.pageSize}
              totalElements={totalElements}
              onPageChange={(pageNumber: number) => {
                updatePagingContextIndex({ ...pagingContextIndex, pageNumber: pageNumber });
              }}
            />
          </Card.Footer>
        </Card>
      ) : (
        <p className="mt-4">{messages.noSearchResults}</p>
      )}
    </>
  );
}
