import defaultIcon from "../assets/img/extensions/icon-default.svg";
import excelIcon from "../assets/img/extensions/icon-excel.svg";
import imageIcon from "../assets/img/extensions/icon-image.svg";
import pdfIcon from "../assets/img/extensions/icon-pdf.svg";
import powerpointIcon from "../assets/img/extensions/icon-powerpoint.svg";
import wordIcon from "../assets/img/extensions/icon-word.svg";
import zipIcon from "../assets/img/extensions/icon-zip.svg";

export const getIconFromExtension = (name: string) => {
  const splits = name.split(".");
  const extension = splits[splits.length - 1];
  let icon: string;

  switch (extension.toLocaleLowerCase()) {
    case "docx":
    case "doc":
      icon = wordIcon;
      break;
    case "pdf":
      icon = pdfIcon;
      break;
    case "png":
    case "jpg":
    case "jpeg":
    case "ico":
      icon = imageIcon;
      break;
    case "xlsx":
    case "xls":
      icon = excelIcon;
      break;
    case "ppt":
    case "pptx":
      icon = powerpointIcon;
      break;
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gz":
      icon = zipIcon;
      break;
    default:
      icon = defaultIcon;
  }
  return icon;
};
