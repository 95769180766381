import React, { useState } from "react";

import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";

import { useLocation, useNavigate } from "react-router-dom";

import { user } from "./api/backend_paths";
import { useEnvironmentVariable } from "./hook/EnvironmentVariableProvider";
import useHandleError from "./hook/useHandleError";

interface AuthProviderProps {
  children: JSX.Element;
}

type AuthProviderContext = {
  user?: CurrentUser;
  login: (
    swap: boolean,
    username: string,
    password: string,
    callback: (jwt: string) => void,
    onFailure: (error: Error) => void
  ) => void;
  signUp: (registerData: RegisterData, callback: (err: Error | undefined) => void) => void;
};

export type RegisterData = {
  swap: boolean;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  eula: boolean;
  dataProcessingAgreement: boolean;
  emailSubscription: boolean;
};

export type CurrentUser = {
  "cognito:username": string;
  given_name: string;
  family_name: string;
  email: string;
  association_id: number;
};

export function parseJwt(token: string): CurrentUser | undefined {
  if (!token) {
    return undefined;
  }
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { handleErrorWoRefreshToken } = useHandleError();
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
  });

  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const login = (
    swap: boolean,
    username: string,
    password: string,
    callback: (jwt: string) => void,
    onFailure: (error: Error) => void
  ) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async function (result) {
        const jwt = result.getIdToken().getJwtToken();
        localStorage.setItem("tokenExp", result?.getIdToken().getExpiration().toString());
        setToken(jwt);
        if (swap) {
          fetch(user.getToken, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + result.getIdToken().getJwtToken(),
            },
          })
            .then((res) => handleErrorWoRefreshToken(res, navigate, location))
            .then((data) => {
              localStorage.setItem("associationName", data.associationName);
              localStorage.setItem("firstName", data.firstName);
              localStorage.setItem("lastName", data.lastName);
              localStorage.setItem("email", data.email);
              localStorage.setItem("username", data.username);
              callback(jwt);
            })
            .catch((err) => onFailure(err));
        } else {
          callback(jwt);
        }
      },

      onFailure: function (err) {
        onFailure(err);
      },
    });
  };

  const signUp = (registerData: RegisterData, callback: (err: Error | undefined) => void) => {
    const dataEmail = {
      Name: "email",
      Value: registerData.email,
    };

    const dataFirstName = {
      Name: "given_name",
      Value: registerData.firstName,
    };

    const dataLastName = {
      Name: "family_name",
      Value: registerData.lastName,
    };

    const attributeList = [
      new CognitoUserAttribute(dataEmail),
      new CognitoUserAttribute(dataFirstName),
      new CognitoUserAttribute(dataLastName),
    ];

    let validationData: any = null;
    userPool.signUp(
      registerData.username.toLowerCase(),
      registerData.password === undefined ? "" : registerData.password,
      attributeList,
      validationData,
      callback
    );
  };

  return <Provider value={{ user: parseJwt(token), login, signUp }}>{children}</Provider>;
}

const useAuthContext = React.createContext<AuthProviderContext>({
  user: undefined,
  login: () => {},
  signUp: () => {},
});

const { Provider } = useAuthContext;

export function useAuth() {
  const context = React.useContext(useAuthContext);
  if (context === undefined) {
    throw new Error(`useAuthProvider hook must be used within AuthProvider`);
  }
  return context;
}
