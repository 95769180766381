import React from "react";

import { Card } from "@tiller/core";
import { CheckboxGroupField, FieldError, InputField } from "@tiller/formik-elements";

import { UserRoleFormType } from "../../../types";
import { convertPermissionsToObject } from "../util/roles-util";

type RoleFormProps = {
  permissions: string[];
  currentRole?: UserRoleFormType;
};

export default function RoleForm({ permissions, currentRole }: RoleFormProps) {
  return (
    <Card className="w-full xl:w-2/3 mt-4">
      <Card.Body>
        <InputField name="name" label="Naziv" className="w-full md:w-1/2 xl:w-1/3 mb-4" required />
        <FieldError name="permissions" className="text-sm mt-2" />
        <CheckboxGroupField name="permissions" label="" vertical>
          <div className="flex flex-col space-y-6">
            <ShowPermissionOptions
              permissions={permissions}
              currentRole={currentRole}
              module="ASSOCIATION"
              title="Evidencija"
            />
            <hr />
            <ShowPermissionOptions
              permissions={permissions}
              currentRole={currentRole}
              module="ACTIVITY"
              title="Zadaci"
            />
            <hr />
            <ShowPermissionOptions
              permissions={permissions}
              currentRole={currentRole}
              module="COMPETITION"
              title="Natječaji"
            />
            <hr />
            <ShowPermissionOptions
              permissions={permissions}
              currentRole={currentRole}
              module="SETTINGS"
              title="Postavke"
            />
          </div>
        </CheckboxGroupField>
      </Card.Body>
    </Card>
  );
}

type ShowPermissionOptionsProps = {
  permissions: string[];
  currentRole: UserRoleFormType | undefined;
  module: string;
  title: string;
};

function ShowPermissionOptions({ permissions, currentRole, module, title }: ShowPermissionOptionsProps) {
  return (
    <div>
      <p className="font-semibold text-sm">{title}</p>
      {convertPermissionsToObject(permissions, module).map((permission) => (
        <CheckboxGroupField.Item
          className="my-1.5"
          key={permission.code}
          label={permission.label}
          value={permission.code}
          checked={currentRole ? permission.code in currentRole!.permissions : false}
        />
      ))}
    </div>
  );
}
