import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Card, IconButton, Link, useLocalPagination } from "@tiller/core";
import { DataTable } from "@tiller/data-display";

import MemberFilter from "./MemberFilter";
import { usePermissions } from "../../../PermissionsProvider";
import { evidence } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Search from "../../../components/Search";
import Spinner from "../../../components/Spinner";
import TranslatedPagination from "../../../components/TranslatedPagination";
import TranslatedResizer from "../../../components/TranslatedReziser";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import {
  Member,
  MemberFilterValuesType,
  MemberType,
  Paging,
  RolesOnCroatian,
  sortOptionsForMembers,
} from "../../../types";
import { checkIfForbidden, serializeParameterSuffix } from "../../../util/GeneralUtil";

type MemberListProps = {
  pagingContextIndex: Paging;
  updatePagingContextIndex: (page: Paging) => void;
  memberType: MemberType;
  memberFilterValues: MemberFilterValuesType;
  setMemberFilterValues: (type: MemberType, filterValues: MemberFilterValuesType) => void;
  setIsExportDisabled?: (disabled: boolean) => void;
  sortType: string | undefined;
  setSortType: (type: MemberType, sortType: string) => void;
  setCount: (count: number) => void;
  setExportQuery: (query: string) => void;
};

export default function MemberList({
  pagingContextIndex,
  updatePagingContextIndex,
  memberType,
  setMemberFilterValues,
  memberFilterValues,
  setIsExportDisabled,
  sortType,
  setSortType,
  setCount,
  setExportQuery,
}: MemberListProps) {
  const [members, setMembers] = useState<Member[]>([]);
  const [paginationState, paginationHook] = useLocalPagination(members, 10);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isListEmpty, setIsListEmpty] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isForbidden, setIsForbidden] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  useEffect(() => {
    setIsLoading(true);
    setIsSearchSuccessful(true);
    setIsListEmpty(false);
    updatePagingContextIndex(pagingContextIndex);
    fetch(
      evidence.getAll(
        serializeParameterSuffix({
          ...pagingContextIndex,
          memberType,
          searchQuery,
          ...memberFilterValues,
          dynamicData: JSON.stringify(memberFilterValues.dynamicData),
          dynamicDataDate: JSON.stringify(memberFilterValues.dynamicDataDate),
          sortType,
        })
      )
    )
      .then(handleError)
      .then((res) => {
        setTotalElements(res.totalElements);
        setMembers([...res.content]);
        if (res.content.length === 0) {
          setIsSearchSuccessful(false);
          setIsExportDisabled && setIsExportDisabled(true);
        } else {
          setIsExportDisabled && setIsExportDisabled(false);
        }
        if (searchQuery === "" && res.content.length === 0) {
          setIsListEmpty(true);
          setIsSearchSuccessful(true);
        }
        setIsLoading(false);
        setCount(res.totalElements);
      })
      .catch((err) => {
        setIsLoading(false);
        checkIfForbidden(err, setIsForbidden);
      });
  }, [
    handleError,
    setIsExportDisabled,
    memberType,
    searchQuery,
    memberFilterValues,
    pagingContextIndex.pageNumber,
    pagingContextIndex,
    sortType,
    setCount,
    updatePagingContextIndex,
  ]);

  return (
    <>
      <Search
        placeholder="Pretraži profile..."
        onSubmit={(searchTerm) => {
          updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
          setSearchQuery(searchTerm);
          setExportQuery(searchTerm);
        }}
        onFilterClick={() => setIsFilterVisible(!isFilterVisible)}
        sortOptions={sortOptionsForMembers}
        onSortClick={(sortType) => setSortType(memberType, sortType)}
      />
      {isFilterVisible && (
        <MemberFilter
          memberType={memberType}
          onSubmit={(values) => {
            setMemberFilterValues(memberType, values);
            updatePagingContextIndex({ ...pagingContextIndex, pageNumber: 0 });
          }}
        />
      )}
      {isLoading ? (
        <Spinner marginTop="-mt-40" />
      ) : isForbidden ? (
        <p className="mt-4">{messages.forbidden}</p>
      ) : isListEmpty ? (
        <p className="mt-4">{messages.noProfiles}</p>
      ) : isSearchSuccessful ? (
        <Card className="mt-4">
          <Card.Body removeSpacing>
            <DataTable data={members}>
              <DataTable.Column header="ime" id="firstName" canSort={false}>
                {(member: Member) => (
                  <p
                    onClick={() => navigate(routes.VIEW_MEMBER_URL(member.code))}
                    className="cursor-pointer text-indigo-900"
                  >
                    {member.personType === "PHYSICAL_PERSON" ? member.firstName : member.name}
                  </p>
                )}
              </DataTable.Column>
              <DataTable.Column header="prezime" id="lastName" canSort={false}>
                {(member: Member) => (
                  <p
                    onClick={() => navigate(routes.VIEW_MEMBER_URL(member.code))}
                    className="cursor-pointer text-indigo-900"
                  >
                    {member.personType === "PHYSICAL_PERSON" ? member.lastName : "-"}
                  </p>
                )}
              </DataTable.Column>
              <DataTable.Column header="kontakt broj" id="phoneNumber" canSort={false}>
                {(member: Member) => (member.phoneNumber ? <p>{member.phoneNumber}</p> : "-")}
              </DataTable.Column>
              <DataTable.Column header="e-mail" id="email" canSort={false}>
                {(member: Member) => (member.email ? <p>{member.email}</p> : "-")}
              </DataTable.Column>
              <DataTable.Column header="vrsta profila" id="memberTypes" canSort={false}>
                {(member: Member) =>
                  member.memberTypes.map((label, index) => <p key={index}>{RolesOnCroatian[label]}</p>)
                }
              </DataTable.Column>
              <DataTable.Column header="akcije" id="edit" canSort={false}>
                {(member: Member) => (
                  <div className="flex justify-start items-center space-x-1">
                    <IconButton
                      type="zoom-in"
                      variant="outline"
                      color="primary"
                      label="Pregledaj"
                      onClick={() => {
                        navigate(routes.VIEW_MEMBER_URL(member.code));
                      }}
                    />
                    {permissions?.includes("ASSOCIATION_MEMBER_CREATE_UPDATE") && (
                      <Link to={routes.UPDATE_MEMBER_URL(member.code)} state={{ routeFrom: location.pathname }}>
                        <IconButton type="pencil" variant="outline" color="primary" label="Uredi" />
                      </Link>
                    )}
                  </div>
                )}
              </DataTable.Column>
            </DataTable>
          </Card.Body>
          <Card.Footer className="flex flex-col gap-4 xl:flex-row xl:justify-between">
            <TranslatedResizer
              onPageSizeChange={(pageSize: number) => {
                updatePagingContextIndex({ pageNumber: 0, pageSize: pageSize });
              }}
              pageSize={pagingContextIndex.pageSize}
              pageSizes={[10, 20, 30]}
              totalElements={totalElements}
            />
            <TranslatedPagination
              paginationState={paginationState}
              paginationHook={paginationHook}
              pageNumber={pagingContextIndex.pageNumber}
              pageSize={pagingContextIndex.pageSize}
              totalElements={totalElements}
              onPageChange={(pageNumber: number) => {
                updatePagingContextIndex({ ...pagingContextIndex, pageNumber: pageNumber });
              }}
            />
          </Card.Footer>
        </Card>
      ) : (
        <p className="mt-4">{messages.noSearchResults}</p>
      )}
    </>
  );
}
