import React, { useCallback, useEffect, useState } from "react";

import { Form, Formik, FormikValues } from "formik";

import { useNavigate } from "react-router-dom";

import { Button, Card } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";

import { CheckboxGroupField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import ChangeConfirmation from "./ChangeConfirmation";
import UpdateAssociationPopup from "./UpdateAssociationPopup";
import { usePermissions } from "../../../PermissionsProvider";
import { activities, association as association_path, evidence, user } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import PopupWithInput from "../../../components/PopupWithInput";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import useSignOut from "../../../hook/useSignOut";
import { Association } from "../../../types";

export default function AssociationTab() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const signOut = useSignOut();
  const navigate = useNavigate();

  const [, setSubscribed] = useState(true);
  const [deleteAssociationModal, setDeleteAssociationModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [updateAssociationPopup, setUpdateAssociationPopup] = useState(false);
  const [association, setAssociation] = useState<Association | undefined>(undefined);

  const deleteAssociationActions = [
    "Izbrisat će se svi korisnički računi povezani na udrugu",
    "Izbrisat će se svi profili",
    "Izbrisat će se svi zadaci",
    "Izbrisat će se svi vlastiti natječaji",
    "Izbrisat će se svi dokumenti",
    "Izbrisat će se sve bilješke",
    "Izbrisat će se sva dodatna polja",
    "Izbrisat će se sve role",
  ];

  const getAssociation = useCallback(() => {
    fetch(association_path.current, { method: "GET" })
      .then(handleError)
      .then((res) => setAssociation(res))
      .catch((err) => console.log(err));
  }, [handleError]);

  useEffect(() => {
    getAssociation();
  }, [getAssociation]);

  useEffect(() => {
    fetch(user.subscription, { method: "GET" })
      .then(handleError)
      .then((s) => setSubscribed(s))
      .catch((err) => console.error(err));
  }, [handleError]);

  const handlePopupClose = () => {
    if (isFormSubmitted) {
      setDeleteAssociationModal(false);
      setIsFormSubmitted(false);
    } else {
      setDeleteAssociationModal(false);
    }
  };

  const initialValues = { export: { members: false, activities: false } };

  const handleExport = (values: FormikValues) => {
    if (values.export.activities) {
      fetch(activities.exportToExcel(), {
        method: "GET",
        headers: {
          "Content-Type": "blob",
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw res;
          } else {
            return res;
          }
        })
        .then((res) => {
          res.blob().then((blob) => {
            const newBlob = new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(newBlob);
            link.download = "Izvještaj-zadaci";
            link.click();
            link.remove();
          });
        })
        .catch((err) => console.log(err));
    }
    if (values.export.members) {
      fetch(evidence.exportToExcel())
        .then((res) => {
          if (!res.ok) {
            throw res;
          } else {
            return res;
          }
        })
        .then((res) => {
          res.blob().then((blob) => {
            const newBlob = new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(newBlob);
            link.download = "Izvještaj-evidencija";
            link.click();
            link.remove();
          });
        })
        .catch((err) => console.log(err));
    }
    setConfirmDeleteModal(true);
    setExportModal(false);
    setDeleteAssociationModal(false);
  };

  return (
    <div className="flex flex-col gap-4 mt-6">
      <Card className="w-full lg:w-1/2">
        <Card.Header>
          <Card.Header.Title>Podaci udruge</Card.Header.Title>

          <Card.Header.Actions>
            {permissions?.includes("SETTINGS_UPDATE_ASSOCIATION") ? (
              <Icon
                type="pencil"
                variant="outline"
                className="cursor-pointer text-indigo-900"
                onClick={() => setUpdateAssociationPopup(true)}
              />
            ) : (
              <></>
            )}
          </Card.Header.Actions>
        </Card.Header>
        <DescriptionList>
          <DescriptionList.Item label="Udruga">{association?.name}</DescriptionList.Item>
          <DescriptionList.Item label="OIB">{association?.oib}</DescriptionList.Item>
        </DescriptionList>
      </Card>
      {permissions?.includes("SETTINGS_DELETE_ASSOCIATION") ? (
        <Card className="w-2/4 flex items-center justify-between lg:w-1/2 2xl:w-1/3">
          <Card.Body>
            <Button
              tabIndex={0}
              color="red"
              type="button"
              size="xl"
              variant="text"
              trailingIcon={<Icon type="trash" variant="solid" />}
              onClick={() => setDeleteAssociationModal(true)}
              hidden={!permissions?.includes("SETTINGS_DELETE_ASSOCIATION")}
            >
              {"Brisanje udruge"}
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}

      <Popup
        onSubmit={() => {
          setDeleteAssociationModal(false);
          setExportModal(true);
        }}
        title={
          <div>
            <div className="mr-10">
              <p className="text-red-500 mb-3 text-xl">
                <span className="text-black">Brisanje udruge iz aplikacije</span>
              </p>
            </div>
            <span className="mb-2">Brisanje udruge iz aplikacije će učiniti sljedeće:</span>
            <ul className="landing-p-sm list-none list-outside pl-4 mb-2">
              {deleteAssociationActions.map((item: string, index: number) => {
                return (
                  <div className="flex space-x-2 mb-1 -ml-4" key={index}>
                    {index < 3 ? (
                      <Icon type="x-circle" color="red" variant="outline" size={6} className="min-w-[24px]" />
                    ) : (
                      <Icon type="check-circle" color="green" variant="outline" size={6} />
                    )}
                    <li key={index}>{item}</li>
                  </div>
                );
              })}
            </ul>
          </div>
        }
        content=""
        isOpen={deleteAssociationModal}
        setIsOpen={setDeleteAssociationModal}
        onClose={handlePopupClose}
        primaryButtonText="Nastavi"
        secondaryButtonText="Odustani"
      />
      <Formik initialValues={initialValues} onSubmit={handleExport}>
        {({ handleSubmit }) => (
          <Popup
            title={
              <Form className="flex flex-col">
                <div>
                  <div className="mr-10">
                    <p className="text-red-500 mb-3 text-xl">
                      <span className="text-black">Brisanje udruge iz aplikacije</span>
                    </p>
                  </div>
                  <div className="mb-2 text-sm">{messages.deleteAssociationExport}</div>
                  <ul className="landing-p-sm list-none list-outside pl-4 mb-2">
                    <div className="flex space-x-2 mb-1 -ml-4">
                      <CheckboxGroupField name="export" label="Izvezite podatke za sljedeće module:" vertical={true}>
                        <div className="flex-col mb-2">
                          <span className="font-semibold">Evidencija</span>
                          <div className="ml-4 mt-2">
                            <CheckboxGroupField.Item label="Detalji svih profila" value="members" />
                          </div>
                        </div>
                        <div className="flex-col mb-2">
                          <span className="font-semibold">Zadaci</span>
                          <div className="ml-4 mt-2">
                            <CheckboxGroupField.Item label="Detalji svih zadataka" value="activities" />
                          </div>
                        </div>
                      </CheckboxGroupField>
                    </div>
                  </ul>
                </div>
              </Form>
            }
            content=""
            isOpen={exportModal}
            setIsOpen={setExportModal}
            primaryButtonText="Nastavi"
            secondaryButtonText="Odustani"
            onSubmit={() => {
              handleSubmit();
            }}
          />
        )}
      </Formik>

      <PopupWithInput
        text={localStorage.getItem("associationName") ?? ""}
        content={messages.deleteAssociationWarning}
        title={
          <div>
            <div className="mr-10">
              <p className="text-red-500 mb-3 text-xl">
                <span className="text-black">Brisanje udruge iz aplikacije</span>
              </p>
            </div>
          </div>
        }
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        primaryButtonText="Da, izbriši"
        onSubmit={() => {
          fetch(association_path.deleteAssociation(), {
            method: "DELETE",
          })
            .then(handleError)
            .catch((err) => console.log(err));
          setDeleteAssociationModal(false);
          setExportModal(false);
          setConfirmDeleteModal(false);
          setIsConfirmationModalOpen(true);
        }}
      />

      <Popup
        onSubmit={() => {
          signOut();
          navigate(routes.USER_LOGIN_URL);
        }}
        title=""
        content=""
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        hideButtons={false}
        primaryButtonText="U redu"
      >
        <ChangeConfirmation
          title={messages.deleteAssociationTitle}
          text={messages.deleteAssociationThankYouNote}
          className="flex flex-col items-center"
        />
      </Popup>

      <Popup
        onSubmit={() => {
          setUpdateAssociationPopup(false);
        }}
        title={messages.changeAssociationData}
        content=""
        isOpen={updateAssociationPopup}
        setIsOpen={setUpdateAssociationPopup}
        hideButtons={true}
      >
        <UpdateAssociationPopup
          association={association}
          onSubmit={() => {
            getAssociation();
            setUpdateAssociationPopup(false);
          }}
        />
      </Popup>
    </div>
  );
}
