// eslint-disable-next-line import/no-unresolved
import { ThemeConfigFactory } from "@tiller/theme/defaultTheme";

export const tillerConfig: ThemeConfigFactory = {
  component: {
    Button: {
      base: {
        borderRadius: "rounded-xl",
      },
    },
    Badge: {
      master: "py-0 inline-flex rounded-full items-center text-xs font-medium",
      base: {
        padding: "px-4",
      },
    },
    Card: {
      header: {
        padding: "px-6 py-5",
      },
      body: {
        padding: "px-6 py-5",
      },
      footer: {
        padding: "px-4 py-3 sm:px-6",
      },
    },
    Container: {
      variant: {
        max: {
          innerContainer: "-mt-6 py-0 px-2 sm:px-6",
        },
      },
    },
    Checkbox: {
      master: "h-4 w-4 min-w-[1rem] appearance-none ",
    },
    DataTable: {
      primaryRowSpacing: "px-6 py-3",
      tableFooter: {
        padding: "px-6 py-3",
      },
      tableHeader: {
        backgroundColor: "bg-zinc-100",
        padding: "px-6 py-3",
      },
      tableRow: {
        odd: "bg-white",
        even: "bg-white",
      },
    },
    DescriptionList: {
      padding: "p-0",
      Item: {
        type: {
          default: {
            itemColumnContainer: "flex px-6 py-3",
            content: "w-1/2",
            label: "w-1/2",
          },
        },
      },
    },
    DragZone: {
      customUploadDropZoneDescriptionContainer: "text-center space-y-1 px-6 pt-5 pb-6",
    },
    FieldGroup: {
      Group: {
        content: {
          vertical: "space-y-0",
        },
      },
    },
    Modal: {
      Dismiss: {
        button: {
          master: "focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 mt-2",
        },
      },
    },
    Pagination: {
      master: "flex flex-col space-y-2 items-center xl:flex-row xl:flex-1 xl:space-x-2 xl:justify-end",
    },
    ProgressBar: {
      indexIcon: {
        backgroundColor: "bg-indigo-600",
        borderColor: "border-indigo-600",
      },
      textIndex: {
        color: "text-indigo-600",
      },
    },
    SidebarLayout: {
      backgroundColor: `bg-secondary-light`,
      padding: "pt-0 pb-6",
    },
    SidebarNavigation: {
      container: {
        master: "flex flex-col md:gap-5 md:h-screen md:w-36 lg:w-64",
        default: `bg-primary text-white`,
      },
      base: {
        master: "overflow-visible h-full",
        default: `bg-primary text-white`,
      },
      bottomActions: {
        padding: "px-3 md:py-2",
      },
      dropdownItem: {
        master: "my-0 md:my-2 block flex justify-center lg:justify-start",
        base: {
          default: `p-4 text-white opacity-80 w-full justify-center md:border-transparent 
            hover:bg-primary-dark hover:opacity-100 text-center
            md:text-start md:justify-start rounded-lg
            md:hover:text-gray-300`,
        },
        active: {
          default: `md:justify-start justify-center bg-primary-dark rounded-lg`,
        },
      },
      item: {
        base: {
          default: `opacity-80 text-white w-full justify-center md:border-transparent 
            hover:bg-primary-dark hover:opacity-100 text-center
            lg:text-start lg:justify-start rounded-lg
            md:hover:text-gray-300`,
        },
        active: {
          default: `opacity-100 justify-center lg:justify-start bg-primary-dark rounded-lg`,
        },
      },
      icon: {
        master:
          "w-5 h-6 flex items-center transition ease-in-out duration-150 group-hover:text-primary-light group-focus:text-primary-light",
      },
      logo: {
        master: "flex md:col-span-3 md:items-center justify-end md:justify-center px-4 md:px-1",
        withTopRightAction: {
          master: "md:justify-start md:col-span-2",
          margin: "md:ml-2",
        },
        withoutTopRightAction: "md:justify-start",
      },
    },
    Tabs: {
      outerContainer: "mt-8",
      innerContainer: "overflow-x-auto -mb-px flex space-x-3",
      Tab: {
        base: {
          padding: "px-[18px] pb-2",
        },
      },
    },
    TimeInput: {
      container: "z-0",
    },
    Toggle: {
      container: "mx-0 mt-1",
    },
  },
};
