export const scrollToInvalidField = (errors: any, formType?: string) => {
  const invalidFields = Object.keys(errors);
  if (invalidFields.length === 0) {
    return;
  }

  let firstInvalidField: string;
  let invalidElement: Element | null;

  if (formType === "members") {
    if (invalidFields[0] === "dynamicData" && invalidFields.length > 1) {
      firstInvalidField = invalidFields[1];
      invalidElement = document.querySelector(`[name="${firstInvalidField}"]`);
    } else if (invalidFields[0] === "dynamicData" && invalidFields.length === 1) {
      firstInvalidField = Object.keys(errors.dynamicData)[0];
      invalidElement = document.querySelector(`[name="dynamicData.${firstInvalidField}"]`);
    } else {
      firstInvalidField = invalidFields[0];
      invalidElement = document.querySelector(`[name="${firstInvalidField}"]`);
    }
  } else {
    if (invalidFields[0] === "dynamicData") {
      firstInvalidField = Object.keys(errors.dynamicData)[0];
      invalidElement = document.querySelector(`[name="dynamicData.${firstInvalidField}"]`);
    } else {
      firstInvalidField = invalidFields[0];
      invalidElement = document.querySelector(`[name="${firstInvalidField}"]`);
    }
  }

  if (invalidElement) {
    invalidElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
