import React, { useContext } from "react";

import { Form, Formik, FormikValues } from "formik";
import { useWizard } from "react-use-wizard";

import * as Yup from "yup";

import { messages } from "../../../constants/messages";
import { DataImportContext } from "../../../context/DataImportContext";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import { DataImportMappingType, DataImportStepType, dynamicFieldType } from "../../../types";

type DataMappingStepProps = {
  step: DataImportStepType;
  data: DataImportMappingType;
};

const dataImportValidationSchema = Yup.object().shape({
  mapping: Yup.object().shape({
    name: Yup.string().required(messages.dataMappingRequired),
    familyName: Yup.string().required(messages.dataMappingRequired),
    dateOfBirth: Yup.string().required(messages.dataMappingRequired),
    oib: Yup.string().required(messages.dataMappingRequired),
    phoneNumber: Yup.string().required(messages.dataMappingRequired),
    email: Yup.string().required(messages.dataMappingRequired),
    address: Yup.string().required(messages.dataMappingRequired),
  }),
});

const validateSchema = (
  values: FormikValues,
  dynamicFieldsRecord: Record<string, dynamicFieldType[]>,
  sheetName: string
) => {
  const errors = {} as any;
  if (values.startingRow && values.endingRow && parseInt(values.startingRow) > parseInt(values.endingRow)) {
    errors.startingRow = "Početni red ne može biti veći od konačnog reda.";
    errors.endingRow = "Konačni red ne može biti manji od početnog reda.";
  }
  errors.mapping = {};
  if (values.mapping?.name === "Ne želim upariti") {
    errors.mapping.name = messages.invalidDataMapping;
  }
  if (values.mapping?.familyName === "Ne želim upariti") {
    errors.mapping.familyName = messages.invalidDataMapping;
  }
  if (dynamicFieldsRecord) {
    const dynamicFields = dynamicFieldsRecord[sheetName];
    if (dynamicFields && dynamicFields.length > 0) {
      errors.mapping.dynamicFields = {};
      dynamicFields.forEach((dynamicField) => {
        if (values.mapping?.dynamicFields?.[dynamicField.code] === undefined) {
          errors.mapping.dynamicFields[dynamicField.code] = messages.dataMappingRequired;
        } else if (values.mapping?.dynamicFields?.[dynamicField.code] === "Ne želim upariti" && dynamicField.required) {
          errors.mapping.dynamicFields[dynamicField.code] = messages.invalidDataMapping;
        }
      });
    }
  }
  if (Object.keys(errors.mapping).length === 0 && errors.startingRow === undefined) {
    return {};
  }
  if (errors.mapping.dynamicFields && Object.keys(errors.mapping.dynamicFields).length === 0) {
    return {};
  }
  return errors;
};

export default function DataMappingStep({ step, data }: DataMappingStepProps) {
  const { nextStep } = useWizard();
  const { updateProgressBar } = useContext(ImportProgressBarContext);
  const { dynamicFieldsRecord } = useContext(DataImportContext);
  return (
    <Formik
      initialValues={data.data?.[step.sheetName] ?? {}}
      onSubmit={() => {
        nextStep();
        updateProgressBar(false, true, false);
      }}
      validationSchema={dataImportValidationSchema}
      validate={(values) => validateSchema(values, dynamicFieldsRecord, step.sheetName)}
    >
      <Form>{step.step}</Form>
    </Formik>
  );
}
