import React, { useEffect, useState } from "react";

import { Form, Formik, FormikProps } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { usePermissions } from "../../../PermissionsProvider";
import { competitions } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import NotesContainer from "../../../components/Notes/NotesContainer";
import Popup from "../../../components/Popup";
import Spinner from "../../../components/Spinner";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { CompetitionDetails, CompetitionFormType, Note } from "../../../types";
import { competitionValidationSchema } from "../NewCompetititon/NewCompetition";
import CompetitionFormSelection from "../components/CompetitionFormSelection";
import { formatCompetition, getCompetitionFormType } from "../util/competition-util";

type UpdateCompetitionType = {
  id: string;
};
export default function UpdateCompetition() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();
  const location = useLocation();
  const { id } = useParams<UpdateCompetitionType>();
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [competition, setCompetition] = useState<CompetitionDetails>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    if (id) {
      fetch(competitions.getCompetitionDetails(id))
        .then(handleError)
        .then((data) => {
          if (!data.custom) {
            navigate(-1);
          } else {
            setCompetition(data);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [id, handleError, navigate]);

  const onSubmit = (values: CompetitionFormType) => {
    setIsSubmitLoading(true);
    setNameError("");
    fetch(competitions.root, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formatCompetition(values)),
    })
      .then(handleError)
      .then((res) => {
        if (res.status === 204) {
          setIsSubmitLoading(false);
          navigate(routes.COMPETITIONS_URL);
        }
      })
      .catch((err) => {
        err
          .json()
          .then((data: any) => {
            if (data.message === "Invalid competition name") {
              setNameError(messages.competitionName);
            }
            console.error(err);
            setIsSubmitLoading(false);
          })
          .catch((err: any) => console.log(err));
      });
  };
  const handleExitButtonClick = () => {
    if (location.state) {
      navigate(routes.COMPETITIONS_URL);
    } else {
      navigate(routes.VIEW_COMPETITION_URL(competition?.id));
    }
  };

  if (competition) {
    return (
      <>
        <Formik
          validationSchema={competitionValidationSchema}
          initialValues={getCompetitionFormType(competition)}
          onSubmit={onSubmit}
        >
          {({ dirty }: FormikProps<CompetitionFormType>) => (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
              <Form className="grid col-span-2">
                <div className="flex flex-col gap-4">
                  <div className="flex w-full sticky top-0 z-20">
                    <Header
                      title="uredi natječaj"
                      primaryButtonIcon="check"
                      primaryButtonText="Spremi"
                      secondaryButtonIcon="x"
                      secondaryButtonText="Odustani"
                      tertiaryButtonText="Bilješke"
                      tertiaryButtonIcon="pencil-alt"
                      onSecondaryClick={(e: any) => {
                        e.preventDefault();
                        !dirty ? handleExitButtonClick() : setIsOpen(true);
                      }}
                      onTertiaryClick={() => setOpen(true)}
                      showBackButton
                      onBackButtonClick={handleExitButtonClick}
                      isPrimaryLoading={isSubmitLoading}
                    />
                  </div>
                  <div className="w-full gap-8">
                    <CompetitionFormSelection errorName={nameError} />
                    <Attachments
                      attachments={competition.attachments}
                      showDragZone={permissions?.includes("COMPETITION_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DELETE")}
                      deleteUrl={competitions.deleteAttachments}
                      downloadUrl={competitions.getAttachments}
                      uploadUrl={competitions.uploadAttachment(Number(competition.id) ?? -1)}
                    />
                  </div>
                </div>
              </Form>
              <div className="grid col-span-1 mt-28">
                <NotesContainer
                  module="COMPETITION"
                  open={open}
                  setOpen={stateHandler}
                  addNoteUrl={competitions.addNote}
                  getObjectContainingNotesUrl={competitions.getCompetitionDetails}
                  notes={competition.notes as Note[]}
                  id={id ?? "-1"}
                  showNoteForm={permissions?.includes("COMPETITION_CRUD_OWN_NOTE")}
                  deleteNoteUrl={competitions.deleteNote}
                  updateNoteUrl={competitions.updateNote}
                />
              </div>
            </div>
          )}
        </Formik>
        <Popup
          title={messages.cancelPrompt}
          content={messages.createWarning}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleExitButtonClick}
        />
      </>
    );
  } else {
    return <Spinner />;
  }
}
