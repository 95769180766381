import React from "react";

import { useNavigate } from "react-router-dom";

import { Card } from "@tiller/core";
import { DataTable } from "@tiller/data-display";

import { routes } from "../api/paths";
import { ActivityTypeValues } from "../pages/ActivityForm/components/ActivityFormSelection";
import { getLabel } from "../pages/ViewActivity/components/ViewActivityForm";
import { Activity } from "../types";

type ActivitiesListProfileProps = {
  activities: Activity[];
  width?: string;
};

export default function ProfileActivityList({ activities, width = "" }: ActivitiesListProfileProps) {
  const navigate = useNavigate();

  return (
    <Card className={`mt-8 ${width}`}>
      <Card.Header>
        <Card.Header.Title>Zadaci</Card.Header.Title>
      </Card.Header>
      <DataTable data={activities}>
        <DataTable.Column header="naziv" id="title">
          {(activity: Activity) => (
            <p
              onClick={() => navigate(routes.VIEW_ACTIVITY_URL(activity.id))}
              className="cursor-pointer text-indigo-900"
            >
              {activity.title}
            </p>
          )}
        </DataTable.Column>
        <DataTable.Column header="vrsta zadatka" id="vrsta zadatka">
          {(activity: Activity) => <p>{getLabel(activity.activityType as ActivityTypeValues)}</p>}
        </DataTable.Column>
        <DataTable.Column header=" program / projekt " id="program / projekt ">
          {(activity: Activity) => <p>{activity.projectTitle === "" ? "-" : activity.projectTitle}</p>}
        </DataTable.Column>
      </DataTable>
    </Card>
  );
}
