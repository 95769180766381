import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Card, IconButton } from "@tiller/core";
import { DataTable } from "@tiller/data-display";

import { role } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { UserRole } from "../../../types";
import { convertPermissionsToObject } from "../../Roles/util/roles-util";

export default function Roles() {
  const [isOpen, setIsOpen] = useState(false);
  const [allRoles, setAllRoles] = useState<UserRole[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { handleError } = useHandleError();
  const navigate = useNavigate();

  const expandedItem = (role: UserRole) => (
    <div className="flex flex-col space-y-4">
      <ShowPermissions permissions={role.permissions} module="ASSOCIATION" title="Evidencija" />
      <ShowPermissions permissions={role.permissions} module="ACTIVITY" title="Zadaci" />
      <ShowPermissions permissions={role.permissions} module="COMPETITION" title="Natječaji" />
      <ShowPermissions permissions={role.permissions} module="SETTINGS" title="Postavke" />
    </div>
  );

  const handleDelete = (id: number | null) => {
    fetch(role.deleteRole(id!), {
      method: "DELETE",
    })
      .then(handleError)
      .then(() => setAllRoles(allRoles.filter((role) => role.id !== id)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch(role.getAllRoles())
      .then(handleError)
      .then((data) => setAllRoles(data));
  }, [handleError]);

  return (
    <Card className="mt-6 w-full lg:w-3/4">
      <Card.Body removeSpacing>
        <DataTable data={allRoles}>
          <DataTable.Expander>{expandedItem}</DataTable.Expander>
          <DataTable.Column header="naziv role" accessor="name" canSort={false} className="w-3/4" />
          <DataTable.Column header="akcije" id="actions" canSort={false}>
            {(role: UserRole) => (
              <>
                {role.mutable ? (
                  <div className="flex justify-start items-center space-x-1 -ml-1">
                    <IconButton
                      type="trash"
                      label="Delete"
                      color="red"
                      onClick={() => {
                        setSelectedId(role.id);
                        setIsOpen(true);
                      }}
                    />
                    <IconButton
                      type="pencil"
                      label="Edit"
                      color="primary"
                      onClick={() => navigate(routes.UPDATE_ROLE_URL(role.id as number))}
                    />
                  </div>
                ) : (
                  "-"
                )}
              </>
            )}
          </DataTable.Column>
        </DataTable>
      </Card.Body>
      <Popup
        title={messages.roleDeletePrompt}
        content={messages.deleteWarning}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryButtonText="Da, izbriši"
        primaryButtonColor="red"
        secondaryButtonText="Ne"
        onSubmit={() => handleDelete(selectedId)}
      />
    </Card>
  );
}

type ShowPermissionsProps = {
  permissions: string[];
  module: string;
  title: string;
};

function ShowPermissions({ permissions, module, title }: ShowPermissionsProps) {
  return (
    <>
      {permissions.some((item) => item.includes(module)) && (
        <div>
          <p className="mb-1">{title}</p>
          {convertPermissionsToObject(permissions, module).map((permission) => (
            <div key={permission.code} className="flex items-center space-x-2">
              <div className="h-1 w-1 rounded-full bg-gray-500" />
              <p className="text-gray-500">{permission.label}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
