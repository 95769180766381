import React from "react";

import { Card, Typography } from "@tiller/core";

type DataImportAboutCardProps = {
  expanded: boolean;
};

export default function DataImportAboutCard({ expanded }: DataImportAboutCardProps) {
  return (
    <Card isExpanded={expanded} status="idle">
      <Card.Header>
        <Card.Header.Title>
          <Typography element="h5" variant="h5">
            O uvozu podataka
          </Typography>
        </Card.Header.Title>
      </Card.Header>
      <Card.Body>
        <div className="flex flex-col space-y-4">
          <p>Ovdje možete uvesti excel datoteke u kojima se nalaze podaci koje želite imati u aplikaciji.</p>
          <div>
            <ul className="px-6 space-y-2 list-disc">
              <li>
                Provjerite imate li dodatna polja u excelu koja ne postoje na formi za profil koji želite uvesti u
                aplikaciju. U slučaju da imate, ista polja trebate kreirate u postavkama Personalizirana polja, prije
                nego krenete s uvozom.
              </li>
              <li>Moguće je više puta uvesti xls datoteku, no jedna xls po uvozu.</li>
              <li>
                Prilikom dodavanja dokumenta, molimo poštujte kriterije koji su dolje navedeni jer u suprotnom, uvoz
                neće biti uspješan.
              </li>
              <li>
                Trenutno je moguće uvesti <b>podatke samo za modul evidenciju</b>.
              </li>
            </ul>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
