import React from "react";
import "../styles/LandingPageStyles.css";

type ValueCardProps = {
  icon: string;
  heading: string;
  paragraph: string;
};

export default function ValueCard({ icon, heading, paragraph }: ValueCardProps) {
  return (
    <div className="card p-4 flex flex-col gap-6 items-center text-center">
      <div>
        <img src={icon} alt="icon" className="w-12 md:w-16" />
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <h5 className="landing-h5 ">{heading}</h5>
        </div>
        <div>
          <p className="landing-p-sm">{paragraph}</p>
        </div>
      </div>
    </div>
  );
}
