import { isAfter, isBefore, parse } from "date-fns";

import { format, utcToZonedTime } from "date-fns-tz";

import { messages } from "../../../constants/messages";
import { Member, MemberFormType, MemberType, Role } from "../../../types";
import { convertFieldsForDatabase, convertFieldsForDisplay } from "../../../util/DynamicFieldUtil";

export type FormType = {
  code: string;
  memberTypes: MemberType[];
  firstName: string;
  lastName: string;
  oib: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string | null;
  associationId: number;
  description: string;
  attachmentUuids: string[];
  dateOfMembership: string | null;
  dateOfTerminationOfMembership: string | null;
  dynamicData: object;
};

export const getRolesFromAny = (memberTypes: { [key in keyof typeof MemberType]: boolean }): Role[] => {
  return Object.entries(memberTypes)
    .filter((key) => key[1] && key[0] !== MemberType.ALL)
    .map((key) => key[0] as Role);
};

const getMemberTypes = (memberTypes?: Role[]): { [key in keyof typeof MemberType]: boolean } => {
  let memberType: any = {};
  if (!memberTypes) {
    return memberType;
  }
  memberTypes?.forEach((type) => (memberType[type] = true));

  return memberType;
};

export function convertDateForDatabase(typedDate: string | null) {
  const inputFormat = "dd. MM. yyyy.";
  const outputFormat = "yyyy-MM-dd";

  const parsedDate = parse(typedDate!, inputFormat, new Date());
  return format(parsedDate, outputFormat);
}

export function convertDateForDisplay(fetchedDate: string | undefined) {
  const outputDateFormat = "dd. MM. yyyy.";
  if (fetchedDate) {
    return format(new Date(fetchedDate), outputDateFormat, { timeZone: "UTC" });
  }
}

export function convertDateTimeForDisplay(fetchedDate: string | undefined) {
  const outputDatetimeFormat = "dd. MM. yyyy. HH:mm";
  if (fetchedDate) {
    const utcDate = utcToZonedTime(new Date(fetchedDate), "UTC");
    return format(utcDate, outputDatetimeFormat, { timeZone: "UTC" });
  }
}

export const getMember = (member: MemberFormType): Member => {
  return {
    ...member,
    memberTypes: getRolesFromAny(member.memberTypes),
    notes: [],
  };
};

export const getMemberFormType = (member: Member): MemberFormType => {
  return {
    ...member,
    memberTypes: getMemberTypes(member.memberTypes),
    attachmentUuids: member.attachments?.map((document) => document.id),
    dateOfBirth: convertDateForDisplay(member.dateOfBirth?.substring(0, 12)) ?? "",
    dateOfMembership: convertDateForDisplay(member.dateOfMembership?.substring(0, 12)) ?? "",
    dateOfTerminationOfMembership: convertDateForDisplay(member.dateOfTerminationOfMembership?.substring(0, 12)) ?? "",
  };
};

export const memberFormTypeValidation = (values: MemberFormType) => {
  const errors = {} as any;
  let personType = values.personType;
  let memberTypes = values.memberTypes;
  let dateOfBirth = parse(values.dateOfBirth as string, "yyyy-MM-dd", new Date());
  let dateOfMembership = parse(values.dateOfMembership as string, "yyyy-MM-dd", new Date());
  let dateOfTerminationOfMembership = parse(values.dateOfTerminationOfMembership as string, "yyyy-MM-dd", new Date());

  if (Object.entries(memberTypes).filter((mt) => mt.includes(true)).length === 0) {
    errors.memberTypes = messages.requiredField;
  }

  if (personType === "PHYSICAL_PERSON") {
    if (values.firstName === "") errors.firstName = messages.requiredField;
    if (values.lastName === "") errors.lastName = messages.requiredField;
    if (isAfter(dateOfBirth, Date.now() + 1)) {
      errors.dateOfBirth = messages.invalidDateOfBirth;
    }
    if (isBefore(dateOfMembership, dateOfBirth)) {
      errors.dateOfMembership = messages.invalidDateOfMembership;
    }
  } else if (personType === "LEGAL_ENTITY") {
    if (values.name === "") errors.name = messages.requiredField;
  }

  if (!values.dateOfMembership && values.dateOfTerminationOfMembership) {
    errors.dateOfTerminationOfMembership = messages.dateOfTerminationMembershipWithoutDateOfMembership;
  } else if (isBefore(dateOfTerminationOfMembership, dateOfMembership)) {
    errors.dateOfTerminationOfMembership = messages.dateOfTerminationMembershipBeforeDateOfMembership;
  }

  return errors;
};

export const newMember = (values: MemberFormType, code?: string): Member => {
  const formattedDynamicFields = convertFieldsForDatabase(values.dynamicData);
  return {
    ...values,
    code: code ?? values.code,
    memberTypes: getRolesFromAny(values.memberTypes),
    dateOfBirth: values.dateOfBirth ? convertDateForDatabase(values.dateOfBirth) : null,
    dateOfMembership: values.dateOfMembership ? convertDateForDatabase(values.dateOfMembership) : null,
    dateOfTerminationOfMembership: values.dateOfTerminationOfMembership
      ? convertDateForDatabase(values.dateOfTerminationOfMembership)
      : null,
    notes: [],
    dynamicData: formattedDynamicFields,
  };
};

export function fillMember(values: Member) {
  const formattedDynamicFields = convertFieldsForDisplay(values.dynamicData);
  return {
    ...values,
    dynamicData: formattedDynamicFields,
  };
}

export interface ValidationError {
  field: string;
  code: string;
}
