import React from "react";

import { motion } from "framer-motion";

import "../styles/LandingPageStyles.css";

export default function VideoSection() {
  return (
    <motion.div
      className="w-full flex"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ amount: 0.2 }}
    >
      <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-12 py-24 lg:py-40" id="video">
        <div className="flex flex-col w-full gap-4">
          <h2 className="landing-h2"> Priča o Altruistu</h2>
          <p className="landing-p-md">Pogledajte kratak video o Altruist aplikaciji kako biste saznali:</p>
          <ul className="landing-p-md list-disc list-outside pl-6">
            <li>kako je krenula priča s udrugama</li>
            <li>glavne probleme koje rješavamo udrugama</li>
            <li>zašto i kako je došlo do besplatnog razvoja</li>
          </ul>
        </div>
        <ResponsiveIframe />
      </section>
    </motion.div>
  );
}

const ResponsiveIframe = () => {
  return (
    <div style={{ position: "relative", paddingBottom: "56.25%" }}>
      <iframe
        src=" https://www.youtube.com/embed/nFYz-oFavnQ"
        title="Altruist"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
