import React, { useContext, useEffect, useState } from "react";

import { useFormikContext } from "formik";

import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";

import { Button, Card, Typography } from "@tiller/core";
import { CheckboxGroupField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import { routes } from "../../../api/paths";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import { DataImportContext } from "../../../context/DataImportContext";
import { ImportProgressBarContext } from "../../../context/ImportProgressBarContext";
import { CheckedSheets } from "../../../types";
import { dataImportFinished } from "../../../util/DataImportUtil";

type ChoosingSheetsProps = {
  sheets: string[];
  filename: string | undefined;
  file: File | undefined;
  sheetNames: string[];
};

export default function ChoosingSheets({ filename, sheets, file, sheetNames }: ChoosingSheetsProps) {
  const { nextStep, isLoading } = useWizard();
  const { values } = useFormikContext<CheckedSheets>();
  const { setCheckedSheets } = useContext(DataImportContext);
  const { updateProgressBar } = useContext(ImportProgressBarContext);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (values.checkedSheets) {
      setCheckedSheets(values.checkedSheets);
    }
  }, [file, setCheckedSheets, sheetNames, values.checkedSheets]);

  return (
    <>
      <Card>
        <Card.Body>
          <Typography variant="h5" element="h5">
            Iz datoteke &#34;{filename}&#34; želim uvesti podatke iz listova:
          </Typography>
          <CheckboxGroupField name="checkedSheets" label="" vertical={true} className="space-y-4">
            {sheets.map((value, index) => (
              <CheckboxGroupField.Item value={value} key={index} label={value} />
            ))}
          </CheckboxGroupField>
        </Card.Body>
      </Card>
      <div className="w-full flex items-center justify-between mt-8">
        <Button
          type="button"
          variant="outlined"
          onClick={() => setIsOpen(true)}
          leadingIcon={<Icon variant="outline" type="x" />}
          size="md"
        >
          Odustani
        </Button>
        <div className="flex space-x-5 items-center">
          <Button
            onClick={() => {
              nextStep();
              updateProgressBar(false, true, false);
            }}
            type="submit"
            disabled={
              isLoading ||
              !(
                values.checkedSheets &&
                Object.keys(values.checkedSheets).filter((key) => values.checkedSheets[key]).length > 0
              )
            }
            trailingIcon={<Icon variant="outline" type="arrow-right" />}
            size="md"
          >
            Sljedeće
          </Button>
        </div>
      </div>
      <Popup
        title={messages.cancelPrompt}
        content={messages.createWarning}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => {
          dataImportFinished();
          navigate(routes.ADMIN_URL);
        }}
      />
    </>
  );
}
