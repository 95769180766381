import React from "react";

import "../styles/LandingPageStyles.css";
import { Button } from "@tiller/core";

import { scrollToSection } from "../util/scrollToSection";

type ValueCardProps = {
  heading: string;
  bullets: string[];
  link?: string;
};
export default function PricingCard({ heading, bullets, link }: ValueCardProps) {
  return (
    <div className="flex flex-col p-4 lg:p-8 gap-4 card h-fit">
      <div>
        <h6 className="landing-h6 ">{heading}</h6>
      </div>
      <div>
        <ul className="landing-p-sm list-disc list-outside pl-4">
          {bullets.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      {link && (
        <div>
          <Button
            variant="text"
            color="indigo"
            className="rounded-full"
            onClick={() => scrollToSection("contact-form")}
          >
            {link}
          </Button>
        </div>
      )}
    </div>
  );
}
