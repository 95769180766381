import React, { createContext, useState } from "react";

import { DataImportMappingType, dynamicFieldType } from "../types";

type DataImportContextType = {
  checkedSheets: Record<string, boolean>;
  setCheckedSheets: (checkedSheets: Record<string, boolean>) => void;
  data: DataImportMappingType;
  setData: (newData: DataImportMappingType) => void;
  dynamicFieldsRecord: Record<string, dynamicFieldType[]>;
  setDynamicFieldsRecord: (newDynamicFieldsRecord: Record<string, dynamicFieldType[]>) => void;
};

type DataImportContextProps = {
  children: React.ReactNode;
};

export const DataImportContext = createContext<DataImportContextType>({
  checkedSheets: {},
  setCheckedSheets: (checkedSheets: Record<string, boolean>) => {},
  data: { data: {} },
  setData: (newData: DataImportMappingType) => {},
  dynamicFieldsRecord: {},
  setDynamicFieldsRecord: (newDynamicFieldsRecord: Record<string, dynamicFieldType[]>) => {},
});

export default function DataImportContextWrapper({ children }: DataImportContextProps) {
  const [checkedSheets, setCheckedSheets] = useState({});
  const [data, setData] = useState<DataImportMappingType>({ data: {} });
  const [dynamicFieldsRecord, setDynamicFieldsRecord] = useState<Record<string, dynamicFieldType[]>>({});

  return (
    <DataImportContext.Provider
      value={{
        checkedSheets,
        setCheckedSheets,
        data,
        setData,
        dynamicFieldsRecord,
        setDynamicFieldsRecord,
      }}
    >
      {children}
    </DataImportContext.Provider>
  );
}
