import React, { useEffect, useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { usePermissions } from "../../../PermissionsProvider";
import { activities, evidence, user } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import NotesContainer from "../../../components/Notes/NotesContainer";
import Popup from "../../../components/Popup";
import Spinner from "../../../components/Spinner";
import { messages } from "../../../constants/messages";
import useComplexValidation from "../../../hook/useComplexValidation";
import useHandleError from "../../../hook/useHandleError";
import { ActivityFormType, ActivityMemberAttendance, Note, User } from "../../../types";
import { activityValidationSchema } from "../NewActivity/NewActivity";
import { ActivityFormSelection } from "../components/ActivityFormSelection";
import AddParticipants from "../components/AddParticipants";
import { fillActivity, newActivity } from "../util/activity-util";

type UpdateActivityParams = {
  id: string;
};

export default function UpdateActivity() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [persons, setPersons] = useState<ActivityMemberAttendance[]>();
  const [activity, setActivity] = useState<ActivityFormType>();
  const [designatedPersons, setDesignatedPersons] = useState<User[]>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const validationSchema = useComplexValidation("activity.create-form", "ACTIVITY", activityValidationSchema);
  const [isOpen, setIsOpen] = useState(false);
  const [areParticipantsOpen, setAreParticipantsOpen] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const { id } = useParams<UpdateActivityParams>();

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    if (id) {
      fetch(activities.getById(id))
        .then(handleError)
        .then((data) => {
          setActivity(fillActivity(data));
        })
        .catch((err) => {
          console.log(err);
          navigate(routes.NEW_ACTIVITY_URL);
        });

      fetch(evidence.getAllWithoutPagination())
        .then(handleError)
        .then((data) => setPersons(data))
        .catch((err) => console.log(err));

      fetch(user.getAll(false))
        .then(handleError)
        .then((data) => setDesignatedPersons(data))
        .catch((err) => console.log(err));
    }
  }, [handleError, id, location, navigate]);

  const onSubmit = (values: ActivityFormType, formikHelpers: FormikHelpers<ActivityFormType>) => {
    setIsSubmitLoading(true);
    setAreParticipantsOpen(false);
    fetch(activities.root(), {
      method: "PUT",
      body: JSON.stringify(newActivity(values, Number(id))),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((data) => {
        navigate(routes.VIEW_ACTIVITY_URL(data.id));
        setIsSubmitLoading(false);
      })
      .catch((err) => console.log(err));

    formikHelpers.resetForm();
  };

  const handleExitButtonClick = () => {
    if (location.state) {
      navigate(routes.ACTIVITIES_URL);
    } else {
      navigate(routes.VIEW_ACTIVITY_URL(activity?.id as number));
    }
  };

  if (activity) {
    return (
      <>
        <Formik validationSchema={validationSchema} initialValues={activity} onSubmit={onSubmit}>
          {({ values, handleSubmit, dirty }) => (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
              <Form className="grid col-span-2">
                <div className="flex flex-col gap-4">
                  <div className="flex w-full sticky top-0 z-20">
                    <Header
                      title="Uredi zadatak"
                      primaryButtonText="Spremi"
                      primaryButtonIcon="check"
                      onPrimaryClick={handleSubmit}
                      secondaryButtonText="Odustani"
                      secondaryButtonIcon="x"
                      tertiaryButtonText="Bilješke"
                      tertiaryButtonIcon="pencil-alt"
                      onSecondaryClick={(e: any) => {
                        e.preventDefault();
                        !dirty ? handleExitButtonClick() : setIsOpen(true);
                      }}
                      onTertiaryClick={() => setOpen(true)}
                      showBackButton
                      onBackButtonClick={handleExitButtonClick}
                      isPrimaryLoading={isSubmitLoading}
                    />
                  </div>
                  <div className="w-full gap-8">
                    <ActivityFormSelection designatedPersons={designatedPersons} activityType={values.activityType} />
                    <AddParticipants
                      isOpen={areParticipantsOpen}
                      setIsOpen={setAreParticipantsOpen}
                      participants={persons}
                      width="w-full"
                    />
                    <Attachments
                      attachments={activity.attachments}
                      showDragZone={permissions?.includes("ACTIVITY_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("ACTIVITY_ATTACHMENTS_DELETE")}
                      uploadUrl={activities.uploadAttachment(activity.id ?? -1)}
                      downloadUrl={activities.getAttachments}
                      deleteUrl={activities.deleteAttachments}
                    />
                  </div>
                </div>
              </Form>
              <div className="grid col-span-1 mt-28">
                {permissions?.includes("ASSOCIATION_MEMBER_NOTES_VIEW") && (
                  <NotesContainer
                    module="ACTIVITY"
                    open={open}
                    setOpen={stateHandler}
                    addNoteUrl={activities.addNote}
                    getObjectContainingNotesUrl={activities.getById}
                    notes={activity?.notes as Note[]}
                    id={`${activity?.id}`}
                    showNoteForm={permissions?.includes("ACTIVITY_CRUD_OWN_NOTE")}
                    updateNoteUrl={activities.updateNote}
                    deleteNoteUrl={activities.deleteNote}
                  />
                )}
              </div>
            </div>
          )}
        </Formik>
        <Popup
          title={messages.cancelPrompt}
          content={messages.updateWarning}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleExitButtonClick}
        />
      </>
    );
  } else {
    return <Spinner />;
  }
}
