import React, { useCallback, useEffect, useState } from "react";

import { format, isBefore } from "date-fns";
import isPast from "date-fns/isPast";
import isSameDay from "date-fns/isSameDay";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { Card, IconButton } from "@tiller/core";
import { DescriptionList } from "@tiller/data-display";
import { Toggle } from "@tiller/form-elements";
import { Icon } from "@tiller/icons";

import { usePermissions } from "../../PermissionsProvider";
import { competitions } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import Attachments from "../../components/Attachments";
import Header from "../../components/Header";
import NotesContainer from "../../components/Notes/NotesContainer";
import Popup from "../../components/Popup";
import Spinner from "../../components/Spinner";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";
import { CompetitionDetails, Note } from "../../types";
import { parseNoteDate } from "../../util/NotesUtil";

export function dateClass(dateTo: string | null) {
  if (!dateTo) {
    return "";
  } else {
    const start = new Date(dateTo);
    start.setDate(start.getDate() - 15);
    const current = Date.now();
    const end = new Date(dateTo);
    if (isPast(end) && !isSameDay(end, current)) {
      return "text-gray-400";
    } else if (
      (isBefore(start, current) || isSameDay(start, current)) &&
      (isBefore(current, end) || isSameDay(current, end))
    ) {
      return "text-red-500 font-semibold";
    }
  }
}

export default function ViewCompetition() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const { id } = useParams();
  const [competition, setCompetition] = useState<CompetitionDetails>();
  const [isApplied, setIsApplied] = useState<boolean>();
  const [isFavorite, setIsFavorite] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    fetch(competitions.getCompetitionDetails(id ?? "-1"))
      .then(handleError)
      .then((data) => {
        parseNoteDate(data.notes);
        setCompetition(data);
        setIsApplied(data.applied);
        setIsFavorite(data.favorite);
      })
      .catch((err) => {
        console.error(err);
      });
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleError, id, navigate]);

  const changeApplied = useCallback(
    (isApplied: boolean) => {
      if (isApplied) {
        fetch(competitions.setCompetitionApplied(id ?? "-1"), {
          method: "POST",
        })
          .then(handleError)
          .catch((err) => console.log(err));
      } else {
        fetch(competitions.setCompetitionApplied(id ?? "-1"), {
          method: "DELETE",
        })
          .then(handleError)
          .catch((err) => console.log(err));
      }
    },
    [handleError, id]
  );

  const changeFavorite = useCallback(
    (isFavorite: boolean) => {
      if (isFavorite) {
        fetch(competitions.setCompetitionFavorite(id ?? "-1"), {
          method: "POST",
        })
          .then(handleError)
          .catch((err) => console.log(err));
      } else {
        fetch(competitions.setCompetitionFavorite(id ?? "-1"), {
          method: "DELETE",
        })
          .then(handleError)
          .catch((err) => console.log(err));
      }
    },
    [handleError, id]
  );

  const deleteCompetition = (id: string) => {
    fetch(competitions.delete(id), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => navigate(routes.COMPETITIONS_URL))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (windowSize > 1280) {
      setOpen(false);
    }
  }, [windowSize]);

  return (
    <>
      {competition ? (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
            <div className="col-span-3 xl:col-span-2">
              <Header
                title="detalji natječaja"
                hideSecondaryButton={!competition.custom || !permissions?.includes("COMPETITION_DELETE")}
                hidePrimaryButton={!competition.custom || !permissions?.includes("COMPETITION_CREATE_UPDATE")}
                showBackButton
                primaryButtonText="Uredi natječaj"
                primaryButtonIcon="pencil"
                secondaryButtonText="Izbriši natječaj"
                secondaryButtonIcon="trash"
                tertiaryButtonText="Bilješke"
                tertiaryButtonIcon="pencil-alt"
                onPrimaryClick={() => navigate(routes.UPDATE_COMPETITION_URL(id))}
                onSecondaryClick={() => setIsOpen(true)}
                onTertiaryClick={() => setOpen(true)}
                onBackButtonClick={() => navigate(routes.COMPETITIONS_URL)}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
              <div className="col-span-3 xl:col-span-2">
                <Card>
                  <Card.Header>
                    <div className="flex flex-col justify-start gap-4 md:flex-row md:justify-between">
                      <Card.Header.Title>
                        <div className="flex">
                          <>
                            {permissions?.includes("COMPETITION_SET_AND_VIEW_FAVORITE") && (
                              <IconButton
                                type="star"
                                {...(isFavorite
                                  ? {
                                      variant: "solid",
                                      label: "Makni iz favorita",
                                      color: "yellow",
                                    }
                                  : {
                                      variant: "outline",
                                      label: "Dodaj u favorite",
                                      color: "primary",
                                    })}
                                buttonClassName="mr-2"
                                onClick={() => {
                                  setIsFavorite(!isFavorite);
                                  changeFavorite(!isFavorite);
                                }}
                              />
                            )}
                            {competition?.name}
                          </>
                        </div>
                      </Card.Header.Title>
                      <Card.Header.Actions>
                        {permissions?.includes("COMPETITION_SET_AND_VIEW_APPLIED") && (
                          <Toggle
                            checked={isApplied}
                            onClick={() => {
                              setIsApplied(!isApplied);
                              changeApplied(!isApplied);
                            }}
                            label={<span className="text-sm font-medium text-gray-900">Prijavljen</span>}
                            fieldClassName="-ml-4 md:ml-4 lg:ml-8"
                          />
                        )}
                      </Card.Header.Actions>
                    </div>
                  </Card.Header>
                  <DescriptionList>
                    <DescriptionList.Item label="Izvor natječaja">
                      {competition.sourceName && competition.sourceName !== ""
                        ? competition.sourceName
                        : "Nije poznato"}
                    </DescriptionList.Item>
                    <DescriptionList.Item label="Izvor financiranja">
                      {competition.institution && competition.institution !== ""
                        ? competition.institution
                        : "Nije poznato"}
                    </DescriptionList.Item>

                    {competition.institutionLevel && competition.institutionLevel !== "" && (
                      <DescriptionList.Item label="Razina institucije">
                        {competition.institutionLevel}
                      </DescriptionList.Item>
                    )}
                    <DescriptionList.Item label="Početak natječaja">
                      <p
                        className={`${
                          competition.dateTo !== null &&
                          isPast(new Date(competition.dateTo)) &&
                          !isSameDay(new Date(competition.dateTo), new Date()) &&
                          "text-gray-400"
                        }`}
                      >
                        {competition.dateFrom
                          ? format(new Date(competition.dateFrom), "dd. MM. yyyy.")
                          : "Nije poznato"}
                      </p>
                    </DescriptionList.Item>
                    <DescriptionList.Item label="Istek roka natječaja">
                      <p className={dateClass(competition.dateTo)}>
                        {competition.dateTo ? format(new Date(competition.dateTo), "dd. MM. yyyy.") : "Nije poznato"}
                      </p>
                    </DescriptionList.Item>
                    <DescriptionList.Item label="Područje provedbe">
                      {competition.area && competition.area !== "" ? competition.area : "Nije poznato"}
                    </DescriptionList.Item>
                    {competition.minFundingAmount && competition.minFundingAmount !== "" && (
                      <DescriptionList.Item label="Najniži iznos sredstava za financiranje">
                        {competition.minFundingAmount}
                      </DescriptionList.Item>
                    )}
                    {competition.maxFundingAmount && competition.maxFundingAmount !== "" && (
                      <DescriptionList.Item label="Najviši iznos sredstava za financiranje">
                        {competition.maxFundingAmount}
                      </DescriptionList.Item>
                    )}
                    <DescriptionList.Item label="Ukupni iznos sredstava za financiranje">
                      {competition.totalFundingAmount && competition.totalFundingAmount !== ""
                        ? competition.totalFundingAmount
                        : "Nije poznato"}
                    </DescriptionList.Item>
                    <DescriptionList.Item label="Link na stranicu natječaja">
                      {competition.urlToSite && competition.urlToSite !== "" ? (
                        <div className="flex items-center">
                          <Icon type="globe-alt" className="mr-1" />
                          <a href={competition.urlToSite} className="hover:underline" target="_blank" rel="noreferrer">
                            Klikni ovdje
                          </a>
                        </div>
                      ) : (
                        "Nije poznato"
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item label="Link  na dokument natječaja">
                      {competition.urlToPdf && competition.urlToPdf !== "" ? (
                        <div className="flex items-center">
                          <Icon type="document" variant="outline" className="mr-1" />
                          <a href={competition.urlToPdf} className="hover:underline" target="_blank" rel="noreferrer">
                            Klikni ovdje
                          </a>
                        </div>
                      ) : (
                        <div className="flex items-center">Nije poznato</div>
                      )}
                    </DescriptionList.Item>
                    <p className="px-6 py-4 text-gray-500 text-sm text-right">
                      <span className="font-medium">Zadnje ažuriranje podataka: </span>
                      {format(new Date(competition.lastUpdated), "dd. MM. yyyy. hh:ss")}
                    </p>
                  </DescriptionList>
                </Card>
                <Formik
                  initialValues={{ files: competition.attachments.map((document) => document.id) }}
                  onSubmit={() => {}}
                >
                  <Form>
                    <Attachments
                      attachments={competition.attachments ?? []}
                      showDragZone={permissions?.includes("COMPETITION_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DELETE")}
                      downloadUrl={competitions.getAttachments}
                      deleteUrl={competitions.deleteAttachments}
                      uploadUrl={competitions.uploadAttachment(Number(competition.id))}
                    />
                  </Form>
                </Formik>
              </div>
              <div>
                {permissions?.includes("COMPETITION_NOTES_VIEW") && (
                  <NotesContainer
                    module="COMPETITION"
                    open={open}
                    setOpen={stateHandler}
                    addNoteUrl={competitions.addNote}
                    getObjectContainingNotesUrl={competitions.getCompetitionDetails}
                    notes={competition?.notes as Note[]}
                    id={competition.id}
                    showNoteForm={permissions?.includes("COMPETITION_CRUD_OWN_NOTE")}
                    updateNoteUrl={competitions.updateNote}
                    deleteNoteUrl={competitions.deleteNote}
                  />
                )}
              </div>
            </div>
          </div>
          <Popup
            title={messages.competitionDeletePrompt}
            content={messages.deleteWarning}
            primaryButtonColor="red"
            primaryButtonText="Da, izbriši."
            onSubmit={() => deleteCompetition(competition.id)}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
