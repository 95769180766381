import React from "react";

import { Form, Formik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { Button, Card } from "@tiller/core";
import { InputField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import { messages } from "../../../constants/messages";
import { Source } from "../../../types";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(messages.requiredField),
  url: Yup.string().required(messages.requiredField).nullable(),
});

type AddSourceFormProps = {
  onSubmit: (source: Source, formikHelpers: FormikHelpers<Source>) => void;
  errorName: string;
};

export default function AddSourceForm({ onSubmit, errorName }: AddSourceFormProps) {
  return (
    <Formik initialValues={{ name: "", url: "" }} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form>
        <Card className="mt-4 mb-4">
          <Card.Body>
            <p className="mb-4 font-semibold text-lg -mt-2">Dodaj izvor natječaja</p>
            <div className="flex flex-col gap-4 md:flex-row w-full mb-4">
              <div className="flex flex-col lg:w-1/4">
                <InputField name="name" label="Naziv izvora" required />
                <p className="text-red-500 text-sm mb-0 mt-0 self-start">{errorName}</p>
              </div>
              <InputField name="url" label="Link na web stranicu" className="lg:w-1/4" required />
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="flex justify-end">
              <Button leadingIcon={<Icon type="refresh" />} className="mr-3" variant="outlined" type="reset">
                Resetiraj
              </Button>
              <Button leadingIcon={<Icon type="check" />} type="submit">
                Spremi
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </Formik>
  );
}
