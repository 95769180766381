import React, { useState } from "react";

import { parseISO } from "date-fns";

import { Card } from "@tiller/core";
import { DataTable, useDataTable } from "@tiller/data-display";
import { Date } from "@tiller/date";
import { Icon } from "@tiller/icons";

import Search from "../../../components/Search";
import { FundingSource } from "../../../types";

type FundingSourcesListProps = {
  fundingSources: FundingSource[];
};

export default function FundingSourcesList({ fundingSources }: FundingSourcesListProps) {
  const [, dataTableHook] = useDataTable();
  const [keyword, setKeyword] = useState("");

  return (
    <>
      <Search
        placeholder="Pretraži izvore..."
        showFilter={false}
        onSubmit={(searchTerm) => {
          setKeyword(searchTerm);
        }}
        showSort={false}
      />
      <Card className="mt-4">
        <Card.Body removeSpacing>
          <DataTable
            data={fundingSources.filter((fundingSource) =>
              fundingSource.name.toLowerCase().includes(keyword.toLowerCase())
            )}
            hook={dataTableHook}
          >
            <DataTable.Column header="izvor natječaja" accessor="name" className="w-1/4" canSort={false} />
            <DataTable.Column header="link na web stranicu" id="url" className="pr-40" canSort={false}>
              {(fundingSource: FundingSource) => (
                <a href={fundingSource.url} target="_blank" rel="noreferrer" className="hover:underline">
                  {fundingSource.url}
                </a>
              )}
            </DataTable.Column>
            <DataTable.Column header="zadnje preuzimanje" id="lastChecked" className="w-1/5" canSort={false}>
              {(fundingSource: FundingSource) =>
                fundingSource.scraped ? (
                  <div className="flex">
                    <p className="mr-2">
                      <Date>{parseISO(fundingSource.lastChecked)}</Date>
                    </p>
                    <p className="mr-2">{fundingSource.lastChecked.substring(11, 16)}</p>
                    {fundingSource.errorOccurred ? (
                      <Icon type="x" className="text-red-600" />
                    ) : (
                      <Icon type="check" className="text-green-600" />
                    )}
                  </div>
                ) : (
                  "-"
                )
              }
            </DataTable.Column>
          </DataTable>
        </Card.Body>
      </Card>
    </>
  );
}
