import { CompetitionDetails, CompetitionFormType } from "../../../types";
import { convertDateForDatabase, convertDateForDisplay } from "../../MemberForm/util/member-util";

export const getCompetitionFormType = (competition: CompetitionDetails): CompetitionFormType => {
  return {
    ...competition,
    attachmentUuids: [],
    dateFrom: convertDateForDisplay(competition.dateFrom?.substring(0, 12)) ?? "",
    dateTo: convertDateForDisplay(competition.dateTo?.substring(0, 12)) ?? "",
  };
};

export const formatCompetition = (values: CompetitionFormType) => {
  return {
    ...values,
    dateFrom: values.dateFrom ? convertDateForDatabase(values.dateFrom) : null,
    dateTo: values.dateTo ? convertDateForDatabase(values.dateTo) : null,
  };
};
