import React from "react";

import { Typography } from "@tiller/core";

import { routes } from "../../api/paths";
import bulb from "../../assets/img/expired-bulb.svg";
import AuthenticationContainer from "../../components/AuthenticationContainer";
import { messages } from "../../constants/messages";

export default function ExpiredLink() {
  return (
    <>
      <AuthenticationContainer
        title=""
        showAdditionalCard
        additionalText={messages.alreadyRegistered}
        additionalCardRoute={routes.USER_LOGIN_URL}
        width="w-[26rem]"
      >
        <div className="flex flex-col align-center">
          <img src={bulb} className="-mt-10 mb-8 self-center" alt="broken-bulb" />
          <Typography
            element="h2"
            variant="h2"
            className="mb-4 text-indigo-900 whitespace-pre-wrap text-center uppercase"
          >
            Nevažeći link
          </Typography>
          <p className="text-sm text-center">{messages.expiredLink}</p>
        </div>
      </AuthenticationContainer>
    </>
  );
}
