import React, { useState } from "react";

import { parse } from "date-fns";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { usePermissions } from "../../../PermissionsProvider";
import { competitions } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import Popup from "../../../components/Popup";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { CompetitionFormType } from "../../../types";
import CompetitionFormSelection from "../components/CompetitionFormSelection";
import { formatCompetition } from "../util/competition-util";

let initialValues = {
  id: "",
  name: "",
  dateFrom: "",
  dateTo: "",
  area: undefined,
  urlToSite: undefined,
  urlToPdf: undefined,
  sourceName: "",
  attachmentUuids: [],
  institution: undefined,
  institutionLevel: undefined,
  applied: false,
  favorite: false,
  totalFundingAmount: undefined,
  minFundingAmount: undefined,
  maxFundingAmount: undefined,
} as CompetitionFormType;

export const competitionValidationSchema = Yup.object({
  name: Yup.string().required(messages.requiredField),
  dateFrom: Yup.date()
    .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
    .typeError(messages.invalidDate)
    .nullable(),
  dateTo: Yup.date()
    .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
    .typeError(messages.invalidDate)
    .nullable(),
});

export default function NewCompetition() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (values: CompetitionFormType, formikHelpers: FormikHelpers<CompetitionFormType>) => {
    setIsSubmitLoading(true);
    setNameError("");
    fetch(competitions.createCompetition, {
      method: "POST",
      body: JSON.stringify(formatCompetition(values)),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((res) => {
        if (res.status === 201) {
          setIsSubmitLoading(false);
          navigate(routes.COMPETITIONS_URL);
          formikHelpers.resetForm();
        }
      })
      .catch((err) => {
        err
          .json()
          .then((data: any) => {
            if (data.message === "Invalid competition name") {
              setNameError(messages.competitionName);
            }
            console.error(err);
            setIsSubmitLoading(false);
          })
          .catch((err: any) => console.log(err));
      });
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={competitionValidationSchema}>
        {({ dirty }: FormikProps<CompetitionFormType>) => (
          <Form>
            <div className="flex flex-col">
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
                <div className="col-span-3 xl:col-span-2">
                  <Header
                    title="kreiraj natječaj"
                    primaryButtonIcon="check"
                    primaryButtonText="Spremi"
                    secondaryButtonIcon="x"
                    secondaryButtonText="Odustani"
                    onSecondaryClick={() => (!dirty ? navigate(routes.COMPETITIONS_URL) : setIsOpen(true))}
                    showBackButton
                    onBackButtonClick={() => navigate(routes.COMPETITIONS_URL)}
                    isPrimaryLoading={isSubmitLoading}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
                  <div className="col-span-3 xl:col-span-2">
                    <CompetitionFormSelection errorName={nameError} className="mt-4" />
                    <Attachments
                      attachments={[]}
                      showDragZone={permissions?.includes("COMPETITION_ATTACHMENTS_ADD")}
                      hideDownloadButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DOWNLOAD")}
                      hideDeleteButton={!permissions?.includes("COMPETITION_ATTACHMENTS_DELETE")}
                      deleteUrl={competitions.deleteAttachments}
                      downloadUrl={competitions.getAttachments}
                      uploadUrl={competitions.uploadAttachmentWoCompetition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Popup
        title={messages.cancelPrompt}
        content={messages.createWarning}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => navigate(routes.COMPETITIONS_URL)}
      />
    </>
  );
}
