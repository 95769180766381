import React, { useState } from "react";

import { useField } from "formik";
import * as Yup from "yup";

import { Card, IconButton } from "@tiller/core";
import { DataTable, useDataTable } from "@tiller/data-display";
import { InputField, useDataTableField } from "@tiller/formik-elements";

import { sources } from "../../../api/backend_paths";
import Popup from "../../../components/Popup";

import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { Source } from "../../../types";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(messages.requiredField),
  url: Yup.string().required(messages.requiredField),
});

export default function SourcesTable() {
  const { handleError } = useHandleError();
  const [, dataTableHook] = useDataTable();
  const [errorName, setErrorName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1);
  const [indexToDelete, setIndexToDelete] = useState(-1);
  const [dataTableFieldState, dataTableFieldHook] = useDataTableField<Source[]>("items", validationSchema);
  const [, , itemHandler] = useField("editRow.items");
  const [items, , itemsHandler] = useField("items");

  const updateSource = (index: number) => {
    const oldItems = items;
    const newValue = dataTableFieldHook.onRowEditSave(index);

    fetch(sources.root, {
      method: "PUT",
      body: JSON.stringify(newValue),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        dataTableFieldHook.onRowEditSave(index);
        setErrorName("");
      })
      .catch((err) => {
        console.error(err);
        err.json().then((data: any) => {
          if (data.message === "Source name already exists") {
            setErrorName(messages.sourceName);
            itemsHandler.setValue(oldItems.value);
            dataTableFieldHook.onRowEdit(index);
            itemHandler.setValue(newValue);
          }
        });
      });
  };

  const deleteSource = (index: number, id: number | undefined) => {
    if (id) {
      fetch(sources.delete(id), {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(handleError)
        .then(() => dataTableFieldHook.onRowDelete(index))
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Card>
        <Card.Body removeSpacing={true}>
          <DataTable
            hook={dataTableHook}
            data={dataTableFieldState.data}
            rowEditingIndex={dataTableFieldState.rowEditingIndex}
          >
            <DataTable.Column header="naziv izvora" id="name" canSort={false}>
              {(source: Source, index: number, row, { isEditMode }) =>
                isEditMode ? (
                  <>
                    <InputField
                      name={dataTableFieldHook.onRowEditField(index, "name")}
                      onFocus={() => dataTableFieldHook.onEditingRow("name")}
                    />
                    <p className="text-red-500 text-sm mb-2 mt-0">{errorName}</p>
                  </>
                ) : (
                  source.name || ""
                )
              }
            </DataTable.Column>
            <DataTable.Column header="Link na web stranicu" id="url" canSort={false}>
              {(source: Source, index: number, row, { isEditMode }) =>
                isEditMode ? (
                  <>
                    <InputField name={dataTableFieldHook.onRowEditField(index, "url")} />
                  </>
                ) : (
                  (
                    <a href={source.url} className="hover:underline" target="_blank" rel="noreferrer">
                      {source.url}
                    </a>
                  ) || ""
                )
              }
            </DataTable.Column>

            <DataTable.Column header="akcije" id="actions" canSort={false}>
              {(source: Source, index, row, { isEditMode }) =>
                isEditMode ? (
                  <div className="flex space-x-2">
                    <IconButton type="x" label="Odustani" color="red" onClick={dataTableFieldHook.onRowEditCancel} />
                    <IconButton type="check" label="Spremi" color="primary" onClick={() => updateSource(index)} />
                  </div>
                ) : (
                  <div className="flex space-x-2">
                    <IconButton
                      type="trash"
                      label="Izbriši"
                      color="red"
                      onClick={() => {
                        setIdToDelete(source.id!);
                        setIndexToDelete(index);
                        setIsOpen(true);
                      }}
                    />

                    <IconButton
                      type="pencil"
                      label="Uredi"
                      color="primary"
                      onClick={() => {
                        setErrorName("");
                        dataTableFieldHook.onRowEdit(index);
                      }}
                    />
                  </div>
                )
              }
            </DataTable.Column>
          </DataTable>
        </Card.Body>
      </Card>
      <Popup
        title={messages.sourceDeletePrompt}
        content={messages.deleteWarning}
        primaryButtonColor="red"
        primaryButtonText="Da, izbriši."
        onSubmit={() => deleteSource(indexToDelete, idToDelete)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}
