import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import EmployeeListTable from "./EmployeeListTable";
import { evidence } from "../../../api/backend_paths";
import useHandleError from "../../../hook/useHandleError";
import { Employee } from "../../../types";

export default function EmployeeList() {
  const { handleError } = useHandleError();

  const [employees, setEmployees] = useState<Employee[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetch(evidence.getUsers)
      .then(handleError)
      .then((data) => setEmployees(data));
  }, [handleError, location, navigate]);

  return (
    <Formik initialValues={{ items: employees }} onSubmit={() => {}} enableReinitialize>
      <Form className="mt-6">
        <EmployeeListTable />
      </Form>
    </Formik>
  );
}
