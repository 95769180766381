import React, { useState } from "react";

import { useField, useFormikContext } from "formik";

import * as Yup from "yup";

import { Button, Card, IconButton, Link } from "@tiller/core";
import { DataTable, useDataTable } from "@tiller/data-display";
import { CheckboxField, InputField, useDataTableField } from "@tiller/formik-elements";

import { Icon } from "@tiller/icons";

import CustomValueInput from "./CustomValueInput";
import { usePermissions } from "../../../PermissionsProvider";
import { dynamicField as backendPath } from "../../../api/backend_paths";
import PopupWithInput from "../../../components/PopupWithInput";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { DynamicField } from "../../../types";

const translation: Record<string, string> = {
  TEXT_FIELD: "Tekst",
  TEXT_AREA: "Duži tekst",
  DATE: "Datum",
  DATE_TIME: "Datum i vrijeme",
  PHONE: "Kontakt broj",
  EMAIL: "E-mail",
  OIB: "OIB",
  TAG_FIELD: "Kategorija",
  CHECKBOX: "Višestruki izbor",
  TOGGLE: "DA/NE",
  RADIO: "Jednostruki izbor",
  DROPDOWN: "Padajući izbornik",
};

function ExpandedItem({
  dynamicField,
  updatingDynamicField,
}: {
  dynamicField: DynamicField;
  updatingDynamicField: DynamicField | undefined;
}) {
  const [, dataTableFieldHook] = useDataTableField<DynamicField[]>("items");
  const [field, , helpers] = useField(`editRow.items.dynamicFieldDataset`);
  const fieldTypesWithOptions = ["CHECKBOX", "DROPDOWN", "RADIO"];

  const removeField = (index: number) => {
    const newValue = [...field.value.slice(0, index), ...field.value.slice(index + 1)];
    helpers.setValue(newValue);
  };

  const addEmptyField = () => {
    const added = [...field.value, ""];
    helpers.setValue(added);
  };

  const getOptions = () => {
    return field.value.map((value: string, index: number) => {
      const name = `dynamicFieldDataset[${index}]`;
      return (
        <CustomValueInput
          onRemoveInput={() => removeField(index)}
          name={dataTableFieldHook.onRowEditField(index, name)}
          key={index}
          className="flex items-center"
        />
      );
    });
  };

  if (field.value) {
    const options = getOptions();
    return (
      <>
        {updatingDynamicField && updatingDynamicField === dynamicField
          ? fieldTypesWithOptions.includes(dynamicField.fieldType?.toString() || "")
            ? options
            : ""
          : ""}
        {updatingDynamicField &&
          updatingDynamicField === dynamicField &&
          fieldTypesWithOptions.includes(dynamicField.fieldType?.toString() || "") && (
            <Button leadingIcon={<Icon type="plus" />} type="button" variant="text" onClick={addEmptyField}>
              Dodaj vrijednost
            </Button>
          )}
      </>
    );
  } else {
    return (
      <>
        {dynamicField.dynamicFieldDataset.map((option, index) => (
          <p key={index}>{option}</p>
        ))}
      </>
    );
  }
}

const validationSchema = Yup.object().shape({
  fieldName: Yup.string().required(messages.requiredField),
  fieldType: Yup.string().required(messages.requiredField),
});

export default function DynamicFieldTable() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [, dataTableHook] = useDataTable();
  const [dataTableFieldState, dataTableFieldHook] = useDataTableField<DynamicField[]>("items", validationSchema);
  const [dynamicFieldError, setDynamicFieldError] = useState("");
  const [deleteIndex, setDeleteIndex] = useState<number>(-1);
  const [deleteRow, setDeleteRow] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dynamicFieldName, setDynamicFieldName] = useState("");
  const [, , itemHandler] = useField("editRow.items");
  const [items, , itemsHandler] = useField("items");
  const [updatingField, setUpdatingField] = useState<DynamicField | undefined>(undefined);

  const { isValid } = useFormikContext();

  const noFilterFields = ["TEXT_FIELD", "TEXT_AREA", "EMAIL", "PHONE", "OIB", "CHECKBOX", "DATE", "DATE_TIME"];

  const updateRow = (index: number) => {
    const oldItems = items;
    const newValue = dataTableFieldHook.onRowEditSave(index);

    fetch(backendPath.updateDynamicField(), {
      method: "PUT",
      body: JSON.stringify(newValue),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(() => {
        dataTableFieldHook.onRowEditSave(index);
        setDynamicFieldError("");
      })
      .catch((err) => {
        err.json().then((data: any) => {
          if (data.message === "This field already exists.") {
            setDynamicFieldError(messages.dynamicFieldError);
            itemsHandler.setValue(oldItems.value);
            dataTableFieldHook.onRowEdit(index);
            itemHandler.setValue(newValue);
          } else if (data.message === "Invalid dataset") {
            setDynamicFieldError(messages.dynamicFieldsDatasetError);
            itemsHandler.setValue(oldItems.value);
            dataTableFieldHook.onRowEdit(index);
          }
        });
      });
  };

  const deleteSelectedRow = (index: number, code: string) => {
    fetch(backendPath.deleteDynamicField(code), {
      method: "DELETE",
    })
      .then(handleError)
      .then(() => dataTableFieldHook.onRowDelete(index))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Card>
        <Card.Body removeSpacing>
          <DataTable hook={dataTableHook} alignHeader="left" {...dataTableFieldState}>
            <DataTable.Expander>
              {(dynamicField: DynamicField) => (
                <ExpandedItem dynamicField={dynamicField} updatingDynamicField={updatingField} />
              )}
            </DataTable.Expander>
            <DataTable.Column header="naziv polja" id="fieldName" canSort={false}>
              {(dynamicField: DynamicField, index: number, row, { isEditMode }) =>
                isEditMode ? (
                  <div>
                    <InputField
                      name={dataTableFieldHook.onRowEditField(index, "fieldName")}
                      onFocus={() => dataTableFieldHook.onEditingRow("fieldName")}
                    />
                    <p className="text-red-500 text-sm mb-2 mt-0">{dynamicFieldError}</p>
                  </div>
                ) : (
                  dynamicField.fieldName || ""
                )
              }
            </DataTable.Column>
            <DataTable.Column header="vrsta polja" id="fieldType" canSort={false}>
              {(dynamicField: DynamicField) => translation[dynamicField.fieldType?.toString() || ""] || ""}
            </DataTable.Column>
            <DataTable.Column header="obavezno polje" id="required" canSort={false}>
              {(dynamicField: DynamicField, index: number, row, { isEditMode }) =>
                isEditMode ? (
                  <CheckboxField name={dataTableFieldHook.onRowEditField(index, "required")} />
                ) : dynamicField.required ? (
                  "DA"
                ) : (
                  "NE"
                )
              }
            </DataTable.Column>
            <DataTable.Column header="filter" id="isOnFilter" canSort={false}>
              {(dynamicField: DynamicField, index: number, row, { isEditMode }) =>
                isEditMode && !noFilterFields.includes(dynamicField.fieldType?.toString() || "") ? (
                  <CheckboxField name={dataTableFieldHook.onRowEditField(index, "isOnFilter")} />
                ) : dynamicField.isOnFilter ? (
                  "DA"
                ) : (
                  "NE"
                )
              }
            </DataTable.Column>
            <DataTable.Column header="akcije" id="actions" canSort={false}>
              {(dynamicField: DynamicField, index: number, row, { isEditMode }) =>
                isEditMode ? (
                  <Link
                    onClick={() => {
                      isValid && row.toggleRowExpanded(!row.isExpanded);
                    }}
                  >
                    <div className="flex space-x-2">
                      <IconButton
                        type="x"
                        label="Odustani"
                        color="red"
                        onClick={() => {
                          setUpdatingField(undefined);
                          dataTableFieldHook.onRowEditCancel();
                          setDynamicFieldError("");
                        }}
                      />
                      <IconButton
                        type="check"
                        label="Spremi"
                        color="primary"
                        onClick={() => {
                          setUpdatingField(undefined);
                          updateRow(index);
                        }}
                        disabled={!isValid}
                      />
                    </div>
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      row.toggleRowExpanded(true);
                    }}
                  >
                    <div className="flex space-x-2">
                      {permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE") && (
                        <IconButton
                          type="trash"
                          label="Izbriši"
                          color="red"
                          onClick={() => {
                            setUpdatingField(undefined);
                            setIsOpen(true);
                            setDeleteIndex(index);
                            setDeleteRow(dynamicField.code);
                            setDynamicFieldName(dynamicField.fieldName);
                          }}
                        />
                      )}
                      {permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") && (
                        <IconButton
                          type="pencil"
                          label="Uredi"
                          color="primary"
                          onClick={() => {
                            setUpdatingField(dynamicField);
                            dataTableFieldHook.onRowEdit(index);
                          }}
                        />
                      )}
                    </div>
                  </Link>
                )
              }
            </DataTable.Column>
          </DataTable>
        </Card.Body>
        <PopupWithInput
          primaryButtonText="Da, izbriši"
          secondaryButtonText="Ne"
          text="OBRIŠI"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={
            <div className="mr-10">
              <p className="text-red-500 mb-3 text-xl">
                <span className="text-2xl"> OPREZ!</span> <span className="text-black">Ovom akcijom</span> nepovratno
                brišete:
              </p>
              <p className="font-normal">
                polje <span className="font-semibold">{dynamicFieldName}</span> i sve upisane podatke koje ste ispunili
                na svim profilima/zadacima.
              </p>
            </div>
          }
          content={messages.fieldDeleteWarning}
          onSubmit={() => deleteSelectedRow(deleteIndex, deleteRow)}
        />
      </Card>
    </>
  );
}
