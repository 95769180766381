import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";

import { parse } from "date-fns";
import { Form, FormikHelpers, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { FormContainer } from "@tiller/formik-elements";

import { usePermissions } from "../../../PermissionsProvider";
import { evidence } from "../../../api/backend_paths";
import { routes } from "../../../api/paths";
import Attachments from "../../../components/Attachments";
import Header from "../../../components/Header";
import Popup from "../../../components/Popup";
import Spinner from "../../../components/Spinner";
import { messages } from "../../../constants/messages";
import useComplexValidation from "../../../hook/useComplexValidation";
import useHandleError from "../../../hook/useHandleError";
import { MemberFormType, MemberType, personType } from "../../../types";
import { MemberFormSelection } from "../components/MemberFormSelection";
import { memberFormTypeValidation, newMember, ValidationError } from "../util/member-util";

function initialValues(code: string): MemberFormType {
  return {
    code: code,
    id: 0,
    firstName: "",
    lastName: "",
    memberTypes: {
      [MemberType.ALL]: false,
      [MemberType.USER]: false,
      [MemberType.MEMBER]: false,
      [MemberType.DONOR]: false,
      [MemberType.OTHERS]: false,
      [MemberType.EMPLOYEE]: false,
      [MemberType.EXTERNAL_COLLABORATOR]: false,
      [MemberType.PARTNER]: false,
      [MemberType.VOLUNTEER]: false,
    },
    oib: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    associationId: 1,
    description: "",
    attachmentUuids: [],
    dateOfMembership: "",
    dateOfTerminationOfMembership: "",
    dynamicData: {},
    personType: personType.PHYSICAL_PERSON,
    attachments: [],
    userCategory: "",
    name: "",
    address: "",
    contactPerson: "",
    activities: [],
  };
}

export const memberValidationSchema = Yup.object().shape({
  memberTypes: Yup.object().nullable(),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  phoneNumber: Yup.string().matches(/^[\d\s\-/+()]+$/, messages.invalidPhoneNumberCharacters),
  dateOfBirth: Yup.date()
    .max(new Date(), messages.invalidDateOfBirth)
    .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
    .typeError(messages.invalidDate)
    .nullable(),
  dateOfMembership: Yup.date().when("memberTypes", {
    is: (memberTypes: any) => memberTypes.MEMBER === true,
    then: Yup.date()
      .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
      .typeError(messages.invalidDate),
    otherwise: Yup.date(),
  }),
  dateOfTerminationOfMembership: Yup.date().when("memberTypes", {
    is: (memberTypes: any) => memberTypes.MEMBER === true,
    then: Yup.date()
      .min(Yup.ref("dateOfMembership"), messages.dateOfTerminationMembershipBeforeDateOfMembership)
      .transform((value, originalValue) => parse(originalValue, "dd. MM. yyyy.", new Date()))
      .typeError(messages.invalidDate),
    otherwise: Yup.date(),
  }),
});

export default function NewMember() {
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [defaultCode, setDefaultCode] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formIds, setFormIds] = useState<string[]>([]);
  const memberTypeCheckboxRef = useRef<Record<string, boolean>>({});
  const validationSchema = useComplexValidation("member.create-form", formIds, memberValidationSchema);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(evidence.getUniqueCode())
      .then(handleError)
      .then((res) => {
        setDefaultCode(res.code);
      })
      .catch((err) => console.log(err));
  }, [handleError, navigate]);

  const initialValue = useMemo(() => initialValues(defaultCode), [defaultCode]);

  const onSubmit = (values: MemberFormType, formikHelpers: FormikHelpers<MemberFormType>) => {
    setIsSubmitLoading(true);
    fetch(evidence.getAssociationMemberRoute(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newMember(values)),
    })
      .then(handleError)
      .then((res) => {
        if (res.status === 201) {
          navigate(routes.VIEW_MEMBER_URL(defaultCode));
          setIsSubmitLoading(false);
        }
      })
      .catch((err) => {
        err.json().then((err: any) => {
          const errorList: ValidationError[] = err;
          const touchedMap = errorList.reduce((all, { field }) => ({ ...all, [field]: true }), {});
          const errorMap = errorList.reduce((all, { field, code }) => ({ ...all, [field]: code }), {});
          formikHelpers.setTouched(touchedMap);
          formikHelpers.setErrors(errorMap);
        });
      });
  };

  const handleOnChange = (event: FormEvent) => {
    if ((event.target as HTMLInputElement).name !== "memberTypes") return;

    let formIdList: string[] = [];
    const value = (event.target as HTMLInputElement).value;
    const checked = (event.target as HTMLInputElement).checked;

    memberTypeCheckboxRef.current[value] = checked;

    Object.keys(memberTypeCheckboxRef.current).forEach((key: string) => {
      if (memberTypeCheckboxRef.current[key]) {
        formIdList.push(`ASSOCIATION_MEMBER_${key}`);
      }
    });
    if (formIdList.length > 0) {
      setFormIds(formIdList);
    }
  };

  if (defaultCode) {
    return (
      <>
        <FormContainer
          validate={memberFormTypeValidation}
          validationSchema={validationSchema}
          initialValues={initialValue}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, dirty }: FormikProps<MemberFormType>) => (
            <Form onChange={handleOnChange}>
              <div className="flex flex-col">
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sticky top-0 z-20">
                  <div className="col-span-3 xl:col-span-2">
                    <Header
                      title="Kreiraj profil"
                      primaryButtonText="Spremi"
                      secondaryButtonText="Odustani"
                      primaryButtonIcon="check"
                      secondaryButtonIcon="x"
                      onPrimaryClick={handleSubmit}
                      onSecondaryClick={() => (!dirty ? navigate(routes.RECORDS_URL) : setIsOpen(true))}
                      showBackButton
                      onBackButtonClick={() => navigate(routes.RECORDS_URL)}
                      isPrimaryLoading={isSubmitLoading}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 mt-4">
                    <div className="col-span-3 xl:col-span-2">
                      <MemberFormSelection className="mt-4" />
                      <Attachments
                        attachments={[]}
                        width=""
                        showDragZone={permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_ADD")}
                        hideDownloadButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DOWNLOAD")}
                        hideDeleteButton={!permissions?.includes("ASSOCIATION_MEMBER_ATTACHMENTS_DELETE")}
                        uploadUrl={evidence.uploadAttachmentWoProfile}
                        downloadUrl={evidence.getAttachments}
                        deleteUrl={evidence.deleteAttachments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </FormContainer>
        <Popup
          title={messages.cancelPrompt}
          content={messages.createWarning}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={() => navigate(routes.RECORDS_URL)}
        />
      </>
    );
  } else {
    return <Spinner />;
  }
}
