import React, { useState } from "react";

type FavoritesCompetitionContextProps = {
  children: React.ReactNode;
};

export type ContextType = {
  pageNumber: number;
  pageSize: number;
  keyword: string;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setKeyword: (keyword: string) => void;
};

export const FavoritesCompetitionContext = React.createContext<ContextType>({
  keyword: "",
  pageNumber: 0,
  pageSize: 0,
  setKeyword(keyword: string): void {},
  setPageNumber(pageNumber: number): void {},
  setPageSize(pageSize: number): void {},
});

export default function CompetitionContextWrapper({ children }: FavoritesCompetitionContextProps) {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [keyword, setKeyword] = useState<string>("");

  return (
    <FavoritesCompetitionContext.Provider
      value={{ pageNumber, pageSize, keyword, setPageNumber, setPageSize, setKeyword }}
    >
      {children}
    </FavoritesCompetitionContext.Provider>
  );
}
