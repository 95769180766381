import React, { useEffect, useState } from "react";

import { parseISO } from "date-fns";
import { useSearchParams } from "react-router-dom";

import { useLocalPagination } from "@tiller/core";
import { DataTable } from "@tiller/data-display";

import { Date } from "@tiller/date";

import ChangeConfirmation from "./ChangeConfirmation";
import DataImportAboutCard from "./DataImportAboutCard";
import DataImportCriteriaCard from "./DataImportCriteriaCard";
import FileImport from "./FileImport";
import { dataImport } from "../../../api/backend_paths";
import Popup from "../../../components/Popup";
import TranslatedPagination from "../../../components/TranslatedPagination";
import TranslatedResizer from "../../../components/TranslatedReziser";
import { messages } from "../../../constants/messages";
import useHandleError from "../../../hook/useHandleError";
import { Document } from "../../../types";
import { getIconFromExtension } from "../../../util/getIconFromExtension";

export default function DataImport() {
  const { handleError } = useHandleError();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [importedFiles, setImportedFiles] = useState<Document[]>([]);
  const [searchParams] = useSearchParams();
  const [paginationState, paginationHook] = useLocalPagination(importedFiles, 5);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (searchParams.get("return")) {
      setIsModalOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    fetch(dataImport.getAttachments, {
      method: "GET",
      headers: {
        "Content-Type": "blob",
      },
    })
      .then(handleError)
      .then((res) => {
        setImportedFiles(res);
      })
      .catch((err) => console.error(err));
  }, [handleError]);

  return (
    <>
      <div className="flex flex-col gap-4 mt-6 w-full lg:w-3/4 xl:w-2/3">
        <DataImportAboutCard expanded={importedFiles.length <= 0} />
        <DataImportCriteriaCard expanded={importedFiles.length <= 0} />
        <FileImport />
        <Popup
          onSubmit={() => {}}
          title=""
          content=""
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          hideButtons={true}
        >
          <ChangeConfirmation text={messages.dataImportSuccess} className="flex flex-col items-center" />
        </Popup>
      </div>
      {importedFiles.length > 0 && (
        <div className="mt-6 w-full lg:w-3/4 xl:w-2/3">
          <DataTable data={importedFiles}>
            <DataTable.Column header="naziv" id="fileName">
              {(document: Document) => (
                <span className="flex items-center">
                  <img src={getIconFromExtension(document.name)} alt="file" className="w-6 mr-1" />
                  <p>{document.name}</p>
                </span>
              )}
            </DataTable.Column>
            <DataTable.Column header="datum učitavanja" id="fileNameDate" className="font-normal">
              {(document: Document) => (
                <span className="flex">
                  <Date>{parseISO(document.dateOfUpload)}</Date>
                </span>
              )}
            </DataTable.Column>
          </DataTable>
          <TranslatedResizer
            onPageSizeChange={(pageSize: number) => {
              setPageSize(pageSize);
              setPageNumber(0);
            }}
            pageSize={pageSize}
            pageSizes={[5, 10, 15]}
            totalElements={paginationState.totalElements}
          />
          <TranslatedPagination
            paginationState={paginationState}
            paginationHook={paginationHook}
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalElements={paginationState.totalElements}
            onPageChange={setPageNumber}
          />
        </div>
      )}
    </>
  );
}
