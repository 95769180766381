import React, { useState } from "react";

import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Form, FormikProps } from "formik";

import * as Yup from "yup";

import { Button } from "@tiller/core";
import { FormContainer, PasswordInputField } from "@tiller/formik-elements";

import { messages } from "../../../constants/messages";
import { useEnvironmentVariable } from "../../../hook/EnvironmentVariableProvider";
import { ChangePasswordData } from "../../../types";

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(messages.requiredField),
  newPassword: Yup.string()
    .matches(/^(?=.*[a-z])/, "Lozinka mora sadržavati barem jedno malo slovo.")
    .matches(/^(?=.*[A-Z])/, "Lozinka mora sadržavati barem jedno veliko slovo.")
    .matches(/^(?=.*[0-9])/, "Lozinka mora sadržavati barem jedan broj.")
    .matches(/^(?=.{8,})/, "Lozinka mora sadržavati barem 8 znakova.")
    .required("Lozinka mora sadržavati barem 8 znakova."),
  confirmNewPassword: Yup.string().oneOf([Yup.ref("newPassword")], "Lozinke se ne podudaraju."),
});

type ChangePasswordPopupProps = {
  onSubmit: () => void;
};

export default function ChangePasswordPopup({ onSubmit }: ChangePasswordPopupProps) {
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
  });

  const [wrongPasswordError, setWrongPasswordError] = useState("");
  const changePassword = (username: string, oldPassword: string, newPassword: string) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: oldPassword,
    });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (result) {
            onSubmit();
          }
          if (err) {
            console.error("err ", err);
          }
        });
      },
      onFailure: function (err) {
        setWrongPasswordError("Upisana lozinka nije ispravna.");
        console.error(err);
      },
    });
  };

  return (
    <FormContainer
      initialValues={{ oldPassword: "", newPassword: "", confirmNewPassword: "" }}
      onSubmit={(values) =>
        changePassword(localStorage.getItem("username") ?? "", values.oldPassword, values.newPassword)
      }
      validationSchema={ChangePasswordSchema}
      validateAfterSubmit
    >
      {({ getFieldHelpers, isValid, dirty }: FormikProps<ChangePasswordData>) => (
        <Form className="flex flex-col items-end ">
          <p className="text-sm mb-4">{messages.passwordRequirements}</p>
          <PasswordInputField
            name="oldPassword"
            label="Trenutna lozinka"
            className="w-full"
            help={<p>{messages.capsLockOn}</p>}
          />
          <p className="text-red-500 text-sm mb-0 mt-0 self-start">{wrongPasswordError}</p>
          <PasswordInputField
            name="newPassword"
            label="Nova lozinka"
            className="w-full mt-4"
            onBlurCapture={() => {
              const helpers = getFieldHelpers("newPassword");
              helpers.setTouched(true, true);
            }}
            help={<p>{messages.capsLockOn}</p>}
          />
          <PasswordInputField
            name="confirmNewPassword"
            label="Potvrdi novu lozinku"
            className="w-full mt-4 mb-6"
            onBlurCapture={() => {
              const helpers = getFieldHelpers("confirmNewPassword");
              helpers.setTouched(true, true);
            }}
            help={<p>{messages.capsLockOn}</p>}
          />
          <Button disabled={!(isValid && dirty)} className="w-1/5">
            Spremi
          </Button>
        </Form>
      )}
    </FormContainer>
  );
}
