import React from "react";

import { Location } from "@remix-run/router";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { useEnvironmentVariable } from "./EnvironmentVariableProvider";
import { user } from "../api/backend_paths";
import { routes } from "../api/paths";

const refreshToken = (cognitoUser: CognitoUser, navigate: NavigateFunction, location: Location) => {
  let tokenExp = localStorage.getItem("tokenExp");
  let tokenExpNum = Number(tokenExp);

  let now = Math.floor(Date.now() / 1000);

  if (now > tokenExpNum - 120) {
    cognitoUser?.getSession((err: any, session: any) => {
      if (!session) return;

      const refreshToken = session?.getRefreshToken();
      cognitoUser?.refreshSession(refreshToken, (err: any, session: any) => {
        if (err) {
          console.log(err);
        } else {
          localStorage.setItem("tokenExp", session?.getIdToken().getExpiration().toString());
          fetch(user.getToken, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + session.getIdToken().getJwtToken(),
            },
          })
            .then((res) => handleErrorWoRefreshToken(res, navigate, location))
            .then((data) => {
              localStorage.setItem("associationName", data.associationName);
              localStorage.setItem("firstName", data.firstName);
              localStorage.setItem("lastName", data.lastName);
              localStorage.setItem("email", data.email);
              localStorage.setItem("username", data.username);
            })
            .catch((err) => console.log(err));
        }
      });
    });
  }
};

const handleErrorWoRefreshToken = (response: Response, navigate: NavigateFunction, location: Location) => {
  if (!response.ok) {
    if (response.status === 401) {
      if (location.pathname !== routes.USER_LOGIN_URL && location.pathname !== "/") {
        navigate(routes.USER_LOGIN_URL + `?return=${location.pathname}`);
      } else if (location.pathname === "/") {
        navigate(routes.USER_LOGIN_URL);
      }
    }
    throw response;
  } else {
    return response.json().catch((e) => {
      if (e instanceof SyntaxError) {
        if (e.message !== "Unexpected end of JSON input") {
          console.log(e);
        }
      }
      return response;
    });
  }
};

export default function useHandleError() {
  const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = useEnvironmentVariable();
  const navigate = useNavigate();
  const location = useLocation();

  const handleError = React.useCallback(
    (response: Response) => {
      const userPool = new CognitoUserPool({
        UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: REACT_APP_COGNITO_CLIENT_ID,
      });

      const cognitoUser = new CognitoUser({
        Username: localStorage.getItem("username") ?? "",
        Pool: userPool,
      });

      refreshToken(cognitoUser, navigate, location);
      return handleErrorWoRefreshToken(response, navigate, location);
    },
    [REACT_APP_COGNITO_CLIENT_ID, REACT_APP_COGNITO_USER_POOL_ID, navigate, location]
  );

  return { handleError, handleErrorWoRefreshToken };
}
