import React from "react";

import "../styles/LandingPageStyles.css";
import { AnimatePresence, motion } from "framer-motion";

import { Icon } from "@tiller/icons";

import ImageCarousel from "./ImageCarousel";

type FeatureSectionPartsProps = {
  flexDirection: string;
  defaultImage: string;
  heading: string;
  images: string[];
  paragraph: string;
  list: string[];
};

export default function FeatureSectionParts({
  flexDirection,
  heading,
  paragraph,
  list,
  defaultImage,
  images,
}: FeatureSectionPartsProps) {
  const [isOpen, setIsOpen] = React.useState(window.innerWidth > 641);

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 641) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`flex gap-4 md:gap-8 lg:gap-16 ${flexDirection}`}>
      <div className="flex flex-col gap-6 w-full">
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ amount: 0.2 }}>
          <div className="flex flex-col">
            <h3 className="landing-h3 ">{heading}</h3>
            <p className="landing-p-md">{paragraph}</p>
          </div>
        </motion.div>
        <div className="flex flex-col gap-2">
          <div className="flex p-2">
            <button type="button" className="btn" onClick={() => setIsOpen((prev) => !prev)}>
              <div className="landing-p-sm flex items-center gap-2 text-indigo-600">
                <Icon type={isOpen ? "chevron-up" : "chevron-down"} />
                <p>{isOpen ? "Pročitaj manje" : "Pročitaj više"}</p>
              </div>
            </button>
          </div>
          <div className="collapsible">
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0, y: "-100%" }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.5,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    y: "-100%",
                    transition: {
                      duration: 0.5,
                    },
                  }}
                >
                  <ul className="landing-p-sm list-disc list-outside pl-4">
                    {list.map((item: string, index: number) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className="w-full">
        <ImageCarousel images={images} defaultImage={defaultImage} />
      </div>
    </div>
  );
}
