export const routes = {
  LANDING_PAGE_URL: "/",
  ASSOCIATION_REGISTRATION_URL: "/app/registracija-udruge",
  USER_REGISTRATION_URL: (code?: string) => `/app/registracija-korisnik/${code ?? ":code"}`,
  ADMIN_REGISTRATION_URL: "/app/registracija-admin",
  USER_LOGIN_URL: "/app/prijava",
  RECORDS_URL: "/app/evidencija",
  NEW_MEMBER_URL: "/app/evidencija/kreiraj-clana",
  UPDATE_MEMBER_URL: (code?: string) => `/app/evidencija/uredi-clana/${code ?? ":code"}`,
  VIEW_MEMBER_URL: (code?: string) => `/app/evidencija/pregled-clana/${code ?? ":code"}`,
  ACTIVITIES_URL: "/app/zadaci",
  NEW_ACTIVITY_URL: "/app/zadaci/kreiraj-zadatak",
  UPDATE_ACTIVITY_URL: (id?: number) => `/app/zadaci/uredi-zadatak/${id ?? ":id"}`,
  VIEW_ACTIVITY_URL: (id?: number) => `/app/zadaci/pregled-zadatka/${id ?? ":id"}`,
  COMPETITIONS_URL: "/app/natjecaji",
  NEW_COMPETITION_URL: "/app/natjecaji/kreiraj-natjecaj",
  VIEW_COMPETITION_URL: (id?: string) => `/app/natjecaji/pregled-natjecaja/${id ?? ":id"}`,
  UPDATE_COMPETITION_URL: (id?: string) => `/app/natjecaji/uredi-natjecaj/${id ?? ":id"}`,
  ADMIN_URL: "/app/postavke",
  EXPIRED_LINK_URL: "/app/nevazeci-link",
  FORGOTTEN_PASSWORD_URL: "/app/zaboravljena-lozinka",
  FORGOTTEN_PASSWORD_CONFIRMATION_URL: "/app/email-potvrda",
  RESET_PASSWORD_URL: "/app/promjena-lozinke",
  PASSWORD_CHANGE_SUCCESS_URL: "/app/uspjesna-promjena-lozinke",
  NEW_ROLE_URL: "/app/postavke/kreiraj-rolu",
  UPDATE_ROLE_URL: (id?: number) => `/app/postavke/uredi-rolu/${id ?? ":id"}`,
  DATA_IMPORT: "/app/postavke/uvoz-podataka",
};
