import { usePermissions } from "../PermissionsProvider";

export function useRolesTabIndex() {
  const { permissions } = usePermissions();

  let rolesTabIndex: number;

  if (
    (permissions?.includes("SETTINGS_EMPLOYEE_LIST") &&
      permissions?.includes("SETTINGS_SEND_INVITATION_LINK") &&
      (permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") ||
        permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE"))) ||
    (permissions?.includes("SETTINGS_SEND_INVITATION_LINK") &&
      (permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") ||
        permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE"))) ||
    (permissions?.includes("SETTINGS_EMPLOYEE_LIST") &&
      (permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") ||
        permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE")))
  ) {
    rolesTabIndex = 4;
  } else if (
    permissions?.includes("SETTINGS_EMPLOYEE_LIST") ||
    permissions?.includes("SETTINGS_SEND_INVITATION_LINK") ||
    permissions?.includes("SETTINGS_DYNAMIC_FIELDS_CREATE_UPDATE") ||
    permissions?.includes("SETTINGS_DYNAMIC_FIELDS_DELETE")
  ) {
    rolesTabIndex = 3;
  } else {
    rolesTabIndex = 2;
  }

  return rolesTabIndex;
}
