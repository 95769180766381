import * as React from "react";
import { ReactElement } from "react";

import env, { ReactEnvironmentVariable } from "../env";

interface EnvironmentVariableProviderProps {
  children: ReactElement;
}

type EnvironmentVariablesContext = {
  env: ReactEnvironmentVariable;
};

export default function EnvironmentVariableProvider({ children }: EnvironmentVariableProviderProps) {
  const [envVars] = React.useState<ReactEnvironmentVariable>({
    REACT_APP_COGNITO_CLIENT_ID:
      env.REACT_APP_COGNITO_CLIENT_ID || process.env.REACT_APP_COGNITO_CLIENT_ID || "4ih63ero3lg1rrcev13cor49lv",
    REACT_APP_COGNITO_USER_POOL_ID:
      env.REACT_APP_COGNITO_USER_POOL_ID || process.env.REACT_APP_COGNITO_USER_POOL_ID || "eu-central-1_Nev7sApAO",
  });

  return <Provider value={{ env: envVars }}>{children}</Provider>;
}

const envVarsContext = React.createContext<EnvironmentVariablesContext>({
  env: {
    REACT_APP_COGNITO_CLIENT_ID: "",
    REACT_APP_COGNITO_USER_POOL_ID: "",
  },
});
const { Provider } = envVarsContext;

export function useEnvironmentVariable() {
  const context = React.useContext(envVarsContext);
  if (context === undefined) {
    throw new Error(`useEnvironmentVariable hook must be used within EnvironmentVariableProvider`);
  }
  return context.env;
}
