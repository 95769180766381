import { format, parseISO } from "date-fns";
import { FormikValues } from "formik";

import { messages } from "../../../constants/messages";
import {
  ActivityFilterValues,
  ActivityFormType,
  ActivityMemberAttendance,
  ActivityTypeNames,
  Note,
} from "../../../types";
import { convertFieldsForDatabase, convertFieldsForDisplay } from "../../../util/DynamicFieldUtil";
import { convertDateForDatabase, convertDateForDisplay } from "../../MemberForm/util/member-util";
import { ActivityType } from "../NewActivity/NewActivity";

export const dateSettings = {
  DATE_FORMAT: "yyyy-MM-dd",
  TIME_FORMAT: "HH:mm",
  DEFAULT_START_TIME: "00:00",
  DEFAULT_END_TIME: "23:59",
};

export const newActivity = (values: FormikValues, id?: number): ActivityType => {
  const formattedDynamicData = convertFieldsForDatabase(values.dynamicData);
  return {
    id: id,
    title: values.title,
    activityType: values.activityType,
    projectTitle: values.projectTitle,
    description: values.description ?? "",
    startDate: connectDateAndTime(
      dateSettings.DEFAULT_START_TIME,
      convertDateForDatabase(values.startDate),
      values.startTime
    ) as string,
    endDate: values.endDate
      ? (connectDateAndTime(
          dateSettings.DEFAULT_END_TIME,
          convertDateForDatabase(values.endDate),
          values.endTime
        ) as string)
      : null,
    taskDuration: values.taskDuration,
    persons: formatParticipants(values.persons),
    designatedPersons: values.designatedPersons,
    author: `${localStorage.getItem("firstName") ?? ""}  ${localStorage.getItem("lastName") ?? ""}`,
    attachmentUuids: values.attachmentUuids,
    numberOfParticipants: values.numberOfParticipants,
    dynamicData: formattedDynamicData,
  };
};

export function parseDate(dateAndTime: string): [string | null, string | null] {
  if (dateAndTime) {
    const date = parseISO(dateAndTime);
    return [format(date, dateSettings.DATE_FORMAT), format(date, dateSettings.TIME_FORMAT)];
  }
  return [null, null];
}

function convertTimeValuesToString(values: number[]): string[] {
  return values.map((value) => (value < 10 ? `0${value}` : `${value}`));
}

export function activityFormTypeValidation(values: ActivityFormType) {
  const errors = {} as any;

  const acceptableHours = convertTimeValuesToString(Array.from(Array(24), (x, i) => i));
  const acceptableMinutes = convertTimeValuesToString(Array.from(Array(60), (x, i) => i));

  if (values.startTime) {
    const [startHour, startMinute] = values.startTime.split(":");
    if (!acceptableHours.includes(startHour) || !acceptableMinutes.includes(startMinute)) {
      errors.startTime = messages.invalidTime;
    }
  }

  if (values.endTime) {
    const [endHour, endMinute] = values.endTime.split(":");
    if (!acceptableHours.includes(endHour) || !acceptableMinutes.includes(endMinute)) {
      errors.endTime = messages.invalidTime;
    }
  }

  return errors;
}

export function fillActivity(data: any) {
  let activity = {} as ActivityFormType;
  const formattedDynamicData = convertFieldsForDisplay(data.dynamicData);
  activity.id = data.id;
  activity.title = data.title;
  activity.activityType = data.activityType;
  activity.projectTitle = data.projectTitle;
  activity.description = data.description;
  [activity.dateOfCreation, activity.timeOfCreation] = parseDate(data.dateOfCreation) as [string, string];
  activity.dateOfUpdate = format(parseISO(data.dateOfUpdate), dateSettings.DATE_FORMAT);

  [activity.startDate, activity.startTime] = parseDate(data.startDate) as [string, string];
  [activity.endDate, activity.endTime] = parseDate(data.endDate);

  activity.startDate = convertDateForDisplay(activity.startDate) as string;
  activity.endDate = activity.endDate ? convertDateForDisplay(activity.endDate as string) : "";

  data.notes?.forEach(
    (note: Note) => ([note.dateOfCreation, note.timeOfCreation] = parseDate(note.dateOfCreation) as [string, string])
  );

  activity.notes = data.notes;

  activity.taskDuration = data.taskDuration;

  activity.tags = data.tags;
  activity.persons = !data.persons ? undefined : data.persons;
  activity.designatedPersons =
    !data.designatedPersons || data.designatedPersons.length === 0 ? undefined : data.designatedPersons;
  activity.attachmentUuids = data.attachmentUuids ?? [];
  activity.attachments = data.attachments ?? [];
  activity.numberOfParticipants = data.numberOfParticipants == null ? undefined : data.numberOfParticipants;
  activity.dynamicData = formattedDynamicData;

  return activity;
}

export function connectDateAndTime(defaultValue: string, date?: string, time?: string): string | undefined {
  if (!date) {
    return undefined;
  }
  return `${date}T${time === "" || time === undefined || time === null ? defaultValue : time}`;
}

export const newFilterValues = (values: ActivityFilterValues): ActivityFilterValues => {
  return {
    activityStatus: values.activityStatus,
    personCodes: values.personCodes,
    designatedPersonUsernames: values.designatedPersonUsernames,
    startDate: connectDateAndTime(dateSettings.DEFAULT_START_TIME, values?.startDate) as string,
    endDate: connectDateAndTime(dateSettings.DEFAULT_END_TIME, values?.endDate) as string,
    dynamicData: values.dynamicData,
    dynamicDataDate: values.dynamicDataDate,
    activityType: ActivityTypeNames[values.activityType as keyof typeof ActivityTypeNames],
    projectTitles: values.projectTitles,
    programTitles: values.programTitles,
  };
};

export function formatParticipants(participants: ActivityMemberAttendance[]) {
  return participants.map((person) => {
    return {
      id: person.id,
      code: person.code,
      name: person.name,
      firstName: person.firstName,
      lastName: person.lastName,
      attended: person.attended,
    };
  });
}

export function getParticipantIndex(
  all: ActivityMemberAttendance[] | undefined,
  current: ActivityMemberAttendance
): number {
  let currentIndex: number = -1;
  for (let i: number = 0; i < all!.length; i++) {
    if (all![i].id === current.id) {
      currentIndex = i as unknown as number;
      break;
    }
  }
  return currentIndex;
}
