import React from "react";

import { Form, Formik } from "formik";

import { Button, Card } from "@tiller/core";

import { DateRangeInputField } from "@tiller/formik-elements";
import { Icon } from "@tiller/icons";

import DynamicFieldFilters from "../../../components/DynamicFieldFilters";
import { MemberFilterBaseValues, MemberFilterValuesType, MemberType } from "../../../types";

const initialValues = {
  birthDateFrom: undefined,
  birthDateTo: undefined,
  membershipDateFrom: undefined,
  membershipDateTo: undefined,
  membershipTerminationDateFrom: undefined,
  membershipTerminationDateTo: undefined,
} as MemberFilterBaseValues;

type MemberFilterProps = {
  memberType: string;
  onSubmit: (values: MemberFilterValuesType) => void;
};

const formPrefix = "ASSOCIATION_MEMBER_";

export default function MemberFilter({ memberType, onSubmit }: MemberFilterProps) {
  return (
    <Card className="mt-2">
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleReset, handleSubmit }) => (
          <Form>
            <Card.Body>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                <DateRangeInputField
                  allowClear={true}
                  popoverPosition="right"
                  start="birthDateFrom"
                  end="birthDateTo"
                  label="Datum rođenja"
                  className="w-full"
                />
                {memberType === MemberType.MEMBER && (
                  <DateRangeInputField
                    allowClear={true}
                    popoverPosition="right"
                    start="membershipDateFrom"
                    end="membershipDateTo"
                    label="Datum učlanjenja"
                    className="w-full"
                  />
                )}
                {memberType === MemberType.MEMBER && (
                  <DateRangeInputField
                    allowClear={true}
                    popoverPosition="right"
                    start="membershipTerminationDateFrom"
                    end="membershipTerminationDateTo"
                    label="Datum prestanka članstva"
                    className="w-full"
                  />
                )}
                {<DynamicFieldFilters formId={`${formPrefix}${memberType}`} />}
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="flex gap-3 justify-end">
                <Button
                  onClick={() => {
                    handleReset();
                    handleSubmit();
                  }}
                  variant="outlined"
                  leadingIcon={<Icon type="refresh" />}
                >
                  Resetiraj
                </Button>
                <Button type="submit">Filtriraj</Button>
              </div>
            </Card.Footer>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
