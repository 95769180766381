import React, { useEffect, useState } from "react";

import { FormikValues } from "formik";

import { Badge, Card, Typography } from "@tiller/core";

import NoteDetails from "./NoteDetails";
import NoteForm from "./NoteForm";
import useHandleError from "../../hook/useHandleError";
import { Note } from "../../types";
import { newNoteRequest, newNoteResponse, parseNoteDate } from "../../util/NotesUtil";
import SlideOverComponent from "../SlideOverComponent";

type NoteProps = {
  notes: Note[];
  id: string;
  addNoteUrl: (id: string, query?: string) => string;
  updateNoteUrl: string;
  deleteNoteUrl: (id: number) => string;
  getObjectContainingNotesUrl: (id: string, query?: string) => string;
  setOpen: (value: boolean) => void;
  open: boolean;
  showNoteForm?: boolean;
  module: string;
};

export default function NotesContainer({
  notes,
  id,
  addNoteUrl,
  getObjectContainingNotesUrl,
  updateNoteUrl,
  deleteNoteUrl,
  setOpen,
  open,
  showNoteForm = true,
  module,
}: NoteProps) {
  const { handleError } = useHandleError();

  const [allNotes, setAllNotes] = useState<Note[]>([]);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function stateHandler(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    setAllNotes(notes ?? []);
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [id, notes]);

  const refreshNotes = () => {
    fetch(getObjectContainingNotesUrl(`${id}`))
      .then(handleError)
      .then((data) => {
        parseNoteDate(data.notes);
        setAllNotes(data.notes);
      })
      .catch((err) => console.log(err));
  };

  const handleCreate = (values: FormikValues) => {
    fetch(addNoteUrl(id), {
      method: "POST",
      body: JSON.stringify(newNoteRequest(values)),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then((data) => {
        setAllNotes([newNoteResponse(data), ...(allNotes ? allNotes : [])]);
        values.content = "";
      })
      .catch((err) => console.log(err));
  };

  return windowSize >= 1280 ? (
    <div className="flex w-full hidden xl:block">
      <div className="flex w-fit">
        <Card className="flex w-1/4 flex-1 flex-col px-6 pb-1 mr-14 fixed overflow-y-auto max-h-[80vh] 3xl:max-h-[85vh]">
          <div className="mb-2">
            <div className="flex items-center">
              <Typography element="h1" variant="title" className="pt-5 mb-2 mr-2">
                Bilješke
              </Typography>
              <Badge className="mt-4">{allNotes ? allNotes.length : 0}</Badge>
            </div>
            {showNoteForm && (
              <NoteForm content="" buttonText="Dodaj" onSubmit={handleCreate} isDisabled={isEditModeEnabled} />
            )}
          </div>
          {allNotes?.map((note, index) => (
            <NoteDetails
              id={note.id as number}
              author={note.author}
              updatedAuthor={note.updatedAuthor}
              dateAdded={note.dateOfCreation}
              timeAdded={note.timeOfCreation}
              dateUpdated={note.dateOfUpdate}
              timeUpdated={note.timeOfUpdate}
              content={note.content}
              key={index}
              index={index}
              arrayLength={allNotes.length - 1}
              refreshNotes={refreshNotes}
              setIsEditModeEnabled={setIsEditModeEnabled}
              module={module}
              updateNoteUrl={updateNoteUrl}
              deleteNoteUrl={deleteNoteUrl}
            />
          ))}
        </Card>
      </div>
    </div>
  ) : (
    <div className="block xl:hidden">
      <SlideOverComponent
        isOpen={open}
        setOpen={stateHandler}
        title="Bilješke"
        hasBadge={true}
        badgeNumber={allNotes ? allNotes.length : 0}
      >
        <div className="flex flex-col w-full">
          <NoteForm content="" buttonText="Dodaj" onSubmit={handleCreate} isDisabled={isEditModeEnabled} />
          {allNotes?.map((note, index) => (
            <NoteDetails
              id={note.id as number}
              author={note.author}
              updatedAuthor={note.updatedAuthor}
              dateAdded={note.dateOfCreation}
              timeAdded={note.timeOfCreation}
              dateUpdated={note.dateOfUpdate}
              timeUpdated={note.timeOfUpdate}
              content={note.content}
              key={index}
              index={index}
              arrayLength={allNotes.length - 1}
              refreshNotes={refreshNotes}
              setIsEditModeEnabled={setIsEditModeEnabled}
              module={module}
              updateNoteUrl={updateNoteUrl}
              deleteNoteUrl={deleteNoteUrl}
            />
          ))}
        </div>
      </SlideOverComponent>
    </div>
  );
}
