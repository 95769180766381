import React, { useState } from "react";

import { parseISO } from "date-fns";
import { FormikValues } from "formik";

import { IconButton } from "@tiller/core";
import { Date } from "@tiller/date";
import { Icon } from "@tiller/icons";

import { cx } from "@tiller/theme";

import NoteForm from "./NoteForm";
import { canUserEditNote } from "./notes-util";
import { messages } from "../../constants/messages";
import useHandleError from "../../hook/useHandleError";
import { updateNoteRequest } from "../../util/NotesUtil";
import Popup from "../Popup";

type NoteProps = {
  id: number;
  author: string;
  updatedAuthor: string;
  dateAdded: string;
  timeAdded: string;
  dateUpdated: string;
  timeUpdated: string;
  content: string;
  index: number;
  arrayLength: number;
  refreshNotes: () => void;
  setIsEditModeEnabled: (isEnabled: boolean) => void;
  updateNoteUrl: string;
  deleteNoteUrl: (id: number) => string;
  module: string;
};

export default function NoteDetails({
  id,
  author,
  updatedAuthor,
  dateAdded,
  timeAdded,
  dateUpdated,
  timeUpdated,
  content,
  index,
  arrayLength,
  refreshNotes,
  setIsEditModeEnabled,
  updateNoteUrl,
  deleteNoteUrl,
  module,
}: NoteProps) {
  const { handleError } = useHandleError();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(window.innerWidth < 1280);
  const [isOpen, setIsOpen] = useState(false);

  const className = cx("mb-2 px-2 pt-2 pb-4 text-sm", { "border-b-2": index !== arrayLength });

  const handleUpdate = (values: FormikValues) => {
    fetch(updateNoteUrl, {
      method: "PUT",
      body: JSON.stringify(updateNoteRequest(values)),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleError)
      .then(refreshNotes)
      .catch((err) => console.log(err));
    setIsEditable(false);
  };

  const handleDelete = () => {
    fetch(deleteNoteUrl(id), {
      method: "DELETE",
    })
      .catch(handleError)
      .then(refreshNotes)
      .catch((err) => console.log(err));
  };

  return (
    <div className={className} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <div className="flex justify-between">
        <div>
          <div className="flex items-center text-gray-600">
            <Icon type="user" className="mr-1" size={4} />
            <p>{author}</p>
          </div>
          <div className="flex items-center text-gray-600 mb-1">
            <Icon type="calendar" className="mr-1" size={4} />
            <Date>{parseISO(dateAdded)}</Date>
            <p className="ml-2">{timeAdded}</p>
          </div>
        </div>
        {canUserEditNote(author, module) &&
          isShown &&
          (!isEditable ? (
            <div className="flex pb-1.5">
              <IconButton
                type="trash"
                label="Izbriši"
                color="red"
                className="mr-2 cursor-pointer"
                onClick={() => setIsOpen(true)}
              />
              <IconButton
                type="pencil"
                label="Uredi"
                color="primary"
                className="cursor-pointer"
                onClick={() => {
                  setIsEditable(true);
                  setIsEditModeEnabled(true);
                }}
              />
            </div>
          ) : (
            <IconButton
              type="x"
              label="Odbaci"
              className="cursor-pointer"
              onClick={() => {
                setIsEditable(false);
                setIsEditModeEnabled(false);
              }}
            />
          ))}
      </div>
      {isEditable ? (
        <NoteForm
          id={id}
          content={content}
          buttonText="Spremi"
          onSubmit={handleUpdate}
          setIsDisabled={setIsEditModeEnabled}
        />
      ) : (
        <>
          {content.length > 250 ? (
            isExpanded ? (
              <p className="break-word whitespace-pre-wrap">{content}</p>
            ) : (
              <>
                <p className="break-word whitespace-pre-wrap">{content.substring(0, 250)}</p>
                <span className="cursor-pointer hover:underline text-blue-700" onClick={() => setIsExpanded(true)}>
                  ... više
                </span>
              </>
            )
          ) : (
            <p className="break-word whitespace-pre-wrap">{content}</p>
          )}
        </>
      )}
      {!isEditable && (
        <span className="flex flex-col gap-1 sm:flex-row sm:gap-2 text-gray-400 italic text-xs">
          <p className="hidden sm:block">Zadnja izmjena: {updatedAuthor},</p>
          <p className="sm:hidden">Zadnja izmjena: {updatedAuthor}</p>
          <Date>{parseISO(dateUpdated)}</Date>
          <p>{timeUpdated}</p>
        </span>
      )}
      <Popup
        title={messages.noteDeletePrompt}
        content={messages.deleteWarning}
        primaryButtonColor="red"
        primaryButtonText="Da, izbriši."
        onSubmit={handleDelete}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}
