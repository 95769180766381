import React from "react";

import { getIconFromExtension } from "../util/getIconFromExtension";

type PropsPopup = {
  documents: string[];
};

export default function DeleteAttachmentPopupContent({ documents }: PropsPopup) {
  return (
    <div className="mt-4">
      {documents.map((name, index) => (
        <span className="flex items-center mb-1.5" key={index}>
          <img src={getIconFromExtension(name)} alt="file" className="w-5 mr-1" />
          <p>{name}</p>
        </span>
      ))}
    </div>
  );
}
