import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useYupFormConfiguration } from "@nrich/form-configuration-core";

import useHandleError from "./useHandleError";
import { getDynamicValidationSchema } from "../util/DynamicFieldUtil";

export default function useComplexValidation(
  validation: string,
  formIds: string | string[],
  yupSchema: Yup.ObjectSchema<any>
) {
  const { handleError } = useHandleError();

  const navigate = useNavigate();
  const location = useLocation();
  const backendValidation = useYupFormConfiguration(validation);

  const [validationSchema, setValidationSchema] = useState(backendValidation.concat(yupSchema));

  React.useEffect(() => {
    const formIdsForFetch = Array.isArray(formIds) ? formIds : [formIds];
    getDynamicValidationSchema(
      navigate,
      location,
      handleError
    )(formIdsForFetch)
      .then((dynamicValidation) => {
        setValidationSchema(
          backendValidation.concat(
            Yup.object({
              dynamicData: dynamicValidation,
            }).concat(yupSchema)
          )
        );
      })

      .catch((error) => console.error(error));
  }, [formIds, backendValidation, location, navigate, yupSchema, handleError]);

  return validationSchema;
}
