import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Tabs } from "@tiller/core";

import MemberList from "./components/MemberList";
import { usePermissions } from "../../PermissionsProvider";
import { evidence } from "../../api/backend_paths";
import { routes } from "../../api/paths";
import Header from "../../components/Header";

import TabLabel from "../../components/TabLabel";
import { PagesStateContext } from "../../context/PagesStateContext";
import useHandleError from "../../hook/useHandleError";
import { MemberCount, MemberFilterValuesType, MemberType, Paging } from "../../types";
import { serializeParameterSuffix } from "../../util/GeneralUtil";

export default function Members() {
  const { membersTabIndex, setMembersTabIndex, memberType, setMemberType } = useContext(PagesStateContext);
  const {
    allMembersRecordPaging,
    updateAll,
    userRecordPaging,
    updateUserRecordPaging,
    volunteerRecordPaging,
    updateVolunteerRecordPaging,
    donorRecordPaging,
    updateDonorRecordPaging,
    memberRecordPaging,
    updateMemberRecordPaging,
    partnerRecordPaging,
    updatePartnerRecordPaging,
    externalCollaboratorRecordPaging,
    updateExternalCollaboratorRecordPaging,
    employeeRecordPaging,
    updateEmployeeRecordPaging,
    otherRecordPaging,
    updateOtherRecordPaging,
  } = useContext(PagesStateContext);

  const [exportQuery, setExportQuery] = useState("");
  const [memberFilterValues, setMemberFilterValues] = useState({
    ALL: {} as MemberFilterValuesType,
    USER: {} as MemberFilterValuesType,
    VOLUNTEER: {} as MemberFilterValuesType,
    DONOR: {} as MemberFilterValuesType,
    MEMBER: {} as MemberFilterValuesType,
    PARTNER: {} as MemberFilterValuesType,
    EMPLOYEE: {} as MemberFilterValuesType,
    EXTERNAL_COLLABORATOR: {} as MemberFilterValuesType,
    OTHERS: {} as MemberFilterValuesType,
  });

  const [memberSortType, setMemberSortType] = useState({
    ALL: "",
    USER: "",
    VOLUNTEER: "",
    DONOR: "",
    MEMBER: "",
    PARTNER: "",
    EMPLOYEE: "",
    EXTERNAL_COLLABORATOR: "",
    OTHERS: "",
  });

  const [isExportDisabled, setIsExportDisabled] = useState<boolean>(true);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { permissions } = usePermissions();

  const [fixedCount, setFixedCount] = useState<MemberCount>({
    totalCount: 0,
    volunteerCount: 0,
    donorCount: 0,
    userCount: 0,
    memberCount: 0,
    partnerCount: 0,
    externalCollaboratorCount: 0,
    employeeCount: 0,
    othersCount: 0,
  });

  const [totalCount, setTotalCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [volunteerCount, setVolunteerCount] = useState(0);
  const [donorCount, setDonorCount] = useState(0);
  const [partnerCount, setPartnerCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [externalCollaboratorCount, setExternalCollaboratorCount] = useState(0);
  const [othersCount, setOthersCount] = useState(0);

  useEffect(() => {
    fetch(evidence.getMemberCount())
      .then(handleError)
      .then((data) => {
        setFixedCount({ ...data });
        setTotalCount(data.totalCount);
        setMemberCount(data.memberCount);
        setUserCount(data.userCount);
        setVolunteerCount(data.volunteerCount);
        setDonorCount(data.donorCount);
        setPartnerCount(data.partnerCount);
        setEmployeeCount(data.employeeCount);
        setExternalCollaboratorCount(data.externalCollaboratorCount);
        setOthersCount(data.othersCount);
      });
  }, [handleError]);

  const exportToExcel = () => {
    setIsExportLoading(true);
    fetch(
      evidence.exportToExcel(
        serializeParameterSuffix({
          memberType,
          searchQuery: exportQuery,
          ...memberFilterValues[memberType],
          dynamicData: JSON.stringify(memberFilterValues[memberType].dynamicData),
          dynamicDataDate: JSON.stringify(memberFilterValues[memberType].dynamicDataDate),
          sortType: memberSortType[memberType],
        })
      )
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res;
        }
      })
      .then((res) => {
        res.blob().then((blob) => {
          const newBlob = new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(newBlob);
          link.download = "Izvještaj-evidencija";
          link.click();
          link.remove();
          setIsExportLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const setFilerValuesForType = (type: MemberType, values: MemberFilterValuesType) => {
    setMemberFilterValues({ ...memberFilterValues, [type]: values });
  };

  const updateSortType = (type: MemberType, sortType: string) => {
    setMemberSortType({
      ...memberSortType,
      [type]: sortType,
    });
  };

  function setTabData(tabIndex: number, memberType: MemberType) {
    setMembersTabIndex(tabIndex);
    setMemberType(memberType);
  }

  return (
    <>
      <Header
        title="Evidencija"
        primaryButtonText="Kreiraj profil"
        secondaryButtonText="Izvoz u Excel"
        primaryButtonIcon="plus"
        secondaryButtonIcon="download"
        onPrimaryClick={() => {
          navigate(routes.NEW_MEMBER_URL);
        }}
        onSecondaryClick={exportToExcel}
        disableSecondaryButton={isExportDisabled}
        isSecondaryLoading={isExportLoading}
        hidePrimaryButton={!permissions?.includes("ASSOCIATION_MEMBER_CREATE_UPDATE")}
        hideSecondaryButton={!permissions?.includes("ASSOCIATION_MEMBER_EXPORT")}
      />
      <Tabs defaultIndex={membersTabIndex}>
        <Tabs.Tab
          label={<TabLabel title="Svi" count={totalCount} />}
          onClick={(tabIndex) => {
            setTabData(tabIndex, MemberType.ALL);
          }}
        >
          <MemberList
            updatePagingContextIndex={(page: Paging) => updateAll(page)}
            pagingContextIndex={allMembersRecordPaging}
            memberType={MemberType.ALL}
            setMemberFilterValues={setFilerValuesForType}
            setExportQuery={setExportQuery}
            memberFilterValues={memberFilterValues.ALL}
            setIsExportDisabled={setIsExportDisabled}
            sortType={memberSortType.ALL}
            setSortType={updateSortType}
            setCount={setTotalCount}
          />
        </Tabs.Tab>
        {fixedCount.userCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Korisnici" count={userCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.USER);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateUserRecordPaging(page)}
              pagingContextIndex={userRecordPaging}
              memberType={MemberType.USER}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.USER}
              sortType={memberSortType.USER}
              setSortType={updateSortType}
              setCount={setUserCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.volunteerCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Volonteri" count={volunteerCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.VOLUNTEER);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateVolunteerRecordPaging(page)}
              pagingContextIndex={volunteerRecordPaging}
              memberType={MemberType.VOLUNTEER}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.VOLUNTEER}
              sortType={memberSortType.VOLUNTEER}
              setSortType={updateSortType}
              setCount={setVolunteerCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.donorCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Donatori" count={donorCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.DONOR);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateDonorRecordPaging(page)}
              pagingContextIndex={donorRecordPaging}
              memberType={MemberType.DONOR}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.DONOR}
              sortType={memberSortType.DONOR}
              setSortType={updateSortType}
              setCount={setDonorCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.memberCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Članovi" count={memberCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.MEMBER);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateMemberRecordPaging(page)}
              pagingContextIndex={memberRecordPaging}
              memberType={MemberType.MEMBER}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.MEMBER}
              sortType={memberSortType.MEMBER}
              setSortType={updateSortType}
              setCount={setMemberCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.partnerCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Partneri" count={partnerCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.PARTNER);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updatePartnerRecordPaging(page)}
              pagingContextIndex={partnerRecordPaging}
              memberType={MemberType.PARTNER}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.PARTNER}
              sortType={memberSortType.PARTNER}
              setSortType={updateSortType}
              setCount={setPartnerCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.employeeCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Zaposlenici" count={employeeCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.EMPLOYEE);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateEmployeeRecordPaging(page)}
              pagingContextIndex={employeeRecordPaging}
              memberType={MemberType.EMPLOYEE}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.EMPLOYEE}
              sortType={memberSortType.EMPLOYEE}
              setSortType={updateSortType}
              setCount={setEmployeeCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.externalCollaboratorCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Vanjski suradnici" count={externalCollaboratorCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.EXTERNAL_COLLABORATOR);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateExternalCollaboratorRecordPaging(page)}
              pagingContextIndex={externalCollaboratorRecordPaging}
              memberType={MemberType.EXTERNAL_COLLABORATOR}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.EXTERNAL_COLLABORATOR}
              sortType={memberSortType.EXTERNAL_COLLABORATOR}
              setSortType={updateSortType}
              setCount={setExternalCollaboratorCount}
            />
          </Tabs.Tab>
        )}
        {fixedCount.othersCount > 0 && (
          <Tabs.Tab
            label={<TabLabel title="Ostali" count={othersCount} />}
            onClick={(tabIndex) => {
              setTabData(tabIndex, MemberType.OTHERS);
            }}
          >
            <MemberList
              updatePagingContextIndex={(page: Paging) => updateOtherRecordPaging(page)}
              pagingContextIndex={otherRecordPaging}
              memberType={MemberType.OTHERS}
              setMemberFilterValues={setFilerValuesForType}
              setExportQuery={setExportQuery}
              memberFilterValues={memberFilterValues.OTHERS}
              sortType={memberSortType.OTHERS}
              setSortType={updateSortType}
              setCount={setOthersCount}
            />
          </Tabs.Tab>
        )}
      </Tabs>
    </>
  );
}
